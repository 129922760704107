import { useAlert } from '../context';
import Alert from './Alert';

const AlertContainer = () => {
	const { alerts, removeAlert } = useAlert();

	return (
		<div className='fixed bottom-0 right-0 m-4 flex flex-col items-end justify-center gap-4'>
			{alerts?.map((alert, idx) => (
				<Alert key={idx} type={alert?.type} message={alert?.message} onClose={() => removeAlert(alert?.id)} />
			))}
		</div>
	);
};

export default AlertContainer;
