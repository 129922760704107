import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTitle } from 'react-use';
import { AuthContext, DataContext } from '../../context';
import { useInputWithError, useAlertHandling, ErrorHandling, formatPhoneNumber, generateStudentId } from '../../utils';
import { Loader, MainContainer } from '../../components';

const NewStudent = () => {
	useTitle('New Student | Grade Portal');

	const { currentUser } = useContext(AuthContext);
	const { appData, countryList, divRef, handleScroll, currentSchool, schoolLevelGroups, enrollmentStatusOptions, currentYear, gradeLevels, months, schools, createNewStudent } = useContext(DataContext);
	const { showAlert } = useAlertHandling();

	const [canAccessStudent, setCanAccessStudent] = useState(false);

	useEffect(() => {
		if (currentUser?.data?.userType === 'principal' || currentUser?.data?.userType === 'admin') {
			setCanAccessStudent(true);
		} else {
			setCanAccessStudent(false);
		}
	}, [currentUser?.data?.userType]);

	const [handleNewStudentLoading, setHandleNewStudentLoading] = useState(false);

	const groupedSchools = schools.reduce((acc, school) => {
		const groupName = schoolLevelGroups[school.level] || 'Other';

		acc[groupName] = acc[groupName] || [];
		acc[groupName].push(school);

		return acc;
	}, {});

	const [studentId, setStudentId] = useState(null);
	const { value: currentSchoolId, setValue: setCurrentSchoolId, error: currentSchoolIdError, setError: setCurrentSchoolIdError } = useInputWithError(null);

	const { value: firstName, setValue: setFirstName, error: firstNameError, setError: setFirstNameError } = useInputWithError('');
	const { value: middleName, setValue: setMiddleName, error: middleNameError, setError: setMiddleNameError } = useInputWithError(null);
	const { value: lastName, setValue: setLastName, error: lastNameError, setError: setLastNameError } = useInputWithError('');
	const [sex, setSex] = useState(null);

	const { value: emailAddress, setValue: setEmailAddress, error: emailAddressError, setError: setEmailAddressError } = useInputWithError('');
	const { value: mobilePhone, setValue: setMobilePhone, error: mobilePhoneError, setError: setMobilePhoneError } = useInputWithError(null, null);
	const { value: homePhone, setValue: setHomePhone, error: homePhoneError, setError: setHomePhoneError } = useInputWithError(null);

	const { value: addressStreet, setValue: setAddressStreet, error: addressStreetError, setError: setAddressStreetError } = useInputWithError(null);
	const { value: addressCommunity, setValue: setAddressCommunity, error: addressCommunityError, setError: setAddressCommunityError } = useInputWithError(null);
	const { value: addressDistrict, setValue: setAddressDistrict, error: addressDistrictError, setError: setAddressDistrictError } = useInputWithError(null);
	const { value: addressCountry, setValue: setAddressCountry, error: addressCountryError, setError: setAddressCountryError } = useInputWithError(null);

	const { value: dateOfBirthDay, setValue: setDateOfBirthDay, error: dateOfBirthDayError, setError: setDateOfBirthDayError } = useInputWithError(null);
	const { value: dateOfBirthMonth, setValue: setDateOfBirthMonth, error: dateOfBirthMonthError, setError: setDateOfBirthMonthError } = useInputWithError(null);
	const { value: dateOfBirthYear, setValue: setDateOfBirthYear, error: dateOfBirthYearError, setError: setDateOfBirthYearError } = useInputWithError(null);

	const [entryYear, setEntryYear] = useState(null);
	const [gradeLevel, setGradeLevel] = useState(null);
	const [enrollmentStatus, setEnrollmentStatus] = useState(null);
	const [graduationYear, setGraduationYear] = useState(null);
	const [expectedGraduationYear, setExpectedGraduationYear] = useState(null);

	const { value: fatherFirstName, setValue: setFatherFirstName, error: fatherFirstNameError, setError: setFatherFirstNameError } = useInputWithError(null);
	const { value: fatherLastName, setValue: setFatherLastName, error: fatherLastNameError, setError: setFatherLastNameError } = useInputWithError(null);
	const { value: fatherEmailAddress, setValue: setFatherEmailAddress, error: fatherEmailAddressError, setError: setFatherEmailAddressError } = useInputWithError(null);
	const { value: fatherMobilePhone, setValue: setFatherMobilePhone, error: fatherMobilePhoneError, setError: setFatherMobilePhoneError } = useInputWithError(null);

	const { value: motherFirstName, setValue: setMotherFirstName, error: motherFirstNameError, setError: setMotherFirstNameError } = useInputWithError(null);
	const { value: motherLastName, setValue: setMotherLastName, error: motherLastNameError, setError: setMotherLastNameError } = useInputWithError(null);
	const { value: motherEmailAddress, setValue: setMotherEmailAddress, error: motherEmailAddressError, setError: setMotherEmailAddressError } = useInputWithError(null);
	const { value: motherMobilePhone, setValue: setMotherMobilePhone, error: motherMobilePhoneError, setError: setMotherMobilePhoneError } = useInputWithError(null);

	const handleNewStudent = async (e) => {
		e.preventDefault();
		setHandleNewStudentLoading(true);

		let newStudentData = {
			userType: 'student',
			uid: null,
			id: studentId,
			name: {
				title: null,
				first: firstName,
				middle: middleName,
				last: lastName,
			},
			emailAddress: emailAddress,
			mobilePhone: mobilePhone,
			homePhone: homePhone,
			dateOfBirth: dateOfBirthDay && dateOfBirthMonth && dateOfBirthYear ? `${dateOfBirthDay}-${dateOfBirthMonth}-${dateOfBirthYear}` : null,
			sex: sex,
			address: {
				street: addressStreet,
				community: addressCommunity,
				district: addressDistrict,
				country: addressCountry,
			},
			school: {
				id: currentUser?.data?.userType === 'principal' ? currentUser?.data?.school?.id : currentSchoolId,
				classId: null,
				entryYear: entryYear,
				gradeLevel: gradeLevel,
				enrollmentStatus: enrollmentStatus,
				graduationYear: graduationYear,
				expectedGraduationYear: expectedGraduationYear,
			},
			grades: [],
			parents: {
				father: {
					name: {
						first: fatherFirstName,
						last: fatherLastName,
					},
					emailAddress: fatherEmailAddress,
					mobilePhone: fatherMobilePhone,
				},
				mother: {
					name: {
						first: motherFirstName,
						last: motherLastName,
					},
					emailAddress: motherEmailAddress,
					mobilePhone: motherMobilePhone,
				},
			},
			preferences: {
				theme: 'system',
			},
			registrationDate: new Date().toISOString().split('T')[0],
			addedBy: currentUser?.data?.id,
		};

		if (
			(await ErrorHandling.handleStudentFirstNameError(newStudentData?.name?.first, setFirstNameError, showAlert)) ||
			(await ErrorHandling.handleStudentMiddleNameError(newStudentData?.name?.middle, setMiddleNameError, showAlert)) ||
			(await ErrorHandling.handleStudentLastNameError(newStudentData?.name?.last, setLastNameError, showAlert)) ||
			(await ErrorHandling.handleStudentEmailAddressError(newStudentData?.emailAddress, setEmailAddressError, showAlert)) ||
			(await ErrorHandling.handleStudentMobilePhoneError(newStudentData?.mobilePhone, setMobilePhoneError, setMobilePhone, formatPhoneNumber, showAlert)) ||
			(await ErrorHandling.handleStudentHomePhoneError(newStudentData?.homePhone, setHomePhoneError, setHomePhone, formatPhoneNumber, showAlert)) ||
			(await ErrorHandling.handleStudentDateOfBirthError(dateOfBirthDay, setDateOfBirthDayError, dateOfBirthMonth, setDateOfBirthMonthError, dateOfBirthYear, setDateOfBirthYearError, showAlert)) ||
			(await ErrorHandling.handleStudentAddressError(newStudentData?.address?.street, setAddressStreetError, newStudentData?.address?.community, setAddressCommunityError, newStudentData?.address?.district, setAddressDistrictError, newStudentData?.address?.country, setAddressCountryError, showAlert)) ||
			(await ErrorHandling.handleStudentCurrentSchoolError(newStudentData?.school?.id, setCurrentSchoolIdError, showAlert)) ||
			(await ErrorHandling.handleStudentMotherDetailsError(
				newStudentData?.parents?.mother?.name?.first,
				setMotherFirstNameError,
				newStudentData?.parents?.mother?.name?.last,
				setMotherLastNameError,
				newStudentData?.parents?.mother?.emailAddress,
				setMotherEmailAddressError,
				newStudentData?.parents?.mother?.mobilePhone,
				setMotherMobilePhoneError,
				formatPhoneNumber,
				setMotherMobilePhone,
				showAlert
			)) ||
			(await ErrorHandling.handleStudentFatherDetailsError(
				newStudentData?.parents?.father?.name?.first,
				setFatherFirstNameError,
				newStudentData?.parents?.father?.name?.last,
				setFatherLastNameError,
				newStudentData?.parents?.father?.emailAddress,
				setFatherEmailAddressError,
				newStudentData?.parents?.father?.mobilePhone,
				setFatherMobilePhoneError,
				formatPhoneNumber,
				setFatherMobilePhone,
				showAlert
			))
		) {
			setHandleNewStudentLoading(false);
		} else {
			try {
				if (newStudentData?.id === null) {
					const newStudentId = generateStudentId(firstName, lastName);
					setStudentId(newStudentId);

					newStudentData = {
						...newStudentData,
						id: newStudentId,
					};
				}

				if (newStudentData?.school?.id === null) {
					newStudentData = {
						...newStudentData,
						school: {
							id: currentSchoolId,
							classId: null,
							entryYear: entryYear,
							gradeLevel: gradeLevel,
							enrollmentStatus: enrollmentStatus,
							graduationYear: graduationYear,
							expectedGraduationYear: expectedGraduationYear,
						},
					};
				}

				if (dateOfBirthDay && dateOfBirthMonth && dateOfBirthYear) {
					newStudentData = {
						...newStudentData,
						dateOfBirth: dateOfBirthDay && dateOfBirthMonth && dateOfBirthYear ? `${dateOfBirthDay}-${dateOfBirthMonth}-${dateOfBirthYear}` : null,
					};
				}

				if (newStudentData?.addedBy === null) {
					newStudentData = {
						...newStudentData,
						addedBy: currentUser?.data?.id,
					};
				}

				await createNewStudent(newStudentData);

				setHandleNewStudentLoading(false);
				window.location.href = '/students';
			} catch (error) {
				if (appData.debugMode) {
					console.error(error);
				}

				showAlert('error', 'An unknown error occurred');
			}
		}

		setHandleNewStudentLoading(false);
	};

	if (!canAccessStudent) {
		return (
			<MainContainer divRef={divRef} handleScroll={handleScroll}>
				<section className='bg-theme-white py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
					<div className='mx-auto max-w-screen-sm text-center'>
						<h1 className='mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-theme-gray-800'>403</h1>

						<p className='mb-4 text-3xl tracking-tight font-bold text-blue-500 md:text-4xl'>Oops! Access Denied</p>

						<p className='mb-8 text-lg text-theme-gray-600'>It seems that the page you're trying to access isn't available.</p>

						<Link to='/' className='inline-block shrink-0 rounded-md border px-5 py-2.5 text-sm font-medium focus:outline-none focus:ring text-white border-blue-500 bg-blue-500 hover:bg-transparent hover:text-blue-500 active:text-blue-500'>
							Back to Homepage
						</Link>
					</div>
				</section>
			</MainContainer>
		);
	}

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<div className='flex flex-row items-center justify-between'>
					<div className='flex flex-row items-center justify-start gap-4'>
						<h3 className='text-lg font-bold text-theme-gray-800 sm:text-xl'>New Student</h3>
					</div>
				</div>
			</section>

			<section className='w-full bg-theme-white'>
				<div className='w-full mt-6'>
					<form autoComplete='off'>
						<h3 className='text-theme-gray-800 text-base font-semibold'>1. Student Information</h3>

						<h5 className='text-blue-500 text-sm font-semibold mt-4'>Student Details</h5>

						<div className='grid grid-cols-12 gap-4 mt-4'>
							<div className='w-full col-span-12 sm:col-span-6'>
								<label htmlFor='firstName' className='block text-sm font-medium text-theme-gray-800'>
									First name
									<span className='text-red-500 ml-1'>*</span>
								</label>

								<input
									type='text'
									onChange={(e) => {
										e.preventDefault();
										setFirstName(e.target.value);
									}}
									className={`mt-1 w-full rounded-md border ${firstNameError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='firstName'
									placeholder='John'
									required
								/>

								{firstNameError && <span className='mt-2 text-sm text-red-500'>{firstNameError}</span>}
							</div>

							<div className='w-full col-span-12 sm:col-span-6'>
								<label htmlFor='middleName' className='block text-sm font-medium text-theme-gray-800'>
									Middle name
								</label>

								<input
									type='text'
									onChange={(e) => {
										e.preventDefault();
										setMiddleName(e.target.value);
										setMiddleNameError(null);
									}}
									className={`mt-1 w-full rounded-md border ${middleNameError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='middleName'
									placeholder='Peter'
								/>

								{middleNameError && <span className='mt-2 text-sm text-red-500'>{middleNameError}</span>}
							</div>

							<div className='w-full col-span-12 sm:col-span-6'>
								<label htmlFor='lastName' className='block text-sm font-medium text-theme-gray-800'>
									Last name
									<span className='text-red-500 ml-1'>*</span>
								</label>

								<input
									type='text'
									onChange={(e) => {
										e.preventDefault();
										setLastName(e.target.value);
									}}
									className={`mt-1 w-full rounded-md border ${lastNameError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='lastName'
									placeholder='Doe'
									required
								/>

								{lastNameError && <span className='mt-2 text-sm text-red-500'>{lastNameError}</span>}
							</div>

							<div className='w-full col-span-12 sm:col-span-6'>
								<label htmlFor='sex' className='block text-sm font-medium text-theme-gray-800'>
									Sex
								</label>

								<select
									onChange={(e) => {
										e.preventDefault();
										setSex(e.target.value);
									}}
									defaultValue={sex || ''}
									className={`mt-1 w-full rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='sex'>
									<option value='' disabled>
										Select a sex
									</option>

									<option value='M'>M - Male</option>
									<option value='F'>F - Female</option>
								</select>
							</div>

							<div className='w-full col-span-12 sm:col-span-6 lg:col-span-4'>
								<label htmlFor='emailAddress' className='block text-sm font-medium text-theme-gray-800'>
									Email address
									<span className='text-red-500 ml-1'>*</span>
								</label>

								<input
									type='email'
									onChange={(e) => {
										e.preventDefault();
										setEmailAddress(e.target.value);
									}}
									className={`mt-1 w-full rounded-md border ${emailAddressError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='emailAddress'
									placeholder='john.doe@school.edu.lc'
									required
								/>

								{emailAddressError && <span className='mt-2 text-sm text-red-500'>{emailAddressError}</span>}
							</div>

							<div className='w-full col-span-12 sm:col-span-6 lg:col-span-4'>
								<label htmlFor='mobilePhone' className='block text-sm font-medium text-theme-gray-800'>
									Mobile Phone
								</label>

								<input
									type='tel'
									onChange={(e) => {
										e.preventDefault();
										setMobilePhone(e.target.value);
									}}
									className={`mt-1 w-full rounded-md border ${mobilePhoneError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='mobilePhone'
									placeholder='1-758-123-4567'
								/>

								{mobilePhoneError && <span className='mt-2 text-sm text-red-500'>{mobilePhoneError}</span>}
							</div>

							<div className='w-full col-span-12 sm:col-span-6 lg:col-span-4'>
								<label htmlFor='homePhone' className='block text-sm font-medium text-theme-gray-800'>
									Home phone
								</label>

								<input
									type='tel'
									onChange={(e) => {
										e.preventDefault();
										setHomePhone(e.target.value);
									}}
									className={`mt-1 w-full rounded-md border ${homePhoneError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='homePhone'
									placeholder='1-758-123-4567'
								/>

								{homePhoneError && <span className='mt-2 text-sm text-red-500'>{homePhoneError}</span>}
							</div>
						</div>

						<h5 className='text-blue-500 text-sm font-semibold mt-4'>Date of Birth</h5>

						<div className='grid grid-cols-12 gap-4 mt-4'>
							<div className='w-full col-span-12 sm:col-span-4'>
								<label htmlFor='dateOfBirthDay' className='block text-sm font-medium text-theme-gray-800'>
									Day
								</label>

								<select
									onChange={(e) => {
										e.preventDefault();
										setDateOfBirthDay(e.target.value);
									}}
									defaultValue={dateOfBirthDay || ''}
									className={`mt-1 w-full rounded-md border ${dateOfBirthDayError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='dateOfBirthDay'>
									<option value='' disabled>
										Select a day
									</option>

									{Array.from(
										{
											length: 31,
										},
										(_, index) => index + 1
									).map((day, idx) => {
										return (
											<option key={idx} value={day}>
												{day}
											</option>
										);
									})}
								</select>

								{dateOfBirthDayError && <span className='mt-2 text-sm text-red-500'>{dateOfBirthDayError}</span>}
							</div>

							<div className='w-full col-span-12 sm:col-span-4'>
								<label htmlFor='dateOfBirthMonth' className='block text-sm font-medium text-theme-gray-800'>
									Month
								</label>

								<select
									onChange={(e) => {
										e.preventDefault();
										setDateOfBirthMonth(e.target.value);
									}}
									defaultValue={dateOfBirthMonth || ''}
									className={`mt-1 w-full rounded-md border ${dateOfBirthMonthError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='dateOfBirthMonth'>
									<option value='' disabled>
										Select a month
									</option>

									{months?.map((month, idx) => {
										return (
											<option key={idx} value={month}>
												{month}
											</option>
										);
									})}
								</select>

								{dateOfBirthMonthError && <span className='mt-2 text-sm text-red-500'>{dateOfBirthMonthError}</span>}
							</div>

							<div className='w-full col-span-12 sm:col-span-4'>
								<label htmlFor='dateOfBirthYear' className='block text-sm font-medium text-theme-gray-800'>
									Year
								</label>

								<select
									onChange={(e) => {
										e.preventDefault();
										setDateOfBirthYear(e.target.value);
									}}
									defaultValue={dateOfBirthYear || ''}
									className={`mt-1 w-full rounded-md border ${dateOfBirthYearError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='dateOfBirthYear'>
									<option value='' disabled>
										Select a year
									</option>

									{Array.from(
										{
											length: currentYear - 1990,
										},
										(_, index) => 1990 + index
									).map((year, idx) => {
										return (
											<option key={idx} value={year}>
												{year}
											</option>
										);
									})}
								</select>

								{dateOfBirthYearError && <span className='mt-2 text-sm text-red-500'>{dateOfBirthYearError}</span>}
							</div>
						</div>

						<h5 className='text-blue-500 text-sm font-semibold mt-4'>Address</h5>

						<div className='grid grid-cols-12 gap-4 mt-4'>
							<div className='w-full col-span-12 sm:col-span-6 lg:col-span-3'>
								<label htmlFor='addressStreet' className='block text-sm font-medium text-theme-gray-800'>
									Street
								</label>

								<input
									type='text'
									onChange={(e) => {
										e.preventDefault();
										setAddressStreet(e.target.value);
									}}
									className={`mt-1 w-full rounded-md border ${addressStreetError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='addressStreet'
									placeholder='123 Main St.'
								/>

								{addressStreetError && <span className='mt-2 text-sm text-red-500'>{addressStreetError}</span>}
							</div>

							<div className='w-full col-span-12 sm:col-span-6 lg:col-span-3'>
								<label htmlFor='addressCommunity' className='block text-sm font-medium text-theme-gray-800'>
									Community
								</label>

								<input
									type='text'
									onChange={(e) => {
										e.preventDefault();
										setAddressCommunity(e.target.value);
									}}
									className={`mt-1 w-full rounded-md border ${addressCommunityError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='addressCommunity'
									placeholder='Fakeville'
								/>

								{addressCommunityError && <span className='mt-2 text-sm text-red-500'>{addressCommunityError}</span>}
							</div>

							<div className='w-full col-span-12 sm:col-span-6 lg:col-span-3'>
								<label htmlFor='addressDistrict' className='block text-sm font-medium text-theme-gray-800'>
									District
								</label>

								<input
									type='text'
									onChange={(e) => {
										e.preventDefault();
										setAddressDistrict(e.target.value);
									}}
									className={`mt-1 w-full rounded-md border ${addressDistrictError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='addressDistrict'
									placeholder='Castries'
								/>

								{addressDistrictError && <span className='mt-2 text-sm text-red-500'>{addressDistrictError}</span>}
							</div>

							<div className='w-full col-span-12 sm:col-span-6 lg:col-span-3'>
								<label htmlFor='addressCountry' className='block text-sm font-medium text-theme-gray-800'>
									Country
								</label>

								<select
									onChange={(e) => {
										e.preventDefault();
										setAddressCountry(e.target.value);
									}}
									defaultValue={addressCountry || ''}
									className={`mt-1 w-full rounded-md border ${addressCountryError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='addressCountry'>
									<option value='' disabled>
										Select a country
									</option>

									{countryList.map((country, idx) => {
										return (
											<option key={idx} value={country}>
												{country}
											</option>
										);
									})}
								</select>

								{addressCountryError && <span className='mt-2 text-sm text-red-500'>{addressCountryError}</span>}
							</div>
						</div>

						<h5 className='text-blue-500 text-sm font-semibold mt-4'>School Details</h5>

						<div className='grid grid-cols-12 gap-4 mt-4'>
							<div className='w-full col-span-12 '>
								<label htmlFor='currentSchoolId' className='block text-sm font-medium text-theme-gray-800'>
									School
									<span className='text-red-500 ml-1'>*</span>
								</label>

								<select
									onChange={(e) => {
										e.preventDefault();
										setCurrentSchoolId(e.target.value);
									}}
									defaultValue={currentUser?.data?.userType === 'principal' ? currentUser?.data?.school?.id : currentSchool || ''}
									className={`mt-1 w-full rounded-md border ${currentSchoolIdError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='currentSchoolId'
									required
									disabled={currentUser?.data?.userType === 'principal'}>
									<option value='' disabled>
										Select a school
									</option>

									{Object.entries(groupedSchools).map(([group, schools]) => (
										<optgroup label={group} key={group}>
											{schools.map((school, idx) => (
												<option key={idx} value={school.id}>
													{school.name}
												</option>
											))}
										</optgroup>
									))}
								</select>

								{currentSchoolIdError && <span className='mt-2 text-sm text-red-500'>{currentSchoolIdError}</span>}
							</div>

							{currentSchoolId && (
								<div className='w-full col-span-12 sm:col-span-6'>
									<label htmlFor='enrollmentStatus' className='block text-sm font-medium text-theme-gray-800'>
										Enrollment Status
									</label>

									<select
										onChange={(e) => {
											e.preventDefault();
											setEnrollmentStatus(e.target.value);
										}}
										defaultValue={enrollmentStatus || ''}
										className='mt-1 w-full rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'
										id='enrollmentStatus'>
										<option value='' disabled>
											Select an enrollment status
										</option>

										{enrollmentStatusOptions.map((enrollmentStatus, idx) => (
											<option key={idx} value={enrollmentStatus.name}>
												{enrollmentStatus.name} - {enrollmentStatus.desc}
											</option>
										))}
									</select>
								</div>
							)}

							{enrollmentStatus && (
								<div className='w-full col-span-12 sm:col-span-6'>
									<label htmlFor='entryYear' className='block text-sm font-medium text-theme-gray-800'>
										Entry year
									</label>

									<select
										onChange={(e) => {
											e.preventDefault();
											setEntryYear(e.target.value);
										}}
										defaultValue={entryYear || ''}
										className='mt-1 w-full rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'
										id='entryYear'>
										<option value='' disabled>
											Select a year
										</option>

										{Array.from(
											{
												length: currentYear - 2000 + 5,
											},
											(_, index) => 2000 + index
										).map((year, idx) => (
											<option key={idx} value={year}>
												{year}
											</option>
										))}
									</select>
								</div>
							)}

							{enrollmentStatus === 'Active' ? (
								<div className='w-full col-span-12 sm:col-span-6'>
									<label htmlFor='gradeLevel' className='block text-sm font-medium text-theme-gray-800'>
										Grade level
									</label>

									<select
										onChange={(e) => {
											e.preventDefault();
											setGradeLevel(e.target.value);
										}}
										defaultValue={gradeLevel || ''}
										className='mt-1 w-full rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'
										id='gradeLevel'>
										<option value='' disabled>
											Select a grade
										</option>

										{(schools?.find((school) => school?.id === currentSchoolId)?.level === 1 ? gradeLevels[1] : gradeLevels[2])?.map((grade, idx) => (
											<option key={idx} value={grade?.level}>
												{grade?.name}
											</option>
										))}
									</select>
								</div>
							) : null}

							{enrollmentStatus === 'Active' ? (
								<div className='w-full col-span-12 sm:col-span-6'>
									<label htmlFor='expectedGraduationYear' className='block text-sm font-medium text-theme-gray-800'>
										Expected graduation year
									</label>

									<select
										onChange={(e) => {
											e.preventDefault();
											setExpectedGraduationYear(e.target.value);
										}}
										defaultValue={expectedGraduationYear || ''}
										className='mt-1 w-full rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'
										id='expectedGraduationYear'>
										<option value='' disabled>
											Select a year
										</option>

										{Array.from(
											{
												length: 10,
											},
											(_, index) => currentYear + index
										)?.map((year, idx) => (
											<option key={idx} value={year}>
												{year}
											</option>
										))}
									</select>
								</div>
							) : enrollmentStatus === 'Graduated' ? (
								<div className='w-full col-span-12 sm:col-span-6'>
									<label htmlFor='graduationYear' className='block text-sm font-medium text-theme-gray-800'>
										Graduation year
									</label>

									<select
										onChange={(e) => {
											e.preventDefault();
											setGraduationYear(e.target.value);
										}}
										defaultValue={graduationYear || ''}
										className='mt-1 w-full rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'
										id='graduationYear'>
										<option value='' disabled>
											Select a year
										</option>

										{Array.from(
											{
												length: currentYear - 2000 + 1,
											},
											(_, index) => 2000 + index
										)?.map((year, idx) => (
											<option key={idx} value={year}>
												{year}
											</option>
										))}
									</select>
								</div>
							) : null}
						</div>

						<hr className='my-8' />

						<h3 className='text-theme-gray-800 text-base font-semibold'>2. Parent Information</h3>

						<h5 className='text-blue-500 text-sm font-semibold mt-4'>Mother Details</h5>

						<div className='grid grid-cols-12 gap-4 mt-4'>
							<div className='w-full col-span-12 sm:col-span-6'>
								<label htmlFor='motherFirstName' className='block text-sm font-medium text-theme-gray-800'>
									First name
								</label>

								<input
									type='text'
									onChange={(e) => {
										e.preventDefault();
										setMotherFirstName(e.target.value);
									}}
									className={`mt-1 w-full rounded-md border ${motherFirstNameError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='motherFirstName'
									placeholder='Jane'
								/>

								{motherFirstNameError && <span className='mt-2 text-sm text-red-500'>{motherFirstNameError}</span>}
							</div>

							<div className='w-full col-span-12 sm:col-span-6'>
								<label htmlFor='motherLastName' className='block text-sm font-medium text-theme-gray-800'>
									Last name
								</label>

								<input
									type='text'
									onChange={(e) => {
										e.preventDefault();
										setMotherLastName(e.target.value);
									}}
									className={`mt-1 w-full rounded-md border ${motherLastNameError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='motherLastName'
									placeholder='Doe'
								/>

								{motherLastNameError && <span className='mt-2 text-sm text-red-500'>{motherLastNameError}</span>}
							</div>

							<div className='w-full col-span-12 sm:col-span-6'>
								<label htmlFor='motherEmailAddress' className='block text-sm font-medium text-theme-gray-800'>
									Email address
								</label>

								<input
									type='email'
									onChange={(e) => {
										e.preventDefault();
										setMotherEmailAddress(e.target.value);
									}}
									className={`mt-1 w-full rounded-md border ${motherEmailAddressError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='motherEmailAddress'
									placeholder='jane.doe@mail.com'
								/>

								{motherEmailAddressError && <span className='mt-2 text-sm text-red-500'>{motherEmailAddressError}</span>}
							</div>

							<div className='w-full col-span-12 sm:col-span-6'>
								<label htmlFor='motherMobilePhone' className='block text-sm font-medium text-theme-gray-800'>
									Mobile Phone
								</label>

								<input
									type='tel'
									onChange={(e) => {
										e.preventDefault();
										setMotherMobilePhone(e.target.value);
									}}
									className={`mt-1 w-full rounded-md border ${motherMobilePhoneError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='motherMobilePhone'
									placeholder='1-758-123-4567'
								/>

								{motherMobilePhoneError && <span className='mt-2 text-sm text-red-500'>{motherMobilePhoneError}</span>}
							</div>
						</div>

						<h5 className='text-blue-500 text-sm font-semibold mt-4'>Father Details</h5>

						<div className='grid grid-cols-12 gap-4 mt-4'>
							<div className='w-full col-span-12 sm:col-span-6'>
								<label htmlFor='fatherFirstName' className='block text-sm font-medium text-theme-gray-800'>
									First name
								</label>

								<input
									type='text'
									onChange={(e) => {
										e.preventDefault();
										setFatherFirstName(e.target.value);
									}}
									className={`mt-1 w-full rounded-md border ${fatherFirstNameError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='fatherFirstName'
									placeholder='John'
								/>

								{fatherFirstNameError && <span className='mt-2 text-sm text-red-500'>{fatherFirstNameError}</span>}
							</div>

							<div className='w-full col-span-12 sm:col-span-6'>
								<label htmlFor='fatherLastName' className='block text-sm font-medium text-theme-gray-800'>
									Last name
								</label>

								<input
									type='text'
									onChange={(e) => {
										e.preventDefault();
										setFatherLastName(e.target.value);
									}}
									className={`mt-1 w-full rounded-md border ${fatherLastNameError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='fatherLastName'
									placeholder='Doe'
								/>

								{fatherLastNameError && <span className='mt-2 text-sm text-red-500'>{fatherLastNameError}</span>}
							</div>

							<div className='w-full col-span-12 sm:col-span-6'>
								<label htmlFor='fatherEmailAddress' className='block text-sm font-medium text-theme-gray-800'>
									Email address
								</label>

								<input
									type='email'
									onChange={(e) => {
										e.preventDefault();
										setFatherEmailAddress(e.target.value);
									}}
									className={`mt-1 w-full rounded-md border ${fatherEmailAddressError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='fatherEmailAddress'
									placeholder='john.doe@mail.com'
								/>

								{fatherEmailAddressError && <span className='mt-2 text-sm text-red-500'>{fatherEmailAddressError}</span>}
							</div>

							<div className='w-full col-span-12 sm:col-span-6'>
								<label htmlFor='fatherMobilePhone' className='block text-sm font-medium text-theme-gray-800'>
									Mobile Phone
								</label>

								<input
									type='tel'
									onChange={(e) => {
										e.preventDefault();
										setFatherMobilePhone(e.target.value);
									}}
									className={`mt-1 w-full rounded-md border ${fatherMobilePhoneError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='fatherMobilePhone'
									placeholder='1-758-123-4567'
								/>

								{fatherMobilePhoneError && <span className='mt-2 text-sm text-red-500'>{fatherMobilePhoneError}</span>}
							</div>
						</div>

						<hr className='my-8' />

						<div className='w-full mt-4 col-span-6 flex flex-col gap-4 sm:flex-row sm:items-center'>
							<button
								type='submit'
								onClick={handleNewStudent}
								disabled={handleNewStudentLoading}
								className={`inline-flex items-center justify-center shrink-0 rounded-md border border-blue-500 bg-blue-500 text-white px-4 py-2 gap-2 text-sm font-medium ${handleNewStudentLoading ? 'opacity-60 cursor-not-allowed' : 'hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'}`}>
								{handleNewStudentLoading ? (
									<div className='flex items-center justify-center'>
										<Loader />
									</div>
								) : (
									'Create New Student'
								)}
							</button>
						</div>
					</form>
				</div>
			</section>
		</MainContainer>
	);
};

export default NewStudent;
