import React, { useContext } from 'react';
import { useTitle } from 'react-use';
import { Link } from 'react-router-dom';
import { DataContext } from '../../context';
import { MainContainer } from '../../components';

const AuthenticatedNotFound = () => {
	useTitle('404 | Grade Portal');

	const { divRef, handleScroll } = useContext(DataContext);

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='bg-theme-white py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
				<div className='mx-auto max-w-screen-sm text-center'>
					<h1 className='mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-theme-gray-800'>404</h1>

					<p className='mb-4 text-3xl tracking-tight font-bold text-blue-500 md:text-4xl'>Oops! Page Not Found</p>

					<p className='mb-8 text-lg text-theme-gray-600'>
						It seems you've stumbled upon a page that doesn't exist. Don't worry, you can explore the home page{' '}
						<Link to='/' className='focus:outline-none focus:ring text-blue-500 hover:opacity-80 active:opacity-80'>
							here
						</Link>
						.
					</p>

					<Link to='/' className='inline-block shrink-0 rounded-md border px-5 py-2.5 text-sm font-medium focus:outline-none focus:ring text-white border-blue-500 bg-blue-500 hover:bg-transparent hover:text-blue-500 active:text-blue-500'>
						Back to Homepage
					</Link>
				</div>
			</section>
		</MainContainer>
	);
};

export default AuthenticatedNotFound;
