import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { DataContext, AuthContext } from '../../context';
import { Loader, GreetingMessage, MainContainer, Table, TableHeadItem, TableBodyItem } from '../../components';
import { numberToWord } from '../../utils';
import { ChatsCard } from '../../components';

ChartJS.register(ArcElement, Tooltip, Legend);

const SchoolsTableContent = ({ school }) => {
	return (
		<>
			<TableBodyItem type='primary' contents={school?.id ? <Link to={`/schools/${school?.id}`}>{school?.id}</Link> : 'N/A'} align='left' />

			<TableBodyItem type='default' contents={school?.name || 'N/A'} align='left' />

			<TableBodyItem type='default' contents={school?.level === '1' ? <span className='rounded-full px-2.5 py-0.5 text-xs bg-blue-500 text-white uppercase'>Primary</span> : school?.level === '2' ? <span className='rounded-full px-2.5 py-0.5 text-xs bg-yellow-500 text-white uppercase'>Secondary</span> : 'N/A'} align='center' />

			<TableBodyItem type='default' contents={school?.district ? numberToWord(school?.district) : 'N/A'} align='center' />
		</>
	);
};

const StudentsPerSchoolPieChart = ({ schools, users }) => {
	const [pieChartData, setPieChartData] = useState({
		labels: [],
		datasets: [
			{
				data: [],
				backgroundColor: [],
				borderColor: [],
				borderWidth: null,
			},
		],
	});

	useEffect(() => {
		if (schools) {
			const pieData = {
				labels: [],
				datasets: [
					{
						data: [],
						backgroundColor: ['#3b82f6', '#22c55e', '#f97316', ' #a855f7', '#eab308', '#14b8a6', '#f43f5e'],
						borderColor: ['#3b82f6', '#22c55e', '#f97316', ' #a855f7', '#eab308', '#14b8a6', '#f43f5e'],
						borderWidth: 1,
					},
				],
			};

			const filteredSchools = schools?.filter((school) => {
				const numStudentsInSchool = users?.filter((user) => user?.userType === 'student' && user?.school?.id === school?.id)?.length;
				return numStudentsInSchool > 0;
			});

			filteredSchools?.forEach((school) => {
				const numStudentsInSchool = users?.filter((user) => user?.userType === 'student' && user?.school?.id === school?.id)?.length;

				pieData?.labels?.push(school?.id);
				pieData?.datasets[0]?.data?.push(numStudentsInSchool);
			});

			setPieChartData(pieData);
		}
	}, [schools, users]);

	return (
		<Pie
			data={pieChartData}
			options={{
				plugins: {
					tooltip: {
						callbacks: {
							label: function (context) {
								const labelIndex = context.dataIndex;
								const numStudents = pieChartData?.datasets[0]?.data[labelIndex] || 0;

								return `Students: ${numStudents}`;
							},
						},
					},
				},
			}}
		/>
	);
};

const AdminDashboard = () => {
	useTitle('Ministry Dashboard | Grade Portal');

	const { currentUser } = useContext(AuthContext);
	const { appData, users, subjects, schools, assessments, divRef, handleScroll } = useContext(DataContext);

	const [numSchools, setNumSchools] = useState(null);
	const [numClasses, setNumClasses] = useState(null);
	const [numSubjects, setNumSubjects] = useState(null);
	const [numStudents, setNumStudents] = useState(null);
	const [numTeachers, setNumTeachers] = useState(null);
	const [numParents, setNumParents] = useState(null);

	const [numSchoolsLoading, setNumSchoolsLoading] = useState(true);
	const [numClassesLoading, setNumClassesLoading] = useState(true);
	const [numSubjectsLoading, setNumSubjectsLoading] = useState(true);
	const [numStudentsLoading, setNumStudentsLoading] = useState(true);
	const [numTeachersLoading, setNumTeachersLoading] = useState(true);
	const [numParentsLoading, setNumParentsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const fetchedSchools = await schools?.length;
				const fetchedSubjects = await subjects?.length;
				const fetchedStudents = await users?.filter((user) => user?.userType === 'student').length;
				const fetchedTeachers = await users?.filter((user) => user?.userType === 'teacher').length;
				const fetchedParents = await users?.filter((user) => user?.userType === 'parent').length;
				const fetchedClasses =
					schools?.reduce((acc, school) => {
						if (school?.classes) {
							return acc + school.classes.length;
						} else {
							return acc;
						}
					}, 0) ?? 0;

				setNumSchools(fetchedSchools);
				setNumClasses(fetchedClasses);
				setNumSubjects(fetchedSubjects);
				setNumStudents(fetchedStudents);
				setNumTeachers(fetchedTeachers);
				setNumParents(fetchedParents);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setNumSchoolsLoading(false);
			setNumClassesLoading(false);
			setNumSubjectsLoading(false);
			setNumStudentsLoading(false);
			setNumTeachersLoading(false);
			setNumParentsLoading(false);
		};

		fetchData();
	}, [currentUser?.data?.id, subjects, assessments, users, schools, appData?.debugMode]);

	const [sortedSchools, setSortedSchools] = useState(null);

	useEffect(() => {
		const sortSchools = () => {
			const _sortedSchools = schools?.slice().sort((a, b) => {
				const nameA = a?.name?.toUpperCase();
				const nameB = b?.name?.toUpperCase();

				if (nameA < nameB) {
					return -1;
				}

				if (nameA > nameB) {
					return 1;
				}

				return 0;
			});

			setSortedSchools(_sortedSchools);
		};

		sortSchools();
	}, [schools]);

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<h3 className='mb-6 text-2xl font-bold text-theme-gray-800'>
					<GreetingMessage currentUser={currentUser} />
				</h3>

				<div className='grid grid-cols-6 gap-6'>
					<div className='col-span-6 md:col-span-1 flex flex-col items-center justify-center bg-blue-500 rounded-lg h-62 w-62 p-8 shadow-lg'>
						<h3 className='text-5xl font-bold text-white'>{!numSchoolsLoading ? numSchools : <Loader size='lg' />}</h3>

						<p className='mt-2 text-white text-xl'>Schools</p>
					</div>

					<div className='col-span-6 md:col-span-1 flex flex-col items-center justify-center bg-blue-500 rounded-lg h-62 w-62 p-8 shadow-lg'>
						<h3 className='text-5xl font-bold text-white'>{!numClassesLoading ? numClasses : <Loader size='lg' />}</h3>

						<p className='mt-2 text-white text-xl'>Classes</p>
					</div>

					<div className='col-span-6 md:col-span-1 flex flex-col items-center justify-center bg-blue-500 rounded-lg h-62 w-62 p-8 shadow-lg'>
						<h3 className='text-5xl font-bold text-white'>{!numSubjectsLoading ? numSubjects : <Loader size='lg' />}</h3>

						<p className='mt-2 text-white text-xl'>Subjects</p>
					</div>

					<div className='col-span-6 md:col-span-1 flex flex-col items-center justify-center bg-blue-500 rounded-lg h-62 w-62 p-8 shadow-lg'>
						<h3 className='text-5xl font-bold text-white'>{!numStudentsLoading ? numStudents : <Loader size='lg' />}</h3>

						<p className='mt-2 text-white text-xl'>Students</p>
					</div>

					<div className='col-span-6 md:col-span-1 flex flex-col items-center justify-center bg-blue-500 rounded-lg h-62 w-62 p-8 shadow-lg'>
						<h3 className='text-5xl font-bold text-white'>{!numTeachersLoading ? numTeachers : <Loader size='lg' />}</h3>

						<p className='mt-2 text-white text-xl'>Teachers</p>
					</div>

					<div className='col-span-6 md:col-span-1 flex flex-col items-center justify-center bg-blue-500 rounded-lg h-62 w-62 p-8 shadow-lg'>
						<h3 className='text-5xl font-bold text-white'>{!numParentsLoading ? numParents : <Loader size='lg' />}</h3>

						<p className='mt-2 text-white text-xl'>Parents</p>
					</div>
				</div>
			</section>

			<section className='w-full bg-theme-white'>
				<div className='grid grid-cols-3 gap-6'>
					<div className='col-span-3 lg:col-span-2 grid grid-cols-6 gap-6'>
						<div className='col-span-6'>
							<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
								<div className='mb-4 flex flex-row items-center justify-between'>
									<h3 className='text-xl font-bold text-theme-gray-800'>Schools</h3>
								</div>

								<Table
									name='Schools'
									headContents={
										<tr>
											<TableHeadItem type='default' title='School ID' align='left' />

											<TableHeadItem type='default' title='Name' align='left' />

											<TableHeadItem type='default' title='Level' align='center' />

											<TableHeadItem type='default' title='District' align='center' />
										</tr>
									}
									bodyContents={({ item }) => <SchoolsTableContent school={item} />}
									sortedData={sortedSchools}
									filteredData={sortedSchools}
									dataLoading={sortedSchools}
									colSpan={4}
								/>
							</div>
						</div>
					</div>

					<div className='col-span-3 lg:col-span-1 flex flex-col gap-6'>
						<ChatsCard />

						<div className='w-full bg-theme-white rounded-lg border border-theme-gray-200 p-4 shadow-sm'>
							<div className='mb-4 flex flex-row items-center justify-between'>
								<h3 className='text-xl font-bold text-theme-gray-800'>Quick Actions</h3>
							</div>

							<div className='flex flex-row items-center justify-evenly'>
								<Link type='button' to='/chats' className='border border-theme-gray-200 p-2 rounded-lg shadow flex items-center justify-center hover:opacity-80'>
									<svg className='size-6 fill-theme-gray-800' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
										<path d='M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z' />
										<path fill-rule='evenodd' d='M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5' />
									</svg>
								</Link>

								<button type='button' className='border border-theme-gray-200 p-2 rounded-lg shadow flex items-center justify-center hover:opacity-80'>
									<svg className='size-6 fill-theme-gray-800' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
										<path d='M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5' />
										<path d='M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z' />
									</svg>
								</button>

								<Link type='button' to='/students/new' className='border border-theme-gray-200 p-2 rounded-lg shadow flex items-center justify-center hover:opacity-80'>
									<svg className='size-6 fill-theme-gray-800' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
										<path d='M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z' />
										<path fill-rule='evenodd' d='M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5' />
									</svg>
								</Link>

								<Link type='button' to='/settings' className='border border-theme-gray-200 p-2 rounded-lg shadow flex items-center justify-center hover:opacity-80'>
									<svg className='size-6 fill-theme-gray-800' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
										<path d='M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492M5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0' />
										<path d='M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115z' />
									</svg>
								</Link>
							</div>
						</div>

						<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
							<div className='mb-4 flex flex-row items-center justify-between'>
								<h3 className='text-xl font-bold text-theme-gray-800'>Students Per School</h3>
							</div>

							<StudentsPerSchoolPieChart schools={schools} users={users} />
						</div>
					</div>
				</div>
			</section>
		</MainContainer>
	);
};

export default AdminDashboard;
