import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Transition, Dialog, Combobox } from '@headlessui/react';
import { AuthContext, DataContext } from '../../context';
import { useAlertHandling } from '../../utils';
import Loader from '../Loader';

const NewChatModal = ({ modalState, setModalState }) => {
	const { currentUser } = useContext(AuthContext);
	const { appData, users, createNewChat } = useContext(DataContext);
	const { showAlert } = useAlertHandling();

	const [recipients, setRecipients] = useState([]);
	const [recipient, setRecipient] = useState(null);

	const [recipientsQuery, setRecipientsQuery] = useState('');

	const filteredRecipients =
		recipientsQuery === ''
			? recipients
			: recipients?.filter((recipient) => {
					const query = recipientsQuery.toLowerCase().replace(/\s+/g, '');

					const firstName = recipient?.name?.first.toLowerCase().replace(/\s+/g, '');
					const lastName = recipient?.name?.last.toLowerCase().replace(/\s+/g, '');
					const userType = recipient?.userType.toLowerCase().replace(/\s+/g, '');

					return firstName.includes(query) || lastName.includes(query) || userType.includes(query);
			  });

	const [handleNewChatLoading, setHandleNewChatLoading] = useState(false);

	useEffect(() => {
		const fetchRecipients = () => {
			try {
				const _recipients = users?.filter((user) => {
					if (currentUser?.data?.userType === 'student') {
						if (user?.userType === 'teacher' && user?.school?.id === currentUser?.data?.school?.id) {
							return true;
						}

						if (user?.userType === 'principal' && user?.school?.id === currentUser?.data?.school?.id) {
							return true;
						}

						return false;
					} else if (currentUser?.data?.userType === 'teacher') {
						if (user?.userType === 'student' && user?.school?.id === currentUser?.data?.school?.id) {
							return true;
						}

						if (user?.userType === 'principal' && user?.school?.id === currentUser?.data?.school?.id) {
							return true;
						}

						if (user?.userType === 'parent' && user?.school?.id === currentUser?.data?.school?.id) {
							return true;
						}

						return false;
					} else if (currentUser?.data?.userType === 'principal') {
						if (user?.userType === 'student' && user?.school?.id === currentUser?.data?.school?.id) {
							return true;
						}

						if (user?.userType === 'teacher' && user?.school?.id === currentUser?.data?.school?.id) {
							return true;
						}

						if (user?.userType === 'admin') {
							return true;
						}

						if (user?.userType === 'alumni' && user?.school?.id === currentUser?.data?.school?.id) {
							return true;
						}

						return false;
					} else if (currentUser?.data?.userType === 'admin') {
						if (user?.userType === 'student' || user?.userType === 'teacher' || user?.userType === 'admin') {
							return true;
						} else {
							return false;
						}
					} else if (currentUser?.data?.userType === 'parent') {
						if (user?.userType === 'teacher' || user?.userType === 'principal') {
							return true;
						} else {
							return false;
						}
					} else if (currentUser?.data?.userType === 'alumni') {
						if (user?.userType === 'principal' && user?.school?.id === currentUser?.data?.school?.id) {
							return true;
						}
					}

					return false;
				});

				setRecipients(_recipients);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}
		};

		fetchRecipients();
	}, [appData?.debugMode, currentUser?.data?.school?.id, currentUser?.data?.userType, users]);

	const handleNewChat = async (e) => {
		e.preventDefault();
		setHandleNewChatLoading(true);

		const newChatData = {
			messages: [],
			participants: [currentUser?.data?.id, recipient?.id],
		};

		try {
			if (!recipient?.id) {
				showAlert('error', 'An unknown error occurred');
			}

			const chatId = await createNewChat(newChatData);
			setModalState(false);

			window.location.href = `/chats/${chatId}`;
		} catch (err) {
			if (appData?.debugMode) {
				console.error(err);
			}

			showAlert('error', 'An unknown error occurred');
		}

		setHandleNewChatLoading(false);
	};

	return (
		<Transition appear show={modalState} as={Fragment}>
			<Dialog as='div' className='relative z-50' onClose={() => setModalState(false)}>
				<Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0' enterTo='opacity-100' leave='ease-in duration-200' leaveFrom='opacity-100' leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/25' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0 scale-95' enterTo='opacity-100 scale-100' leave='ease-in duration-200' leaveFrom='opacity-100 scale-100' leaveTo='opacity-0 scale-95'>
							<Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-xl bg-theme-white p-6 text-left align-middle shadow-xl transition-all duration-300 ease-in-out'>
								<Dialog.Title as='h3' className='text-lg font-medium leading-6 text-theme-gray-800'>
									Create New Chat
								</Dialog.Title>

								<div
									className='mt-2'
									style={{
										position: 'relative',
										zIndex: 1,
									}}>
									<p className='text-sm text-theme-gray-600'>Select a recipient</p>

									<Combobox value={recipient} onChange={setRecipient}>
										<div className='relative mt-2'>
											<div className='relative w-full cursor-default overflow-hidden border rounded-lg bg-theme-white border-theme-gray-200 text-theme-gray-800 text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-theme-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-500 sm:text-sm'>
												<Combobox.Input
													displayValue={(recipient) => {
														const capitalizedUserType = recipient?.userType?.charAt(0).toUpperCase() + recipient?.userType?.slice(1);
														return recipient ? `${recipient?.name?.first} ${recipient?.name?.last} (${capitalizedUserType})` : '';
													}}
													onChange={(e) => {
														e.preventDefault();
														setRecipientsQuery(e.target.value);
													}}
													placeholder='Search for a user...'
													className='w-full border-none bg-theme-white py-2 pl-3 pr-10 text-sm leading-5 text-theme-gray-800 focus:ring-0'
												/>

												<Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
													<svg className='h-5 w-5 text-theme-gray-600' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
														<path fillRule='evenodd' d='M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708m0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708' />
													</svg>
												</Combobox.Button>
											</div>

											<Transition as={Fragment} afterLeave={() => setRecipientsQuery('')} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
												<Combobox.Options
													style={{
														position: 'relative',
														zIndex: 1000,
													}}
													className='no-scrollbar absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-theme-white py-1 text-base shadow-lg ring-1 ring-theme-black/5 focus:outline-none sm:text-sm'>
													{filteredRecipients?.length === 0 && recipientsQuery !== '' ? (
														<div className='relative cursor-default select-none px-4 py-2 text-theme-gray-600'>No users found.</div>
													) : (
														filteredRecipients?.map((recipient, idx) => {
															const capitalizedUserType = recipient?.userType?.charAt(0).toUpperCase() + recipient?.userType?.slice(1);

															return (
																<Combobox.Option key={idx} value={recipient} className={({ active }) => `transition-all duration-300 ease-in-out relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-500 text-theme-white' : 'text-theme-gray-800'}`}>
																	{({ selected, active }) => (
																		<>
																			<span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
																				{recipient?.name?.first} {recipient?.name?.last} ({capitalizedUserType})
																			</span>

																			{selected ? (
																				<span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-theme-white' : 'text-blue-500'}`}>
																					<svg className='size-5' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
																						<path d='M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0' />
																					</svg>
																				</span>
																			) : null}
																		</>
																	)}
																</Combobox.Option>
															);
														})
													)}
												</Combobox.Options>
											</Transition>
										</div>
									</Combobox>
								</div>

								<div className='mt-4 flex flex-col gap-2'>
									<button
										type='button'
										onClick={handleNewChat}
										disabled={handleNewChatLoading || !recipient}
										className={`w-full inline-flex items-center justify-center shrink-0 rounded-md border border-blue-500 bg-blue-500 text-white px-4 py-2 gap-2 text-sm font-medium ${handleNewChatLoading || !recipient ? 'opacity-60 cursor-not-allowed' : 'hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'}`}>
										{handleNewChatLoading ? (
											<div className='flex items-center justify-center'>
												<Loader />
											</div>
										) : (
											'Create'
										)}
									</button>

									<button
										type='button'
										onClick={(e) => {
											e.preventDefault();
											setHandleNewChatLoading(false);
											setRecipient();
											setRecipientsQuery('');
											setModalState(false);
										}}
										disabled={handleNewChatLoading}
										className={`w-full col-span-3 inline-flex items-center justify-center shrink-0 rounded-md border border-gray-500 bg-gray-500 text-white px-4 py-2 gap-2 text-sm font-medium ${handleNewChatLoading ? 'opacity-60 cursor-not-allowed' : 'hover:bg-transparent hover:text-gray-500 focus:outline-none focus:ring active:text-gray-500'}`}>
										{handleNewChatLoading ? (
											<div className='flex items-center justify-center'>
												<Loader />
											</div>
										) : (
											'Cancel'
										)}
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export default NewChatModal;
