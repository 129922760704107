import { useState } from 'react';

const useInputWithError = (initialValue, initialError = null) => {
	const [value, setValue] = useState(initialValue);
	const [error, setError] = useState(initialError);

	const handleChange = (newValue) => {
		setValue(newValue);
		setError(null);
	};

	return {
		value,
		setValue: handleChange,
		error,
		setError,
	};
};

export default useInputWithError;
