import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Disclosure, Transition } from '@headlessui/react';
import { DataContext, AuthContext } from '../context';
import NewChatModal from './modals/NewChatModal';
import badWords from '../pages/chats/badWords';
import Filter from 'bad-words';

const ChatsCard = () => {
	const { chatId } = useParams();

	const { currentUser } = useContext(AuthContext);
	const { appData, chats, users } = useContext(DataContext);

	const [showNewChatModal, setShowNewChatModal] = useState(false);

	const [userChats, setUserChats] = useState([]);
	const [userChatsLoading, setUserChatsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				let fetchedChats = [];
				if (chats) {
					fetchedChats = chats.filter((chat) => chat && chat.participants.includes(currentUser?.data?.id));
				}
				const sortedChats = fetchedChats.slice().sort((chatA, chatB) => {
					const latestMessageTimeA = chatA?.messages?.length > 0 ? chatA?.messages[0]?.timeSent.toDate() : null;
					const latestMessageTimeB = chatB?.messages?.length > 0 ? chatB?.messages[0]?.timeSent.toDate() : null;

					if (!latestMessageTimeA && !latestMessageTimeB) return 0;
					if (!latestMessageTimeA) return 1;
					if (!latestMessageTimeB) return -1;

					return latestMessageTimeB - latestMessageTimeA;
				});

				setUserChats(sortedChats);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setUserChatsLoading(false);
		};

		fetchData();
	}, [appData?.debugMode, currentUser?.data?.id, chatId, chats, users]);

	const [chatsSearchQuery, setChatsSearchQuery] = useState('');
	const [filteredChats, setFilteredChats] = useState([]);

	useEffect(() => {
		const _filteredChats = userChats?.filter((chat) => {
			const searchString = chatsSearchQuery?.toLowerCase();

			const participantMatches = chat?.participants?.some((participantId) => {
				const participantUser = users?.find((user) => user?.id === participantId);

				return participantUser && (participantUser?.name?.first?.toLowerCase()?.includes(searchString) || participantUser?.name?.last?.toLowerCase()?.includes(searchString) || participantUser?.emailAddress?.toLowerCase()?.includes(searchString));
			});

			return chat?.id?.toLowerCase()?.includes(searchString) || participantMatches || chat?.messages?.some((message) => message?.message?.content?.toLowerCase()?.includes(searchString));
		});

		setFilteredChats(_filteredChats);
	}, [chatsSearchQuery, userChats, users]);

	const badWordsFilter = new Filter();
	badWordsFilter.addWords(...badWords);

	return (
		<>
			<div className='flex flex-shrink-0 md:hidden flex-col flex-grow overflow-y-auto bg-theme-white rounded-lg border border-theme-gray-200 p-6 shadow-sm'>
				<Disclosure>
					{({ open }) => (
						<>
							<div className='flex flex-row items-center justify-between'>
								<div className='flex flex-row items-center justify-start gap-4'>
									<Disclosure.Button>
										<h3 className='text-lg font-bold text-theme-gray-800 sm:text-xl'>
											<Link to='/chats'>Chats</Link>
										</h3>
									</Disclosure.Button>
								</div>

								<div className='flex flex-row items-center justify-end gap-4'>
									<button
										type='button'
										onClick={(e) => {
											e.preventDefault();
											setShowNewChatModal(true);
										}}
										className='inline-block shrink-0 rounded-md border border-blue-500 bg-blue-500 px-4 py-2 text-sm font-medium text-white transition-all duration-300 ease-in-out hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'>
										New Chat
									</button>

									<Disclosure.Button className='group p-1.5 flex items-center justify-center rounded-lg border border-theme-gray-400 text-center text-theme-gray-200 hover:opacity-80'>
										<svg className='size-4 transition-all duration-300 ease-in-out fill-theme-gray-600 group-hover:fill-theme-gray-600/80' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
											<path d='M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0' />
										</svg>
									</Disclosure.Button>
								</div>
							</div>

							<Transition show={open} enter='transition duration-100 ease-out' enterFrom='transform scale-95 opacity-0' enterTo='transform scale-100 opacity-100' leave='transition duration-75 ease-out' leaveFrom='transform scale-100 opacity-100' leaveTo='transform scale-95 opacity-0'>
								<Disclosure.Panel static>
									<div className='mt-4 mb-8 flex flex-row items-center justify-between'>
										<div className='w-full'>
											<label htmlFor='chatSearch' className='sr-only'>
												Search
											</label>

											<div className='relative'>
												<div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
													<svg className='size-4 text-theme-gray-800' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
														<path d='M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z' fill='none' stroke='currentColor' strokeMiterlimit='10' strokeWidth='32' />
														<path fill='none' stroke='currentColor' strokeLinecap='round' strokeMiterlimit='10' strokeWidth='32' d='M338.29 338.29L448 448' />
													</svg>
												</div>

												<form>
													<input
														type='search'
														onChange={(e) => {
															e.preventDefault();
															setChatsSearchQuery(e.target.value);
														}}
														className='min-w-64 w-full ps-10 rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'
														id='chatSearch'
														placeholder='Search chats'
													/>
												</form>
											</div>
										</div>
									</div>

									<div className='-my-3 divide-y divide-theme-gray-200'>
										{!userChatsLoading && filteredChats?.length > 0 ? (
											filteredChats?.map((chat, idx) => {
												const sortedMessages = chat?.messages?.sort((a, b) => {
													const timeA = a?.timeSent.toDate();
													const timeB = b?.timeSent.toDate();

													return timeB - timeA;
												});

												const counterpart = users?.find((user) => user?.id === chat?.participants?.find((participant) => participant !== currentUser?.data?.id));
												const counterpartName = counterpart?.name?.first + ' ' + counterpart?.name?.last;

												const baseUrl = 'https://ui-avatars.com/api/?background=random&color=fff&name=';
												let imgUrl = '';

												if (counterpart?.name?.first && counterpart?.name?.last) {
													imgUrl = baseUrl + counterpart?.name?.first + '+' + counterpart?.name?.last;
												} else if (counterpart?.name?.first && !counterpart?.name?.last) {
													imgUrl = baseUrl + counterpart?.name?.first;
												} else if (!counterpart?.name?.first && counterpart?.name?.last) {
													imgUrl = baseUrl + counterpart?.name?.last;
												}

												let firstMessage;

												if (sortedMessages && sortedMessages?.length > 0) {
													firstMessage = sortedMessages[0];

													const timeSent = firstMessage?.timeSent.toDate();
													const formattedTime = timeSent
														? timeSent.toLocaleTimeString('en-US', {
																hour: 'numeric',
																minute: 'numeric',
														  })
														: '';

													let content = '';

													if (firstMessage?.message?.type === 'text') {
														content = firstMessage?.message?.content;
														content = badWordsFilter.clean(content);
													} else if (firstMessage?.message?.type === 'voiceNote') {
														content = 'New voice note';
													} else {
														content = '';
													}

													return (
														<Link key={idx} type='button' to={`/chats/${chat?.id}`} className='grid grid-cols-6 gap-2 py-3 transition-all duration-300 ease-in-out bg-theme-white even:bg-theme-gray-50 hover:opacity-85'>
															<div className='col-span-1 h-full bg-theme-white flex items-center justify-center'>
																<img className='inline-block object-cover rounded-full size-8' src={imgUrl} alt={counterpartName} />
															</div>

															<div className='col-span-5 flex flex-col items-center justify-center gap-0.5'>
																<div className='w-full flex flex-row items-center justify-between'>
																	<p className='text-base text-theme-gray-800 font-bold'>{counterpartName}</p>

																	<span className='text-sm text-blue-500'>{formattedTime}</span>
																</div>

																<div className='w-full'>
																	<p className='text-sm text-theme-gray-600 truncate'>{content}</p>
																</div>
															</div>
														</Link>
													);
												} else {
													return (
														<Link key={idx} type='button' to={`/chats/${chat?.id}`} className='grid grid-cols-6 gap-2 py-3 transition-all duration-300 ease-in-out bg-theme-white even:bg-theme-gray-50 hover:opacity-85'>
															<div className='col-span-1 h-full bg-theme-white flex items-center justify-center'>
																<img className='inline-block object-cover rounded-full size-8' src={imgUrl} alt={counterpartName} />
															</div>

															<div className='col-span-5 flex flex-col items-center justify-center'>
																<div className='w-full flex flex-row items-center justify-between'>
																	<p className='text-base text-theme-gray-800 font-bold'>{counterpartName}</p>
																</div>
															</div>
														</Link>
													);
												}
											})
										) : (
											<div className='h-full w-full flex items-center justify-center'>
												<p className='text-base text-theme-gray-800 font-bold'>No chats</p>
											</div>
										)}
									</div>
								</Disclosure.Panel>
							</Transition>
						</>
					)}
				</Disclosure>
			</div>

			<div className='hidden md:flex md:flex-shrink-0 flex-col flex-grow overflow-y-auto bg-theme-white rounded-lg border border-theme-gray-200 p-6 shadow-sm'>
				<div className='mb-4 flex flex-row items-center justify-between'>
					<div className='flex flex-row items-center justify-start gap-4'>
						<h3 className='text-lg font-bold text-theme-gray-800 sm:text-xl'>
							<Link to='/chats'>Chats</Link>
						</h3>
					</div>

					<div className='flex flex-row items-center justify-end gap-4'>
						<button
							type='button'
							onClick={(e) => {
								e.preventDefault();
								setShowNewChatModal(true);
							}}
							className='inline-block shrink-0 rounded-md border border-blue-500 bg-blue-500 px-4 py-2 text-sm font-medium text-white transition-all duration-300 ease-in-out hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'>
							New Chat
						</button>
					</div>
				</div>

				<div className='mb-8 flex flex-row items-center justify-between'>
					<div className='w-full'>
						<label htmlFor='chatSearch' className='sr-only'>
							Search
						</label>

						<div className='relative'>
							<div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
								<svg className='size-4 text-theme-gray-800' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
									<path d='M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z' fill='none' stroke='currentColor' strokeMiterlimit='10' strokeWidth='32' />
									<path fill='none' stroke='currentColor' strokeLinecap='round' strokeMiterlimit='10' strokeWidth='32' d='M338.29 338.29L448 448' />
								</svg>
							</div>

							<form>
								<input
									type='search'
									onChange={(e) => {
										e.preventDefault();
										setChatsSearchQuery(e.target.value);
									}}
									className='min-w-64 w-full ps-10 rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'
									id='chatSearch'
									placeholder='Search chats'
								/>
							</form>
						</div>
					</div>
				</div>

				<div className='-my-3 divide-y divide-theme-gray-200'>
					{!userChatsLoading && filteredChats?.length > 0 ? (
						filteredChats?.map((chat, idx) => {
							const sortedMessages = chat?.messages?.sort((a, b) => {
								const timeA = a?.timeSent.toDate();
								const timeB = b?.timeSent.toDate();

								return timeB - timeA;
							});

							const counterpart = users?.find((user) => user?.id === chat?.participants?.find((participant) => participant !== currentUser?.data?.id));
							const counterpartName = counterpart?.name?.first + ' ' + counterpart?.name?.last;

							const baseUrl = 'https://ui-avatars.com/api/?background=3b82f6&color=fff&name=';
							let imgUrl = '';

							if (counterpart?.name?.first && counterpart?.name?.last) {
								imgUrl = baseUrl + counterpart?.name?.first + '+' + counterpart?.name?.last;
							} else if (counterpart?.name?.first && !counterpart?.name?.last) {
								imgUrl = baseUrl + counterpart?.name?.first;
							} else if (!counterpart?.name?.first && counterpart?.name?.last) {
								imgUrl = baseUrl + counterpart?.name?.last;
							}

							let firstMessage;

							if (sortedMessages && sortedMessages?.length > 0) {
								firstMessage = sortedMessages[0];

								const timeSent = firstMessage?.timeSent.toDate();
								const formattedTime = timeSent
									? timeSent.toLocaleTimeString('en-US', {
											hour: 'numeric',
											minute: 'numeric',
									  })
									: '';

								let content = '';

								if (firstMessage?.message?.type === 'text') {
									content = firstMessage?.message?.content;
									content = badWordsFilter.clean(content);
								} else if (firstMessage?.message?.type === 'voiceNote') {
									content = 'New voice note';
								} else {
									content = '';
								}

								return (
									<Link key={idx} type='button' to={`/chats/${chat?.id}`} className='grid grid-cols-6 gap-2 py-3 transition-all duration-300 ease-in-out bg-theme-white even:bg-theme-gray-50 hover:opacity-85'>
										<div className='col-span-1 h-full bg-theme-white flex items-center justify-center'>
											<img className='inline-block object-cover rounded-full size-8' src={imgUrl} alt={counterpartName} />
										</div>

										<div className='col-span-5 flex flex-col items-center justify-center gap-0.5'>
											<div className='w-full flex flex-row items-center justify-between'>
												<p className='text-base text-theme-gray-800 font-bold'>{counterpartName}</p>

												<span className='text-sm text-blue-500'>{formattedTime}</span>
											</div>

											<div className='w-full'>
												<p className='text-sm text-theme-gray-600 truncate'>{content}</p>
											</div>
										</div>
									</Link>
								);
							} else {
								return (
									<Link key={idx} type='button' to={`/chats/${chat?.id}`} className='grid grid-cols-6 gap-2 py-3 transition-all duration-300 ease-in-out bg-theme-white even:bg-theme-gray-50 hover:opacity-85'>
										<div className='col-span-1 h-full bg-theme-white flex items-center justify-center'>
											<img className='inline-block object-cover rounded-full size-8' src={imgUrl} alt={counterpartName} />
										</div>

										<div className='col-span-5 flex flex-col items-center justify-center'>
											<div className='w-full flex flex-row items-center justify-between'>
												<p className='text-base text-theme-gray-800 font-bold'>{counterpartName}</p>
											</div>
										</div>
									</Link>
								);
							}
						})
					) : (
						<div className='h-full w-full flex items-center justify-center'>
							<p className='text-base text-theme-gray-800 font-bold'>No chats</p>
						</div>
					)}
				</div>
			</div>

			<NewChatModal modalState={showNewChatModal} setModalState={setShowNewChatModal} />
		</>
	);
};

export default ChatsCard;
