import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { EmailAuthProvider, reauthenticateWithCredential, sendEmailVerification, updateEmail, updatePassword, updateProfile } from 'firebase/auth';
import { query, getDocs, collection, where, doc, updateDoc, addDoc, getDoc, deleteDoc, writeBatch } from 'firebase/firestore';
import { useNetworkState } from 'react-use';
import { auth, db } from '../config/firebase';
import { useAlertHandling, generateStudentId, getCurrentSemester, generateNewAssessmentID, generateNewGradeID, generateNewClassID } from '../utils';
import { AuthContext } from './authContext';
import Logo from '../assets/img/logo.png';

import { appData, countryList, schoolLevelGroups, enrollmentStatusOptions, classStatusOptions, languageOptions, timeZoneOptions, themeOptions, semesterData, currentSemesterDuration, currentYear, gradeLevels, months, colors } from './rawData';
// import { createNewClass, editClass, deleteClass, addStudentToClass, removeStudentFromClass, archiveClass, unarchiveClass } from './functions/classFunctions';
// import {
// 	createNewAssessment,
// 	editAssessment,
// 	//  deleteAssessment,
// 	createNewGrade,
// } from './functions/assessmentsFunctions';
// import { createNewStudent, updatePersonalInformation, updateContactInformation, updateAddressInformation, updateProfileImg, updateLanguage, updateTimeZone, updateUserPassword, updateTheme } from './functions/userFunctions';
// import { createNewChatMessage, createNewChat, deleteChat } from './functions/chatFunctions';
// import { deleteSchool } from './functions/schoolFunctions';

const DataContext = createContext();

const DataProvider = ({ children }) => {
	const { useLocalData, currentUser } = useContext(AuthContext);
	const { showAlert } = useAlertHandling();
	const [isAlertShown, setIsAlertShown] = useState(false);
	const networkState = useNetworkState();

	const [users, setUsers] = useState([]);
	const [schools, setSchools] = useState([]);
	const [currentSchool, setCurrentSchool] = useState({});
	const [subjects, setSubjects] = useState(null);
	const [assessments, setAssessments] = useState(null);
	const [chats, setChats] = useState(null);

	const [usersLoading, setUsersLoading] = useState(true);
	const [schoolsLoading, setSchoolsLoading] = useState(true);
	const [currentSchoolLoading, setCurrentSchoolLoading] = useState(true);
	const [subjectsLoading, setSubjectsLoading] = useState(true);
	const [assessmentsLoading, setAssessmentsLoading] = useState(true);
	const [chatsLoading, setChatsLoading] = useState(true);
	const [loading, setLoading] = useState(true);

	const [isDarkMode, setIsDarkMode] = useState(false);
	const [footerHeight, setFooterHeight] = useState(0);

	const changeTheme = async (theme) => {
		let htmlClasses = document.querySelector('html').classList;

		if (theme === 'System') {
			if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
				localStorage.theme = 'Dark';
				htmlClasses.add('dark');
				setIsDarkMode(true);
			} else {
				localStorage.theme = 'Light';
				htmlClasses.remove('dark');
				setIsDarkMode(false);
			}
		} else if (theme === 'Light') {
			localStorage.theme = 'Light';
			htmlClasses.remove('dark');
			setIsDarkMode(false);
		} else if (theme === 'Dark') {
			localStorage.theme = 'Dark';
			htmlClasses.add('dark');
			setIsDarkMode(true);
		}
	};

	useEffect(() => {
		let htmlClasses = document.querySelector('html').classList;

		if (!loading) {
			if (currentUser) {
				changeTheme(currentUser?.data?.preferences?.theme);
			} else {
				localStorage.theme = 'Light';
				htmlClasses.remove('dark');
				setIsDarkMode(false);
			}
		}
	}, [loading, currentUser]);

	const [isScrolled, setIsScrolled] = useState(false);

	const divRef = useRef(null);

	const handleScroll = () => {
		if (divRef.current) {
			const { scrollTop } = divRef?.current;
			setIsScrolled(scrollTop !== 0);
		}
	};

	const [sidebarOpen, setSidebarOpen] = useState(false);

	useEffect(() => {
		setLoading(usersLoading || schoolsLoading || currentSchoolLoading || subjectsLoading || assessmentsLoading || chatsLoading);
	}, [usersLoading, schoolsLoading, currentSchoolLoading, subjectsLoading, assessmentsLoading, chatsLoading]);

	useEffect(() => {
		const fetchUsers = async () => {
			try {
				const usersQuery = query(collection(db, 'users'));
				const usersSnapshot = await getDocs(usersQuery);

				const usersData = [];

				usersSnapshot?.forEach((user) => {
					usersData?.push(user?.data());
				});

				if (usersData?.length > 0) {
					setUsers(usersData);
				}
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setUsersLoading(false);
		};

		const fetchSchools = async () => {
			try {
				const schoolsQuery = query(collection(db, 'schools'));
				const schoolsSnapshot = await getDocs(schoolsQuery);

				const schoolsData = [];

				schoolsSnapshot?.forEach((school) => {
					schoolsData?.push(school?.data());
				});

				if (schoolsData?.length > 0) {
					setSchools(schoolsData);
				}
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setSchoolsLoading(false);
		};

		const fetchCurrentSchool = async () => {
			try {
				if (schools?.length > 0) {
					setCurrentSchool(schools?.find((school) => school?.id === currentUser?.data?.school?.id));
				}
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setCurrentSchoolLoading(false);
		};

		const fetchSubjects = async () => {
			try {
				const subjectsQuery = query(collection(db, 'subjects'));
				const subjectsSnapshot = await getDocs(subjectsQuery);

				const subjectsData = [];

				subjectsSnapshot?.forEach((subjects) => {
					subjectsData?.push(subjects?.data());
				});

				if (subjectsData?.length > 0) {
					setSubjects(subjectsData);
				}
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setSubjectsLoading(false);
		};

		const fetchAssessments = async () => {
			try {
				const assessmentsQuery = query(collection(db, 'assessments'));
				const assessmentsSnapshot = await getDocs(assessmentsQuery);

				const assessmentsData = [];

				assessmentsSnapshot?.forEach((assessment) => {
					assessmentsData?.push(assessment.data());
				});

				if (assessmentsData?.length > 0) {
					setAssessments(assessmentsData);
				}
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setAssessmentsLoading(false);
		};

		const fetchChats = async () => {
			try {
				const chatsQuery = query(collection(db, 'chats'));
				const chatsSnapshot = await getDocs(chatsQuery);

				const chatsData = [];

				chatsSnapshot?.forEach((chat) => {
					chatsData?.push({
						id: chat?.id,
						...chat?.data(),
					});
				});

				if (chatsData?.length > 0) {
					setChats(chatsData);
				}
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setChatsLoading(false);
		};

		if (currentUser) {
			fetchUsers();
			fetchSchools();
			fetchCurrentSchool();
			fetchSubjects();
			fetchAssessments();
			fetchChats();
		} else {
			setUsersLoading(false);
			setSchoolsLoading(false);
			setCurrentSchoolLoading(false);
			setSubjectsLoading(false);
			setAssessmentsLoading(false);
			setChatsLoading(false);
		}
	}, [currentUser, schools, appData?.debugMode, appData?.localData]);

	const createNewStudent = async (newStudentData) => {
		try {
			let uniqueIdFound = false;
			let newId = newStudentData?.id;

			while (!uniqueIdFound) {
				const firebaseQuery = query(collection(db, 'users'), where('id', '==', newId));
				const users = await getDocs(firebaseQuery);

				if (users?.docs?.length === 0) {
					uniqueIdFound = true;
				} else {
					newId = generateStudentId(newStudentData?.name?.first, newStudentData?.name?.last);
				}
			}

			await addDoc(collection(db, 'users'), {
				...newStudentData,
				id: newId,
			});

			showAlert('success', 'Successfully added new student');
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};

	const createNewAssessment = async (data) => {
		try {
			let uniqueIdFound = false;
			let newId = data?.id;

			while (!uniqueIdFound) {
				const firebaseQuery = query(collection(db, 'assessments'), where('id', '==', newId));
				const assessments = await getDocs(firebaseQuery);

				if (assessments?.docs?.length === 0) {
					uniqueIdFound = true;
				} else {
					newId = generateNewAssessmentID();
				}
			}

			await addDoc(collection(db, 'assessments'), {
				...data,
				id: newId,
			});

			showAlert('success', 'Successfully added new assessment');
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};

	const editAssessment = async (data) => {
		try {
			const firebaseQuery = query(collection(db, 'assessments'), where('id', '==', data?.id));
			const assessments = await getDocs(firebaseQuery);

			if (assessments?.docs?.length > 0) {
				const assessmentDocRef = assessments?.docs[0]?.ref;
				const foundAssessment = assessments?.docs[0]?.data();

				const updatedAssessment = {
					...foundAssessment,
					type: data?.type,
					name: data?.name,
					description: data?.description,
					grading: {
						totalPoints: data?.grading?.totalPoints,
						weight: data?.grading?.weight,
					},
				};

				await updateDoc(assessmentDocRef, updatedAssessment);

				showAlert('success', 'Successfully edited assessment');
			} else {
				if (appData?.debugMode) {
					console.error('Assessment not found.');
				}

				throw new Error('Assessment not found.');
			}
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};
	const [isupdatingGrade, setIsUpdatingGrade] = useState(false);

	const createNewGrade = async (id, data) => {
		if (isupdatingGrade) {
			return;
		}
		setIsUpdatingGrade(true);
		try {
			const firebaseQuery = query(collection(db, 'users'), where('id', '==', id));
			const users = await getDocs(firebaseQuery);

			if (users?.docs?.length > 0) {
				const userDocRef = users?.docs[0]?.ref;
				const userData = users?.docs[0]?.data();

				const existingGradeIndex = userData?.grades?.findIndex((grade) => grade?.class === data?.class && grade?.assessmentId === data?.assessmentId);

				if (existingGradeIndex !== -1) {
					const updatedGrades = [...userData?.grades];
					updatedGrades[existingGradeIndex] = data;

					const updatedData = {
						...userData,
						grades: updatedGrades,
					};

					await updateDoc(userDocRef, updatedData);
					showAlert('success', 'Successfully updated grade');
				} else {
					let newData = data;

					if (!newData?.id || newData?.id?.trim() === '') {
						newData = {
							...newData,
							id: generateNewGradeID(),
						};
					}

					const updatedData = {
						...userData,
						grades: [...userData?.grades, newData],
					};

					await updateDoc(userDocRef, updatedData);
					showAlert('success', 'Successfully created new grade');
				}
			} else {
				if (appData?.debugMode) {
					console.error('User not found.');
				}
				throw new Error('User not found.');
			}
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}
			throw error;
		} finally {
			setIsUpdatingGrade(false);
		}
	};

	const updatePersonalInformation = async (uid, data) => {
		try {
			const firebaseQuery = query(collection(db, 'users'), where('uid', '==', uid));
			const users = await getDocs(firebaseQuery);

			if (users?.docs?.length > 0) {
				const userDocRef = users?.docs[0]?.ref;
				const userData = users?.docs[0]?.data();

				const updatedData = {
					...userData,
					name: data?.name,
					sex: data?.sex,
					dateOfBirth: data?.dateOfBirth,
				};

				await updateDoc(userDocRef, updatedData);

				await updateProfile(auth?.currentUser, {
					displayName: data?.firstName + ' ' + data?.lastName,
				});

				showAlert('success', 'Successfully updated profile');
			} else {
				if (appData?.debugMode) {
					console.error('User not found.');
				}

				throw new Error('User not found.');
			}
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};

	const updateContactInformation = async (uid, data) => {
		try {
			const firebaseQuery = query(collection(db, 'users'), where('uid', '==', uid));
			const users = await getDocs(firebaseQuery);

			if (users?.docs?.length > 0) {
				const userDocRef = users?.docs[0]?.ref;
				const userData = users?.docs[0]?.data();

				const updatedData = {
					...userData,
					emailAddress: data?.emailAddress,
					mobilePhone: data?.mobilePhone,
					homePhone: data?.homePhone,
				};

				await updateDoc(userDocRef, updatedData);

				if (userData?.emailAddress !== updatedData?.emailAddress) {
					await updateEmail(auth?.currentUser, updatedData?.emailAddress);
					await sendEmailVerification(auth?.currentUser);
				}

				showAlert('success', 'Successfully updated profile');
			} else {
				if (appData?.debugMode) {
					console.error('User not found.');
				}

				throw new Error('User not found.');
			}
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};

	const updateAddressInformation = async (uid, data) => {
		try {
			const firebaseQuery = query(collection(db, 'users'), where('uid', '==', uid));
			const users = await getDocs(firebaseQuery);

			if (users?.docs?.length > 0) {
				const userDocRef = users?.docs[0]?.ref;
				const userData = users?.docs[0]?.data();

				const updatedData = {
					...userData,
					address: data?.address,
				};

				await updateDoc(userDocRef, updatedData);
				showAlert('success', 'Successfully updated profile');
			} else {
				if (appData?.debugMode) {
					console.error('User not found.');
				}

				throw new Error('User not found.');
			}
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};

	const updateProfileImg = async (photoURL) => {
		await updateProfile(auth?.currentUser, {
			photoURL: photoURL,
		})
			.then(() => {
				showAlert('success', 'Successfully updated profile image');
			})
			.catch((error) => {
				if (appData?.debugMode) {
					console.error(error);
				}

				throw error;
			});
	};

	const updateLanguage = async (uid, language) => {
		try {
			const firebaseQuery = query(collection(db, 'users'), where('uid', '==', uid));
			const users = await getDocs(firebaseQuery);

			if (users?.docs?.length > 0) {
				const userDocRef = users?.docs[0]?.ref;
				const userData = users?.docs[0]?.data();

				const updatedPreferences = {
					...userData?.preferences,
					language: language,
				};

				await updateDoc(userDocRef, {
					preferences: updatedPreferences,
				});

				showAlert('success', 'Successfully updated language');
			} else {
				if (appData?.debugMode) {
					console.error('User not found.');
				}

				throw new Error('User not found.');
			}
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};

	const updateTimeZone = async (uid, timeZone) => {
		try {
			const firebaseQuery = query(collection(db, 'users'), where('uid', '==', uid));
			const users = await getDocs(firebaseQuery);

			if (users?.docs?.length > 0) {
				const userDocRef = users?.docs[0]?.ref;
				const userData = users?.docs[0]?.data();

				const updatedPreferences = {
					...userData?.preferences,
					timeZone: timeZone,
				};

				await updateDoc(userDocRef, {
					preferences: updatedPreferences,
				});

				showAlert('success', 'Successfully updated time zone');
			} else {
				if (appData?.debugMode) {
					console.error('User not found.');
				}

				throw new Error('User not found.');
			}
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};

	const updateTheme = async (uid, theme) => {
		try {
			console.log(uid);

			const firebaseQuery = query(collection(db, 'users'), where('uid', '==', uid));
			const _users = await getDocs(firebaseQuery);

			console.log(_users);

			if (_users?.docs?.length > 0) {
				const userDocRef = _users?.docs[0]?.ref;
				const userData = _users?.docs[0]?.data();

				const updatedPreferences = {
					...userData?.preferences,
					theme: theme,
				};

				await updateDoc(userDocRef, {
					preferences: updatedPreferences,
				});

				await changeTheme(theme);

				showAlert('success', 'Successfully updated theme');
			} else {
				if (appData?.debugMode) {
					console.error('User not found.');
				}

				throw new Error('User not found.');
			}
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};

	const updateUserPassword = async (data) => {
		try {
			const authCreds = await EmailAuthProvider.getCredential(auth.currentUser.email, data.currentPassword);
			// const authCreds = promptForCredentials();
			// const authResult = await auth.currentUser(auth.currentUser.email, data.currentPassword);

			reauthenticateWithCredential(auth.currentUser, authCreds)
				.then(() => {
					console.log('User re-authenticated.');
				})
				.catch((error) => {
					console.error(error);
				});

			// await updatePassword(auth.currentUser, data.newPassword);

			showAlert('success', 'Successfully updated password');
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};

	const createNewChat = async (newChatData) => {
		try {
			const docRef = await addDoc(collection(db, 'chats'), newChatData);
			showAlert('success', 'Successfully created new chat');

			return docRef?.id;
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};

	const createNewChatMessage = async (newChatMessageData) => {
		try {
			const chatsRef = doc(db, 'chats', newChatMessageData?.chatId);
			const chatsSnapshot = await getDoc(chatsRef);

			if (chatsSnapshot?.exists()) {
				const chatData = chatsSnapshot?.data();
				const { chatId, ...newDataWithoutChatId } = newChatMessageData;

				const updatedData = {
					...chatData,
					messages: [...(chatData?.messages || []), newDataWithoutChatId],
				};

				await updateDoc(chatsRef, updatedData);

				showAlert('success', 'Message sent');
			} else {
				if (appData?.debugMode) {
					console.error('Chat not found');
				}

				throw new Error('Chat not found');
			}
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};

	const createNewClass = async (schoolId, data) => {
		try {
			const firebaseQuery = query(collection(db, 'schools'), where('id', '==', schoolId));
			const schools = await getDocs(firebaseQuery);

			if (schools?.docs?.length > 0) {
				const schoolDocRef = schools?.docs[0]?.ref;
				const schoolData = schools?.docs[0]?.data();

				let uniqueIdFound = false;
				let newId = data?.id;

				while (!uniqueIdFound) {
					const existingClassId = schoolData?.classes?.find((_class) => _class?.id === data?.id);

					if (!existingClassId) {
						uniqueIdFound = true;
					} else {
						newId = generateNewClassID(schoolId);
					}
				}

				const updatedData = {
					...schoolData,
					classes: [
						...schoolData?.classes,
						{
							id: newId,
							...data,
						},
					],
				};

				await updateDoc(schoolDocRef, updatedData);
				showAlert('success', 'Successfully created new class');
			} else {
				if (appData?.debugMode) {
					console.error('School not found');
				}

				throw new Error('School not found');
			}
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};

	const archiveClass = async (schoolId, classId) => {
		try {
			const firebaseQuery = query(collection(db, 'schools'), where('id', '==', schoolId));
			const schools = await getDocs(firebaseQuery);

			if (schools?.docs?.length > 0) {
				const schoolDocRef = schools?.docs[0]?.ref;
				const schoolData = schools?.docs[0]?.data();
				const classes = schoolData?.classes;

				if (Array.isArray(classes)) {
					const classIdx = classes?.findIndex((_class) => _class?.id === classId);

					if (classIdx !== -1) {
						const updatedClasses = [...classes];

						updatedClasses[classIdx].status = 'inactive';

						await updateDoc(schoolDocRef, {
							classes: updatedClasses,
						});

						showAlert('success', 'Successfully archived class');
					} else {
						if (appData?.debugMode) {
							console.error('Class not found.');
						}

						throw new Error('Class not found.');
					}
				} else {
					if (appData?.debugMode) {
						console.error('School has no classes.');
					}

					throw new Error('School has no classes.');
				}
			} else {
				if (appData?.debugMode) {
					console.error('School not found.');
				}

				throw new Error('School not found.');
			}
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};

	const unarchiveClass = async (schoolId, classId) => {
		try {
			const firebaseQuery = query(collection(db, 'schools'), where('id', '==', schoolId));
			const schools = await getDocs(firebaseQuery);

			if (schools?.docs?.length > 0) {
				const schoolDocRef = schools?.docs[0]?.ref;
				const schoolData = schools?.docs[0]?.data();
				const classes = schoolData?.classes;

				if (Array.isArray(classes)) {
					const classIdx = classes?.findIndex((_class) => _class?.id === classId);

					if (classIdx !== -1) {
						const updatedClasses = [...classes];

						updatedClasses[classIdx].status = 'active';

						await updateDoc(schoolDocRef, {
							classes: updatedClasses,
						});

						showAlert('success', 'Successfully unarchived class');
					} else {
						if (appData?.debugMode) {
							console.error('Class not found.');
						}

						throw new Error('Class not found.');
					}
				} else {
					if (appData?.debugMode) {
						console.error('School has no classes.');
					}

					throw new Error('School has no classes.');
				}
			} else {
				if (appData?.debugMode) {
					console.error('School not found.');
				}

				throw new Error('School not found.');
			}
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};

	const deleteClass = async (appData, showAlert, schoolId, classId) => {};

	const addStudentsToClass = async (studentIds, classId) => {
		try {
			for (const studentId of studentIds) {
				const studentsQuery = query(collection(db, 'users'), where('id', '==', studentId));
				const students = await getDocs(studentsQuery);

				if (students?.docs?.length > 0) {
					const studentDocRef = students?.docs[0]?.ref;
					const studentData = students?.docs[0]?.data();

					if (studentData?.school && studentData?.school?.classes) {
						if (!studentData?.school?.classes?.includes(classId)) {
							const updatedClasses = [...studentData?.school?.classes, classId];

							const updatedData = {
								...studentData,
								school: {
									...studentData?.school,
									classes: updatedClasses,
								},
							};

							await updateDoc(studentDocRef, updatedData);
							showAlert('success', 'Successfully added student to class');
						} else {
							showAlert('info', `Student with ID ${studentId} is already enrolled in the class`);
						}
					} else if (studentData?.school) {
						const updatedData = {
							...studentData,
							school: {
								...studentData?.school,
								classes: [classId],
							},
						};

						await updateDoc(studentDocRef, updatedData);
						showAlert('success', 'Successfully added student to class');
					} else {
						if (appData?.debugMode) {
							console.error('Student data is incomplete');
						}

						throw new Error('Student data is incomplete');
					}
				} else {
					if (appData?.debugMode) {
						console.error(`Student with ID ${studentId} not found`);
					}

					throw new Error(`Student with ID ${studentId} not found`);
				}
			}
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};

	const addStudentToClass = async (appData, showAlert, studentId, classId) => {
		try {
			// const studentsQuery = query(collection(db, 'users'), where('id', '==', studentId));
			// const students = await getDocs(studentsQuery);
			// if (students?.docs?.length > 0) {
			// 	const studentDocRef = students?.docs[0]?.ref;
			// 	const studentData = students?.docs[0]?.data();
			// 	if (studentData?.school && studentData?.school?.classes && studentData?.school?.classes.includes(classId)) {
			// 		const updatedClasses = studentData?.school?.classes.filter((classItem) => classItem !== classId);
			// 		const updatedGrades = studentData?.grades?.filter((grade) => grade?.classId !== classId);
			// 		const updatedData = {
			// 			...studentData,
			// 			school: {
			// 				...studentData?.school,
			// 				classes: updatedClasses,
			// 			},
			// 			grades: updatedGrades,
			// 		};
			// 		await updateDoc(studentDocRef, updatedData);
			// 		showAlert('success', 'Successfully removed student');
			// 	} else {
			// 		throw new Error('Student is not enrolled in the specified class');
			// 	}
			// } else {
			// 	if (appData?.debugMode) {
			// 		console.error('Student not found');
			// 	}
			// 	throw new Error('Student not found');
			// }
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};

	const removeStudentFromClass = async (studentId, classId) => {
		try {
			const studentsQuery = query(collection(db, 'users'), where('id', '==', studentId));
			const students = await getDocs(studentsQuery);

			if (students?.docs?.length > 0) {
				const studentDocRef = students?.docs[0]?.ref;
				const studentData = students?.docs[0]?.data();

				if (studentData?.school && studentData?.school?.classes && studentData?.school?.classes?.includes(classId)) {
					const updatedClasses = studentData?.school?.classes?.filter((classItem) => classItem !== classId);
					const updatedGrades = studentData?.grades?.filter((grade) => grade?.classId !== classId);

					const updatedData = {
						...studentData,
						school: {
							...studentData?.school,
							classes: updatedClasses,
						},
						grades: updatedGrades,
					};

					await updateDoc(studentDocRef, updatedData);
					showAlert('success', 'Successfully removed student');
				} else {
					throw new Error('Student is not enrolled in the specified class');
				}
			} else {
				if (appData?.debugMode) {
					console.error('Student not found');
				}

				throw new Error('Student not found');
			}
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};

	// const deleteSchool = async (schoolId) => {
	// 	try {
	// 		// await deleteDoc(doc(db, 'schools', schoolId));
	// 		showAlert('success', 'Successfully deleted school');
	// 	} catch (error) {
	// 		if (appData?.debugMode) {
	// 			console.error(error);
	// 		}

	// 		throw error;
	// 	}
	// };

	const deleteChat = async (chatId, messageIndex) => {
		try {
			const chatRef = doc(db, 'chats', chatId);
			const chatSnap = await getDoc(chatRef);

			if (chatSnap?.exists()) {
				const messages = chatSnap?.data()?.messages || [];

				if (messageIndex === -1) {
					if (appData?.debugMode) {
						console.error('Message not found');
					}

					throw new Error('Message not found');
				}

				messages?.splice(messageIndex, 1);

				await updateDoc(chatRef, {
					messages: messages,
				});

				showAlert('success', 'Successfully deleted message');
			} else {
				if (appData?.debugMode) {
					console.error('Chat not found');
				}

				throw new Error('Chat not found');
			}
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};

	const createNewSubject = async (newSubjectData) => {
		try {
			await addDoc(collection(db, 'subjects'), newSubjectData);
			showAlert('success', 'Successfully created new subject');
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};

	const editSubject = async (data) => {
		try {
			const firebaseQuery = query(collection(db, 'subjects'), where('id', '==', data?.id));
			const subjects = await getDocs(firebaseQuery);

			if (subjects?.docs?.length > 0) {
				const subjectDocRef = subjects?.docs[0]?.ref;
				const foundSubject = subjects?.docs[0]?.data();

				const updatedSubject = {
					...foundSubject,
					name: data?.name,
					desc: data?.desc,
				};

				await updateDoc(subjectDocRef, updatedSubject);
				showAlert('success', 'Successfully edited subject');
			} else {
				if (appData?.debugMode) {
					console.error('Subject not found.');
				}

				throw new Error('Subject not found.');
			}
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};

	const editStudent = async (data) => {
		try {
			const firebaseQuery = query(collection(db, 'users'), where('id', '==', data?.id));
			const users = await getDocs(firebaseQuery);

			if (users?.docs?.length > 0) {
				const userDocRef = users?.docs[0]?.ref;
				const foundUser = users?.docs[0]?.data();

				const updatedStudent = {
					...foundUser,
					name: {
						first: data?.name?.first,
						middle: data?.name?.middle,
						last: data?.name?.last,
					},
					emailAddress: data?.emailAddress,
					mobilePhone: data?.mobilePhone,
					homePhone: data?.homePhone,
					dateOfBirth: data?.dateOfBirth,
					sex: data?.sex,
					address: {
						street: data?.address?.street,
						community: data?.address?.community,
						district: data?.address?.district,
						country: data?.address?.country,
					},
					school: {
						id: data?.school?.id,
						entryYear: data?.school?.entryYear,
						gradeLevel: data?.school?.gradeLevel,
						enrollmentStatus: data?.school?.enrollmentStatus,
						graduationYear: data?.school?.graduationYear,
						expectedGraduationYear: data?.school?.expectedGraduationYear,
					},
					parents: {
						father: {
							name: {
								first: data?.parents?.father?.name?.first,
								last: data?.parents?.father?.name?.first,
							},
							emailAddress: data?.parents?.father?.mobilePhone,
							mobilePhone: data?.parents?.father?.mobilePhone,
						},
						mother: {
							name: {
								first: data?.parents?.mother?.name?.first,
								last: data?.parents?.mother?.name?.last,
							},
							emailAddress: data?.parents?.mother?.emailAddress,
							mobilePhone: data?.parents?.mother?.mobilePhone,
						},
					},
				};

				await updateDoc(userDocRef, updatedStudent);
				showAlert('success', 'Successfully edited student');
			} else {
				if (appData?.debugMode) {
					console.error('Student not found.');
				}

				throw new Error('Student not found.');
			}
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};

	const editClass = async (schoolId, data) => {
		try {
			const firebaseQuery = query(collection(db, 'schools'), where('id', '==', schoolId));
			const schools = await getDocs(firebaseQuery);

			if (schools?.docs?.length > 0) {
				const schoolDocRef = schools?.docs[0]?.ref;
				const schoolData = schools?.docs[0]?.data();
				const classes = schoolData?.classes;

				if (Array.isArray(classes)) {
					const classIdx = classes?.findIndex((_class) => _class?.id === data?.id);

					if (classIdx !== -1) {
						const updatedClasses = [...classes];

						updatedClasses[classIdx] = {
							...updatedClasses[classIdx],
							subjectId: data?.subjectId || updatedClasses[classIdx].subjectId,
							teacherId: data?.teacherId || updatedClasses[classIdx].teacherId,
							room: data?.room || updatedClasses[classIdx].room,
							status: data?.status || updatedClasses[classIdx].status,
							duration: {
								semester: parseInt(data?.duration?.semester) || parseInt(updatedClasses[classIdx]?.duration?.semester),
								year: parseInt(data?.duration?.year) || parseInt(updatedClasses[classIdx]?.duration?.year),
							},
						};

						await updateDoc(schoolDocRef, {
							classes: updatedClasses,
						});

						showAlert('success', 'Successfully edited class');
					} else {
						if (appData?.debugMode) {
							console.error('Class not found.');
						}

						throw new Error('Class not found.');
					}
				} else {
					if (appData?.debugMode) {
						console.error('School has no classes.');
					}

					throw new Error('School has no classes.');
				}
			} else {
				if (appData?.debugMode) {
					console.error('School not found.');
				}

				throw new Error('School not found.');
			}
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};

	const deleteSubject = async (subjectId) => {
		try {
			const firebaseQuery = query(collection(db, 'subjects'), where('id', '==', subjectId));
			const subjects = await getDocs(firebaseQuery);

			if (subjects?.docs?.length > 0) {
				const subjectDocRef = subjects?.docs[0]?.ref;

				await deleteDoc(subjectDocRef);
				showAlert('success', 'Successfully deleted subject');
			} else {
				if (appData?.debugMode) {
					console.error('Subject not found');
				}

				throw new Error('Subject not found');
			}
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};

	const deleteStudent = async (studentId) => {
		try {
			const firebaseQuery = query(collection(db, 'users'), where('id', '==', studentId));
			const users = await getDocs(firebaseQuery);

			if (users?.docs?.length > 0) {
				const userDocRef = users?.docs[0]?.ref;

				await deleteDoc(userDocRef);
				showAlert('success', 'Successfully deleted student');
			} else {
				if (appData?.debugMode) {
					console.error('Student not found');
				}

				throw new Error('Student not found');
			}
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			throw error;
		}
	};

	return (
		<DataContext.Provider
			value={{
				// useLocalData,
				footerHeight,
				setFooterHeight,
				isAlertShown,
				setIsAlertShown,
				isDarkMode,
				isScrolled,
				handleScroll,
				sidebarOpen,
				setSidebarOpen,
				networkState,
				users,
				schools,
				currentSchool,
				subjects,
				assessments,
				chats,

				// RAW DATA
				appData,
				countryList,
				schoolLevelGroups,
				enrollmentStatusOptions,
				classStatusOptions,
				languageOptions,
				timeZoneOptions,
				themeOptions,
				semesterData,
				currentSemesterDuration,
				currentYear,
				gradeLevels,
				months,
				colors,

				// CLASS FUNCTIONS
				createNewClass,
				editClass,
				deleteClass,
				addStudentToClass,
				removeStudentFromClass,
				archiveClass,
				unarchiveClass,

				// ASSESSMENT FUNCTIONS
				createNewAssessment,
				editAssessment,
				//  deleteAssessment,
				createNewGrade,

				// USER FUNCTIONS
				createNewStudent,
				updatePersonalInformation,
				updateContactInformation,
				updateAddressInformation,
				updateProfileImg,
				updateLanguage,
				updateTimeZone,
				updateUserPassword,
				updateTheme,
				// createNewClass,
				addStudentsToClass,
				// removeStudentFromClass,
				deleteChat,
				// archiveClass,
				// unarchiveClass,
				deleteSubject,
				editSubject,
				editStudent,
				// editClass,
				createNewSubject,
				deleteStudent,
				createNewChat,
				createNewChatMessage,
			}}>
			{children}
		</DataContext.Provider>
	);
};

export { DataContext, DataProvider };

// sendEmailVerification(auth.currentUser).then(() => {
// 	// Email verification sent!
// 	// ...
// });

// if currentPassword !== password stored in firebase
