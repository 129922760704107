import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { AuthContext, DataContext } from '../../context';
import { MainContainer, Table, TableBodyItem, TableHeadItem, RemoveModal, EnlistedStudentsTable } from '../../components';
import { useAlertHandling, formatDate, getProperty, getGradeLevelName } from '../../utils';

const studentsTableContent = ({ student, gradeLevels, userType, studentIds, setStudentIds }) => {
	return (
		<>
			<TableBodyItem
				type='select'
				onClick={() => {
					if (studentIds.includes(student?.id)) {
						setStudentIds(studentIds?.filter((id) => id !== student?.id));
					} else {
						setStudentIds([...studentIds, student?.id]);
					}
				}}
				checked={studentIds?.includes(student?.id)}
				onChange={(e) => e.preventDefault()}
			/>

			<TableBodyItem type='primary' contents={student?.id ? <Link to={`/student/${student?.id}`}>{student?.id}</Link> : 'N/A'} />

			<TableBodyItem type='default' contents={student?.name?.first || 'N/A'} align='left' />

			<TableBodyItem type='default' contents={student?.name?.last || 'N/A'} align='left' />

			{userType === 'admin' && <TableBodyItem type='default' contents={student?.school?.id || 'N/A'} align='left' />}

			<TableBodyItem type='default' contents={student?.dateOfBirth ? formatDate(student?.dateOfBirth) : 'N/A'} align='center' />

			<TableBodyItem type='default' contents={student?.school?.gradeLevel ? getGradeLevelName(String(student?.school?.gradeLevel), gradeLevels) : 'N/A'} align='center' />

			<TableBodyItem type='default' contents={student?.registrationDate ? formatDate(student?.registrationDate) : 'N/A'} align='center' />
		</>
	);
};

const AddStudents = () => {
	const { classId } = useParams();

	useTitle(`${classId ? classId : 'Class'} | Grade Portal`);

	const { currentUser } = useContext(AuthContext);
	const { appData, divRef, handleScroll, currentSchool, subjects, assessments, users, createNewGrade, gradeLevels, addStudentsToClass, removeStudentsFromClass } = useContext(DataContext);
	const { showAlert } = useAlertHandling();

	const [subject, setSubject] = useState('');
	const [canAccessClass, setCanAccessClass] = useState(false);

	useEffect(() => {
		const isClassIdInSchool = (school, classId) => {
			if (school && school?.classes) {
				const classIds = school?.classes?.map((_class) => _class?.id);
				return classIds?.includes(classId);
			} else {
				return false;
			}
		};

		const classIdInSchool = isClassIdInSchool(currentSchool, classId);

		if (currentUser?.data?.userType === 'principal' && classIdInSchool) {
			const foundClass = currentSchool?.classes?.find((_class) => _class?.id === classId);

			const fetchSubject = async (foundClass) => {
				try {
					const foundSubject = subjects?.find((subject) => subject?.id === foundClass?.subjectId);
					setSubject(foundSubject);
				} catch (err) {
					if (appData?.debugMode) {
						console.error(err);
					}
				}
			};

			if (foundClass) {
				fetchSubject(foundClass);
				setCanAccessClass(true);
			} else {
				setCanAccessClass(false);
			}
		} else {
			setCanAccessClass(false);
		}
	}, [currentSchool, currentUser?.data?.id, currentUser?.data?.school?.id, currentUser?.data?.userType, appData?.debugMode, classId, subjects]);

	const [students, setStudents] = useState([]);
	const [studentsLoading, setStudentsLoading] = useState(true);
	const [studentSearchQuery, setStudentSearchQuery] = useState('');

	useEffect(() => {
		const fetchStudents = async () => {
			try {
				const fetchedUsers = await users;
				const fetchedStudents = fetchedUsers?.filter((user) => user?.userType === 'student');
				let filteredStudents = [];

				if (currentUser?.data?.userType === 'principal') {
					filteredStudents = fetchedStudents?.filter((student) => {
						if (student?.school?.id === currentSchool?.id) {
							const studentClasses = student?.school?.classes;

							if (!studentClasses?.some((_classId) => _classId === classId)) {
								return true;
							} else {
								return false;
							}
						} else {
							return false;
						}
					});
				}

				if (studentSearchQuery !== null && studentSearchQuery !== undefined) {
					const trimmedQuery = studentSearchQuery?.trim().toLowerCase();

					filteredStudents = filteredStudents?.filter((student) => {
						const id = student?.id ? student?.id?.toLowerCase() : '';
						const firstName = student?.name?.first ? student?.name?.first?.toLowerCase() : '';
						const lastName = student?.name?.last ? student?.name?.last?.toLowerCase() : '';
						const emailAddress = student?.emailAddress ? student?.emailAddress?.toLowerCase() : '';

						return id?.includes(trimmedQuery) || firstName?.includes(trimmedQuery) || lastName?.includes(trimmedQuery) || emailAddress?.includes(trimmedQuery);
					});
				}

				setStudents(filteredStudents);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setStudentsLoading(false);
		};

		fetchStudents();
	}, [appData?.debugMode, currentSchool?.id, currentUser?.data?.userType, users, classId, studentSearchQuery]);

	const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState('asc');
	const [sortedStudents, setSortedStudents] = useState([]);

	const handleSortClick = (field) => {
		if (sortField === field) {
			setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
		} else {
			setSortField(field);
			setSortOrder('asc');
		}
	};

	useEffect(() => {
		const sortStudents = async () => {
			let sortedData = [...(students ?? [])];

			if (!studentsLoading && sortField) {
				sortedData?.sort((a, b) => {
					const valueA = getProperty(a, sortField);
					const valueB = getProperty(b, sortField);

					if (valueA && valueB) {
						const numA = Number(valueA);
						const numB = Number(valueB);

						if (!isNaN(numA) && !isNaN(numB)) {
							return sortOrder === 'asc' ? numA - numB : numB - numA;
						} else {
							if (sortOrder === 'asc') {
								return valueA?.localeCompare(valueB, undefined, {
									numeric: true,
								});
							} else {
								return valueB?.localeCompare(valueA, undefined, {
									numeric: true,
								});
							}
						}
					} else {
						return 0;
					}
				});
			}

			setSortedStudents(sortedData);
		};

		sortStudents();
	}, [students, sortField, sortOrder, studentsLoading]);

	const [removeStudentId, setRemoveStudentId] = useState(null);
	const [removeStudentModalState, setRemoveStudentModalState] = useState(false);

	const handleRemoveStudent = async (studentId) => {
		try {
			await removeStudentsFromClass(studentId, classId);
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			showAlert('error', 'An unknown error occurred');
		}
	};

	const [studentIds, setStudentIds] = useState([]);
	const [allSelected, setAllSelected] = useState(false);

	useEffect(() => {
		if (allSelected) {
			const allStudentIds = students?.map((student) => student?.id);
			setStudentIds(allStudentIds);
		}
	}, [allSelected, students]);

	const [addStudentsLoading, setAddStudentsLoading] = useState(false);

	const handleAddStudents = async (e) => {
		e.preventDefault();
		setAddStudentsLoading(true);

		try {
			await addStudentsToClass(studentIds, classId);
			window.location.href = `/classes/${classId}`;
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			showAlert('error', 'An unknown error occurred');
		}

		setAddStudentsLoading(false);
	};

	if (!canAccessClass) {
		return (
			<MainContainer divRef={divRef} handleScroll={handleScroll}>
				<section className='bg-theme-white py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
					<div className='mx-auto max-w-screen-sm text-center'>
						<h1 className='mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-theme-gray-800'>404</h1>

						<p className='mb-4 text-3xl tracking-tight font-bold text-blue-500 md:text-4xl'>Oops! Class Not Found</p>

						<p className='mb-8 text-lg text-theme-gray-600'>
							It seems that the class you're trying to access isn't available or authorized for your account. Don't worry, you can explore your classes{' '}
							<Link to='/classes' className='focus:outline-none focus:ring text-blue-500 hover:opacity-80 active:opacity-80'>
								here
							</Link>
							.
						</p>

						<Link to='/' className='inline-block shrink-0 rounded-md border px-5 py-2.5 text-sm font-medium focus:outline-none focus:ring text-white border-blue-500 bg-blue-500 hover:bg-transparent hover:text-blue-500 active:text-blue-500'>
							Back to Homepage
						</Link>
					</div>
				</section>
			</MainContainer>
		);
	}

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<h3 className='text-2xl font-bold text-theme-gray-800'>{subject && classId ? `${classId} - ${subject?.name}` : 'Class'}</h3>
			</section>

			<section className='w-full bg-theme-white'>
				<div className='flex flex-row items-center justify-between'>
					<div className='flex flex-row items-center justify-start gap-4'>
						<h3 className='text-lg font-bold text-theme-gray-800 sm:text-xl'>Students</h3>

						<div className='w-full'>
							<label htmlFor='studentSearch' className='sr-only'>
								Search
							</label>

							<div className='relative'>
								<div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
									<svg className='size-4 text-theme-gray-800' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
										<path d='M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z' fill='none' stroke='currentColor' strokeMiterlimit='10' strokeWidth='32' />
										<path fill='none' stroke='currentColor' strokeLinecap='round' strokeMiterlimit='10' strokeWidth='32' d='M338.29 338.29L448 448' />
									</svg>
								</div>

								<input
									type='search'
									onChange={(e) => {
										e.preventDefault();
										setStudentSearchQuery(e.target.value);
									}}
									className='min-w-64 w-full ps-10 rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'
									id='studentSearch'
									placeholder='Search students'
								/>
							</div>
						</div>
					</div>

					<div className='flex flex-row items-center justify-end gap-4'>
						<button
							type='button'
							onClick={(e) => {
								handleAddStudents(e);
							}}
							disabled={addStudentsLoading}
							className='inline-block shrink-0 rounded-md border border-blue-500 bg-blue-500 px-4 py-2 text-sm font-medium text-white transition-all duration-300 ease-in-out hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'>
							Add Students
						</button>

						<Link type='button' to={`/students/new`} className='inline-block shrink-0 rounded-md border border-blue-500 bg-blue-500 px-4 py-2 text-sm font-medium text-white transition-all duration-300 ease-in-out hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'>
							New Student
						</Link>
					</div>
				</div>
			</section>

			<section className='w-full bg-theme-white'>
				<Table
					name='Students'
					headContents={
						<tr>
							<TableHeadItem
								type='select'
								onClick={() => {
									if (allSelected) {
										setStudentIds([]);
										setAllSelected(false);
									} else {
										setAllSelected(true);
									}
								}}
								checked={allSelected}
								onChange={(e) => e.preventDefault()}
							/>

							<TableHeadItem type='sort' title='Student ID' align='left' onClick={() => handleSortClick('id')} />

							<TableHeadItem type='sort' title='First Name' align='left' onClick={() => handleSortClick('name.first')} />

							<TableHeadItem type='sort' title='Last Name' align='left' onClick={() => handleSortClick('name.last')} />

							{currentUser?.data?.userType === 'admin' && <TableHeadItem type='sort' title='School' align='left' onClick={() => handleSortClick('school.id')} />}

							<TableHeadItem type='default' title='D.O.B' align='center' />

							<TableHeadItem type='sort' title='Grade/Form' align='center' onClick={() => handleSortClick('school.gradeLevel')} />

							<TableHeadItem type='sort' title='Registration Date' align='center' onClick={() => handleSortClick('registrationDate')} />
						</tr>
					}
					bodyContents={({ item }) =>
						studentsTableContent({
							student: item,
							gradeLevels: gradeLevels,
							userType: currentUser?.data?.userType,
							studentIds: studentIds,
							setStudentIds: setStudentIds,
						})
					}
					sortedData={sortedStudents}
					filteredData={sortedStudents}
					dataLoading={studentsLoading}
					colSpan={currentUser?.data?.userType === 'admin' ? 8 : 7}
				/>
			</section>

			<section className='w-full bg-theme-white'>
				<EnlistedStudentsTable appData={appData} classId={classId} users={users} currentUser={currentUser} currentSchool={currentSchool} assessments={assessments} sortedAssessments={null} setRemoveStudentModalState={setRemoveStudentModalState} setRemoveStudentId={setRemoveStudentId} showAlert={showAlert} createNewGrade={createNewGrade} addStudents={true} />
			</section>

			<RemoveModal title='Student' removeId={removeStudentId} handleRemove={handleRemoveStudent} removeModalState={removeStudentModalState} setRemoveModalState={setRemoveStudentModalState} />
		</MainContainer>
	);
};

export default AddStudents;
