const UserProfileInformation = ({ user }) => {
	return (
		<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
			<div className='mb-4 flex flex-row items-center justify-between'>
				<h3 className='text-xl font-bold text-theme-gray-800'>Profile Information</h3>
			</div>

			<div className='w-full mt-6 py-3 rounded-lg border border-theme-gray-200'>
				<dl className='-my-3 divide-y divide-theme-gray-200 text-sm'>
					<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
						<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>User ID</dt>

						<dd className='text-theme-gray-600'>{user?.id || 'N/A'}</dd>
					</div>

					<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
						<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>User Type</dt>

						<dd className='text-theme-gray-600'>{user?.userType ? user?.userType.charAt(0).toUpperCase() + user?.userType.slice(1) : 'N/A'}</dd>
					</div>
				</dl>
			</div>
		</div>
	);
};

const UserSchoolDetails = ({ user, school, students }) => {
	return (
		<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
			<div className='mb-4 flex flex-row items-center justify-between'>
				{user?.userType === 'student' || user?.userType === 'teacher' || user?.userType === 'principal' || user?.userType === 'alumni' ? <h3 className='text-xl font-bold text-theme-gray-800'>School Details</h3> : user?.userType === 'parent' ? <h3 className='text-xl font-bold text-theme-gray-800'>Children's School Details</h3> : null}
			</div>

			{user?.userType === 'student' || user?.userType === 'alumni' ? (
				<div className='w-full mt-6 py-3 rounded-lg border border-theme-gray-200'>
					<dl className='-my-3 divide-y divide-theme-gray-200 text-sm'>
						<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
							<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>School</dt>

							<dd className='text-theme-gray-600'>{school?.id || 'N/A'}</dd>
						</div>

						<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
							<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>Website</dt>

							<dd className='text-theme-gray-600'>
								{school?.website ? (
									<a href={school?.website} className='inline-flex items-center gap-2 hover:opacity-80' target='_blank' rel='noopener noreferrer'>
										<span>Visit</span>

										<svg className='size-4 fill-theme-gray-600' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
											<path d='M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9q-.13 0-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z' />
											<path d='M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4 4 0 0 1-.82 1H12a3 3 0 1 0 0-6z' />
										</svg>
									</a>
								) : (
									'N/A'
								)}
							</dd>
						</div>

						<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
							<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>Class</dt>

							<dd className='text-theme-gray-600'>{user?.school?.classId || 'N/A'}</dd>
						</div>

						<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
							<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>Enrollment Status</dt>

							<dd className='text-theme-gray-600'>{user?.school?.enrollmentStatus ? user?.school?.enrollmentStatus.charAt(0).toUpperCase() + user?.school?.enrollmentStatus.slice(1) : 'N/A'}</dd>
						</div>

						<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
							<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>Entry Year</dt>

							<dd className='text-theme-gray-600'>{user?.school?.entryYear || 'N/A'}</dd>
						</div>

						{user?.school?.gradeLevel && (
							<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
								<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>{user?.school?.gradeLevel < 6 ? 'Grade' : 'Form'}</dt>

								<dd className='text-theme-gray-600'>{user?.school?.gradeLevel < 6 ? user?.school?.gradeLevel : user?.school?.gradeLevel - 6}</dd>
							</div>
						)}

						<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4 rounded-b-lg'>
							<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>
								{user?.school?.graduationYear
									? 'Graduation Year'
									: user?.school?.enrollmentStatus === 'inactive' || user?.school?.enrollmentStatus === 'graduated'
									? 'Graduation Year'
									: user?.school?.expectedGraduationYear
									? 'Expected Graduation Year'
									: user?.school?.entryYear && new Date().getFullYear() - user?.school?.entryYear < 5
									? 'Expected Graduation Year'
									: 'Expected Graduation Year'}
							</dt>

							<dd className='text-theme-gray-600'>{user?.school?.graduationYear ? user?.school?.graduationYear : user?.school?.expectedGraduationYear ? user?.school?.expectedGraduationYear : user?.school?.graduationYear && user?.school?.expectedGraduationYear ? user?.school?.graduationYear : user?.school?.entryYear ? user?.school?.entryYear + 5 : 'N/A'}</dd>
						</div>
					</dl>
				</div>
			) : user?.userType === 'teacher' || user?.userType === 'principal' ? (
				<div className='w-full mt-6 py-3 rounded-lg border border-theme-gray-200'>
					<dl className='-my-3 divide-y divide-theme-gray-200 text-sm'>
						<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
							<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>School name</dt>

							<dd className='text-theme-gray-600'>{user?.school?.id || 'N/A'}</dd>
						</div>

						<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
							<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>Website</dt>

							<dd className='text-theme-gray-600'>
								{school?.website ? (
									<a href={school?.website} className='inline-flex items-center gap-2 hover:opacity-80' target='_blank' rel='noopener noreferrer'>
										<span>Visit</span>

										<svg className='size-4 fill-theme-gray-600' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
											<path d='M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9q-.13 0-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z' />
											<path d='M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4 4 0 0 1-.82 1H12a3 3 0 1 0 0-6z' />
										</svg>
									</a>
								) : (
									'N/A'
								)}
							</dd>
						</div>
					</dl>
				</div>
			) : user?.userType === 'parent' ? (
				user?.dependents?.map((dependent, idx) => {
					const student = students?.find((student) => student.id === dependent.id);

					return (
						<div key={idx} className='w-full mt-6 py-3 rounded-lg border border-theme-gray-200'>
							<dl className='-my-3 divide-y divide-theme-gray-200 text-sm'>
								<div className='grid grid-cols-1 gap-1 p-3 bg-theme-gray-100 sm:grid-cols-3 sm:gap-4'>
									<dt className='flex items-center font-bold text-theme-gray-800 sm:col-span-3'>John Doe</dt>
								</div>

								<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
									<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>School name</dt>

									<dd className='text-theme-gray-600'>{student?.school?.id || 'N/A'}</dd>
								</div>

								<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
									<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>Website</dt>

									<dd className='text-theme-gray-600'>
										{school?.website ? (
											<a href={school?.website} className='inline-flex items-center gap-2 hover:opacity-80' target='_blank' rel='noopener noreferrer'>
												<span>Visit</span>

												<svg className='size-4 fill-theme-gray-600' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
													<path d='M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9q-.13 0-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z' />
													<path d='M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4 4 0 0 1-.82 1H12a3 3 0 1 0 0-6z' />
												</svg>
											</a>
										) : (
											'N/A'
										)}
									</dd>
								</div>

								<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
									<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>Class</dt>

									<dd className='text-theme-gray-600'>{student?.school?.classId || 'N/A'}</dd>
								</div>

								<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
									<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>Enrollment status</dt>

									<dd className='text-theme-gray-600'>{student?.school?.enrollmentStatus ? student?.school?.enrollmentStatus.charAt(0).toUpperCase() + student?.school?.enrollmentStatus.slice(1) : 'N/A'}</dd>
								</div>

								<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
									<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>Entry year</dt>

									<dd className='text-theme-gray-600'>{student?.school?.entryYear || 'N/A'}</dd>
								</div>

								{student?.school?.gradeLevel && (
									<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
										<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>{student?.school?.gradeLevel < 6 ? 'Grade' : 'Form'}</dt>

										<dd className='text-theme-gray-600'>{student?.school?.gradeLevel < 6 ? student?.school?.gradeLevel : student?.school?.gradeLevel - 6}</dd>
									</div>
								)}

								<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4 rounded-b-lg'>
									<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>
										{student?.school?.graduationYear
											? 'Graduation year'
											: student?.school?.enrollmentStatus === 'inactive' || student?.school?.enrollmentStatus === 'graduated'
											? 'Graduation year'
											: student?.school?.expectedGraduationYear
											? 'Expected graduation year'
											: student?.school?.entryYear && new Date().getFullYear() - student?.school?.entryYear < 5
											? 'Expected graduation year'
											: 'Expected graduation year'}
									</dt>

									<dd className='text-theme-gray-600'>
										{student?.school?.graduationYear ? student?.school?.graduationYear : student?.school?.expectedGraduationYear ? student?.school?.expectedGraduationYear : student?.school?.graduationYear && student?.school?.expectedGraduationYear ? student?.school?.graduationYear : student?.school?.entryYear ? student?.school?.entryYear + 5 : 'N/A'}
									</dd>
								</div>
							</dl>
						</div>
					);
				})
			) : null}
		</div>
	);
};

const UserPersonalDetails = ({ user }) => {
	return (
		<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
			<div className='mb-4 flex flex-row items-center justify-between'>
				<h3 className='text-xl font-bold text-theme-gray-800'>Personal Details</h3>
			</div>

			<div className='w-full grid grid-cols-12 gap-6'>
				<div className='w-full col-span-12 lg:col-span-4'>
					<label htmlFor='firstName' className='block text-sm font-medium text-theme-gray-800'>
						First name
					</label>

					<div className='w-full mt-1 py-2 pr-3'>
						<span className='text-theme-gray-800 text-sm'>{user?.name?.first || 'N/A'}</span>
					</div>
				</div>

				<div className='w-full col-span-12 lg:col-span-4'>
					<label htmlFor='middleName' className='block text-sm font-medium text-theme-gray-800'>
						Middle name
					</label>

					<div className='w-full mt-1 py-2 pr-3'>
						<span className='text-theme-gray-800 text-sm'>{user?.name?.middle || 'N/A'}</span>
					</div>
				</div>

				<div className='w-full col-span-12 lg:col-span-4'>
					<label htmlFor='lastName' className='block text-sm font-medium text-theme-gray-800'>
						Last name
					</label>

					<div className='w-full mt-1 py-2 pr-3'>
						<span className='text-theme-gray-800 text-sm'>{user?.name?.last || 'N/A'}</span>
					</div>
				</div>

				<div className='w-full col-span-12 lg:col-span-6'>
					<label htmlFor='sex' className='block text-sm font-medium text-theme-gray-800'>
						Sex
					</label>

					<div className='w-full mt-1 py-2 pr-3'>
						<span className='text-theme-gray-800 text-sm'>{user?.sex || 'N/A'}</span>
					</div>
				</div>

				<div className='w-full col-span-12 lg:col-span-6 text-theme-gray-800'>
					<label htmlFor='dateOfBirth' className='block text-sm font-medium text-theme-gray-800'>
						Date of birth
					</label>

					<div className='w-full mt-1 py-2 pr-3'>
						<span className='text-theme-gray-800 text-sm'>{user?.dateOfBirth || 'N/A'}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

const UserContactDetails = ({ user }) => {
	return (
		<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
			<div className='mb-4 flex flex-row items-center justify-between'>
				<h3 className='text-xl font-bold text-theme-gray-800'>Contact Details</h3>
			</div>

			<div className='w-full grid grid-cols-12 gap-6'>
				<div className='w-full col-span-12 lg:col-span-6'>
					<label htmlFor='emailAddress' className='block text-sm font-medium text-theme-gray-800'>
						Email address
					</label>

					<div className='w-full mt-1 py-2 pr-3'>
						<span className='text-theme-gray-800 text-sm'>
							{user?.emailAddress ? (
								<a href={`mailto:${user?.emailAddress}`} className='text-blue-500 hover:opacity-80' target='_blank' rel='noopener noreferrer'>
									{user?.emailAddress}
								</a>
							) : (
								'N/A'
							)}
						</span>
					</div>
				</div>

				<div className='w-full col-span-12 lg:col-span-6'>
					<label htmlFor='mobilePhone' className='block text-sm font-medium text-theme-gray-800'>
						Mobile phone
					</label>

					<div className='w-full mt-1 py-2 pr-3'>
						<span className='text-theme-gray-800 text-sm'>
							{user?.mobilePhone ? (
								<a href={`tel:${user?.mobilePhone}`} className='text-blue-500 hover:opacity-80'>
									{user?.mobilePhone}
								</a>
							) : (
								'N/A'
							)}
						</span>
					</div>
				</div>

				<div className='w-full col-span-12 lg:col-span-6'>
					<label htmlFor='homePhone' className='block text-sm font-medium text-theme-gray-800'>
						Home phone
					</label>

					<div className='w-full mt-1 py-2 pr-3'>
						<span className='text-theme-gray-800 text-sm'>
							{user?.homePhone ? (
								<a href={`tel:${user?.homePhone}`} className='text-blue-500 hover:opacity-80'>
									{user?.homePhone}
								</a>
							) : (
								'N/A'
							)}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

const UserAddressDetails = ({ user }) => {
	return (
		<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
			<div className='mb-4 flex flex-row items-center justify-between'>
				<h3 className='text-xl font-bold text-theme-gray-800'>Address Details</h3>
			</div>

			<div className='w-full grid grid-cols-12 gap-6'>
				<div className='w-full col-span-12 lg:col-span-6'>
					<label htmlFor='addressStreet' className='block text-sm font-medium text-theme-gray-800'>
						Street
					</label>

					<div className='w-full mt-1 py-2 pr-3'>
						<span className='text-theme-gray-800 text-sm'>{user?.address?.street || 'N/A'}</span>
					</div>
				</div>

				<div className='w-full col-span-12 lg:col-span-6'>
					<label htmlFor='addressCommunity' className='block text-sm font-medium text-theme-gray-800'>
						Community
					</label>

					<div className='w-full mt-1 py-2 pr-3'>
						<span className='text-theme-gray-800 text-sm'>{user?.address?.community || 'N/A'}</span>
					</div>
				</div>

				<div className='w-full col-span-12 lg:col-span-6'>
					<label htmlFor='addressDistrict' className='block text-sm font-medium text-theme-gray-800'>
						District
					</label>

					<div className='w-full mt-1 py-2 pr-3'>
						<span className='text-theme-gray-800 text-sm'>{user?.address?.district || 'N/A'}</span>
					</div>
				</div>

				<div className='w-full col-span-12 lg:col-span-6'>
					<label htmlFor='addressCountry' className='block text-sm font-medium text-theme-gray-800'>
						Country
					</label>

					<div className='w-full mt-1 py-2 pr-3'>
						<span className='text-theme-gray-800 text-sm'>{user?.address?.country || 'N/A'}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

const UserParentInformation = ({ user }) => {
	return (
		<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
			<div className='mb-4 flex flex-row items-center justify-between'>
				<h3 className='text-xl font-bold text-theme-gray-800'>Parent Information</h3>
			</div>

			<div className='mb-2 flex flex-row items-center justify-between'>
				<h3 className='text-xs font-bold text-blue-500 uppercase'>Mother</h3>
			</div>

			<div className='w-full grid grid-cols-12 gap-6'>
				<div className='w-full col-span-12 lg:col-span-6'>
					<label htmlFor='motherFirstName' className='block text-sm font-medium text-theme-gray-800'>
						First name
					</label>

					<div className='w-full mt-1 py-2 pr-3'>
						<span className='text-theme-gray-800 text-sm'>{user?.parents?.mother?.name?.first || 'N/A'}</span>
					</div>
				</div>

				<div className='w-full col-span-12 lg:col-span-6'>
					<label htmlFor='motherLastName' className='block text-sm font-medium text-theme-gray-800'>
						Last name
					</label>

					<div className='w-full mt-1 py-2 pr-3'>
						<span className='text-theme-gray-800 text-sm'>{user?.parents?.mother?.name?.last || 'N/A'}</span>
					</div>
				</div>

				<div className='w-full col-span-12 lg:col-span-6'>
					<label htmlFor='motherEmailAddress' className='block text-sm font-medium text-theme-gray-800'>
						Email address
					</label>

					<div className='w-full mt-1 py-2 pr-3'>
						<span className='text-theme-gray-800 text-sm'>{user?.parents?.mother?.emailAddress || 'N/A'}</span>
					</div>
				</div>

				<div className='w-full col-span-12 lg:col-span-6'>
					<label htmlFor='motherMobilePhone' className='block text-sm font-medium text-theme-gray-800'>
						Mobile phone
					</label>

					<div className='w-full mt-1 py-2 pr-3'>
						<span className='text-theme-gray-800 text-sm'>{user?.parents?.mother?.mobilePhone || 'N/A'}</span>
					</div>
				</div>
			</div>

			<div className='mt-6 mb-2 flex flex-row items-center justify-between'>
				<h3 className='text-xs font-bold text-blue-500 uppercase'>Father</h3>
			</div>

			<div className='w-full grid grid-cols-12 gap-6'>
				<div className='w-full col-span-12 lg:col-span-6'>
					<label htmlFor='fatherFirstName' className='block text-sm font-medium text-theme-gray-800'>
						First name
					</label>

					<div className='w-full mt-1 py-2 pr-3'>
						<span className='text-theme-gray-800 text-sm'>{user?.parents?.father?.name?.first || 'N/A'}</span>
					</div>
				</div>

				<div className='w-full col-span-12 lg:col-span-6'>
					<label htmlFor='fatherLastName' className='block text-sm font-medium text-theme-gray-800'>
						Last name
					</label>

					<div className='w-full mt-1 py-2 pr-3'>
						<span className='text-theme-gray-800 text-sm'>{user?.parents?.father?.name?.last || 'N/A'}</span>
					</div>
				</div>

				<div className='w-full col-span-12 lg:col-span-6'>
					<label htmlFor='fatherEmailAddress' className='block text-sm font-medium text-theme-gray-800'>
						Email address
					</label>

					<div className='w-full mt-1 py-2 pr-3'>
						<span className='text-theme-gray-800 text-sm'>{user?.parents?.father?.emailAddress || 'N/A'}</span>
					</div>
				</div>

				<div className='w-full col-span-12 lg:col-span-6'>
					<label htmlFor='fatherMobilePhone' className='block text-sm font-medium text-theme-gray-800'>
						Mobile phone
					</label>

					<div className='w-full mt-1 py-2 pr-3'>
						<span className='text-theme-gray-800 text-sm'>{user?.parents?.father?.mobilePhone || 'N/A'}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export { UserProfileInformation, UserSchoolDetails, UserPersonalDetails, UserContactDetails, UserAddressDetails, UserParentInformation };
