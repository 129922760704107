import React from 'react';
import { Transition, Disclosure } from '@headlessui/react';

const FaqItem = ({ faq }) => {
	return (
		<Disclosure>
			{({ open }) => (
				<div className='w-full bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
					<Disclosure.Button className='w-full flex flex-row items-center justify-between'>
						<h3 className='text-left text-lg font-bold text-theme-gray-800'>Q: {faq.question}</h3>

						<div className='inline-block text-left'>
							<button type='button' className='p-1.5 flex items-center justify-center text-center text-theme-gray-800 hover:text-theme-gray-800/80  '>
								<svg className={`${open && 'rotate-180 transform'} w-4 h-4`} xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
									<path fillRule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708' />
								</svg>
							</button>
						</div>
					</Disclosure.Button>

					<Transition enter='transition duration-100 ease-out' enterFrom='transform scale-95 opacity-0' enterTo='transform scale-100 opacity-100' leave='transition duration-75 ease-out' leaveFrom='transform scale-100 opacity-100' leaveTo='transform scale-95 opacity-0'>
						<Disclosure.Panel className='mt-4'>
							<p className='text-theme-gray-800 text-base text-justify'>A: {faq.answer}</p>
						</Disclosure.Panel>
					</Transition>
				</div>
			)}
		</Disclosure>
	);
};

export default FaqItem;
