const Alert = ({ type, message, onClose }) => {
	return (
		<div role='alert' className='rounded-lg border border-gray-100 bg-white p-4 shadow-md'>
			<div className='flex items-start gap-4'>
				{type === 'error' ? (
					<span className='text-red-500'>
						<svg className='size-6' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
							<path d='M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' />
							<path d='M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' />
							<path d='M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z' />
						</svg>
					</span>
				) : type === 'success' ? (
					<span className='text-green-500'>
						<svg className='size-6' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor'>
							<path strokeLinecap='round' strokeLinejoin='round' d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z' />
						</svg>
					</span>
				) : type === 'info' ? (
					<span className='text-yellow-500'>
						<svg className='size-6' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth='1.5' stroke='currentColor'>
							<path strokeLinecap='round' strokeLinejoin='round' d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z' />
						</svg>
					</span>
				) : null}

				<div className='flex-1'>
					<strong className='block font-medium text-black'>{type === 'error' ? 'Error' : type === 'success' ? 'Success' : 'Alert'}</strong>

					<p className='mt-1 text-sm text-gray-800'>{message}</p>
				</div>

				<button type='button' onClick={onClose} className='text-gray-600 transition hover:text-gray-600/80'>
					<span className='sr-only'>Dismiss popup</span>

					<svg className='size-6' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
						<path fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' d='M368 368L144 144M368 144L144 368' />
					</svg>
				</button>
			</div>
		</div>
	);
};

export default Alert;
