import React, { createContext, useState, useContext } from 'react';

const AlertContext = createContext();

const useAlert = () => useContext(AlertContext);

const AlertProvider = ({ children }) => {
	const [alerts, setAlerts] = useState([]);

	const addAlert = (alertType, alertMessage) => {
		const hasDuplicateDifferentType = alerts.some((alert) => alert.message === alertMessage && alert.type !== alertType);

		if (hasDuplicateDifferentType) {
			setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.message !== alertMessage || alert.type === alertType));
		} else {
			const hasDuplicate = alerts.some((alert) => alert.message === alertMessage && alert.type === alertType);

			if (hasDuplicate) {
				setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.message !== alertMessage));
			}
		}

		const id = Math.random().toString(36).substr(2, 9);

		setAlerts((prevAlerts) => [
			...prevAlerts,
			{
				id,
				type: alertType,
				message: alertMessage,
			},
		]);

		setTimeout(() => removeAlert(id), 5000);
	};

	const removeAlert = (alertId) => {
		setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== alertId));
	};

	return (
		<AlertContext.Provider
			value={{
				alerts,
				addAlert,
				removeAlert,
			}}>
			{children}
		</AlertContext.Provider>
	);
};

export { useAlert, AlertProvider };
