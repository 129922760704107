import React, { useContext, Fragment, useEffect, useRef, useState } from 'react';
import { useBeforeUnload, useParams } from 'react-router-dom';
import { useCopyToClipboard, useStartTyping, useTitle, useToggle } from 'react-use';
import { Menu, Transition } from '@headlessui/react';
import { Timestamp } from 'firebase/firestore';
import { DataContext, AuthContext } from '../../context';
import { MainContainer, Loader, ChatsCard } from '../../components';
import { useAlertHandling } from '../../utils';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import badWords from './badWords';
import Filter from 'bad-words';

const badWordsFilter = new Filter();

badWordsFilter.addWords(...badWords);

const ChatTextSender = ({ idx, msg, time, status, handleDeleteChatMessage }) => {
	const { appData } = useContext(DataContext);
	const { showAlert } = useAlertHandling();

	const [copyState, copyToClipboard] = useCopyToClipboard();
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const cleanMsg = badWordsFilter.clean(msg);

	const handleCopyMessage = async (e) => {
		e.preventDefault();

		try {
			if (!cleanMsg) {
				showAlert('error', 'An unknown error occurred');
			}

			copyToClipboard(cleanMsg);

			if (copyState?.error) {
				showAlert('error', 'An unknown error occurred');
			} else {
				showAlert('success', 'Copied to clipboard');
			}
		} catch (err) {
			if (appData?.debugMode) {
				console.error(err);
			}

			showAlert('error', 'An unknown error occurred');
		}

		setDropdownOpen(false);
	};

	return (
		<li className='flex justify-end gap-2.5'>
			<Menu as='div' className='relative inline-flex self-center items-center'>
				<Menu.Button
					onClick={(e) => {
						e.preventDefault();
						setDropdownOpen(true);
					}}
					className='inline-flex self-center items-center p-2 text-sm font-medium text-center text-theme-gray-800 bg-theme-white rounded-lg ring-0 ring-offset-0 border-none outline-none hover:opacity-80 focus:outline-none focus-visible:outline-none focus:ring-offset-0 focus:ring-0 focus-visible:ring-0'>
					<svg className='size-4 fill-theme-gray-600' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
						<path d='M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0' />
					</svg>
				</Menu.Button>

				{dropdownOpen && (
					<Menu.Items static className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-theme-gray-100 rounded-md bg-theme-white shadow-lg ring-1 ring-theme-black/5 focus:outline-none'>
						<div className='px-1 py-1'>
							<Menu.Item>
								{({ active }) => (
									<button
										type='button'
										onClick={(e) => {
											e.preventDefault();
											setDropdownOpen(false);
										}}
										className={`${active ? 'bg-blue-500 text-white' : 'text-theme-gray-800'} group flex w-full items-center rounded-md px-2 py-2 text-sm gap-2`}>
										<svg className={`${active ? 'fill-white' : 'fill-theme-gray-800'} transition-all duration-300 ease-in-out`} xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
											<path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.7 8.7 0 0 0-1.921-.306 7 7 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254l-.042-.028a.147.147 0 0 1 0-.252l.042-.028zM7.8 10.386q.103 0 .223.006c.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96z' />
										</svg>
										Reply
									</button>
								)}
							</Menu.Item>

							<Menu.Item>
								{({ active }) => (
									<button
										type='button'
										onClick={(e) => {
											e.preventDefault();
											setDropdownOpen(false);
										}}
										className={`${active ? 'bg-blue-500 text-white' : 'text-theme-gray-800'} group flex w-full items-center rounded-md px-2 py-2 text-sm gap-2`}>
										<svg className={`${active ? 'fill-white' : 'fill-theme-gray-800'} transition-all duration-300 ease-in-out`} xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
											<path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
											<path fill-rule='evenodd' d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z' />
										</svg>
										Edit
									</button>
								)}
							</Menu.Item>

							<Menu.Item>
								{({ active }) => (
									<button type='button' onClick={handleCopyMessage} className={`${active ? 'bg-blue-500 text-white' : 'text-theme-gray-800'} group flex w-full items-center rounded-md px-2 py-2 text-sm gap-2`}>
										<svg className={`${active ? 'fill-white' : 'fill-theme-gray-800'} transition-all duration-300 ease-in-out`} xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
											<path fillRule='evenodd' d='M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z' />
										</svg>
										Copy
									</button>
								)}
							</Menu.Item>

							<Menu.Item>
								{({ active }) => (
									<button
										type='button'
										onClick={(e) => {
											e.preventDefault();
											showAlert('success', 'Message reported');
											setDropdownOpen(false);
										}}
										className={`${active ? 'bg-blue-500 text-white' : 'text-theme-gray-800'} group flex w-full items-center rounded-md px-2 py-2 text-sm gap-2`}>
										<svg className={`${active ? 'fill-white' : 'fill-theme-gray-800'} transition-all duration-300 ease-in-out`} xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
											<path d='M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z' />
										</svg>
										Report
									</button>
								)}
							</Menu.Item>

							<Menu.Item>
								{({ active }) => (
									<button
										type='button'
										onClick={(e) => {
											e.preventDefault();
											handleDeleteChatMessage(idx);
											setDropdownOpen(false);
										}}
										className={`${active ? 'bg-red-50 dark:text-white dark:bg-red-800' : 'text-red-500'} group flex w-full items-center rounded-md px-2 py-2 text-sm gap-2`}>
										<svg className={`${active ? 'dark:fill-white' : 'fill-red-500'} transition-all duration-300 ease-in-out`} xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
											<path d='M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5' />
										</svg>
										Delete
									</button>
								)}
							</Menu.Item>
						</div>
					</Menu.Items>
				)}
			</Menu>

			<div className='flex flex-col gap-1 w-full max-w-[320px]'>
				<div className='flex items-center justify-end space-x-2'>
					<span className='text-sm font-normal text-theme-gray-600'>{time}</span>
				</div>

				<div className='flex flex-col leading-1.5 p-4 border-blue-500 bg-blue-500 rounded-tl-xl rounded-tr-none rounded-bl-xl rounded-br-xl'>
					<p className='text-sm font-normal text-white'>{cleanMsg}</p>
				</div>

				{status && <span className='text-sm font-normal text-theme-gray-600'>{status}</span>}
			</div>
		</li>
	);
};

const ChatTextRecipient = ({ idx, msg, time, handleDeleteChatMessage }) => {
	const { appData } = useContext(DataContext);
	const { showAlert } = useAlertHandling();

	const [copyState, copyToClipboard] = useCopyToClipboard();
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const cleanMsg = badWordsFilter.clean(msg);

	const handleCopyMessage = async (e) => {
		e.preventDefault();

		try {
			if (!cleanMsg) {
				showAlert('error', 'An unknown error occurred');
			}

			copyToClipboard(cleanMsg);

			if (copyState?.error) {
				showAlert('error', 'An unknown error occurred');
			} else {
				showAlert('success', 'Copied to clipboard');
			}
		} catch (err) {
			if (appData?.debugMode) {
				console.error(err);
			}

			showAlert('error', 'An unknown error occurred');
		}

		setDropdownOpen(false);
	};

	return (
		<li className='flex justify-start gap-2.5'>
			<div className='flex flex-col gap-1 w-full max-w-[320px]'>
				<div className='flex items-center space-x-2'>
					<span className='text-sm font-normal text-theme-gray-600'>{time}</span>
				</div>

				<div className='flex flex-col leading-1.5 p-4 border-theme-gray-200 bg-theme-gray-100 rounded-e-xl rounded-es-2xl'>
					<p className='text-sm font-normal text-theme-gray-800'>{cleanMsg}</p>
				</div>
			</div>

			<Menu as='div' className='relative inline-flex self-center items-center'>
				<Menu.Button
					onClick={(e) => {
						e.preventDefault();
						setDropdownOpen(true);
					}}
					className='inline-flex self-center items-center p-2 text-sm font-medium text-center text-theme-gray-800 bg-theme-white rounded-lg ring-0 ring-offset-0 border-none outline-none hover:opacity-80 focus:outline-none focus-visible:outline-none focus:ring-offset-0 focus:ring-0 focus-visible:ring-0'>
					<svg className='size-4 fill-theme-gray-600' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
						<path d='M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0' />
					</svg>
				</Menu.Button>

				{dropdownOpen && (
					<Menu.Items static className='absolute right-0 mt-2 w-56 origin-top-right divide-y divide-theme-gray-100 rounded-md bg-theme-white shadow-lg ring-1 ring-theme-black/5 focus:outline-none'>
						<div className='px-1 py-1'>
							<Menu.Item>
								{({ active }) => (
									<button
										type='button'
										onClick={(e) => {
											e.preventDefault();
											setDropdownOpen(false);
										}}
										className={`${active ? 'bg-blue-500 text-white' : 'text-theme-gray-800'} group flex w-full items-center rounded-md px-2 py-2 text-sm gap-2`}>
										<svg className={`${active ? 'fill-white' : 'fill-theme-gray-800'} transition-all duration-300 ease-in-out`} xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
											<path d='M6.598 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.7 8.7 0 0 0-1.921-.306 7 7 0 0 0-.798.008h-.013l-.005.001h-.001L7.3 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L2.614 8.254l-.042-.028a.147.147 0 0 1 0-.252l.042-.028zM7.8 10.386q.103 0 .223.006c.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96z' />
										</svg>
										Reply
									</button>
								)}
							</Menu.Item>

							<Menu.Item>
								{({ active }) => (
									<button
										type='button'
										onClick={(e) => {
											e.preventDefault();
											setDropdownOpen(false);
										}}
										className={`${active ? 'bg-blue-500 text-white' : 'text-theme-gray-800'} group flex w-full items-center rounded-md px-2 py-2 text-sm gap-2`}>
										<svg className={`${active ? 'fill-white' : 'fill-theme-gray-800'} transition-all duration-300 ease-in-out`} xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
											<path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
											<path fill-rule='evenodd' d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z' />
										</svg>
										Edit
									</button>
								)}
							</Menu.Item>

							<Menu.Item>
								{({ active }) => (
									<button type='button' onClick={handleCopyMessage} className={`${active ? 'bg-blue-500 text-white' : 'text-theme-gray-800'} group flex w-full items-center rounded-md px-2 py-2 text-sm gap-2`}>
										<svg className={`${active ? 'fill-white' : 'fill-theme-gray-800'} transition-all duration-300 ease-in-out`} xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
											<path fillRule='evenodd' d='M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z' />
										</svg>
										Copy
									</button>
								)}
							</Menu.Item>

							<Menu.Item>
								{({ active }) => (
									<button
										type='button'
										onClick={(e) => {
											e.preventDefault();
											showAlert('success', 'Message reported');
											setDropdownOpen(false);
										}}
										className={`${active ? 'bg-blue-500 text-white' : 'text-theme-gray-800'} group flex w-full items-center rounded-md px-2 py-2 text-sm gap-2`}>
										<svg className={`${active ? 'fill-white' : 'fill-theme-gray-800'} transition-all duration-300 ease-in-out`} xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
											<path d='M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12 12 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A20 20 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a20 20 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21 21 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21 21 0 0 0 14 7.655V1.222z' />
										</svg>
										Report
									</button>
								)}
							</Menu.Item>

							<Menu.Item>
								{({ active }) => (
									<button
										type='button'
										onClick={(e) => {
											e.preventDefault();
											handleDeleteChatMessage(idx);
											setDropdownOpen(false);
										}}
										className={`${active ? 'bg-red-50 dark:text-white dark:bg-red-800' : 'text-red-500'} group flex w-full items-center rounded-md px-2 py-2 text-sm gap-2`}>
										<svg className={`${active ? 'dark:fill-white' : 'fill-red-500'} transition-all duration-300 ease-in-out`} xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
											<path d='M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5' />
										</svg>
										Delete
									</button>
								)}
							</Menu.Item>
						</div>
					</Menu.Items>
				)}
			</Menu>
		</li>
	);
};

const ChatVoiceNoteSender = ({ length, currentPlaybackDuration, time, status }) => {
	const [isPlaying, setIsPlaying] = useState(false);
	const [playbackDuration, setPlaybackDuration] = useState(currentPlaybackDuration || 0);

	useEffect(() => {
		let intervalId;

		if (isPlaying && playbackDuration < length) {
			intervalId = setInterval(() => {
				setPlaybackDuration((prev) => Math.min(prev + 1, length));
			}, 1000);
		} else {
			clearInterval(intervalId);
		}

		return () => clearInterval(intervalId);
	}, [isPlaying, length, playbackDuration]);

	const formatTime = (duration) => {
		const minutes = Math.floor(duration / 60);
		const seconds = duration % 60;

		return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
	};

	return (
		<li className='flex justify-start gap-2.5'>
			<div className='flex flex-col gap-1 w-full max-w-[320px]'>
				<div className='flex items-center space-x-2'>
					<span className='text-sm font-normal text-theme-gray-600'>{time}</span>
				</div>

				<div className='flex flex-col leading-1.5 p-4 border-theme-gray-200 bg-theme-gray-100 rounded-e-xl rounded-es-2xl'>
					<div className='flex items-center space-x-2 rtl:space-x-reverse'>
						<button
							type='button'
							onClick={(e) => {
								e.preventDefault();
								setIsPlaying(!isPlaying);
							}}
							className='inline-flex self-center items-center p-2 text-sm font-medium text-center text-theme-gray-800 bg-theme-gray-100 rounded-lg hover:opacity-80 focus:ring-4 focus:outline-none focus:ring-gray-50'>
							<svg className='size-6 fill-theme-gray-800' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
								{isPlaying ? <path d='M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5m5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5' /> : <path d='m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393' />}
							</svg>
						</button>

						<svg className='w-[145px] md:w-[185px] md:h-[40px]' aria-hidden='true' viewBox='0 0 185 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<rect y='17' width='3' height='6' rx='1.5' fill='#6B7280' className='dark:fill-white' />
							<rect x='7' y='15.5' width='3' height='9' rx='1.5' fill='#6B7280' className='dark:fill-white' />
							<rect x='21' y='6.5' width='3' height='27' rx='1.5' fill='#6B7280' className='dark:fill-white' />
							<rect x='14' y='6.5' width='3' height='27' rx='1.5' fill='#6B7280' className='dark:fill-white' />
							<rect x='28' y='3' width='3' height='34' rx='1.5' fill='#6B7280' className='dark:fill-white' />
							<rect x='35' y='3' width='3' height='34' rx='1.5' fill='#6B7280' className='dark:fill-white' />
							<rect x='42' y='5.5' width='3' height='29' rx='1.5' fill='#6B7280' className='dark:fill-white' />
							<rect x='49' y='10' width='3' height='20' rx='1.5' fill='#6B7280' className='dark:fill-white' />
							<rect x='56' y='13.5' width='3' height='13' rx='1.5' fill='#6B7280' className='dark:fill-white' />
							<rect x='63' y='16' width='3' height='8' rx='1.5' fill='#6B7280' className='dark:fill-white' />
							<rect x='70' y='12.5' width='3' height='15' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='77' y='3' width='3' height='34' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='84' y='3' width='3' height='34' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='91' y='0.5' width='3' height='39' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='98' y='0.5' width='3' height='39' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='105' y='2' width='3' height='36' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='112' y='6.5' width='3' height='27' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='119' y='9' width='3' height='22' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='126' y='11.5' width='3' height='17' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='133' y='2' width='3' height='36' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='140' y='2' width='3' height='36' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='147' y='7' width='3' height='26' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='154' y='9' width='3' height='22' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='161' y='9' width='3' height='22' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='168' y='13.5' width='3' height='13' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='175' y='16' width='3' height='8' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='182' y='17.5' width='3' height='5' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='66' y='16' width='8' height='8' rx='4' fill='#1C64F2' />
						</svg>

						<span className='inline-flex self-center items-center p-2 text-sm font-medium text-theme-gray-800'>{formatTime(playbackDuration)}</span>
					</div>
				</div>

				{status && <span className='text-sm font-normal text-theme-gray-600'>{status}</span>}
			</div>
		</li>
	);
};

const ChatVoiceNoteRecipient = ({ length, currentPlaybackDuration, time }) => {
	const [isPlaying, setIsPlaying] = useState(false);
	const [playbackDuration, setPlaybackDuration] = useState(currentPlaybackDuration || 0);

	useEffect(() => {
		let intervalId;

		if (isPlaying && playbackDuration < length) {
			intervalId = setInterval(() => {
				setPlaybackDuration((prev) => Math.min(prev + 1, length));
			}, 1000);
		} else {
			clearInterval(intervalId);
		}

		return () => clearInterval(intervalId);
	}, [isPlaying, length, playbackDuration]);

	const formatTime = (duration) => {
		const minutes = Math.floor(duration / 60);
		const seconds = duration % 60;

		return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
	};

	return (
		<li className='flex justify-start gap-2.5'>
			<div className='flex flex-col gap-1 w-full max-w-[320px]'>
				<div className='flex items-center space-x-2'>
					<span className='text-sm font-normal text-theme-gray-600'>{time}</span>
				</div>

				<div className='flex flex-col leading-1.5 p-4 border-theme-gray-200 bg-theme-gray-100 rounded-e-xl rounded-es-2xl'>
					<div className='flex items-center space-x-2 rtl:space-x-reverse'>
						<button
							type='button'
							onClick={(e) => {
								e.preventDefault();
								setIsPlaying(!isPlaying);
							}}
							className='inline-flex self-center items-center p-2 text-sm font-medium text-center text-theme-gray-800 bg-theme-gray-100 rounded-lg hover:opacity-80 focus:ring-4 focus:outline-none focus:ring-gray-50'>
							<svg className='size-6 fill-theme-gray-800' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
								{isPlaying ? <path d='M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5m5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5' /> : <path d='m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393' />}
							</svg>
						</button>

						<svg className='w-[145px] md:w-[185px] md:h-[40px]' aria-hidden='true' viewBox='0 0 185 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
							<rect y='17' width='3' height='6' rx='1.5' fill='#6B7280' className='dark:fill-white' />
							<rect x='7' y='15.5' width='3' height='9' rx='1.5' fill='#6B7280' className='dark:fill-white' />
							<rect x='21' y='6.5' width='3' height='27' rx='1.5' fill='#6B7280' className='dark:fill-white' />
							<rect x='14' y='6.5' width='3' height='27' rx='1.5' fill='#6B7280' className='dark:fill-white' />
							<rect x='28' y='3' width='3' height='34' rx='1.5' fill='#6B7280' className='dark:fill-white' />
							<rect x='35' y='3' width='3' height='34' rx='1.5' fill='#6B7280' className='dark:fill-white' />
							<rect x='42' y='5.5' width='3' height='29' rx='1.5' fill='#6B7280' className='dark:fill-white' />
							<rect x='49' y='10' width='3' height='20' rx='1.5' fill='#6B7280' className='dark:fill-white' />
							<rect x='56' y='13.5' width='3' height='13' rx='1.5' fill='#6B7280' className='dark:fill-white' />
							<rect x='63' y='16' width='3' height='8' rx='1.5' fill='#6B7280' className='dark:fill-white' />
							<rect x='70' y='12.5' width='3' height='15' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='77' y='3' width='3' height='34' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='84' y='3' width='3' height='34' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='91' y='0.5' width='3' height='39' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='98' y='0.5' width='3' height='39' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='105' y='2' width='3' height='36' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='112' y='6.5' width='3' height='27' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='119' y='9' width='3' height='22' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='126' y='11.5' width='3' height='17' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='133' y='2' width='3' height='36' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='140' y='2' width='3' height='36' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='147' y='7' width='3' height='26' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='154' y='9' width='3' height='22' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='161' y='9' width='3' height='22' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='168' y='13.5' width='3' height='13' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='175' y='16' width='3' height='8' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='182' y='17.5' width='3' height='5' rx='1.5' fill='#E5E7EB' className='dark:fill-gray-500' />
							<rect x='66' y='16' width='8' height='8' rx='4' fill='#1C64F2' />
						</svg>

						<span className='inline-flex self-center items-center p-2 text-sm font-medium text-theme-gray-800'>{formatTime(playbackDuration)}</span>
					</div>
				</div>
			</div>
		</li>
	);
};

const Chat = () => {
	const { chatId } = useParams();

	useTitle('Chats | Grade Portal');

	const { currentUser } = useContext(AuthContext);
	const { appData, chats, divRef, isDarkMode, handleScroll, users, footerHeight, createNewChatMessage, deleteChat } = useContext(DataContext);
	const { showAlert } = useAlertHandling();

	const headerRef = useRef(null);
	const composerRef = useRef(null);

	const [headerHeight, setHeaderHeight] = useState(null);
	const [composerHeight, setComposerHeight] = useState(null);

	const [screenWidthPadding, setScreenWidthPadding] = useState(null);

	useEffect(() => {
		const getScreenWidthPadding = () => {
			const width = window?.innerWidth;

			switch (true) {
				case width > 768:
					return '4rem';
				case width > 640:
					return '2.5rem';
				default:
					return '1rem';
			}
		};

		const _screenWidthPadding = getScreenWidthPadding();
		setScreenWidthPadding(_screenWidthPadding);
	}, []);

	const chatContainerRef = useRef(null);

	const scrollToBottom = () => {
		if (chatContainerRef?.current) {
			chatContainerRef.current.scrollTop = chatContainerRef?.current?.scrollHeight;
		}
	};

	useEffect(() => {
		scrollToBottom();
	}, []);

	useStartTyping(() => scrollToBottom());

	const [chat, setChat] = useState([]);
	const [chatLoading, setChatLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (chat) {
					const fetchedChats = chats?.filter((chat) => chat?.participants?.includes(currentUser?.data?.id));
					if (fetchedChats) {
						const fetchedChat = fetchedChats?.find((chat) => chat?.id === chatId);

						const counterpart = users?.find((user) => user?.id === fetchedChat?.participants?.find((participant) => participant !== currentUser?.data?.id));

						const baseUrl = 'https://ui-avatars.com/api/?background=3b82f6&color=fff&name=';
						let imgUrl = '';

						if (counterpart?.name?.first && counterpart?.name?.last) {
							imgUrl = baseUrl + counterpart?.name?.first + '+' + counterpart?.name?.last;
						} else if (counterpart?.name?.first && !counterpart?.name?.last) {
							imgUrl = baseUrl + counterpart?.name?.first;
						} else if (!counterpart?.name?.first && counterpart?.name?.last) {
							imgUrl = baseUrl + counterpart?.name?.last;
						}

						setChat({
							fetchedChat,
							counterpart,
							imgUrl,
						});
					}
				}
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setChatLoading(false);
		};

		fetchData();
	}, [appData?.debugMode, currentUser?.data?.id, chatId, chats, users]);

	const [counterpartStatus, setCounterpartStatus] = useState('');

	useEffect(() => {
		const headerElement = headerRef?.current;
		const headerElementHeight = headerElement?.offsetHeight;
	
		const composerElement = composerRef?.current;
		const composerElementHeight = composerElement?.offsetHeight;
	
		setHeaderHeight(headerElementHeight);
		setComposerHeight(composerElementHeight);
	}, [chats?.chat, chatLoading, counterpartStatus]);

	useEffect(() => {
		if (currentUser && chat) {
			const lastCounterpartMessage = chat?.fetchedChat?.messages
				?.filter((msg) => msg?.senderId === chat?.counterpart?.id)
				?.reduce((latest, msg) => {
					return !latest || msg?.timeSent?.toDate() > latest?.timeSent?.toDate() ? msg : latest;
				}, null);

			const lastCounterpartMessageTime = lastCounterpartMessage?.timeSent?.toDate();

			const lastCounterpartMessageTimeStr = lastCounterpartMessageTime?.toLocaleTimeString('en-US', {
				hour: 'numeric',
				minute: 'numeric',
			});

			const twoMinsAgo = new Date(Date.now() - 2 * 60 * 1000);

			if (chat?.fetchedChat?.messages?.length > 0 && lastCounterpartMessageTime) {
				if (lastCounterpartMessageTime >= twoMinsAgo) {
					setCounterpartStatus('Online');
				} else {
					setCounterpartStatus(`Last online ${lastCounterpartMessageTimeStr}`);
				}
			} else {
				setCounterpartStatus(null);
			}
		}
	}, [currentUser, chat]);

	const [chatContent, setChatContent] = useState('');
	const [handleSendNewChatLoading, setHandleSendNewChatLoading] = useState(false);

	const emojiPickerRef = useRef(null);
	const [showEmojiPicker, setShowEmojiPicker] = useState(false);

	useEffect(() => {
		const handleClickOutside = (e) => {
			if (emojiPickerRef?.current && !emojiPickerRef?.current?.contains(e.target)) {
				setShowEmojiPicker(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [setShowEmojiPicker]);

	const handleSendNewChat = async (e) => {
		e.preventDefault();
		setHandleSendNewChatLoading(true);

		try {
			const newChatMessageData = {
				chatId: chatId,
				message: {
					type: 'text',
					content: chatContent,
				},
				senderId: currentUser?.data?.id,
				status: 'Delivered',
				timeSent: Timestamp.now(),
			};

			await createNewChatMessage(newChatMessageData);

			setChatContent('');
			scrollToBottom();
		} catch (err) {
			showAlert('error', 'Error sending message');

			if (appData?.debugMode) {
				console.error(err);
			}
		}

		setHandleSendNewChatLoading(false);
	};

	const handleDeleteChatMessage = async (idx) => {
		try {
			await deleteChat(chat?.fetchedChat?.id, idx);
		} catch (err) {
			showAlert('error', 'Error deleting message');

			if (appData?.debugMode) {
				console.error(err);
			}
		}
	};

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<div className='grid grid-cols-12 gap-6 bg-theme-white'>
				<div className='col-span-12 md:col-span-4'>
					<ChatsCard />
				</div>

				<div className='col-span-12 md:col-span-8'>
					<main
						className='h-full sticky top-8 flex-1'
						style={{
							height: `calc(90vh - ${footerHeight}px - ${screenWidthPadding})`,
						}}>
						<div className='h-full relative flex-1 bg-theme-white rounded-lg border border-theme-gray-200 shadow-sm focus:outline-none'>
							{chat?.fetchedChat && chat?.counterpart ? (
								<>
									<div ref={headerRef} className='w-full absolute left-0 top-0 px-6 py-4 border-b border-theme-gray-200'>
										<div className='w-full bg-theme-white flex flex-row items-center justify-between gap-4'>
											<div className='h-full flex items-center justify-start gap-4'>
												<img className='inline-block object-cover rounded-full size-14' src={chat?.imgUrl} alt={chat?.counterpart?.name?.first + ' ' + chat?.counterpart?.name?.last} />

												<div className='flex flex-col items-start justify-center gap-0.5'>
													<p className='text-base text-theme-gray-800 font-bold'>{chat?.counterpart?.name?.first + ' ' + chat?.counterpart?.name?.last}</p>

													{counterpartStatus && <p className='text-sm text-theme-gray-600 truncate'>{counterpartStatus}</p>}
												</div>
											</div>
										</div>
									</div>

									<ul
										ref={chatContainerRef}
										className='w-full bg-theme-white flex flex-col p-6 gap-1 overflow-x-hidden overflow-y-auto no-scrollbar'
										style={{
											height: `calc(100% - ${headerHeight}px - ${composerHeight}px)`,
											marginTop: headerHeight ? `${headerHeight}px` : '0',
											marginBottom: composerHeight ? `${composerHeight}px` : '0',
										}}>
										{!chatLoading ? (
											chat?.fetchedChat?.messages?.length > 0 ? (
												chat?.fetchedChat?.messages
													?.sort((a, b) => a?.timeSent.toDate() - b?.timeSent.toDate())
													?.map((msg, idx, messagesArray) => {
														const timeSent = msg?.timeSent.toDate();
														const formattedTime = timeSent
															? timeSent.toLocaleTimeString('en-US', {
																hour: 'numeric',
																minute: 'numeric',
															})
															: '';

														const isSameDay = (date1, date2) => {
															return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
														};

														const isLastMessage = idx === messagesArray?.length - 1;
														const isFirstMessageOfDay = idx === 0 || !isSameDay(messagesArray[idx - 1]?.timeSent?.toDate(), timeSent);

														const dateSeparator = isFirstMessageOfDay && (
															<li key={`date-separator-${timeSent}`} className='w-full text-center my-2'>
																<span className='text-theme-gray-600 bg-theme-white px-2.5'>
																	{timeSent?.toLocaleDateString('en-US', {
																		weekday: 'long',
																		year: 'numeric',
																		month: 'long',
																		day: 'numeric',
																	})}
																</span>
															</li>
														);

														const messageComponent = () => {
															if (msg?.message?.type === 'text') {
																if (msg?.senderId === currentUser?.data?.id) {
																	return <ChatTextSender key={idx} idx={idx} msg={msg?.message?.content} time={formattedTime} status={isLastMessage ? msg?.status : null} handleDeleteChatMessage={handleDeleteChatMessage} />;
																} else {
																	return <ChatTextRecipient key={idx} idx={idx} msg={msg?.message?.content} time={formattedTime} status={isLastMessage ? msg?.status : null} handleDeleteChatMessage={handleDeleteChatMessage} />;
																}
															} else if (msg?.message?.type === 'voiceNote') {
																if (msg?.senderId === currentUser?.data?.id) {
																	return <ChatVoiceNoteSender key={idx} idx={idx} length={msg?.message?.length} currentPlaybackDuration={74} time={formattedTime} status={isLastMessage ? msg?.status : null} handleDeleteChatMessage={handleDeleteChatMessage} />;
																} else {
																	return <ChatVoiceNoteRecipient key={idx} idx={idx} length={msg?.message?.length} currentPlaybackDuration={74} time={formattedTime} status={isLastMessage ? msg?.status : null} handleDeleteChatMessage={handleDeleteChatMessage} />;
																}
															} else {
																return null;
															}
														};

														return (
															<React.Fragment key={idx}>
																{dateSeparator}

																{messageComponent()}
															</React.Fragment>
														);
													})
											) : (
												<li className='h-full flex items-center justify-center gap-2.5'>
													<div className='h-full w-full flex items-center justify-center'>
														<p className='text-base text-theme-gray-800 font-bold'>No messages</p>
													</div>
												</li>
											)
										) : (
											<li className='h-full flex items-center justify-center gap-2.5'>
												<div className='h-full w-full flex items-center justify-center'>
													<Loader size='lg' />
												</div>
											</li>
										)}
									</ul>

									<div ref={composerRef} className='w-full absolute left-0 bottom-0 p-6'>
										<div className='w-full bg-theme-white inline-flex items-center justify-between gap-4'>
											<div className='w-full'>
												<label htmlFor='message' className='sr-only'>
													Message
												</label>

												<form onSubmit={handleSendNewChat} autoComplete='off'>
													<input
														type='text'
														onChange={(e) => {
															e.preventDefault();
															setChatContent(e.target.value);
														}}
														value={chatContent || ''}
														className='w-full rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'
														id='message'
														placeholder='Message'
														disabled={handleSendNewChatLoading}
													/>
												</form>
											</div>

											<div ref={emojiPickerRef} className='relative h-full flex items-center justify-center'>
												<button
													type='button'
													onClick={(e) => {
														e.preventDefault();
														setShowEmojiPicker(!showEmojiPicker);
													}}
													disabled={handleSendNewChatLoading}
													className={`${handleSendNewChatLoading && 'cursor-not-allowed'} inline-flex items-center justify-center shrink-0 p-1.5 rounded-md border border-theme-gray-200 bg-theme-white shadow-sm focus:outline-none hover:opacity-60`}>
													<svg className='size-4 fill-theme-gray-600' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
														<path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16' />
														<path d='M4.285 9.567a.5.5 0 0 1 .683.183A3.5 3.5 0 0 0 8 11.5a3.5 3.5 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.5 4.5 0 0 1 8 12.5a4.5 4.5 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683M7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5m4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5' />
													</svg>
												</button>

												<Transition show={showEmojiPicker} as={Fragment} className='absolute right-0 bottom-10' enter='transition ease-out duration-100' enterFrom='transform opacity-0 scale-95' enterTo='transform opacity-100 scale-100' leave='transition ease-in duration-75' leaveFrom='transform opacity-100 scale-100' leaveTo='transform opacity-0 scale-95'>
													<div className='shadow-md'>
														<Picker
															data={data}
															theme={isDarkMode ? 'dark' : 'light'}
															previewPosition='none'
															onEmojiSelect={(emoji) => {
																setChatContent((prev) => prev + emoji?.native);
															}}
														/>
													</div>
												</Transition>
											</div>

											<button
												type='button'
												onClick={handleSendNewChat}
												disabled={!chatContent || handleSendNewChatLoading}
												className={`inline-flex items-center justify-center shrink-0 rounded-md border border-blue-500 bg-blue-500 text-white px-4 py-2 gap-2 text-sm font-medium ${!chatContent || handleSendNewChatLoading ? 'opacity-60 cursor-not-allowed' : 'hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'}`}>
												Send
												<svg className='size-4 fill-white' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
													<path d='M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z' />
												</svg>
											</button>
										</div>
									</div>
								</>
							) : (
								<>
									<div ref={headerRef} className='w-full absolute left-0 top-0 px-6 py-4'></div>

									<ul
										ref={chatContainerRef}
										className='w-full bg-theme-white flex flex-col p-6 gap-1 overflow-x-hidden overflow-y-auto no-scrollbar'
										style={{
											height: `calc(100% - ${headerHeight}px - ${composerHeight}px)`,
											marginTop: headerHeight ? `${headerHeight}px` : '0',
											marginBottom: composerHeight ? `${composerHeight}px` : '0',
										}}>
										<li className='h-full flex items-center justify-center gap-2.5'>
											<div className='h-full w-full flex items-center justify-center'>
												<p className='text-base text-theme-gray-800 font-bold'>Chat not found</p>
											</div>
										</li>
									</ul>

									<div ref={composerRef} className='w-full absolute left-0 bottom-0 p-6'></div>
								</>
							)}
						</div>
					</main>
				</div>
			</div>
		</MainContainer>
	);
};

export default Chat;
