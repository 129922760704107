import React from 'react';

const NetworkStateOffline = () => {
	return (
		<div className='z-50 absolute top-0 left-0 bg-black bg-opacity-80 backdrop-filter backdrop-blur-sm h-screen w-full flex items-center justify-center transition-all ease-in-out duration-[400ms]'>
			<section className='py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
				<div className='mx-auto max-w-screen-sm text-center'>
					<h1 className='w-full mb-4 flex items-center justify-center'>
						<svg className='size-28 md:size-44 fill-white' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
							<path d='M10.706 3.294A12.6 12.6 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.52.52 0 0 0 .668.05A11.45 11.45 0 0 1 8 4q.946 0 1.852.148zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.45 8.45 0 0 1 3.51-1.27zm2.596 1.404.785-.785q.947.362 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.5 8.5 0 0 0-1.98-.932zM8 10l.933-.933a6.5 6.5 0 0 1 2.013.637c.285.145.326.524.1.75l-.015.015a.53.53 0 0 1-.611.09A5.5 5.5 0 0 0 8 10m4.905-4.905.747-.747q.886.451 1.685 1.03a.485.485 0 0 1 .047.737.52.52 0 0 1-.668.05 11.5 11.5 0 0 0-1.811-1.07M9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 0 1-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A2 2 0 0 1 8 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 0 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75z' />
						</svg>
					</h1>

					<div className='mb-8 inline-flex items-center gap-4'>
						<p className='text-2xl tracking-tight font-bold text-blue-500 md:text-3xl'>Oops! It looks like you're offline</p>

						<div className='w-14 h-8 flex justify-center items-end'>
							<div className='w-5 h-2.5 bg-blue-500 loading-bar mx-[5px] my-0 rounded-[5px]'></div>
							<div className='w-5 h-2.5 bg-blue-500 loading-bar mx-[5px] my-0 rounded-[5px] animation-delay-100'></div>
							<div className='w-5 h-2.5 bg-blue-500 loading-bar mx-[5px] my-0 rounded-[5px] animation-delay-200'></div>
							<div className='w-5 h-2.5 bg-blue-500 loading-bar mx-[5px] my-0 rounded-[5px] animation-delay-300'></div>
						</div>
					</div>

					<p className='mb-8 text-base text-gray-200'>We couldn't establish a connection to the internet. Please check your network connection and try again.</p>
				</div>
			</section>
		</div>
	);
};

export default NetworkStateOffline;
