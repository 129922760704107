import { useAlert } from '../context';

const useAlertHandling = () => {
	const { addAlert } = useAlert();

	const showAlert = (alertType, alertMessage) => {
		addAlert(alertType, alertMessage);
	};

	return {
		showAlert,
	};
};

export default useAlertHandling;
