import React, { useContext, useEffect, useRef } from 'react';
import { DataContext } from '../context';
import Footer from './Footer';

const MainContainer = ({ children, divRef, handleScroll }) => {
	const { footerHeight, setFooterHeight } = useContext(DataContext);
	const footerRef = useRef(null);

	useEffect(() => {
		if (footerRef?.current) {
			const height = footerRef?.current?.clientHeight;
			setFooterHeight(height);
		}
	}, [setFooterHeight]);

	return (
		<main ref={divRef} onScroll={handleScroll} className='relative flex-1 overflow-x-hidden overflow-y-auto focus:outline-none'>
			<div
				className='flex flex-col gap-8 mx-auto 2xl:max-w-7xl px-4 py-4 sm:px-6 md:px-8 sm:py-6 md:py-8'
				style={{
					minHeight: `calc(90vh - ${footerHeight}px)`,
				}}>
				{children}
			</div>

			<Footer footerRef={footerRef} />
		</main>
	);
};

export default MainContainer;
