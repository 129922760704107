import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { AuthContext, DataContext } from '../../context';
import { EnlistedStudentsTable, MainContainer } from '../../components';
import { useAlertHandling } from '../../utils';

const SchoolClass = () => {
	const { schoolId, classId } = useParams();

	useTitle(`${classId || 'Class'} | Grade Portal`);

	const { currentUser } = useContext(AuthContext);
	const { appData, divRef, schools, handleScroll, subjects, assessments, users, createNewGrade } = useContext(DataContext);
	const { showAlert } = useAlertHandling();

	const [_class, setClass] = useState(null);

	const [studentsLoading, setStudentsLoading] = useState(true);
	const [classLoading, setClassLoading] = useState(false);

	const [classAssessments, setClassAssessments] = useState(null);
	const [classAssessmentsLoading, setClassAssessmentsLoading] = useState(true);

	const [canAccessClass, setCanAccessClass] = useState(false);
	const [subject, setSubject] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const filteredClasses = schools?.map((school) => school?.classes).flat(1);
				const foundClass = filteredClasses?.find((_class) => _class?.id === classId);
				const _classAssessments = await assessments?.filter((assessment) => assessment?.classId === classId);

				setClassAssessments(_classAssessments);
				setClass(foundClass);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setStudentsLoading(false);
			setClassLoading(false);
			setClassAssessmentsLoading(false);
		};

		fetchData();
	}, [appData?.debugMode, classId, schoolId, schools, users, assessments]);

	useEffect(() => {
		const filteredClasses = schools?.map((school) => school?.classes).flat(1);
		const foundClass = filteredClasses?.find((_class) => _class?.id === classId);

		const fetchSubject = async (foundClass) => {
			try {
				const foundSubject = subjects?.find((subject) => subject?.id === foundClass?.subjectId);
				setSubject(foundSubject);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}
		};

		if (foundClass) {
			fetchSubject(foundClass);
			setCanAccessClass(true);
		} else {
			setCanAccessClass(false);
		}
	}, [appData?.debugMode, classId, schools, subjects]);

	if (!canAccessClass) {
		return (
			<MainContainer divRef={divRef} handleScroll={handleScroll}>
				<section className='bg-theme-white py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
					<div className='mx-auto max-w-screen-sm text-center'>
						<h1 className='mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-theme-gray-800'>404</h1>

						<p className='mb-4 text-3xl tracking-tight font-bold text-blue-500 md:text-4xl'>Oops! Class Not Found</p>

						<p className='mb-8 text-lg text-theme-gray-600'>
							It seems that the class you're trying to access isn't available or authorized for your account. Don't worry, you can explore your classes{' '}
							<Link to='/classes' className='focus:outline-none focus:ring text-blue-500 hover:opacity-80 active:opacity-80'>
								here
							</Link>
							.
						</p>

						<Link to='/' className='inline-block shrink-0 rounded-md border px-5 py-2.5 text-sm font-medium focus:outline-none focus:ring text-white border-blue-500 bg-blue-500 hover:bg-transparent hover:text-blue-500 active:text-blue-500'>
							Back to Homepage
						</Link>
					</div>
				</section>
			</MainContainer>
		);
	}

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<h3 className='text-2xl font-bold text-theme-gray-800'>{subject && classId ? `${classId} - ${subject?.name}` : 'Class'}</h3>
			</section>

			<section className='w-full bg-theme-white'>
				<div className='grid grid-cols-3 gap-6'>
					<div className='col-span-3 flex flex-col items-start gap-6'>
						<div className='w-full bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
							<div className='mb-4 flex flex-row items-center justify-between'>
								<h3 className='text-xl font-bold text-theme-gray-800'>Class Details</h3>
							</div>

							<div className='flow-root'>
								<dl className='-my-3 divide-y divide-theme-gray-200 text-sm'>
									<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-6 md:gap-4'>
										<dt className='font-bold text-theme-gray-800 col-span-2 md:col-span-1'>Subject:</dt>

										<dd className='text-theme-gray-800 col-span-4 md:col-span-5'>{!subject?.name ? 'Loading...' : subject?.name}</dd>
									</div>

									<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-6 md:gap-4'>
										<dt className='font-bold text-theme-gray-800 col-span-2 md:col-span-1'>Description:</dt>

										<dd className='text-theme-gray-800 col-span-4 md:col-span-5'>{!subject?.desc ? 'Loading...' : subject?.desc}</dd>
									</div>
								</dl>
							</div>
						</div>
					</div>

					<div className='col-span-3'>
						{!studentsLoading && !classAssessmentsLoading && (
							<EnlistedStudentsTable
								appData={appData}
								classId={classId}
								users={users}
								currentUser={currentUser}
								currentSchool={schools?.find((school) => school?.id === schoolId)}
								assessments={classAssessments}
								sortedAssessments={classAssessments}
								setRemoveStudentModalState={false}
								setRemoveStudentId={false}
								showAlert={showAlert}
								createNewGrade={createNewGrade}
								archived={!classLoading && _class?.status?.toLowerCase() !== 'active'}
							/>
						)}
					</div>
				</div>
			</section>
		</MainContainer>
	);
};

export default SchoolClass;
