import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { AuthContext, DataContext } from '../context';
import Loader from './Loader';

const Assessments = ({ classId, assessmentId, other, student }) => {
	const { currentUser } = useContext(AuthContext);
	const { appData, assessments } = useContext(DataContext);

	const [classAssessments, setClassAssessments] = useState(null);
	const [classAssessmentsLoading, setClassAssessmentsLoading] = useState(true);

	useEffect(() => {
		const fetchAssessments = async () => {
			try {
				const _classAssessments = await assessments?.filter((assessment) => assessment?.classId === classId);
				const otherAssessments = _classAssessments.filter((assessment) => assessment?.id !== assessmentId);

				setClassAssessments(other ? otherAssessments : _classAssessments);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setClassAssessmentsLoading(false);
		};

		fetchAssessments();
	}, [appData?.debugMode, assessments, assessmentId, classId, other]);

	const [sortAssessmentsField, setSortAssessmentsField] = useState('name');
	const [sortAssessmentsOrder, setSortAssessmentsOrder] = useState('asc');
	const [sortedAssessments, setSortedAssessments] = useState([]);

	const handleSortAssessmentsClick = async (field) => {
		if (sortAssessmentsField === field) {
			setSortAssessmentsOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
		} else {
			setSortAssessmentsField(field);
			setSortAssessmentsOrder('asc');
		}
	};

	useEffect(() => {
		const sortAssessments = async () => {
			let sortedData = [...(classAssessments ?? [])];

			if (sortAssessmentsField) {
				sortedData = sortedData?.sort((a, b) => {
					let valueA, valueB;

					if (sortAssessmentsField === 'weight') {
						valueA = a?.grading?.weight;
						valueB = b?.grading?.weight;
					} else if (sortAssessmentsField === 'name') {
						valueA = a?.name;
						valueB = b?.name;
					} else if (sortAssessmentsField === 'type') {
						valueA = a?.type;
						valueB = b?.type;
					} else if (sortAssessmentsField === 'totalPoints') {
						valueA = a?.grading?.totalPoints;
						valueB = b?.grading?.totalPoints;
					}

					if (sortAssessmentsOrder === 'asc') {
						if (typeof valueA === 'string' && typeof valueB === 'string') {
							return valueA?.localeCompare(valueB, undefined, {
								numeric: true,
							});
						} else if (typeof valueA === 'number' && typeof valueB === 'number') {
							return valueA - valueB;
						} else {
							return 0;
						}
					} else if (sortAssessmentsOrder === 'desc') {
						if (typeof valueB === 'string' && typeof valueA === 'string') {
							return valueB?.localeCompare(valueA, undefined, {
								numeric: true,
							});
						} else if (typeof valueB === 'number' && typeof valueA === 'number') {
							return valueB - valueA;
						} else {
							return 0;
						}
					}

					return 0;
				});
			}

			setSortedAssessments(sortedData);
		};

		sortAssessments();
	}, [classAssessments, sortAssessmentsField, sortAssessmentsOrder]);

	const [showAll, setShowAll] = useState(false);
	const [renderedAssessments, setRenderedAssessments] = useState([]);

	useEffect(() => {
		setRenderedAssessments(showAll ? sortedAssessments : sortedAssessments?.slice(0, 5));
	}, [showAll, classId, sortedAssessments]);

	return (
		<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
			<div className='mb-4 hidden md:flex flex-row items-center justify-between'>
				<div className='w-full flex flex-row items-center justify-start gap-4'>
					<h3 className='text-xl font-bold text-theme-gray-800'>{other && 'Other '}Assessments</h3>

					{!classAssessmentsLoading && classAssessments?.length > 0 && (
						<div className='flex flex-row items-center justify-start'>
							<span className='w-full mr-2 text-sm text-theme-gray-800'>Sort by:</span>

							<select
								onChange={(e) => {
									e.preventDefault();
									handleSortAssessmentsClick(e.target.value);
								}}
								className='mr-2 rounded-lg bg-theme-white border-theme-gray-200 text-theme-gray-800 sm:text-sm'>
								<option value='name'>Name</option>
								<option value='weight'>Weight</option>
								<option value='type'>Type</option>
								<option value='totalPoints'>Total Points</option>
							</select>

							<select
								onChange={(e) => {
									e.preventDefault();
									setSortAssessmentsOrder(e.target.value);
								}}
								className='rounded-lg bg-theme-white border-theme-gray-200 text-theme-gray-800 sm:text-sm'>
								<option value='asc'>Asc</option>
								<option value='desc'>Desc</option>
							</select>
						</div>
					)}
				</div>

				{currentUser?.data?.userType === 'teacher' && (
					<Link type='button' to={`/classes/${classId}/assessments/new`} className='inline-block shrink-0 rounded-md border border-blue-500 bg-blue-500 px-4 py-2 text-sm font-medium text-white transition-all duration-300 ease-in-out hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'>
						New Assessment
					</Link>
				)}
			</div>

			<div className='mb-4 flex md:hidden flex-col items-start justify-start gap-4'>
				<div className='w-full flex flex-row items-center justify-between gap-4'>
					<h3 className='text-xl font-bold text-theme-gray-800'>{other && 'Other '}Assessments</h3>

					{currentUser?.data?.userType === 'teacher' && !classAssessmentsLoading && classAssessments?.length > 0 && (
						<Menu as='div' className='relative inline-block md:hidden text-left'>
							<Menu.Button className='p-1.5 flex items-center justify-center rounded-lg border border-gray-400 text-center text-gray-600 hover:border-gray-400/80 hover:text-gray-600/80'>
								<svg className='size-4' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
									<circle cx='256' cy='256' r='32' fill='none' stroke='currentColor' strokeMiterlimit='10' strokeWidth='32' />
									<circle cx='256' cy='416' r='32' fill='none' stroke='currentColor' strokeMiterlimit='10' strokeWidth='32' />
									<circle cx='256' cy='96' r='32' fill='none' stroke='currentColor' strokeMiterlimit='10' strokeWidth='32' />
								</svg>
							</Menu.Button>

							<Transition as={Fragment} enter='transition ease-out duration-100' enterFrom='transform opacity-0 scale-95' enterTo='transform opacity-100 scale-100' leave='transition ease-in duration-75' leaveFrom='transform opacity-100 scale-100' leaveTo='transform opacity-0 scale-95'>
								<Menu.Items className='z-50 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-200 rounded-md bg-theme-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
									<div className='px-1 py-1'>
										<Menu.Item>
											<Link to={`/classes/${classId}/assessments/new`} className='flex w-full items-center transition-all duration-300 ease-in-out px-2 py-2 rounded-lg text-sm text-black hover:bg-gray-100'>
												<svg className='size-4' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
													<path d='M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5' />
													<path d='M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z' />
												</svg>

												<span className='ml-2'>New Assessment</span>
											</Link>
										</Menu.Item>
									</div>
								</Menu.Items>
							</Transition>
						</Menu>
					)}
				</div>

				{!classAssessmentsLoading && classAssessments?.length > 0 && (
					<div className='flex flex-row items-center justify-start'>
						<span className='w-full mr-2 text-sm text-theme-gray-800'>Sort by:</span>

						<select
							onClick={(e) => {
								handleSortAssessmentsClick(e.target.value);
							}}
							className='mr-2 rounded-lg border-theme-gray-200 text-theme-gray-800 sm:text-sm'>
							<option value='name'>Name</option>
							<option value='weight'>Weight</option>
							<option value='type'>Type</option>
							<option value='totalPoints'>Total Points</option>
						</select>

						<select
							onClick={(e) => {
								setSortAssessmentsOrder(e.target.value);
							}}
							className='rounded-lg border-theme-gray-200 text-theme-gray-800 sm:text-sm'>
							<option value='asc'>Asc</option>
							<option value='desc'>Desc</option>
						</select>
					</div>
				)}
			</div>

			<div className='w-full grid grid-cols-12 gap-4 overflow-hidden'>
				{classAssessmentsLoading ? (
					<div className='col-span-12'>
						<div className='w-full px-4 py-2 inline-flex items-center justify-center'>
							<Loader size='sm' />
						</div>
					</div>
				) : renderedAssessments?.length > 0 ? (
					<>
						{renderedAssessments?.map((assessment, idx) => {
							return (
								<div key={idx} className='col-span-12 md:col-span-6 lg:col-span-4'>
									{student ? (
										<div className={`p-4 bg-${assessment?.color}-500 rounded-lg select-none`}>
											<div className='flex flex-row items-center justify-between'>
												<h3 className='text-lg font-bold text-white'>{assessment?.name}</h3>
											</div>

											<p className='mt-2 text-sm text-white'>
												<strong className='mr-2'>Type:</strong>

												{assessment.type === 'Assignment' ? (
													<span className='bg-fuchsia-300 py-1 px-3 rounded-full'>Assignment</span>
												) : assessment.type === 'Exam' ? (
													<span className='bg-sky-300 py-1 px-3 rounded-full'>Exam</span>
												) : assessment.type === 'Quiz' ? (
													<span className='bg-emerald-300 py-1 px-3 rounded-full'>Quiz</span>
												) : assessment.type === 'Project' ? (
													<span className='bg-pink-300 py-1 px-3 rounded-full'>Project</span>
												) : assessment.type === 'Presentation' ? (
													<span className='bg-lime-300 py-1 px-3 rounded-full'>Presentation</span>
												) : (
													'N/A'
												)}
											</p>

											<p className='mt-2 text-sm text-white'>
												<strong>Weight:</strong> {assessment.grading.weight}%
											</p>

											<p className='mt-2 text-sm text-white'>
												<strong>Total Points:</strong> {assessment.grading.totalPoints}
											</p>
										</div>
									) : (
										<Link to={`/classes/${classId}/assessments/${assessment?.id}`}>
											<div className={`p-4 bg-${assessment?.color}-500 rounded-lg select-none`}>
												<div className='flex flex-row items-center justify-between'>
													<h3 className='text-lg font-bold text-white'>{assessment?.name}</h3>

													<button type='button' className='text-white'>
														<svg className='size-4' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
															<path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
															<path fillRule='evenodd' d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z' />
														</svg>
													</button>
												</div>

												<p className='mt-2 text-sm text-white'>
													<strong className='mr-2'>Type:</strong>

													{assessment.type === 'Assignment' ? (
														<span className='bg-fuchsia-300 py-1 px-3 rounded-full'>Assignment</span>
													) : assessment.type === 'Exam' ? (
														<span className='bg-sky-300 py-1 px-3 rounded-full'>Exam</span>
													) : assessment.type === 'Quiz' ? (
														<span className='bg-emerald-300 py-1 px-3 rounded-full'>Quiz</span>
													) : assessment.type === 'Project' ? (
														<span className='bg-pink-300 py-1 px-3 rounded-full'>Project</span>
													) : assessment.type === 'Presentation' ? (
														<span className='bg-lime-300 py-1 px-3 rounded-full'>Presentation</span>
													) : (
														'N/A'
													)}
												</p>

												<p className='mt-2 text-sm text-white'>
													<strong>Weight:</strong> {assessment.grading.weight}%
												</p>

												<p className='mt-2 text-sm text-white'>
													<strong>Total Points:</strong> {assessment.grading.totalPoints}
												</p>
											</div>
										</Link>
									)}
								</div>
							);
						})}

						{!showAll && renderedAssessments?.length > 5 && (
							<div className='col-span-3 md:col-span-4'>
								<div className='h-full p-4 select-none flex flex-row items-center justify-start'>
									<button
										type='button'
										onClick={(e) => {
											e.preventDefault();
											setShowAll(true);
										}}
										className='text-theme-gray-800 hover:text-theme-gray-800/80'>
										Show All
									</button>
								</div>
							</div>
						)}
					</>
				) : (
					<div className='col-span-12'>
						<p className='px-4 py-2 text-center text-theme-gray-600'>No assessments found</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default Assessments;
