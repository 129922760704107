const handleStudentFirstNameError = async (firstName, setFirstNameError, showAlert) => {
	let error = false;

	if (firstName.length < 2 || !firstName.trim()) {
		await setFirstNameError('Please enter a valid first name');
		showAlert('error', 'Please enter a valid first name');

		error = true;
	}

	if (firstName.length > 50) {
		await setFirstNameError('First name too long');
		showAlert('error', 'First name too long');

		error = true;
	}

	if (firstName.length > 2) {
		if (!/^[a-zA-ZÀ-ÿ'\s\-,.]+$/.test(firstName)) {
			await setFirstNameError('First name should only contain alphabetic characters, accents, apostrophes, hyphens, spaces, periods, & commas');
			showAlert('error', 'First name should only contain alphabetic characters, accents, apostrophes, hyphens, spaces, periods, & commas');

			error = true;
		}
	}

	return error;
};

const handleStudentMiddleNameError = async (middleName, setMiddleNameError, showAlert) => {
	let error = false;

	if (middleName !== null || middleName !== '' || !middleName.trim()) {
		if (middleName?.length < 2) {
			await setMiddleNameError('Please enter a valid middle name');
			showAlert('error', 'Please enter a valid middle name');

			error = true;
		} else if (middleName?.length > 50) {
			await setMiddleNameError('Middle name too long');
			showAlert('error', 'Middle name too long');

			error = true;
		} else if (!/^[a-zA-ZÀ-ÿ'\s\-,.]+$/.test(middleName)) {
			await setMiddleNameError('Middle name should only contain alphabetic characters, accents, apostrophes, hyphens, spaces, periods, & commas');
			showAlert('error', 'Middle name should only contain alphabetic characters, accents, apostrophes, hyphens, spaces, periods, & commas');

			error = true;
		}
	}

	return error;
};

const handleStudentLastNameError = async (lastName, setLastNameError, showAlert) => {
	let error = false;

	if (lastName.length < 2 || !lastName.trim()) {
		await setLastNameError('Please enter a valid last name');
		showAlert('error', 'Please enter a valid last name');

		error = true;
	}

	if (lastName.length > 50) {
		await setLastNameError('Last name too long');
		showAlert('error', 'Last name too long');

		error = true;
	}

	if (lastName.length > 2) {
		if (!/^[a-zA-ZÀ-ÿ'\s\-,.]+$/.test(lastName)) {
			await setLastNameError('Last name should only contain alphabetic characters, accents, apostrophes, hyphens, spaces, periods, & commas');
			showAlert('error', 'Last name should only contain alphabetic characters, accents, apostrophes, hyphens, spaces, periods, & commas');

			error = true;
		}
	}

	return error;
};

const handleStudentEmailAddressError = async (emailAddress, setEmailAddressError, showAlert) => {
	let error = false;

	if (emailAddress.length < 5 || !emailAddress.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress)) {
		await setEmailAddressError('Please enter a valid email address');
		showAlert('error', 'Please enter a valid email address');

		error = true;
	}

	if (emailAddress.length > 120) {
		await setEmailAddressError('Email address too long');
		showAlert('error', 'Email address too long');

		error = true;
	}

	return error;
};

const validatePhoneNumber = (phoneNumber) => {
	if (phoneNumber === null || phoneNumber === '' || phoneNumber?.length < 2 || !phoneNumber.trim()) {
		return false;
	} else {
		return true;
	}
};

const handleStudentMobilePhoneError = async (mobilePhone, setMobilePhoneError, setMobilePhone, formatPhoneNumber, showAlert) => {
	let error = false;

	if (mobilePhone !== null) {
		if (!validatePhoneNumber(mobilePhone)) {
			await setMobilePhoneError('Please follow the format 1-758-123-4567');
			showAlert('error', 'Please follow the format 1-758-123-4567');
			error = true;
		} else {
			await setMobilePhoneError(null);
			const formattedPhone = formatPhoneNumber(mobilePhone);
			await setMobilePhone(formattedPhone);
		}
	}

	return error;
};

const handleStudentHomePhoneError = async (homePhone, setHomePhoneError, setHomePhone, formatPhoneNumber, showAlert) => {
	let error = false;

	if (homePhone !== null) {
		if (!validatePhoneNumber(homePhone)) {
			await setHomePhoneError('Please follow the format 1-758-123-4567');
			showAlert('error', 'Please follow the format 1-758-123-4567');
			error = true;
		} else {
			await setHomePhoneError(null);
			const formattedPhone = formatPhoneNumber(homePhone);
			await setHomePhone(formattedPhone);
		}
	}

	return error;
};

const handleStudentDateOfBirthError = async (dateOfBirthDay, setDateOfBirthDayError, dateOfBirthMonth, setDateOfBirthMonthError, dateOfBirthYear, setDateOfBirthYearError, showAlert) => {
	let error = false;

	if (dateOfBirthDay !== null || dateOfBirthMonth !== null || dateOfBirthYear !== null) {
		if (dateOfBirthDay === null || dateOfBirthDay === '') {
			await setDateOfBirthDayError('Please select a valid day');
			showAlert('error', 'Please select a valid day');

			error = true;
		}

		if (dateOfBirthMonth === null || dateOfBirthMonth === '') {
			await setDateOfBirthMonthError('Please select a valid month');
			showAlert('error', 'Please select a valid month');

			error = true;
		}

		if (dateOfBirthYear === null || dateOfBirthYear === '') {
			await setDateOfBirthYearError('Please select a valid year');
			showAlert('error', 'Please select a valid year');

			error = true;
		}
	}

	return error;
};

const handleStudentAddressError = async (addressStreet, setAddressStreetError, addressCommunity, setAddressCommunityError, addressDistrict, setAddressDistrictError, addressCountry, setAddressCountryError, showAlert) => {
	let error = false;

	if (addressStreet !== null) {
		if (addressStreet.length < 2) {
			await setAddressStreetError('Please enter a valid street');
			showAlert('error', 'Please enter a valid street');

			error = true;
		}

		if (addressStreet.length > 60) {
			await setAddressStreetError('Street too long');
			showAlert('error', 'Street too long');

			error = true;
		}
	}

	if (addressCommunity !== null) {
		if (addressCommunity.length < 2) {
			await setAddressCommunityError('Please enter a valid community');
			showAlert('error', 'Please enter a valid community');

			error = true;
		}

		if (addressCommunity.length > 60) {
			await setAddressCommunityError('Community too long');
			showAlert('error', 'Community too long');

			error = true;
		}
	}

	if (addressDistrict !== null) {
		if (addressDistrict.length < 2) {
			await setAddressDistrictError('Please enter a valid district');
			showAlert('error', 'Please enter a valid district');

			error = true;
		}

		if (addressDistrict.length > 60) {
			await setAddressDistrictError('District too long');
			showAlert('error', 'District too long');

			error = true;
		}
	}

	if (addressCountry !== null) {
		if (addressCountry.length < 2) {
			await setAddressCountryError('Please select a valid country');
			showAlert('error', 'Please select a valid country');

			error = true;
		}
	}

	if (addressStreet !== null || addressDistrict !== null || addressCommunity !== null) {
		if (addressCountry === null || addressCountry === '') {
			await setAddressCountryError('Please select a valid country');
			showAlert('error', 'Please select a valid country');

			error = true;
		}
	}

	return error;
};

const handleStudentCurrentSchoolError = async (currentSchool, setCurrentSchoolError, showAlert) => {
	let error = false;

	if (currentSchool === null) {
		await setCurrentSchoolError('Please select a school');
		showAlert('error', 'Please select a school');

		error = true;
	}

	return error;
};

const handleStudentMotherDetailsError = async (motherFirstName, setMotherFirstNameError, motherLastName, setMotherLastNameError, motherEmailAddress, setMotherEmailAddressError, motherMobilePhone, setMotherMobilePhoneError, formatPhoneNumber, setMotherMobilePhone, showAlert) => {
	let error = false;

	if (motherFirstName !== null) {
		if (motherFirstName.length < 2) {
			await setMotherFirstNameError('Please enter a valid first name');
			showAlert('error', 'Please enter a valid first name');

			error = true;
		}

		if (motherFirstName.length > 50) {
			await setMotherFirstNameError('First name too long');
			showAlert('error', 'First name too long');

			error = true;
		}

		if (!/^[a-zA-ZÀ-ÿ'\s\-,.]+$/.test(motherFirstName)) {
			await setMotherFirstNameError('First name should only contain alphabetic characters, accents, apostrophes, hyphens, spaces, periods, & commas');
			showAlert('error', 'First name should only contain alphabetic characters, accents, apostrophes, hyphens, spaces, periods, & commas');

			error = true;
		}
	}

	if (motherLastName !== null) {
		if (motherLastName.length < 2) {
			await setMotherLastNameError('Please enter a valid last name');
			showAlert('error', 'Please enter a valid last name');

			error = true;
		}

		if (motherLastName.length > 50) {
			await setMotherLastNameError('Last name too long');
			showAlert('error', 'Last name too long');

			error = true;
		}

		if (!/^[a-zA-ZÀ-ÿ'\s\-,.]+$/.test(motherLastName)) {
			await setMotherLastNameError('Last name should only contain alphabetic characters, accents, apostrophes, hyphens, spaces, periods, & commas');
			showAlert('error', 'Last name should only contain alphabetic characters, accents, apostrophes, hyphens, spaces, periods, & commas');

			error = true;
		}
	}

	if (motherEmailAddress !== null) {
		if (motherEmailAddress.length < 5 || !motherEmailAddress.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(motherEmailAddress)) {
			await setMotherEmailAddressError('Please enter a valid email address');
			showAlert('error', 'Please enter a valid email address');

			error = true;
		}

		if (motherEmailAddress.length > 120) {
			await setMotherEmailAddressError('Email address too long');
			showAlert('error', 'Email address too long');

			error = true;
		}
	}

	if (motherMobilePhone !== null) {
		if (motherMobilePhone.length < 2) {
			await setMotherMobilePhoneError('Please enter a valid phone number');
			showAlert('error', 'Please enter a valid phone number');

			error = true;
		} else if (motherMobilePhone.length > 14) {
			await setMotherMobilePhoneError('Phone number too long');
			showAlert('error', 'Phone number too long');

			error = true;
		} else if (!validatePhoneNumber(motherMobilePhone)) {
			await setMotherMobilePhoneError('Please follow 1-758-123-4567 format');
			showAlert('error', 'Please follow 1-758-123-4567 format');

			error = true;
		} else {
			await setMotherMobilePhoneError(null);

			const formattedPhone = formatPhoneNumber(motherMobilePhone);

			await setMotherMobilePhone(formattedPhone);
		}
	}

	return error;
};

const handleStudentFatherDetailsError = async (fatherFirstName, setFatherFirstNameError, fatherLastName, setFatherLastNameError, fatherEmailAddress, setFatherEmailAddressError, fatherMobilePhone, setFatherMobilePhoneError, formatPhoneNumber, setFatherMobilePhone, showAlert) => {
	let error = false;

	if (fatherFirstName !== null) {
		if (fatherFirstName.length < 2) {
			await setFatherFirstNameError('Please enter a valid first name');
			showAlert('error', 'Please enter a valid first name');

			error = true;
		}

		if (fatherFirstName.length > 50) {
			await setFatherFirstNameError('First name too long');
			showAlert('error', 'First name too long');

			error = true;
		}

		if (!/^[a-zA-ZÀ-ÿ'\s\-,.]+$/.test(fatherFirstName)) {
			await setFatherFirstNameError('First name should only contain alphabetic characters, accents, apostrophes, hyphens, spaces, periods, & commas');
			showAlert('error', 'First name should only contain alphabetic characters, accents, apostrophes, hyphens, spaces, periods, & commas');

			error = true;
		}
	}

	if (fatherLastName !== null) {
		if (fatherLastName.length < 2) {
			await setFatherLastNameError('Please enter a valid last name');
			showAlert('error', 'Please enter a valid last name');

			error = true;
		}

		if (fatherLastName.length > 50) {
			await setFatherLastNameError('Last name too long');
			showAlert('error', 'Last name too long');

			error = true;
		}

		if (!/^[a-zA-ZÀ-ÿ'\s\-,.]+$/.test(fatherLastName)) {
			await setFatherLastNameError('Last name should only contain alphabetic characters, accents, apostrophes, hyphens, spaces, periods, & commas');
			showAlert('error', 'Last name should only contain alphabetic characters, accents, apostrophes, hyphens, spaces, periods, & commas');

			error = true;
		}
	}

	if (fatherEmailAddress !== null) {
		if (fatherEmailAddress.length < 5 || !fatherEmailAddress.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(fatherEmailAddress)) {
			await setFatherEmailAddressError('Please enter a valid email address');
			showAlert('error', 'Please enter a valid email address');

			error = true;
		}

		if (fatherEmailAddress.length > 120) {
			await setFatherEmailAddressError('Email address too long');
			showAlert('error', 'Email address too long');

			error = true;
		}
	}

	if (fatherMobilePhone !== null) {
		if (fatherMobilePhone.length < 2) {
			await setFatherMobilePhoneError('Please enter a valid phone number');
			showAlert('error', 'Please enter a valid phone number');

			error = true;
		} else if (fatherMobilePhone.length > 14) {
			await setFatherMobilePhoneError('Phone number too long');
			showAlert('error', 'Phone number too long');

			error = true;
		} else if (!validatePhoneNumber(fatherMobilePhone)) {
			await setFatherMobilePhoneError('Please follow 1-758-123-4567 format');
			showAlert('error', 'Please follow 1-758-123-4567 format');

			error = true;
		} else {
			await setFatherMobilePhoneError(null);

			error = true;

			const formattedPhone = formatPhoneNumber(fatherMobilePhone);

			await setFatherMobilePhone(formattedPhone);
		}
	}

	return error;
};

//

const handleSettingsFirstNameError = async (firstName, setFirstNameError) => {
	let error = false;

	if (firstName.length < 2 || !firstName.trim()) {
		await setFirstNameError('Please enter a valid first name');

		error = true;
	}

	if (firstName.length > 50) {
		await setFirstNameError('First name too long');

		error = true;
	}

	if (firstName.length > 2) {
		if (!/^[a-zA-ZÀ-ÿ'\s\-,.]+$/.test(firstName)) {
			await setFirstNameError('First name should only contain alphabetic characters, accents, apostrophes, hyphens, spaces, periods, & commas');

			error = true;
		}
	}

	return error;
};

const handleSettingsMiddleNameError = async (middleName, setMiddleNameError) => {
	let error = false;

	if (middleName?.length > 0) {
		if (middleName?.length < 2) {
			await setMiddleNameError('Please enter a valid middle name');

			error = true;
		}

		if (middleName?.length > 50) {
			await setMiddleNameError('Middle name too long');

			error = true;
		}

		if (!/^[a-zA-ZÀ-ÿ'\s\-,.]+$/.test(middleName)) {
			await setMiddleNameError('Middle name should only contain alphabetic characters, accents, apostrophes, hyphens, spaces, periods, & commas');

			error = true;
		}
	}

	return error;
};

const handleSettingsLastNameError = async (lastName, setLastNameError) => {
	let error = false;

	if (lastName.length < 2 || !lastName.trim()) {
		await setLastNameError('Please enter a valid last name');

		error = true;
	}

	if (lastName.length > 50) {
		await setLastNameError('Last name too long');

		error = true;
	}

	if (lastName.length > 2) {
		if (!/^[a-zA-ZÀ-ÿ'\s\-,.]+$/.test(lastName)) {
			await setLastNameError('Last name should only contain alphabetic characters, accents, apostrophes, hyphens, spaces, periods, & commas');

			error = true;
		}
	}

	return error;
};

const handleSettingsEmailAddressError = async (emailAddress, setEmailAddressError) => {
	let error = false;

	if (emailAddress.length < 5 || !emailAddress.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress)) {
		await setEmailAddressError('Please enter a valid email address');

		error = true;
	}

	if (emailAddress.length > 120) {
		await setEmailAddressError('Email address too long');

		error = true;
	}

	return error;
};

const handleSettingsMobilePhoneError = async (mobilePhone, setMobilePhoneError) => {
	let error = false;

	if (mobilePhone?.length > 0) {
		if (mobilePhone.length < 2) {
			await setMobilePhoneError('Please enter a valid phone number');

			error = true;
		} else if (mobilePhone.length > 14) {
			await setMobilePhoneError('Phone number too long');

			error = true;
		} else if (!validatePhoneNumber(mobilePhone)) {
			await setMobilePhoneError('Please follow 758-123-4567 format');

			error = true;
		} else {
			await setMobilePhoneError(null);
		}
	}

	return error;
};

const handleSettingsHomePhoneError = async (homePhone, setHomePhoneError) => {
	let error = false;

	if (homePhone?.length > 0) {
		if (homePhone.length < 2) {
			await setHomePhoneError('Please enter a valid phone number');

			error = true;
		} else if (homePhone.length > 14) {
			await setHomePhoneError('Phone number too long');

			error = true;
		} else if (!validatePhoneNumber(homePhone)) {
			await setHomePhoneError('Please follow 1-758-123-4567 format');

			error = true;
		} else {
			await setHomePhoneError(null);
		}
	}

	return error;
};

const handleSettingsAddressError = async (addressStreet, setAddressStreetError, addressCommunity, setAddressCommunityError, addressDistrict, setAddressDistrictError, addressCountry, setAddressCountryError) => {
	let error = false;

	if (addressStreet?.length > 0) {
		if (addressStreet.length < 2) {
			await setAddressStreetError('Please enter a valid street');

			error = true;
		}

		if (addressStreet.length > 60) {
			await setAddressStreetError('Street too long');

			error = true;
		}
	}

	if (addressCommunity?.length > 0) {
		if (addressCommunity.length < 2) {
			await setAddressCommunityError('Please enter a valid community');

			error = true;
		}

		if (addressCommunity.length > 60) {
			await setAddressCommunityError('Community too long');

			error = true;
		}
	}

	if (addressDistrict?.length > 0) {
		if (addressDistrict.length < 2) {
			await setAddressDistrictError('Please enter a valid district');

			error = true;
		}

		if (addressDistrict.length > 60) {
			await setAddressDistrictError('District too long');

			error = true;
		}
	} else {
		await setAddressDistrictError('Please enter a district');

		error = true;
	}

	if (addressCountry?.length > 0) {
		if (addressCountry.length < 2) {
			await setAddressCountryError('Please select a valid country');

			error = true;
		}
	} else {
		await setAddressDistrictError('Please select a country');

		error = true;
	}

	return error;
};

//

const handleAssessmentNameError = async (name, setNameError, showAlert) => {
	let error = false;

	if (name.length < 2 || !name.trim()) {
		await setNameError('Please enter a valid name');
		showAlert('error', 'Please enter a valid name');

		error = true;
	}

	if (name.length > 120) {
		await setNameError('Name too long');
		showAlert('error', 'Name too long');

		error = true;
	}

	return error;
};

const handleAssessmentTypeError = async (type, setTypeError, showAlert) => {
	let error = false;

	if (type === null || type === '') {
		await setTypeError('Please select a valid type');
		showAlert('error', 'Please select a valid type');

		error = true;
	}

	return error;
};

const handleAssessmentTotalPointsError = async (totalPoints, setTotalPointsError, showAlert) => {
	let error = false;

	if (totalPoints < 0 || !totalPoints.trim()) {
		await setTotalPointsError('Please enter a valid total points');
		showAlert('error', 'Please enter a valid total points');

		error = true;
	}

	if (totalPoints > 1000) {
		await setTotalPointsError('Total points too large');
		showAlert('error', 'Total points too large');

		error = true;
	}

	return error;
};

const handleAssessmentWeightError = async (remainingWeight, weight, setWeightError, showAlert) => {
	let error = false;

	if (weight < 0 || !weight.trim()) {
		await setWeightError('Please enter a valid weight');
		showAlert('error', 'Please enter a valid weight');

		error = true;
	}

	if (weight > 100) {
		await setWeightError('Weight too large');
		showAlert('error', 'Weight too large');

		error = true;
	}

	if (weight > remainingWeight) {
		await setWeightError('Weight cannot exceed remaining weight');
		showAlert('error', 'Weight cannot exceed remaining weight');

		error = true;
	}

	return error;
};

const handleSubjectNameError = async (name, setNameError, showAlert) => {
	let error = false;

	if (name.length < 2 || !name.trim()) {
		await setNameError('Please enter a valid name');
		showAlert('error', 'Please enter a valid name');

		error = true;
	}

	if (name.length > 120) {
		await setNameError('Name too long');
		showAlert('error', 'Name too long');

		error = true;
	}

	return error;
};

//

const handleClassSubjectError = async (subject, setSubjectError, showAlert) => {
	let error = false;

	if (subject === null || subject === '' || !subject.trim()) {
		await setSubjectError('Please select a subject');
		showAlert('error', 'Please select a subject');

		error = true;
	}

	return error;
};

const handleClassTeacherError = async (teacher, setTeacherError, showAlert) => {
	let error = false;

	if (teacher === null || teacher === '' || !teacher.trim()) {
		await setTeacherError('Please select a teacher');
		showAlert('error', 'Please select a teacher');

		error = true;
	}

	return error;
};

const handleClassRoomError = async (room, setRoomError, showAlert) => {
	let error = false;

	if (room === null || room === '' || room < 0 || room > 1000 || !room.trim()) {
		await setRoomError('Please enter a room');
		showAlert('error', 'Please enter a room');

		error = true;
	}

	return error;
};

const handleClassStatusError = async (status, setStatusError, showAlert) => {
	let error = false;

	if (status === null || status === '' || !status.trim()) {
		await setStatusError('Please select a status');
		showAlert('error', 'Please select a status');

		error = true;
	}

	return error;
};

const handleClassSemesterError = async (semester, setSemesterError, showAlert) => {
	let error = false;

	if (semester === null || semester === '') {
		await setSemesterError('Please select a semester');
		showAlert('error', 'Please select a semester');

		error = true;
	}

	return error;
};

const handleClassYearError = async (year, setYearError, showAlert) => {
	let error = false;

	if (year === null || year === '') {
		await setYearError('Please select a year');
		showAlert('error', 'Please select a year');

		error = true;
	}

	return error;
};

const ErrorHandling = {
	handleStudentFirstNameError,
	handleStudentMiddleNameError,
	handleStudentLastNameError,
	handleStudentEmailAddressError,
	validatePhoneNumber,
	handleStudentMobilePhoneError,
	handleStudentHomePhoneError,
	handleStudentDateOfBirthError,
	handleStudentAddressError,
	handleStudentCurrentSchoolError,
	handleStudentMotherDetailsError,
	handleStudentFatherDetailsError,

	handleSettingsFirstNameError,
	handleSettingsMiddleNameError,
	handleSettingsLastNameError,
	handleSettingsEmailAddressError,
	handleSettingsMobilePhoneError,
	handleSettingsHomePhoneError,
	handleSettingsAddressError,

	handleAssessmentNameError,
	handleAssessmentTotalPointsError,
	handleAssessmentWeightError,
	handleAssessmentTypeError,

	handleSubjectNameError,

	handleClassSubjectError,
	handleClassTeacherError,
	handleClassRoomError,
	handleClassStatusError,
	handleClassSemesterError,
	handleClassYearError,
};

export default ErrorHandling;
