import React from 'react';
import ReactDOM from 'react-dom/client';
import { DataProvider, AlertProvider, AuthProvider } from './context';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<React.StrictMode>
		<AlertProvider>
			<AuthProvider>
				<DataProvider>
					<App />
				</DataProvider>
			</AuthProvider>
		</AlertProvider>
	</React.StrictMode>
);
