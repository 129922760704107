import React, { useContext, useEffect, useState } from 'react';
import { AuthContext, DataContext } from '../../context';
import { MainContainer, Loader } from '../../components';
import { calculateGPAFromPercentage, calculateLetterGradeFromPercentage, generateUserGrades } from '../../utils';

const StudentReports = () => {
	const { currentUser } = useContext(AuthContext);
	const { appData, divRef, users, currentSchool, handleScroll, subjects, assessments } = useContext(DataContext);
	const scoringFormats = ['Percentage', 'Letter', 'GPA'];

	const [scoringFormat, setScoringFormat] = useState('Percentage');
	const [year, setYear] = useState(new Date().getFullYear());

	const [studentGrades, setStudentGrades] = useState(null);
	const [dataLoading, setDataLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const _classes = await currentUser?.data?.school?.classes;
				const studentClasses = await currentSchool?.classes?.filter((_class) => _classes?.includes(_class?.id));

				const fetchedGrades = [];

				for (const _class of studentClasses) {
					const classAssessments = await assessments?.filter((assessment) => assessment?.classId === _class?.id);
					const currentStudentGrades = await generateUserGrades(currentSchool, currentUser, classAssessments, null, _class?.id);

					const currentStudentTotalPercentage = currentStudentGrades?.reduce((accumulator, currentGrade) => {
						return accumulator + parseInt(currentGrade.grade);
					}, 0);

					const calculatedStudentTotalAverage = currentStudentTotalPercentage / currentStudentGrades?.length;

					fetchedGrades?.push({
						studentId: currentUser?.data?.id,
						classId: _class?.id,
						subjectId: _class?.subjectId,
						teacherId: _class?.teacherId,
						average: calculatedStudentTotalAverage,
					});
				}

				setStudentGrades(fetchedGrades);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setDataLoading(false);
		};

		fetchData();
	}, [appData?.debugMode, currentSchool, currentUser, assessments]);

	const gradesBySemester = studentGrades?.reduce((acc, grade) => {
		const foundClass = currentSchool?.classes?.find((cls) => cls?.id === grade?.classId);
		const classYear = foundClass?.duration?.year;
		const classSemester = foundClass?.duration?.semester;
		const key = `Semester ${classSemester}`;

		if (classYear === parseInt(year)) {
			if (!acc[key]) {
				acc[key] = [];
			}

			acc[key]?.push(grade);
		}

		return acc;
	}, {});

	const [allStudentGrades, setAllStudentGrades] = useState(null);

	const [classes, setClasses] = useState(null);
	const [students, setStudents] = useState(null);

	const [classesLoading, setClassesLoading] = useState(true);
	const [studentsLoading, setStudentsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const _classes = await currentSchool?.classes;
				const _students = await users?.filter((user) => user?.userType === 'student');

				setClasses(_classes);
				setStudents(_students);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setClassesLoading(false);
			setStudentsLoading(false);
		};

		fetchData();
	}, [appData?.debugMode, currentSchool?.classes, currentUser?.data?.id, users]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (!classesLoading && classes?.length > 0) {
					if (!studentsLoading && students?.length > 0) {
						const fetchedGrades = [];

						for (const _class of classes) {
							for (const student of students) {
								const studentInClass = student?.school?.classes?.some((classId) => classId === _class?.id);

								if (studentInClass) {
									const studentWithData = {
										data: student,
									};

									const classAssessments = await assessments?.filter((assessment) => assessment?.classId === _class?.id);
									const currentStudentGrades = await generateUserGrades(currentSchool, studentWithData, classAssessments, null, _class?.id);

									const currentStudentTotalPercentage = currentStudentGrades?.reduce((accumulator, currentGrade) => {
										return accumulator + parseInt(currentGrade.grade);
									}, 0);

									const calculatedStudentTotalAverage = currentStudentTotalPercentage / currentStudentGrades?.length;

									fetchedGrades?.push({
										studentId: student?.id,
										classId: _class?.id,
										average: calculatedStudentTotalAverage,
									});
								}
							}
						}

						const studentAveragesSum = {};

						for (const grade of fetchedGrades) {
							const { studentId, average } = grade;

							if (studentAveragesSum.hasOwnProperty(studentId)) {
								studentAveragesSum[studentId] += average;
							} else {
								studentAveragesSum[studentId] = average;
							}
						}

						setAllStudentGrades(fetchedGrades);
					}
				}
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}
		};

		fetchData();
	}, [appData?.debugMode, assessments, classes, currentSchool, students, classesLoading, studentsLoading]);

	const [classAveragePerformanceData, setClassAveragePerformanceData] = useState(null);

	useEffect(() => {
		let _classAveragePerformanceData = [];

		if (!classesLoading && !studentsLoading && allStudentGrades) {
			for (const studentGrade of allStudentGrades) {
				const { classId, average } = studentGrade;

				if (_classAveragePerformanceData.hasOwnProperty(classId)) {
					_classAveragePerformanceData[classId].total += average;
					_classAveragePerformanceData[classId].grades.push(average);
				} else {
					_classAveragePerformanceData[classId] = {
						total: average,
						grades: [average],
					};
				}
			}

			for (const classId in _classAveragePerformanceData) {
				const totalAverage = _classAveragePerformanceData[classId]?.total || 0;
				const grades = _classAveragePerformanceData[classId]?.grades || [];

				const numberOfStudents = grades.length;
				const classAverage = numberOfStudents > 0 ? totalAverage / numberOfStudents : 0;

				const lowestGrade = numberOfStudents > 0 ? Math.min(...grades) : 0;
				const highestGrade = numberOfStudents > 0 ? Math.max(...grades) : 0;

				_classAveragePerformanceData[classId] = {
					classId: classId,
					classAverage: classAverage,
					lowestAverage: lowestGrade,
					highestAverage: highestGrade,
				};
			}

			setClassAveragePerformanceData(_classAveragePerformanceData);
		}
	}, [allStudentGrades, classes, subjects, classesLoading, studentsLoading]);

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<div className='flex flex-row items-center justify-between'>
					<div className='flex flex-row items-center justify-start gap-4'>
						<h3 className='text-lg font-bold text-theme-gray-800 sm:text-xl'>Official Grade Report</h3>
					</div>

					<div className='flex flex-row items-center justify-end gap-4'>
						<div className='flex flex-row items-center justify-center'>
							<span className='w-full mr-2 text-sm text-theme-gray-800'>Scoring Format:</span>

							<select
								onChange={(e) => {
									e.preventDefault();
									setScoringFormat(e.target.value);
								}}
								value={scoringFormat || ''}
								className='mr-2 rounded-lg bg-theme-white border-theme-gray-200 text-theme-gray-800 sm:text-sm'>
								<option value='' disabled>
									Select scoring format
								</option>

								{scoringFormats?.map((scoringFormat, idx) => {
									return (
										<option key={idx} value={scoringFormat}>
											{scoringFormat}
										</option>
									);
								})}
							</select>
						</div>

						<div className='flex flex-row items-center justify-center'>
							<span className='w-full mr-2 text-sm text-theme-gray-800'>Year:</span>

							<select
								onChange={(e) => {
									e.preventDefault();
									setYear(e.target.value);
								}}
								value={year || ''}
								className='mr-2 rounded-lg bg-theme-white border-theme-gray-200 text-theme-gray-800 sm:text-sm'>
								<option value='' disabled>
									Select year
								</option>

								{Array.from({ length: new Date().getFullYear() - (currentUser?.data?.school?.entryYear || new Date().getFullYear() - 10) + 1 }, (_, idx) => (currentUser?.data?.school?.entryYear || new Date().getFullYear() - 10) + idx).map((year, idx) => (
									<option key={idx} value={year}>
										{year}
									</option>
								))}
							</select>
						</div>
					</div>
				</div>
			</section>

			<section className='w-full bg-theme-white'>
				<div id='pdfContent'>
					<div className='w-full rounded-lg border border-theme-gray-200'>
						<div className='rounded-t-lg overflow-x-auto'>
							<table className='min-w-full rounded-lg divide-y-2 divide-theme-gray-200 bg-theme-white text-sm'>
								<thead className='w-full'>
									<tr>
										<th className='whitespace-nowrap px-4 py-2 font-medium text-left text-theme-gray-800'>Subject</th>

										<th className='whitespace-nowrap px-4 py-2 font-medium text-left text-theme-gray-800'>Teacher</th>

										<th className='whitespace-nowrap px-4 py-2 font-medium text-center text-theme-gray-800'>Section/Room</th>

										<th className='whitespace-nowrap px-4 py-2 font-medium text-center text-theme-gray-800'>Status</th>

										<th className='whitespace-nowrap px-4 py-2 font-medium text-center text-theme-gray-800'>Class Average</th>

										<th className='whitespace-nowrap px-4 py-2 font-medium text-center text-theme-gray-800'>Final Grade</th>
									</tr>
								</thead>

								<tbody className='w-full divide-y divide-theme-gray-200'>
									{Object.entries(gradesBySemester || {})?.map(([semester, grades]) => (
										<React.Fragment key={semester}>
											<tr>
												<td colSpan='6' className='whitespace-nowrap px-4 py-2'>
													<span className='font-medium text-theme-gray-800'>{semester}</span>
												</td>
											</tr>

											{grades?.map((grade, idx) => {
												const foundClass = currentSchool?.classes?.find((cls) => cls?.id === grade?.classId);
												const subjectName = subjects?.find((subject) => subject?.id === foundClass?.subjectId)?.name;
												const foundTeacher = users?.find((user) => user.id === foundClass?.teacherId && user.userType === 'teacher');
												const teacherName = foundTeacher?.name?.first + ' ' + foundTeacher?.name?.last;

												let classAverage = null;

												if (classAveragePerformanceData) classAverage = classAveragePerformanceData[grade?.classId]?.classAverage;

												return (
													<tr key={idx} className='odd:bg-theme-gray-50'>
														<td className='whitespace-nowrap px-4 py-2 text-left text-theme-gray-600'>{subjectName || 'N/A'}</td>

														<td className='whitespace-nowrap px-4 py-2 text-left text-theme-gray-600'>{foundTeacher ? teacherName : 'N/A'}</td>

														<td className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>{foundClass?.room ? foundClass?.room : 'N/A'}</td>

														<td className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>
															{grade?.average && grade?.average >= currentSchool?.passMark ? (
																<span className='rounded-full px-2.5 py-0.5 text-xs bg-green-600 text-white uppercase'>Pass</span>
															) : grade?.average && grade?.average < currentSchool?.passMark ? (
																<span className='rounded-full px-2.5 py-0.5 text-xs bg-red-600 text-white uppercase'>Fail</span>
															) : (
																<span className='rounded-full px-2.5 py-0.5 text-xs bg-gray-600 text-white uppercase'>Unassigned</span>
															)}
														</td>

														<td className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>
															{classAverage ? (scoringFormat === 'Percentage' ? classAverage?.toFixed(2) + '%' : scoringFormat === 'GPA' ? calculateGPAFromPercentage(classAverage).toFixed(2) : scoringFormat === 'Letter' ? calculateLetterGradeFromPercentage(classAverage) : classAverage?.toFixed(2) + '%') : 'N/A'}
														</td>

														<td className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>
															{grade?.average ? (scoringFormat === 'Percentage' ? grade?.average?.toFixed(2) + '%' : scoringFormat === 'GPA' ? calculateGPAFromPercentage(grade?.average).toFixed(2) : scoringFormat === 'Letter' ? calculateLetterGradeFromPercentage(grade?.average) : grade?.average?.toFixed(2) + '%') : 'N/A'}
														</td>
													</tr>
												);
											})}
										</React.Fragment>
									))}

									{studentGrades && studentGrades?.length === 0 && (
										<tr>
											<td colSpan='6' className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>
												{dataLoading ? <Loader size='sm' /> : 'No Grades found'}
											</td>
										</tr>
									)}

									{gradesBySemester && Object.keys(gradesBySemester)?.length === 0 && (
										<tr>
											<td colSpan='6' className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>
												No grades found for the selected year
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</section>
		</MainContainer>
	);
};

export default StudentReports;
