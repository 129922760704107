import React, { useEffect, useState } from 'react';
import Loader from '../Loader';

const Table = ({ name, headContents, bodyContents, sortedData, filteredData, dataLoading, colSpan }) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(10);

	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;

	const [visibleData, setVisibleData] = useState(filteredData?.slice(startIndex, endIndex));
	const [totalPages, setTotalPages] = useState(Math.ceil(sortedData?.length / itemsPerPage));

	useEffect(() => {
		setVisibleData(filteredData?.slice(startIndex, endIndex));
		setTotalPages(Math.ceil(sortedData?.length / itemsPerPage));
	}, [endIndex, filteredData, itemsPerPage, sortedData?.length, startIndex]);

	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};

	return (
		<div id='pdfContent'>
			<h3 className='content-to-hide-on-screen text-lg mb-6 font-bold text-theme-gray-800 sm:text-xl'>{name} List</h3>

			<div className='w-full rounded-lg border border-theme-gray-200 overflow-auto'>
				<div className='rounded-t-lg overflow-x-auto'>
					<table className={`min-w-full rounded-t-lg overflow-auto ${filteredData?.length <= itemsPerPage && 'rounded-b-lg'} divide-y-2 divide-theme-gray-200 bg-theme-white text-sm`}>
						<thead className='w-full select-none'>{headContents}</thead>

						<tbody className='w-full divide-y divide-theme-gray-200'>
							{visibleData?.length > 0 ? (
								visibleData?.map((item, idx) => {
									return (
										<tr key={idx} className='odd:bg-theme-gray-50'>
											{bodyContents({
												item,
											})}
										</tr>
									);
								})
							) : (
								<tr>
									<td colSpan={colSpan} className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>
										{dataLoading ? <Loader size='sm' /> : `No ${name.toLowerCase()} found.`}
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>

				<div className='w-full select-none rounded-b-lg border-t border-theme-gray-200 px-4 py-2 grid grid-cols-1 sm:grid-cols-2'>
					<div className='inline-flex items-center gap-4'>
						<span className='w-fit text-sm text-theme-gray-800'>Show per page:</span>

						<select
							value={itemsPerPage}
							onChange={(e) => {
								e.preventDefault();
								setItemsPerPage(parseInt(e.target.value));
							}}
							className='rounded-lg bg-theme-white border-theme-gray-200 text-theme-gray-800 sm:text-sm'>
							<option value='10'>10</option>
							<option value='25'>25</option>
							<option value='50'>50</option>
							<option value='100'>100</option>
							<option value='10000'>All</option>
						</select>
					</div>

					<div className='inline-flex items-center justify-end'>
						{filteredData?.length > itemsPerPage && (
							<ol className='flex justify-end gap-1 text-xs font-medium'>
								{currentPage > 1 && (
									<li>
										<button type='button' onClick={() => handlePageChange(currentPage - 1)} className='inline-flex size-8 items-center justify-center rounded-lg border border-theme-gray-200 bg-theme-white text-theme-gray-800 hover:bg-theme-gray-100'>
											<span className='sr-only'>Prev Page</span>

											<svg className='size-3' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
												<path fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='48' d='M328 112L184 256l144 144' />
											</svg>
										</button>
									</li>
								)}

								{Array.from({
									length: totalPages,
								}).map((_, idx) => {
									return (
										<li key={idx}>
											<button type='button' onClick={() => handlePageChange(idx + 1)} className={`inline-flex h-8 w-8 items-center justify-center rounded-lg border ${currentPage === idx + 1 ? 'bg-blue-500 text-white border-blue-500' : 'border-theme-gray-200 bg-theme-white text-theme-gray-800 hover:bg-theme-gray-100'}`}>
												{idx + 1}
											</button>
										</li>
									);
								})}

								{currentPage !== totalPages && (
									<li>
										<button type='button' onClick={() => handlePageChange(currentPage + 1)} className='inline-flex size-8 items-center justify-center rounded-lg border border-theme-gray-200 bg-theme-white text-theme-gray-800 hover:bg-theme-gray-100'>
											<span className='sr-only'>Next Page</span>

											<svg className='size-3' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
												<path fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='48' d='M184 112l144 144-144 144' />
											</svg>
										</button>
									</li>
								)}
							</ol>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Table;
