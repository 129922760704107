import { useEffect, useState } from 'react';

const GreetingMessage = ({ currentUser }) => {
	const [timeOfDay, setTimeOfDay] = useState('');

	useEffect(() => {
		const currentTime = new Date().getHours();

		if (currentTime >= 5 && currentTime < 12) {
			setTimeOfDay('morning');
		} else if (currentTime >= 12 && currentTime < 17) {
			setTimeOfDay('afternoon');
		} else if (currentTime >= 17 && currentTime < 20) {
			setTimeOfDay('evening');
		} else {
			setTimeOfDay('night');
		}
	}, []);

	let greetingMessage = '';

	switch (timeOfDay) {
		case 'morning':
			greetingMessage = 'Good morning';
			break;
		case 'afternoon':
			greetingMessage = 'Good afternoon';
			break;
		case 'evening':
			greetingMessage = 'Good evening';
			break;
		case 'night':
			greetingMessage = 'Good night';
			break;
		default:
			greetingMessage = 'Hello';
	}

	if (currentUser?.data?.name?.first) {
		return `${greetingMessage}, ${currentUser?.data?.name?.first}!`;
	} else {
		return `${greetingMessage}!`;
	}
};

export default GreetingMessage;
