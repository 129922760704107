import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTitle } from 'react-use';
import { DataContext } from '../../context';
import { ChatsCard, MainContainer } from '../../components';

const Chats = () => {
	useTitle('Chats | Grade Portal');

	const { divRef, handleScroll, footerHeight } = useContext(DataContext);

	const headerRef = useRef(null);
	const composerRef = useRef(null);

	const [headerHeight, setHeaderHeight] = useState(null);
	const [composerHeight, setComposerHeight] = useState(null);

	useEffect(() => {
		const headerElement = headerRef.current;
		const headerElementHeight = headerElement.offsetHeight;

		const composerElement = composerRef.current;
		const composerElementHeight = composerElement.offsetHeight;

		setHeaderHeight(headerElementHeight);
		setComposerHeight(composerElementHeight);
	}, []);

	const [screenWidthPadding, setScreenWidthPadding] = useState(null);

	useEffect(() => {
		const getScreenWidthPadding = () => {
			const width = window?.innerWidth;

			switch (true) {
				case width > 768:
					return '4rem';
				case width > 640:
					return '2.5rem';
				default:
					return '1rem';
			}
		};

		const _screenWidthPadding = getScreenWidthPadding();
		setScreenWidthPadding(_screenWidthPadding);
	}, []);

	const chatContainerRef = useRef(null);

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<div className='grid grid-cols-12 gap-6 bg-theme-white'>
				<div className='col-span-4'>
					<ChatsCard />
				</div>

				<div className='col-span-8'>
					<main
						className='h-full sticky top-8 flex-1'
						style={{
							height: `calc(90vh - ${footerHeight}px - ${screenWidthPadding})`,
						}}>
						<div className='h-full relative flex-1 bg-theme-white rounded-lg border border-theme-gray-200 shadow-sm focus:outline-none'>
							<div ref={headerRef} className='w-full absolute left-0 top-0 px-6 py-4'></div>

							<ul
								ref={chatContainerRef}
								className='w-full bg-theme-white flex flex-col p-6 gap-1 overflow-x-hidden overflow-y-auto no-scrollbar'
								style={{
									height: `calc(100% - ${headerHeight}px - ${composerHeight}px)`,
									marginTop: headerHeight ? `${headerHeight}px` : '0',
									marginBottom: composerHeight ? `${composerHeight}px` : '0',
								}}>
								<li className='h-full flex items-center justify-center gap-2.5'>
									<div className='h-full w-full flex items-center justify-center'>
										<p className='text-base text-theme-gray-800 font-bold'>Please select a chat</p>
									</div>
								</li>
							</ul>

							<div ref={composerRef} className='w-full absolute left-0 bottom-0 p-6'></div>
						</div>
					</main>
				</div>
			</div>
		</MainContainer>
	);
};

export default Chats;
