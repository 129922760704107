import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { AuthContext, DataContext } from '../../context';
import { Loader, MainContainer } from '../../components';
import { ErrorHandling, generateNewAssessmentID, useAlertHandling, useInputWithError } from '../../utils';

const NewAssessment = () => {
	useTitle('New Assessment | Grade Portal');

	const { classId } = useParams();

	const { currentUser } = useContext(AuthContext);
	const { appData, currentSchool, createNewAssessment, assessments, subjects, divRef, handleScroll, colors } = useContext(DataContext);
	const { showAlert } = useAlertHandling();

	const [canAccessClass, setCanAccessClass] = useState(false);
	const [subjectName, setSubjectName] = useState(null);

	useEffect(() => {
		const filteredClasses = currentSchool?.classes?.filter((_class) => _class?.teacherId === currentUser?.data?.id);
		const foundClass = filteredClasses?.find((_class) => _class?.id === classId);

		if (foundClass) {
			const _subjectName = subjects?.find((subject) => subject?.id === foundClass?.subjectId)?.name;

			setCanAccessClass(true);
			setSubjectName(_subjectName);
		} else {
			setCanAccessClass(false);
		}
	}, [currentSchool?.classes, currentUser?.data?.id, appData?.debugMode, subjects, classId]);

	const { value: name, setValue: setName, error: nameError, setError: setNameError } = useInputWithError('');
	const { value: type, setValue: setType, error: typeError, setError: setTypeError } = useInputWithError('');
	const [description, setDescription] = useState('');

	const { value: totalPoints, setValue: setTotalPoints, error: totalPointsError, setError: setTotalPointsError } = useInputWithError('');
	const { value: weight, setValue: setWeight, error: weightError, setError: setWeightError } = useInputWithError('');
	const [remainingWeight, setRemainingWeight] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const classAssessments = await assessments?.filter((assessment) => assessment?.classId === classId);

				const totalWeight = classAssessments?.reduce((sum, assessment) => {
					return sum + parseInt(assessment?.grading?.weight);
				}, 0);

				setRemainingWeight(100 - totalWeight);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}
		};

		fetchData();
	}, [appData?.debugMode, assessments, classId]);

	const [handleNewAssessmentLoading, setHandleNewAssessmentLoading] = useState(false);

	const handleNewAssessment = async (e) => {
		e.preventDefault();

		setHandleNewAssessmentLoading(true);

		let newAssessmentData = {
			id: generateNewAssessmentID(),
			classId: classId,
			type: type,
			name: name,
			description: description,
			color: colors[Math.floor(Math.random() * colors?.length)],
			grading: {
				totalPoints: totalPoints,
				weight: weight,
			},
			creationDate: new Date().toISOString().split('T')[0],
			addedBy: currentUser?.data?.id,
		};

		if ((await ErrorHandling.handleAssessmentNameError(name, setNameError, showAlert)) || (await ErrorHandling.handleAssessmentTypeError(type, setTypeError, showAlert)) || (await ErrorHandling.handleAssessmentTotalPointsError(totalPoints, setTotalPointsError, showAlert)) || (await ErrorHandling.handleAssessmentWeightError(remainingWeight, weight, setWeightError, showAlert))) {
			setHandleNewAssessmentLoading(false);
		} else {
			try {
				await createNewAssessment(newAssessmentData);
				setHandleNewAssessmentLoading(false);

				window.location.href = `/classes/${classId}`;
			} catch (error) {
				if (appData.debugMode) {
					console.error(error);
				}

				showAlert('error', 'An unknown error occurred');
				setHandleNewAssessmentLoading(false);
			}
		}

		setHandleNewAssessmentLoading(false);
	};

	if (!canAccessClass) {
		return (
			<MainContainer divRef={divRef} handleScroll={handleScroll}>
				<section className='bg-theme-white py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
					<div className='mx-auto max-w-screen-sm text-center'>
						<h1 className='mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-theme-gray-800'>404</h1>

						<p className='mb-4 text-3xl tracking-tight font-bold text-blue-500 md:text-4xl'>Oops! Class Not Found</p>

						<p className='mb-8 text-lg text-theme-gray-600'>
							It seems that the class you're trying to access isn't available or authorized for your account. Don't worry, you can explore your classes{' '}
							<Link to='/classes' className='focus:outline-none focus:ring text-blue-500 hover:opacity-80 active:opacity-80'>
								here
							</Link>
							.
						</p>

						<Link to='/' className='inline-block shrink-0 rounded-md border px-5 py-2.5 text-sm font-medium focus:outline-none focus:ring text-white border-blue-500 bg-blue-500 hover:bg-transparent hover:text-blue-500 active:text-blue-500'>
							Back to Homepage
						</Link>
					</div>
				</section>
			</MainContainer>
		);
	}

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<h3 className='text-2xl font-bold text-theme-gray-800'>
					New Assessment - {subjectName} (
					<Link to={`/classes/${classId}`} className='text-theme-gray-800 hover:opacity-80'>
						{classId}
					</Link>
					)
				</h3>
			</section>

			<section className='w-full bg-theme-white'>
				<form autoComplete='off'>
					<h3 className='text-theme-gray-800 text-base font-semibold'>1. Assessment Details</h3>

					<div className='grid grid-cols-12 gap-4 mt-4'>
						<div className='w-full col-span-12 sm:col-span-6'>
							<label htmlFor='name' className='block text-sm font-medium text-theme-gray-800'>
								Name
								<span className='text-red-500 ml-1'>*</span>
							</label>

							<input
								type='text'
								onChange={(e) => {
									e.preventDefault();
									setName(e.target.value);
								}}
								className={`mt-1 w-full rounded-md border ${nameError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
								id='name'
								placeholder='Assessment One'
								required
							/>

							{nameError && <span className='mt-2 text-sm text-red-500'>{nameError}</span>}
						</div>

						<div className='w-full col-span-12 sm:col-span-6'>
							<label htmlFor='type' className='block text-sm font-medium text-theme-gray-800'>
								Type
								<span className='text-red-500 ml-1'>*</span>
							</label>

							<select
								onChange={(e) => {
									e.preventDefault();
									setType(e.target.value);
								}}
								className={`mt-1 w-full rounded-md border ${typeError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
								id='type'
								value={type || ''}>
								<option value='' disabled>
									Select a type
								</option>

								<option value='Assignment'>Assignment</option>
								<option value='Exam'>Exam</option>
								<option value='Quiz'>Quiz</option>
								<option value='Project'>Project</option>
								<option value='Presentation'>Presentation</option>
							</select>

							{typeError && <span className='mt-2 text-sm text-red-500'>{typeError}</span>}
						</div>

						<div className='w-full col-span-12'>
							<label htmlFor='description' className='block text-sm font-medium text-theme-gray-800'>
								Description
							</label>

							<textarea
								onChange={(e) => {
									e.preventDefault();
									setDescription(e.target.value);
								}}
								className='mt-1 w-full rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'
								id='description'
								rows='5'></textarea>
						</div>
					</div>

					<hr className='my-8' />

					<h3 className='text-theme-gray-800 text-base font-semibold'>2. Grading Details</h3>

					<div className='grid grid-cols-12 gap-4 mt-4'>
						<div className='w-full col-span-12 sm:col-span-6'>
							<label htmlFor='totalPoints' className='block text-sm font-medium text-theme-gray-800'>
								Total Points
								<span className='text-red-500 ml-1'>*</span>
							</label>

							<input
								type='number'
								onChange={(e) => {
									e.preventDefault();
									setTotalPoints(e.target.value);
								}}
								className={`mt-1 w-full rounded-md border ${totalPointsError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
								id='totalPoints'
								placeholder='100'
								min={0}
								max={1000}
								required
							/>

							{totalPointsError && <span className='mt-2 text-sm text-red-500'>{totalPointsError}</span>}
						</div>

						<div className='w-full col-span-12 sm:col-span-6'>
							<label htmlFor='weight' className='block text-sm font-medium text-theme-gray-800'>
								Weight
								<span className='text-red-500 ml-1'>*</span>
							</label>

							<div className='relative mt-1'>
								<input
									type='number'
									onChange={(e) => {
										e.preventDefault();
										setWeight(e.target.value);
									}}
									className={`pe-10 w-full rounded-md border ${weightError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='weight'
									placeholder='10'
									min={1}
									max={remainingWeight}
									required
								/>

								<span className='pointer-events-none absolute inset-y-0 end-0 grid w-10 place-content-center text-theme-gray-600'>
									<svg className='w-4 h-4' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
										<path d='M13.442 2.558a.625.625 0 0 1 0 .884l-10 10a.625.625 0 1 1-.884-.884l10-10a.625.625 0 0 1 .884 0M4.5 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5m7 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5' />
									</svg>
								</span>
							</div>

							<div className='flex flex-col items-start justify-center mt-2 text-sm'>
								<span className='text-theme-gray-600'>Remaining Weight: {remainingWeight}%</span>

								{weightError && <span className='mt-1 text-red-500'>{weightError}</span>}
							</div>
						</div>
					</div>

					<hr className='my-8' />

					<div className='w-full mt-4 col-span-6 flex flex-col gap-4 sm:flex-row sm:items-center'>
						<button
							type='submit'
							onClick={handleNewAssessment}
							disabled={handleNewAssessmentLoading}
							className={`inline-flex items-center justify-center shrink-0 rounded-md border border-blue-500 bg-blue-500 text-white px-4 py-2 gap-2 text-sm font-medium ${handleNewAssessmentLoading ? 'opacity-60 cursor-not-allowed' : 'hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'}`}>
							{handleNewAssessmentLoading ? (
								<div className='flex items-center justify-center'>
									<Loader />
								</div>
							) : (
								'Create New Assessment'
							)}
						</button>
					</div>
				</form>
			</section>
		</MainContainer>
	);
};

export default NewAssessment;
