import Loader from './Loader';

const ProfileInformation = ({ currentUser, profileImg, profileImgLoading, handleProfileImgUploadChange }) => {
	return (
		<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
			<div className='mb-4 flex flex-row items-center justify-between'>
				<h3 className='text-xl font-bold text-theme-gray-800'>Profile Information</h3>
			</div>

			<div className='w-full col-span-12 flex items-center gap-4'>
				<img className='size-12 rounded-full' src={profileImg ? profileImg : `https://ui-avatars.com/api/?name=${currentUser?.data?.name?.first}+${currentUser?.data?.name?.last}`} alt={currentUser?.displayName} />

				<form>
					<label
						htmlFor='profileImgUpload'
						disabled={profileImgLoading}
						className={`inline-block shrink-0 rounded-md border px-4 py-1.5 text-sm font-medium transition-all duration-300 ease-in-out focus:outline-none focus:ring ${profileImgLoading ? 'border-blue-300 bg-blue-300 hover:border-blue-300 hover:bg-blue-300' : 'text-white border-blue-500 bg-blue-500 hover:bg-transparent hover:text-blue-500 active:text-blue-500'} cursor-pointer`}>
						{profileImgLoading ? (
							<div className='flex items-center justify-center'>
								<Loader />
							</div>
						) : (
							'Upload Photo'
						)}
					</label>

					<input type='file' onChange={handleProfileImgUploadChange} className='hidden' id='profileImgUpload' accept='image/*' />
				</form>
			</div>

			<div className='w-full mt-6 py-3 rounded-lg border border-theme-gray-200'>
				<dl className='-my-3 divide-y divide-theme-gray-200 text-sm'>
					<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
						<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>User ID</dt>

						<dd className='text-theme-gray-600'>{currentUser?.data?.id || 'N/A'}</dd>
					</div>

					<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
						<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>User Type</dt>

						<dd className='text-theme-gray-600'>{currentUser?.data?.userType ? currentUser?.data?.userType.charAt(0).toUpperCase() + currentUser?.data?.userType.slice(1) : 'N/A'}</dd>
					</div>
				</dl>
			</div>
		</div>
	);
};

const SchoolDetails = ({ currentUser, currentSchool, students }) => {
	return (
		<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
			<div className='mb-4 flex flex-row items-center justify-between'>
				{currentUser?.data?.userType === 'student' || currentUser?.data?.userType === 'teacher' || currentUser?.data?.userType === 'principal' || currentUser?.data?.userType === 'alumni' ? (
					<h3 className='text-xl font-bold text-theme-gray-800'>School Details</h3>
				) : currentUser?.data?.userType === 'parent' ? (
					<h3 className='text-xl font-bold text-theme-gray-800'>Children's School Details</h3>
				) : null}
			</div>

			{currentUser?.data?.userType === 'student' || currentUser?.data?.userType === 'alumni' ? (
				<div className='w-full mt-6 py-3 rounded-lg border border-theme-gray-200'>
					<dl className='-my-3 divide-y divide-theme-gray-200 text-sm'>
						<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
							<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>School name</dt>

							<dd className='text-theme-gray-600'>{currentUser?.data?.school?.id || 'N/A'}</dd>
						</div>

						<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
							<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>Website</dt>

							<dd className='text-theme-gray-600'>
								{currentSchool?.website ? (
									<a href={currentSchool?.website} className='inline-flex items-center gap-2 hover:opacity-80' target='_blank' rel='noopener noreferrer'>
										<span>Visit</span>

										<svg className='size-4 fill-theme-gray-600' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
											<path d='M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9q-.13 0-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z' />
											<path d='M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4 4 0 0 1-.82 1H12a3 3 0 1 0 0-6z' />
										</svg>
									</a>
								) : (
									'N/A'
								)}
							</dd>
						</div>

						<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
							<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>Class</dt>

							<dd className='text-theme-gray-600'>{currentUser?.data?.school?.classId || 'N/A'}</dd>
						</div>

						<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
							<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>Enrollment status</dt>

							<dd className='text-theme-gray-600'>{currentUser?.data?.school?.enrollmentStatus ? currentUser?.data?.school?.enrollmentStatus.charAt(0).toUpperCase() + currentUser?.data?.school?.enrollmentStatus.slice(1) : 'N/A'}</dd>
						</div>

						<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
							<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>Entry year</dt>

							<dd className='text-theme-gray-600'>{currentUser?.data?.school?.entryYear || 'N/A'}</dd>
						</div>

						{currentUser?.data?.school?.gradeLevel && (
							<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
								<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>{currentUser?.data?.school?.gradeLevel < 6 ? 'Grade' : 'Form'}</dt>

								<dd className='text-theme-gray-600'>{currentUser?.data?.school?.gradeLevel < 6 ? currentUser?.data?.school?.gradeLevel : currentUser?.data?.school?.gradeLevel - 6}</dd>
							</div>
						)}

						<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4 rounded-b-lg'>
							<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>
								{currentUser?.data?.school?.graduationYear
									? 'Graduation year'
									: currentUser?.data?.school?.enrollmentStatus === 'inactive' || currentUser?.data?.school?.enrollmentStatus === 'graduated'
									? 'Graduation year'
									: currentUser?.data?.school?.expectedGraduationYear
									? 'Expected graduation year'
									: currentUser?.data?.school?.entryYear && new Date().getFullYear() - currentUser?.data?.school?.entryYear < 5
									? 'Expected graduation year'
									: 'Expected graduation year'}
							</dt>

							<dd className='text-theme-gray-600'>
								{currentUser?.data?.school?.graduationYear
									? currentUser?.data?.school?.graduationYear
									: currentUser?.data?.school?.expectedGraduationYear
									? currentUser?.data?.school?.expectedGraduationYear
									: currentUser?.data?.school?.graduationYear && currentUser?.data?.school?.expectedGraduationYear
									? currentUser?.data?.school?.graduationYear
									: currentUser?.data?.school?.entryYear
									? currentUser?.data?.school?.entryYear + 5
									: 'N/A'}
							</dd>
						</div>
					</dl>
				</div>
			) : currentUser?.data?.userType === 'teacher' || currentUser?.data?.userType === 'principal' ? (
				<div className='w-full mt-6 py-3 rounded-lg border border-theme-gray-200'>
					<dl className='-my-3 divide-y divide-theme-gray-200 text-sm'>
						<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
							<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>School name</dt>

							<dd className='text-theme-gray-600'>{currentUser?.data?.school?.id || 'N/A'}</dd>
						</div>

						<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
							<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>Website</dt>

							<dd className='text-theme-gray-600'>
								{currentSchool?.website ? (
									<a href={currentSchool?.website} className='inline-flex items-center gap-2 hover:opacity-80' target='_blank' rel='noopener noreferrer'>
										<span>Visit</span>

										<svg className='size-4 fill-theme-gray-600' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
											<path d='M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9q-.13 0-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z' />
											<path d='M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4 4 0 0 1-.82 1H12a3 3 0 1 0 0-6z' />
										</svg>
									</a>
								) : (
									'N/A'
								)}
							</dd>
						</div>
					</dl>
				</div>
			) : currentUser?.data?.userType === 'parent' ? (
				currentUser?.data?.dependents?.map((dependent, idx) => {
					const student = students?.find((student) => student.id === dependent.id);

					return (
						<div key={idx} className='w-full mt-6 py-3 rounded-lg border border-theme-gray-200'>
							<dl className='-my-3 divide-y divide-theme-gray-200 text-sm'>
								<div className='grid grid-cols-1 gap-1 p-3 bg-theme-gray-100 sm:grid-cols-3 sm:gap-4'>
									<dt className='flex items-center font-bold text-theme-gray-800 sm:col-span-3'>John Doe</dt>
								</div>

								<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
									<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>School name</dt>

									<dd className='text-theme-gray-600'>{student?.school?.id || 'N/A'}</dd>
								</div>

								<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
									<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>Website</dt>

									<dd className='text-theme-gray-600'>
										{currentSchool?.website ? (
											<a href={currentSchool?.website} className='inline-flex items-center gap-2 hover:opacity-80' target='_blank' rel='noopener noreferrer'>
												<span>Visit</span>

												<svg className='size-4 fill-theme-gray-600' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
													<path d='M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9q-.13 0-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z' />
													<path d='M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4 4 0 0 1-.82 1H12a3 3 0 1 0 0-6z' />
												</svg>
											</a>
										) : (
											'N/A'
										)}
									</dd>
								</div>

								<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
									<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>Class</dt>

									<dd className='text-theme-gray-600'>{student?.school?.classId || 'N/A'}</dd>
								</div>

								<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
									<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>Enrollment status</dt>

									<dd className='text-theme-gray-600'>{student?.school?.enrollmentStatus ? student?.school?.enrollmentStatus.charAt(0).toUpperCase() + student?.school?.enrollmentStatus.slice(1) : 'N/A'}</dd>
								</div>

								<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
									<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>Entry year</dt>

									<dd className='text-theme-gray-600'>{student?.school?.entryYear || 'N/A'}</dd>
								</div>

								{student?.school?.gradeLevel && (
									<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4'>
										<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>{student?.school?.gradeLevel < 6 ? 'Grade' : 'Form'}</dt>

										<dd className='text-theme-gray-600'>{student?.school?.gradeLevel < 6 ? student?.school?.gradeLevel : student?.school?.gradeLevel - 6}</dd>
									</div>
								)}

								<div className='grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4 rounded-b-lg'>
									<dt className='flex items-center font-medium text-theme-gray-800 sm:col-span-2'>
										{student?.school?.graduationYear
											? 'Graduation year'
											: student?.school?.enrollmentStatus === 'inactive' || student?.school?.enrollmentStatus === 'graduated'
											? 'Graduation year'
											: student?.school?.expectedGraduationYear
											? 'Expected graduation year'
											: student?.school?.entryYear && new Date().getFullYear() - student?.school?.entryYear < 5
											? 'Expected graduation year'
											: 'Expected graduation year'}
									</dt>

									<dd className='text-theme-gray-600'>
										{student?.school?.graduationYear ? student?.school?.graduationYear : student?.school?.expectedGraduationYear ? student?.school?.expectedGraduationYear : student?.school?.graduationYear && student?.school?.expectedGraduationYear ? student?.school?.graduationYear : student?.school?.entryYear ? student?.school?.entryYear + 5 : 'N/A'}
									</dd>
								</div>
							</dl>
						</div>
					);
				})
			) : null}
		</div>
	);
};

const LanguageTime = ({ language, languageOptions, languageChangeLoading, handleLanguageChange, timeZone, timeZoneOptions, timeZoneChangeLoading, handleTimeZoneChange }) => {
	return (
		<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
			<div className='mb-4 flex flex-row items-center justify-between'>
				<h3 className='text-xl font-bold text-theme-gray-800'>Language & Time</h3>
			</div>

			<div className='w-full grid grid-cols-12 gap-6'>
				<div className='w-full col-span-12'>
					<label htmlFor='language' className='block text-sm font-medium text-theme-gray-800'>
						Language
					</label>

					<select onChange={handleLanguageChange} id='language' defaultValue={language || ''} disabled={languageChangeLoading} className='w-full mt-1 rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'>
						<option value='' disabled>
							Select a language
						</option>

						{languageOptions.map((language, idx) => {
							return (
								<option key={idx} value={language.value}>
									{language.name}
								</option>
							);
						})}
					</select>
				</div>

				<div className='w-full col-span-12'>
					<label htmlFor='timeZone' className='block text-sm font-medium text-theme-gray-800'>
						Time zone
					</label>

					<select onChange={handleTimeZoneChange} id='timeZone' defaultValue={timeZone || ''} disabled={timeZoneChangeLoading} className='w-full mt-1 rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'>
						<option value='' disabled>
							Select a time zone
						</option>

						{timeZoneOptions.map((timeZone, idx) => {
							return (
								<option key={idx} value={timeZone.value}>
									{timeZone.name}
								</option>
							);
						})}
					</select>
				</div>
			</div>
		</div>
	);
};

const Preferences = ({ theme, themeOptions, themeChangeLoading, handleThemeChange }) => {
	return (
		<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
			<div className='mb-4 flex flex-row items-center justify-between'>
				<h3 className='text-xl font-bold text-theme-gray-800'>Preferences</h3>
			</div>

			<div className='w-full grid grid-cols-12 gap-6'>
				<div className='w-full col-span-12'>
					<label htmlFor='theme' className='block text-sm font-medium text-theme-gray-800'>
						Theme
					</label>

					<select onChange={handleThemeChange} id='theme' defaultValue={theme || ''} disabled={themeChangeLoading} className='w-full mt-1 rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'>
						<option value='' disabled>
							Select a theme
						</option>

						{themeOptions.map((theme, idx) => {
							return (
								<option key={idx} value={theme.value}>
									{theme.name}
								</option>
							);
						})}
					</select>
				</div>
			</div>
		</div>
	);
};

const ChangePassword = ({ currentPassword, setCurrentPassword, currentPasswordError, setCurrentPasswordError, newPassword, setNewPassword, newPasswordError, setNewPasswordError, confirmPassword, setConfirmPassword, confirmPasswordError, setConfirmPasswordError, showPassword, setShowPassword, handlePasswordChange, passwordChangeLoading }) => {
	return (
		<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
			<form>
				<div className='mb-4 flex flex-row items-center justify-between'>
					<h3 className='text-xl font-bold text-theme-gray-800'>Change Password</h3>
				</div>

				<div className='w-full grid grid-cols-12 gap-6 mb-6'>
					<div className='w-full col-span-12'>
						<label htmlFor='currentPassword' className='block text-sm font-medium text-theme-gray-800'>
							Current password <span className='text-red-500'>*</span>
						</label>

						<div className='relative'>
							<input
								type={showPassword ? 'text' : 'password'}
								defaultValue={currentPassword || ''}
								onChange={(e) => {
									setCurrentPassword(e.target.value);
									setCurrentPasswordError(null);
								}}
								placeholder='********'
								id='currentPassword'
								className={`w-full mt-1 rounded-md border ${currentPasswordError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none pr-10`}
								autoComplete='current-password'
							/>

							<button
								type='button'
								onClick={() => {
									setShowPassword(!showPassword);
								}}
								className='mt-1 absolute inset-y-0 right-0 flex items-center justify-center px-2 mr-3 focus:outline-none'>
								{showPassword ? (
									<svg className='size-4 fill-gray-800 dark:fill-gray-200' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
										<path d='M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z' />
										<path d='M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829' />
										<path d='M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z' />
									</svg>
								) : (
									<svg className='size-4 fill-gray-800 dark:fill-gray-200' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
										<path d='M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z' />
										<path d='M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0' />
									</svg>
								)}
							</button>
						</div>

						{currentPasswordError && <span className='mt-2 text-sm text-red-500'>{currentPasswordError}</span>}
					</div>

					<div className='w-full col-span-12'>
						<label htmlFor='newPassword' className='block text-sm font-medium text-theme-gray-800'>
							New password <span className='text-red-500'>*</span>
						</label>

						<div className='relative'>
							<input
								type={showPassword ? 'text' : 'password'}
								defaultValue={newPassword || ''}
								onChange={(e) => {
									setNewPassword(e.target.value);
									setNewPasswordError(null);
								}}
								placeholder='********'
								id='newPassword'
								className={`w-full mt-1 rounded-md border ${newPasswordError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none pr-10`}
								autoComplete='new-password'
							/>

							<button
								type='button'
								onClick={() => {
									setShowPassword(!showPassword);
								}}
								className='mt-1 absolute inset-y-0 right-0 flex items-center justify-center px-2 mr-3 focus:outline-none'>
								{showPassword ? (
									<svg className='size-4 fill-gray-800 dark:fill-gray-200' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
										<path d='M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z' />
										<path d='M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829' />
										<path d='M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z' />
									</svg>
								) : (
									<svg className='size-4 fill-gray-800 dark:fill-gray-200' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
										<path d='M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z' />
										<path d='M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0' />
									</svg>
								)}
							</button>
						</div>

						{newPasswordError && <span className='mt-2 text-sm text-red-500'>{newPasswordError}</span>}
					</div>

					<div className='w-full col-span-12'>
						<label htmlFor='confirmPassword' className='block text-sm font-medium text-theme-gray-800'>
							Confirm password <span className='text-red-500'>*</span>
						</label>

						<div className='relative'>
							<input
								type={showPassword ? 'text' : 'password'}
								defaultValue={confirmPassword || ''}
								onChange={(e) => {
									setConfirmPassword(e.target.value);
									setConfirmPasswordError(null);
								}}
								placeholder='********'
								id='confirmPassword'
								className={`w-full mt-1 rounded-md border ${confirmPasswordError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none pr-10`}
								autoComplete='new-password'
							/>

							<button
								type='button'
								onClick={() => {
									setShowPassword(!showPassword);
								}}
								className='mt-1 absolute inset-y-0 right-0 flex items-center justify-center px-2 mr-3 focus:outline-none'>
								{showPassword ? (
									<svg className='size-4 fill-gray-800 dark:fill-gray-200' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
										<path d='M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z' />
										<path d='M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829' />
										<path d='M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z' />
									</svg>
								) : (
									<svg className='size-4 fill-gray-800 dark:fill-gray-200' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
										<path d='M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z' />
										<path d='M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0' />
									</svg>
								)}
							</button>
						</div>

						{confirmPasswordError && <span className='mt-2 text-sm text-red-500'>{confirmPasswordError}</span>}
					</div>
				</div>

				<div className='w-full mb-6'>
					<h5 className='text-theme-gray-800 font-medium mt-2'>Password requirements:</h5>

					<ul className='text-theme-gray-600 mt-2'>
						<li className='text-sm'>- At least 8 characters</li>
						<li className='text-sm'>- At least one number</li>
						<li className='text-sm'>- At least one special character</li>
						<li className='text-sm'>- At least one uppercase letter</li>
						<li className='text-sm'>- At least one lowercase letter</li>
					</ul>
				</div>

				<button
					type='submit'
					onClick={handlePasswordChange}
					disabled={passwordChangeLoading}
					className={`inline-block shrink-0 rounded-md border px-4 py-1.5 text-sm font-medium transition-all duration-300 ease-in-out focus:outline-none focus:ring ${passwordChangeLoading ? 'border-blue-300 bg-blue-300 hover:border-blue-300 hover:bg-blue-300' : 'text-white border-blue-500 bg-blue-500 hover:bg-transparent hover:text-blue-500 active:text-blue-500'}`}>
					{passwordChangeLoading ? (
						<div className='flex items-center justify-center'>
							<Loader />
						</div>
					) : (
						'Change'
					)}
				</button>
			</form>
		</div>
	);
};

const PersonalDetails = ({
	editPersonalInformation,
	editPersonalInformationLoading,
	firstName,
	setFirstName,
	firstNameError,
	setFirstNameError,
	middleName,
	setMiddleName,
	middleNameError,
	setMiddleNameError,
	lastName,
	setLastName,
	lastNameError,
	setLastNameError,
	sex,
	setSex,
	sexError,
	setSexError,
	dateOfBirth,
	setDateOfBirth,
	dateOfBirthError,
	setDateOfBirthError,
	calculateMinDate,
	getCurrentDate,
	handleSavePersonalInformation,
	setEditPersonalInformation,
	setEditPersonalInformationLoading,
}) => {
	return (
		<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
			<form>
				<div className='mb-3'>
					<div className='mb-4 flex flex-row items-center justify-between'>
						<h3 className='text-xl font-bold text-theme-gray-800'>Personal Details</h3>
					</div>

					<div className='w-full grid grid-cols-12 gap-6'>
						<div className='w-full col-span-12 lg:col-span-4'>
							<label htmlFor='firstName' className='block text-sm font-medium text-theme-gray-800'>
								First name <span className='text-red-500'>*</span>
							</label>

							{editPersonalInformation ? (
								<>
									<input
										type='text'
										onChange={(e) => {
											setFirstName(e.target.value);
											setFirstNameError(null);
										}}
										defaultValue={firstName || ''}
										placeholder='John'
										id='firstName'
										className={`w-full mt-1 rounded-md border ${firstNameError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
										autoComplete='given-name'
										disabled={!editPersonalInformation}
										required
									/>

									{firstNameError && <span className='mt-2 text-sm text-red-500'>{firstNameError}</span>}
								</>
							) : (
								<div className='w-full mt-1 py-2 pr-3'>
									<span className='text-theme-gray-800 text-sm'>{firstName ? firstName : 'N/A'}</span>
								</div>
							)}
						</div>

						<div className='w-full col-span-12 lg:col-span-4'>
							<label htmlFor='middleName' className='block text-sm font-medium text-theme-gray-800'>
								Middle name
							</label>

							{editPersonalInformation ? (
								<>
									<input
										type='text'
										onChange={(e) => {
											setMiddleName(e.target.value);
											setMiddleNameError(null);
										}}
										defaultValue={middleName}
										placeholder='Peter'
										id='middleName'
										className={`w-full mt-1 ${editPersonalInformation ? 'shadow-sm rounded-md border' : 'pl-0 border-0'} ${middleNameError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm focus:outline-none`}
										autoComplete='additional-name'
									/>

									{middleNameError && <span className='mt-2 text-sm text-red-500'>{middleNameError}</span>}
								</>
							) : (
								<div className='w-full mt-1 py-2 pr-3'>
									<span className='text-theme-gray-800 text-sm'>{middleName ? middleName : 'N/A'}</span>
								</div>
							)}
						</div>

						<div className='w-full col-span-12 lg:col-span-4'>
							<label htmlFor='lastName' className='block text-sm font-medium text-theme-gray-800'>
								Last name <span className='text-red-500'>*</span>
							</label>

							{editPersonalInformation ? (
								<>
									<input
										type='text'
										onChange={(e) => {
											setLastName(e.target.value);
											setLastNameError(null);
										}}
										defaultValue={lastName}
										placeholder='Doe'
										id='lastName'
										className={`w-full mt-1 ${editPersonalInformation ? 'shadow-sm rounded-md border' : 'pl-0 border-0'} ${lastNameError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm focus:outline-none`}
										autoComplete='family-name'
										required
									/>

									{lastNameError && <span className='mt-2 text-sm text-red-500'>{lastNameError}</span>}
								</>
							) : (
								<div className='w-full mt-1 py-2 pr-3'>
									<span className='text-theme-gray-800 text-sm'>{lastName ? lastName : 'N/A'}</span>
								</div>
							)}
						</div>

						<div className='w-full col-span-12 lg:col-span-6'>
							<label htmlFor='sex' className='block text-sm font-medium text-theme-gray-800'>
								Sex <span className='text-red-500'>*</span>
							</label>

							{editPersonalInformation ? (
								<>
									<select
										onChange={(e) => {
											setSex(e.target.value);
											setSexError(null);
										}}
										id='sex'
										defaultValue={sex || ''}
										className={`w-full mt-1 rounded-md border ${sexError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
										required>
										<option value='' disabled>
											Select a sex
										</option>
										<option value='M'>Male</option>
										<option value='F'>Female</option>
									</select>

									{sexError && <span className='mt-2 text-sm text-red-500'>{sexError}</span>}
								</>
							) : (
								<div className='w-full mt-1 py-2 pr-3'>
									<span className='text-theme-gray-800 text-sm'>{sex ? sex : 'N/A'}</span>
								</div>
							)}
						</div>

						<div className='w-full col-span-12 lg:col-span-6 text-theme-gray-800'>
							<label htmlFor='dateOfBirth' className='block text-sm font-medium text-theme-gray-800'>
								Date of birth <span className='text-red-500'>*</span>
							</label>

							{editPersonalInformation ? (
								<>
									<input
										type='date'
										onChange={(e) => {
											setDateOfBirth(e.target.value);
											setDateOfBirthError(null);
										}}
										defaultValue={dateOfBirth}
										id='dateOfBirth'
										className='w-full mt-1 rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'
										min={calculateMinDate()}
										max={getCurrentDate()}
										required
									/>

									{dateOfBirthError && <span className='mt-2 text-sm text-red-500'>{dateOfBirthError}</span>}
								</>
							) : (
								<div className='w-full mt-1 py-2 pr-3'>
									<span className='text-theme-gray-800 text-sm'>{dateOfBirth ? dateOfBirth : 'N/A'}</span>
								</div>
							)}
						</div>
					</div>
				</div>

				{editPersonalInformation ? (
					<div className='flex flex-row gap-2'>
						<button
							type='submit'
							onClick={handleSavePersonalInformation}
							disabled={editPersonalInformationLoading}
							className={`inline-block shrink-0 rounded-md border px-4 py-1.5 text-sm font-medium transition-all duration-300 ease-in-out focus:outline-none focus:ring ${editPersonalInformationLoading ? 'border-blue-300 bg-blue-300 hover:border-blue-300 hover:bg-blue-300' : 'text-white border-blue-500 bg-blue-500 hover:bg-transparent hover:text-blue-500 active:text-blue-500'}`}>
							{editPersonalInformationLoading ? (
								<div className='flex items-center justify-center'>
									<Loader />
								</div>
							) : (
								'Save'
							)}
						</button>

						<button
							type='button'
							onClick={async (e) => {
								e.preventDefault();

								await setEditPersonalInformation(false);
								await setEditPersonalInformationLoading(false);
							}}
							className='inline-block shrink-0 rounded-md border px-4 py-1.5 text-sm font-medium focus:outline-none focus:ring text-theme-gray-600 border-theme-gray-400 bg-transparent hover:bg-transparent hover:text-theme-gray-400 hover:border-theme-gray-400 active:text-theme-gray-400'>
							Cancel
						</button>
					</div>
				) : (
					<button
						type='button'
						onClick={async (e) => {
							e.preventDefault();

							await setEditPersonalInformation(true);
							await setEditPersonalInformationLoading(false);
						}}
						className='inline-block shrink-0 rounded-md border px-4 py-1.5 text-sm font-medium transition-all duration-300 ease-in-out focus:outline-none focus:ring text-white border-blue-500 bg-blue-500 hover:bg-transparent hover:text-blue-500 active:text-blue-500'>
						Edit
					</button>
				)}
			</form>
		</div>
	);
};

const ContactDetails = ({ editContactInformation, editContactInformationLoading, emailAddress, setEmailAddress, emailAddressError, setEmailAddressError, mobilePhone, setMobilePhone, mobilePhoneError, setMobilePhoneError, homePhone, setHomePhone, homePhoneError, setHomePhoneError, handleSaveContactInformation, setEditContactInformation, setEditContactInformationLoading }) => {
	return (
		<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
			<form>
				<div className='mb-3'>
					<div className='mb-4 flex flex-row items-center justify-between'>
						<h3 className='text-xl font-bold text-theme-gray-800'>Contact Details</h3>
					</div>

					<div className='w-full grid grid-cols-12 gap-6'>
						<div className='w-full col-span-12 lg:col-span-6'>
							<label htmlFor='emailAddress' className='block text-sm font-medium text-theme-gray-800'>
								Email address <span className='text-red-500'>*</span>
							</label>

							{editContactInformation ? (
								<>
									<input
										type='email'
										onChange={(e) => {
											setEmailAddress(e.target.value);
											setEmailAddressError(null);
										}}
										defaultValue={emailAddress}
										placeholder='john.doe@school.edu.lc'
										id='emailAddress'
										className={`w-full mt-1 rounded-md border ${emailAddressError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
										autoComplete='email'
										required
									/>

									{emailAddressError && <span className='mt-2 text-sm text-red-500'>{emailAddressError}</span>}
								</>
							) : (
								<div className='w-full mt-1 py-2 pr-3'>
									<span className='text-theme-gray-800 text-sm'>{emailAddress || 'N/A'}</span>
								</div>
							)}
						</div>

						<div className='w-full col-span-12 lg:col-span-6'>
							<label htmlFor='mobilePhone' className='block text-sm font-medium text-theme-gray-800'>
								Mobile phone
							</label>

							{editContactInformation ? (
								<>
									<input
										type='tel'
										onChange={(e) => {
											setMobilePhone(e.target.value);
											setMobilePhoneError(null);
										}}
										defaultValue={mobilePhone}
										placeholder='758-123-4567'
										id='mobilePhone'
										className={`w-full mt-1 rounded-md border ${mobilePhoneError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
										autoComplete='tel-national'
									/>

									{mobilePhoneError && <span className='mt-2 text-sm text-red-500'>{mobilePhoneError}</span>}
								</>
							) : (
								<div className='w-full mt-1 py-2 pr-3'>
									<span className='text-theme-gray-800 text-sm'>{mobilePhone || 'N/A'}</span>
								</div>
							)}
						</div>

						<div className='w-full col-span-12 lg:col-span-6'>
							<label htmlFor='homePhone' className='block text-sm font-medium text-theme-gray-800'>
								Home phone
							</label>

							{editContactInformation ? (
								<>
									<input
										type='tel'
										onChange={(e) => {
											setHomePhone(e.target.value);
											setHomePhoneError(null);
										}}
										defaultValue={homePhone}
										placeholder='758-123-4567'
										id='homePhone'
										className={`w-full mt-1 rounded-md border ${homePhoneError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
										autoComplete='tel-national'
									/>

									{homePhoneError && <span className='mt-2 text-sm text-red-500'>{homePhoneError}</span>}
								</>
							) : (
								<div className='w-full mt-1 py-2 pr-3'>
									<span className='text-theme-gray-800 text-sm'>{homePhone || 'N/A'}</span>
								</div>
							)}
						</div>
					</div>
				</div>

				{editContactInformation ? (
					<div className='flex flex-row gap-2'>
						<button
							type='submit'
							onClick={handleSaveContactInformation}
							disabled={editContactInformationLoading}
							className={`inline-block shrink-0 rounded-md border px-4 py-1.5 text-sm font-medium transition-all duration-300 ease-in-out focus:outline-none focus:ring ${editContactInformationLoading ? 'border-blue-300 bg-blue-300 hover:border-blue-300 hover:bg-blue-300' : 'text-white border-blue-500 bg-blue-500 hover:bg-transparent hover:text-blue-500 active:text-blue-500'}`}>
							{editContactInformationLoading ? (
								<div className='flex items-center justify-center'>
									<Loader />
								</div>
							) : (
								'Save'
							)}
						</button>

						<button
							type='button'
							onClick={async (e) => {
								e.preventDefault();

								await setEditContactInformation(false);
								await setEditContactInformationLoading(false);
							}}
							className='inline-block shrink-0 rounded-md border px-4 py-1.5 text-sm font-medium focus:outline-none focus:ring text-theme-gray-600 border-theme-gray-400 bg-transparent hover:bg-transparent hover:text-theme-gray-400 hover:border-theme-gray-400 active:text-theme-gray-400'>
							Cancel
						</button>
					</div>
				) : (
					<button
						type='button'
						onClick={async (e) => {
							e.preventDefault();

							await setEditContactInformation(true);
							await setEditContactInformationLoading(false);
						}}
						className='inline-block shrink-0 rounded-md border px-4 py-1.5 text-sm font-medium transition-all duration-300 ease-in-out focus:outline-none focus:ring text-white border-blue-500 bg-blue-500 hover:bg-transparent hover:text-blue-500 active:text-blue-500'>
						Edit
					</button>
				)}
			</form>
		</div>
	);
};

const AddressDetails = ({
	editAddressInformation,
	editAddressInformationLoading,
	addressStreet,
	setAddressStreet,
	addressStreetError,
	setAddressStreetError,
	addressCommunity,
	setAddressCommunity,
	addressCommunityError,
	setAddressCommunityError,
	addressDistrict,
	setAddressDistrict,
	addressDistrictError,
	setAddressDistrictError,
	addressCountry,
	setAddressCountry,
	addressCountryError,
	setAddressCountryError,
	handleSaveAddressInformation,
	setEditAddressInformation,
	setEditAddressInformationLoading,
	countryList,
}) => {
	return (
		<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
			<form>
				<div className='mb-3'>
					<div className='mb-4 flex flex-row items-center justify-between'>
						<h3 className='text-xl font-bold text-theme-gray-800'>Address Details</h3>
					</div>

					<div className='w-full grid grid-cols-12 gap-6'>
						<div className='w-full col-span-12 lg:col-span-6'>
							<label htmlFor='addressStreet' className='block text-sm font-medium text-theme-gray-800'>
								Street
							</label>

							{editAddressInformation ? (
								<>
									<input
										type='text'
										onChange={(e) => {
											setAddressStreet(e.target.value);
											setAddressStreetError(null);
										}}
										defaultValue={addressStreet}
										placeholder='123 St.'
										id='addressStreet'
										className={`w-full mt-1 rounded-md border ${addressStreetError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
										autoComplete='street-address'
									/>

									{addressStreetError && <span className='mt-2 text-sm text-red-500'>{addressStreetError}</span>}
								</>
							) : (
								<div className='w-full mt-1 py-2 pr-3'>
									<span className='text-theme-gray-800 text-sm'>{addressStreet || 'N/A'}</span>
								</div>
							)}
						</div>

						<div className='w-full col-span-12 lg:col-span-6'>
							<label htmlFor='addressCommunity' className='block text-sm font-medium text-theme-gray-800'>
								Community
							</label>

							{editAddressInformation ? (
								<>
									<input
										type='text'
										onChange={(e) => {
											setAddressCommunity(e.target.value);
											setAddressCommunityError(null);
										}}
										defaultValue={addressCommunity}
										placeholder='Mayfield'
										id='addressCommunity'
										className={`w-full mt-1 rounded-md border ${addressCommunityError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
										autoComplete='address-level2'
									/>

									{addressCommunityError && <span className='mt-2 text-sm text-red-500'>{addressCommunityError}</span>}
								</>
							) : (
								<div className='w-full mt-1 py-2 pr-3'>
									<span className='text-theme-gray-800 text-sm'>{addressCommunity || 'N/A'}</span>
								</div>
							)}
						</div>

						<div className='w-full col-span-12 lg:col-span-6'>
							<label htmlFor='addressDistrict' className='block text-sm font-medium text-theme-gray-800'>
								District <span className='text-red-500'>*</span>
							</label>

							{editAddressInformation ? (
								<>
									<input
										type='text'
										onChange={(e) => {
											setAddressDistrict(e.target.value);
											setAddressDistrictError(null);
										}}
										defaultValue={addressDistrict}
										placeholder='Anytown'
										id='addressDistrict'
										className={`w-full mt-1 rounded-md border ${addressDistrictError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
										autoComplete='address-level1'
										required
									/>

									{addressDistrictError && <span className='mt-2 text-sm text-red-500'>{addressDistrictError}</span>}
								</>
							) : (
								<div className='w-full mt-1 py-2 pr-3'>
									<span className='text-theme-gray-800 text-sm'>{addressDistrict || 'N/A'}</span>
								</div>
							)}
						</div>

						<div className='w-full col-span-12 lg:col-span-6'>
							<label htmlFor='addressCountry' className='block text-sm font-medium text-theme-gray-800'>
								Country <span className='text-red-500'>*</span>
							</label>

							{editAddressInformation ? (
								<>
									<select
										onChange={(e) => {
											setAddressCountry(e.target.value);
											setAddressCountryError(null);
										}}
										id='addressCountry'
										defaultValue={addressCountry || ''}
										className={`w-full mt-1 rounded-md border ${addressCountryError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
										required>
										<option value='' disabled>
											Select a country
										</option>

										{countryList.map((country, idx) => {
											return (
												<option key={idx} value={country}>
													{country}
												</option>
											);
										})}
									</select>

									{addressCountryError && <span className='mt-2 text-sm text-red-500'>{addressCountryError}</span>}
								</>
							) : (
								<div className='w-full mt-1 py-2 pr-3'>
									<span className='text-theme-gray-800 text-sm'>{addressCountry || 'N/A'}</span>
								</div>
							)}
						</div>
					</div>
				</div>

				{editAddressInformation ? (
					<div className='flex flex-row gap-2'>
						<button
							type='submit'
							onClick={handleSaveAddressInformation}
							disabled={editAddressInformationLoading}
							className={`inline-block shrink-0 rounded-md border px-4 py-1.5 text-sm font-medium transition-all duration-300 ease-in-out focus:outline-none focus:ring ${editAddressInformationLoading ? 'border-blue-300 bg-blue-300 hover:border-blue-300 hover:bg-blue-300' : 'text-white border-blue-500 bg-blue-500 hover:bg-transparent hover:text-blue-500 active:text-blue-500'}`}>
							{editAddressInformationLoading ? (
								<div className='flex items-center justify-center'>
									<Loader />
								</div>
							) : (
								'Save'
							)}
						</button>

						<button
							type='button'
							onClick={async (e) => {
								e.preventDefault();

								await setEditAddressInformation(false);
								await setEditAddressInformationLoading(false);
							}}
							className='inline-block shrink-0 rounded-md border px-4 py-1.5 text-sm font-medium focus:outline-none focus:ring text-theme-gray-600 border-theme-gray-400 bg-transparent hover:bg-transparent hover:text-theme-gray-400 hover:border-theme-gray-400 active:text-theme-gray-400'>
							Cancel
						</button>
					</div>
				) : (
					<button
						type='button'
						onClick={async (e) => {
							e.preventDefault();

							await setEditAddressInformation(true);
							await setEditAddressInformationLoading(false);
						}}
						className='inline-block shrink-0 rounded-md border px-4 py-1.5 text-sm font-medium transition-all duration-300 ease-in-out focus:outline-none focus:ring text-white border-blue-500 bg-blue-500 hover:bg-transparent hover:text-blue-500 active:text-blue-500'>
						Edit
					</button>
				)}
			</form>
		</div>
	);
};

const ParentInformation = ({ motherFirstName, motherLastName, motherEmailAddress, motherMobilePhone, fatherFirstName, fatherLastName, fatherEmailAddress, fatherMobilePhone }) => {
	return (
		<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
			<form>
				<div className='mb-4 flex flex-row items-center justify-between'>
					<h3 className='text-xl font-bold text-theme-gray-800'>Parent Information</h3>
				</div>

				<div className='mb-2 flex flex-row items-center justify-between'>
					<h3 className='text-xs font-bold text-blue-500 uppercase'>Mother</h3>
				</div>

				<div className='w-full grid grid-cols-12 gap-6'>
					<div className='w-full col-span-12 lg:col-span-6'>
						<label htmlFor='motherFirstName' className='block text-sm font-medium text-theme-gray-800'>
							First name
						</label>

						<div className='w-full mt-1 py-2 pr-3'>
							<span className='text-theme-gray-800 text-sm'>{motherFirstName || 'N/A'}</span>
						</div>
					</div>

					<div className='w-full col-span-12 lg:col-span-6'>
						<label htmlFor='motherLastName' className='block text-sm font-medium text-theme-gray-800'>
							Last name
						</label>

						<div className='w-full mt-1 py-2 pr-3'>
							<span className='text-theme-gray-800 text-sm'>{motherLastName || 'N/A'}</span>
						</div>
					</div>

					<div className='w-full col-span-12 lg:col-span-6'>
						<label htmlFor='motherEmailAddress' className='block text-sm font-medium text-theme-gray-800'>
							Email address
						</label>

						<div className='w-full mt-1 py-2 pr-3'>
							<span className='text-theme-gray-800 text-sm'>{motherEmailAddress || 'N/A'}</span>
						</div>
					</div>

					<div className='w-full col-span-12 lg:col-span-6'>
						<label htmlFor='motherMobilePhone' className='block text-sm font-medium text-theme-gray-800'>
							Mobile phone
						</label>

						<div className='w-full mt-1 py-2 pr-3'>
							<span className='text-theme-gray-800 text-sm'>{motherMobilePhone || 'N/A'}</span>
						</div>
					</div>
				</div>

				<div className='mt-6 mb-2 flex flex-row items-center justify-between'>
					<h3 className='text-xs font-bold text-blue-500 uppercase'>Father</h3>
				</div>

				<div className='w-full grid grid-cols-12 gap-6'>
					<div className='w-full col-span-12 lg:col-span-6'>
						<label htmlFor='fatherFirstName' className='block text-sm font-medium text-theme-gray-800'>
							First name
						</label>

						<div className='w-full mt-1 py-2 pr-3'>
							<span className='text-theme-gray-800 text-sm'>{fatherFirstName || 'N/A'}</span>
						</div>
					</div>

					<div className='w-full col-span-12 lg:col-span-6'>
						<label htmlFor='fatherLastName' className='block text-sm font-medium text-theme-gray-800'>
							Last name
						</label>

						<div className='w-full mt-1 py-2 pr-3'>
							<span className='text-theme-gray-800 text-sm'>{fatherLastName || 'N/A'}</span>
						</div>
					</div>

					<div className='w-full col-span-12 lg:col-span-6'>
						<label htmlFor='fatherEmailAddress' className='block text-sm font-medium text-theme-gray-800'>
							Email address
						</label>

						<div className='w-full mt-1 py-2 pr-3'>
							<span className='text-theme-gray-800 text-sm'>{fatherEmailAddress || 'N/A'}</span>
						</div>
					</div>

					<div className='w-full col-span-12 lg:col-span-6'>
						<label htmlFor='fatherMobilePhone' className='block text-sm font-medium text-theme-gray-800'>
							Mobile phone
						</label>

						<div className='w-full mt-1 py-2 pr-3'>
							<span className='text-theme-gray-800 text-sm'>{fatherMobilePhone || 'N/A'}</span>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export { ProfileInformation, SchoolDetails, LanguageTime, Preferences, ChangePassword, PersonalDetails, ContactDetails, AddressDetails, ParentInformation };
