import React from 'react';
import { useTitle } from 'react-use';
import { FaqItem, Footer } from '../../components';

const faqItems = [
	{
		question: 'What is the School Grading Portal all about?',
		answer: 'The School Grading Portal is an online platform that allows students, alumni and parents to access their grades, progress reports, and feedback from teachers. It also enables teachers to record and manage student assessment data, communicate with students and parents, and generate reports.',
	},
	{
		question: 'How do I access the School Grading Portal?',
		answer: 'You can access the School Grading Portal by visiting the school website and clicking on the link to the portal. You will need to log in with your username and password, which will be provided by the school administration.',
	},
	{
		question: 'What can I do on the School Grading Portal?',
		answer: 'Students, alumni and parents can view grades, progress reports, and feedback from teachers. Teachers can record and manage student assessment data, communicate with students and parents, and generate reports.',
	},
	{
		question: ' How do I change my password or personal information?',
		answer: 'To communicate with your teacher or parent, you need to log in to the School Grading Portal and click on the "Messages" tab. You will see a list of your contacts and the messages you have exchanged with them. You can also compose a new message by clicking on the "New Message" button and selecting the recipient from the drop-down menu. You can attach files, images, or links to your message if needed',
	},
	{
		question: 'How do I contact my teachers on the School Grading Portal?',
		answer: 'To contact your teachers, log in to the portal and click on the "Messages" tab. You will be able to send messages to your teachers and view any messages they have sent to you.',
	},
	{
		question: 'What are the benefits of using the School Grading Portal?',
		answer: "The School Grading Portal offers several benefits for students, parents, and teachers, such as:\n\n- Students can monitor their own learning progress, view their grades and feedback, and set goals for improvement.\n- Parents can stay informed about their child's academic performance, attendance, and behavior, and communicate with teachers if they have any concerns or questions.\n- Teachers can save time and effort in grading and reporting, provide timely and meaningful feedback to students, and track student growth and achievement.",
	},
	{
		question: 'What if I forget my password or username?',
		answer: 'If you forget your password or username, you can click on the "Forgot Password" or "Forgot Username" link on the login page. You will need to enter your email address or phone number, and you will receive a verification code or a link to reset your password or username.',
	},
	{
		question: 'What if I have a problem or a question about the School Grading Portal?',
		answer: 'If you have a problem or a question about the School Grading Portal, you can contact the school administration or the technical support team. You can find their contact information on the school website or the portal homepage. You can also check the "Help" tab on the portal for more information and guidance.',
	},
	{
		question: ' How do I request a transcript or a report card?',
		answer: 'To request a transcript or a report card, you need to log in to the School Grading Portal and click on the "Transcript" or "Report Card" tab. You will see a form where you can select the type of document you want, the format you prefer, and the delivery method you choose. You will also see the fee for the document and the payment options. You need to fill out the form and submit it to complete your request.',
	},
	{
		question: 'How do I contact my teachers on the School Grading Portal?',
		answer: 'To contact your teachers, log in to the portal and click on the "Chat" tab. You will be able to send messages to your teachers and view any messages they have sent to you.',
	},
	{
		question: 'Can I view my overall GPA on the School Grading Portal?',
		answer: 'Yes, you can view your overall GPA on the School Grading Portal. You need to log in to the portal and click on the "Reports" tab. You will see your grades for each course and your overall GPA at the bottom of the page. You can also view your GPA for each semester or academic year by selecting the appropriate option from the drop-down menu.',
	},
	{
		question: 'Does Grade Portal show Performance Ranking?',
		answer: 'Yes, Grade Portal shows Performance Ranking. You can view your performance ranking in each course and overall on the School Grading Portal. You need to log in to the portal and click on the "Reports" tab. You will see your grades for each course and your performance ranking at the bottom of the page. You can also view your performance ranking for each semester or academic year by selecting the appropriate option from the drop-down menu.',
	},
];

const Faq = () => {
	useTitle('FAQ | Grade Portal');

	return (
		<main className='relative flex-1 overflow-x-hidden overflow-y-auto focus:outline-none'>
			<div className='flex flex-col gap-8 mx-auto 2xl:max-w-7xl px-4 py-4 sm:px-6 md:px-8 sm:py-6 md:py-8'>
				<section className='w-full bg-theme-white'>
					<h3 className='text-2xl font-bold text-theme-gray-800'>Frequently Asked Questions</h3>

					<div className='grid grid-cols-6 gap-6 mt-6'>
						{faqItems?.map((item, idx) => {
							return (
								<div key={idx} className='col-span-6 md:col-span-3 '>
									<FaqItem faq={item} />
								</div>
							);
						})}
					</div>
				</section>
			</div>

			<Footer />
		</main>
	);
};

export default Faq;
