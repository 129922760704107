import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTitle } from 'react-use';
import { AuthContext, DataContext } from '../../context';
import { Loader, MainContainer } from '../../components';
import { ErrorHandling, generateNewSubjectID, useAlertHandling, useInputWithError } from '../../utils';

const NewSubject = () => {
	useTitle('New Subject | Grade Portal');

	const { currentUser } = useContext(AuthContext);
	const { appData, divRef, handleScroll, createNewSubject } = useContext(DataContext);
	const { showAlert } = useAlertHandling();

	const [canAccessSubject, setCanAccessSubject] = useState(false);

	useEffect(() => {
		if (currentUser?.data?.userType === 'principal' || currentUser?.data?.userType === 'admin') {
			setCanAccessSubject(true);
		} else {
			setCanAccessSubject(false);
		}
	}, [currentUser?.data?.userType]);

	const { value: name, setValue: setName, error: nameError, setError: setNameError } = useInputWithError('');
	const [description, setDescription] = useState(null);

	const [handleNewSubjectLoading, setHandleNewSubjectLoading] = useState(false);

	const handleNewSubject = async (e) => {
		e.preventDefault();
		setHandleNewSubjectLoading(true);

		let newSubjectData = {
			id: generateNewSubjectID(),
			name: name,
			desc: description,
			creationDate: new Date().toISOString().split('T')[0],
			addedBy: currentUser?.data?.id,
		};

		if (await ErrorHandling?.handleSubjectNameError(newSubjectData?.name, setNameError, showAlert)) {
			setHandleNewSubjectLoading(false);
		} else {
			try {
				await createNewSubject(newSubjectData);
				setHandleNewSubjectLoading(false);

				window.location.href = `/subjects/${newSubjectData?.id}`;
			} catch (error) {
				if (appData.debugMode) {
					console.error(error);
				}

				showAlert('error', 'An unknown error occurred');
			}
		}

		setHandleNewSubjectLoading(false);
	};

	if (!canAccessSubject) {
		return (
			<MainContainer divRef={divRef} handleScroll={handleScroll}>
				<section className='bg-theme-white py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
					<div className='mx-auto max-w-screen-sm text-center'>
						<h1 className='mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-theme-gray-800'>403</h1>

						<p className='mb-4 text-3xl tracking-tight font-bold text-blue-500 md:text-4xl'>Oops! Access Denied</p>

						<p className='mb-8 text-lg text-theme-gray-600'>It seems that the page you're trying to access isn't available.</p>

						<Link to='/' className='inline-block shrink-0 rounded-md border px-5 py-2.5 text-sm font-medium focus:outline-none focus:ring text-white border-blue-500 bg-blue-500 hover:bg-transparent hover:text-blue-500 active:text-blue-500'>
							Back to Homepage
						</Link>
					</div>
				</section>
			</MainContainer>
		);
	}

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<h3 className='text-2xl font-bold text-theme-gray-800'>New Subject</h3>
			</section>

			<section className='w-full bg-theme-white'>
				<form autoComplete='off'>
					<div className='grid grid-cols-12 gap-4 mt-4'>
						<div className='w-full col-span-12'>
							<label htmlFor='name' className='block text-sm font-medium text-theme-gray-800'>
								Name
								<span className='text-red-500 ml-1'>*</span>
							</label>

							<input
								type='text'
								onChange={(e) => {
									e.preventDefault();
									setName(e.target.value);
								}}
								className={`mt-1 w-full rounded-md border ${nameError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
								id='name'
								required
							/>

							{nameError && <span className='mt-2 text-sm text-red-500'>{nameError}</span>}
						</div>

						<div className='w-full col-span-12'>
							<label htmlFor='description' className='block text-sm font-medium text-theme-gray-800'>
								Description
							</label>

							<textarea
								onChange={(e) => {
									e.preventDefault();
									setDescription(e.target.value);
								}}
								id='description'
								rows='5'
								className='mt-1 w-full rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'></textarea>
						</div>
					</div>

					<hr className='my-8' />

					<div className='w-full mt-4 col-span-6 flex flex-col gap-4 sm:flex-row sm:items-center'>
						<button
							type='submit'
							onClick={handleNewSubject}
							disabled={handleNewSubjectLoading}
							className={`inline-flex items-center justify-center shrink-0 rounded-md border border-blue-500 bg-blue-500 text-white px-4 py-2 gap-2 text-sm font-medium ${handleNewSubjectLoading ? 'opacity-60 cursor-not-allowed' : 'hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'}`}>
							{handleNewSubjectLoading ? (
								<div className='flex items-center justify-center'>
									<Loader />
								</div>
							) : (
								'Create New Subject'
							)}
						</button>
					</div>
				</form>
			</section>
		</MainContainer>
	);
};

export default NewSubject;
