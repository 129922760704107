import React from 'react';

const TableHeadItem = ({ type, title, align, onClick, checked, onChange }) => {
	if (type === 'select') {
		return (
			<th className='px-4 py-2 text-left' data-html2canvas-ignore='true'>
				<label htmlFor='selectAll' className='sr-only'>
					Select All
				</label>

				<input type='checkbox' checked={checked} onChange={onChange} onClick={onClick} id='selectAll' className='size-5 rounded bg-theme-white border-theme-gray-400' />
			</th>
		);
	} else if (type === 'sort') {
		return (
			<th className={`whitespace-nowrap px-4 py-2 font-medium ${align === 'left' && 'text-left'} text-theme-gray-800`}>
				<button type='button' onClick={onClick} className={`${align === 'center' && 'mx-auto'} flex items-center justify-center text-center`}>
					{title}

					<svg className='size-4 ml-2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' data-html2canvas-ignore='true'>
						<path d='M136 208l120-104 120 104M136 304l120 104 120-104' stroke='currentColor' fill='none' strokeWidth='32' strokeLinecap='round' strokeLinejoin='round' />
					</svg>
				</button>
			</th>
		);
	} else if (type === 'default') {
		return <th className={`whitespace-nowrap px-4 py-2 font-medium ${align === 'left' ? 'text-left' : align === 'center' ? 'text-center' : null} text-theme-gray-800`}>{title}</th>;
	} else if (type === 'dropdown') {
		return <th className='whitespace-nowrap px-4 py-2' data-html2canvas-ignore='true'></th>;
	}
};

export default TableHeadItem;
