import { createContext, useState, useEffect } from 'react';
import { GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { query, getDocs, collection, where } from 'firebase/firestore';
import { auth, db } from '../config/firebase';
import { useAlertHandling } from '../utils';
import { redirect } from 'react-router-dom';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
	const { showAlert } = useAlertHandling();
	const [currentUser, setCurrentUser] = useState(null);
	const [handleLoginLoading, setHandleLoginLoading] = useState(false);

	const useLocalData = false;

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (user) => {
			if (user) {
				const foundUsers = [];

				const usersRef = collection(db, 'users');
				const usersQuery = query(usersRef, where('uid', '==', user?.uid), where('emailAddress', '==', user?.email));
				const usersQuerySnapshot = await getDocs(usersQuery);

				usersQuerySnapshot.forEach((foundUser) => {
					const userData = foundUser?.data();
					foundUsers?.push(userData);
				});

				if (foundUsers?.length > 0) {
					if (Object.keys(foundUsers[0])?.length > 0) {
						setCurrentUser({
							user: user,
							data: foundUsers[0],
						});
					} else {
						await signOut(auth);
						setCurrentUser(null);

						return redirect('/auth/login');
					}
				} else {
					await signOut(auth);
					setCurrentUser(null);

					return redirect('/auth/login');
				}
			} else {
				await signOut(auth);
				setCurrentUser(null);

				return redirect('/auth/login');
			}
		});

		return () => unsubscribe();
	}, []);

	const emailLogin = async (credentials) => {
		try {
			const response = await signInWithEmailAndPassword(auth, credentials?.emailAddress, credentials?.password);
			const user = response?.user;

			setCurrentUser(user);
			showAlert('success', 'Login successful');

			return user;
		} catch (error) {
			logOut(false, false);

			throw error;
		}
	};

	const googleLogin = async (userType) => {
		const googleProvider = new GoogleAuthProvider();

		try {
			const response = await signInWithPopup(auth, googleProvider);
			const user = response?.user;

			const usersRef = collection(db, 'users');
			const usersQuery = query(usersRef, where('uid', '==', user?.uid), where('emailAddress', '==', user?.email));
			const usersQuerySnapshot = await getDocs(usersQuery);

			const foundUsers = [];

			usersQuerySnapshot?.forEach((foundUser) => {
				const userData = foundUser?.data();
				foundUsers.push(userData);
			});

			if (foundUsers?.length > 0) {
				if (Object.keys(foundUsers[0])?.length > 0) {
					if (foundUsers[0]?.userType === userType && foundUsers[0]?.emailAddress === user?.email) {
						setCurrentUser({
							user: user,
							data: foundUsers[0],
						});

						showAlert('success', 'Login successful');
					} else {
						setCurrentUser(null);

						showAlert('error', 'Access denied');
						throw new Error('Access denied');
					}
				} else {
					setCurrentUser(null);

					showAlert('error', 'Account does not exist');
					throw new Error('Account does not exist');
				}
			} else {
				setCurrentUser(null);

				showAlert('error', 'Account does not exist');
				throw new Error('Account does not exist');
			}
		} catch (err) {
			logOut(false, false);
			throw err;
		}

		setHandleLoginLoading(false);
	};

	const logOut = async (userInvoked, _redirect) => {
		try {
			await signOut(auth);
			setCurrentUser(null);
			redirect('/auth/login');

			if (userInvoked) showAlert('success', 'Logout successful');
			if (_redirect) return redirect('/auth/login');
		} catch (err) {
			throw err;
		}
	};

	return (
		<AuthContext.Provider
			value={{
				useLocalData,
				handleLoginLoading,
				setHandleLoginLoading,
				currentUser,
				setCurrentUser,
				emailLogin,
				googleLogin,
				logOut,
			}}>
			{children}
		</AuthContext.Provider>
	);
};

export { AuthContext, AuthProvider };
