import html2pdf from 'html2pdf.js';

const calculateGPAFromPercentage = (percentage) => {
	const minPercentage = 0;
	const maxPercentage = 100;

	const minGPA = 0;
	const maxGPA = 4.0;

	const gpa = minGPA + ((percentage - minPercentage) / (maxPercentage - minPercentage)) * (maxGPA - minGPA);

	return Math.max(minGPA, Math.min(maxGPA, gpa));
};

const calculateLetterGradeFromPercentage = (percentage) => {
	if (percentage >= 90) {
		return 'A+';
	} else if (percentage >= 85) {
		return 'A';
	} else if (percentage >= 80) {
		return 'A-';
	} else if (percentage >= 75) {
		return 'B+';
	} else if (percentage >= 70) {
		return 'B';
	} else if (percentage >= 65) {
		return 'B-';
	} else if (percentage >= 60) {
		return 'C+';
	} else if (percentage >= 55) {
		return 'C';
	} else if (percentage >= 50) {
		return 'C-';
	} else if (percentage >= 40) {
		return 'D';
	} else {
		return 'F';
	}
};

const calculateCXCGradeFromPercentage = (percentage) => {
	if (percentage >= 90) {
		return 'I*';
	} else if (percentage >= 85) {
		return 'I';
	} else if (percentage >= 75) {
		return 'II';
	} else if (percentage >= 65) {
		return 'III';
	} else if (percentage >= 50) {
		return 'IV';
	} else {
		return 'V';
	}
};

const generateUserGrades = async (currentSchool, currentUser, assessments, duration, clsId) => {
	if (!currentSchool) {
		console.error('Current school data is missing or incomplete.');

		return [];
	}

	if (!currentUser || !currentUser?.data || !currentUser?.data?.grades) {
		console.error('User data is missing or incomplete.');

		return [];
	}

	if (!assessments) {
		console.error('Assessments data is missing or incomplete.');

		return [];
	}

	const calculateClassTotal = async (grades, assessments, classId) => {
		let classTotal = 0;

		if (grades?.length === 0) {
			return 'N/A';
		}

		const filteredGrades = grades?.filter(async (grade) => {
			const assessment = await assessments?.find((assessment) => assessment?.id === grade?.assessmentId);

			return assessment && assessment?.classId === classId;
		});

		for (const grade of filteredGrades) {
			const assessment = assessments?.find((assessment) => assessment?.id === grade?.assessmentId);

			if (assessment) {
				classTotal += (parseFloat(grade?.grade) / parseFloat(assessment?.grading?.totalPoints)) * parseFloat(assessment?.grading?.weight);
			}
		}

		return classTotal;
	};

	const calculateGrades = async (currentSchool, grades, assessments, duration) => {
		const classGrades = [];

		if (clsId) {
			const foundClass = currentSchool?.classes?.filter((cls) => cls?.id === clsId);
			let classTotal = await calculateClassTotal(grades, assessments, clsId);

			if (duration) {
				if (foundClass?.duration?.semester === duration?.semester && foundClass?.duration?.year === duration?.year) {
					classGrades?.push({
						schoolId: currentSchool?.id,
						classId: foundClass?.id,
						teacherId: foundClass?.teacherId,
						subjectId: foundClass?.subjectId,
						grade: classTotal === 'N/A' ? classTotal : parseInt(classTotal),
						year: foundClass?.duration?.year,
					});
				}
			} else {
				classGrades?.push({
					schoolId: currentSchool?.id,
					classId: foundClass?.id,
					teacherId: foundClass?.teacherId,
					subjectId: foundClass?.subjectId,
					grade: classTotal === 'N/A' ? classTotal : parseInt(classTotal),
					year: foundClass?.duration?.year,
				});
			}
		} else {
			const userSchoolClassIds = await currentUser?.data?.school?.classes?.map((cls) => cls);
			const usersClasses = await currentSchool?.classes?.filter((cls) => userSchoolClassIds?.includes(cls?.id));

			if (usersClasses && Object.keys(usersClasses)?.length > 0) {
				for (const cls of usersClasses) {
					let classTotal = await calculateClassTotal(grades, assessments, cls?.id);

					if (duration) {
						if (cls?.duration?.semester === duration?.semester && cls?.duration?.year === duration?.year) {
							classGrades?.push({
								schoolId: currentSchool?.id,
								classId: cls?.id,
								teacherId: cls?.teacherId,
								subjectId: cls?.subjectId,
								grade: classTotal === 'N/A' ? classTotal : parseInt(classTotal),
								semester: cls?.duration?.semester,
								year: cls?.duration?.year,
							});
						}
					} else {
						classGrades?.push({
							schoolId: currentSchool?.id,
							classId: cls?.id,
							teacherId: cls?.teacherId,
							subjectId: cls?.subjectId,
							grade: classTotal === 'N/A' ? classTotal : parseInt(classTotal),
							semester: cls?.duration?.semester,
							year: cls?.duration?.year,
						});
					}
				}
			} else {
				console.error('User has no classes in the current school.');
			}
		}

		return classGrades;
	};

	return calculateGrades(currentSchool, currentUser?.data?.grades, assessments, duration);
};

const getProperty = (obj, prop) => {
	const props = prop.split('.');
	let value = obj;

	for (let i = 0; i < props.length; i++) {
		value = value[props[i]];
	}

	return value;
};

const getCurrentSemester = (semesterData) => {
	const currentDate = new Date();
	const currentMonth = currentDate.getMonth() + 1;

	let currentSemester = null;

	for (const semester of semesterData) {
		const { startDate, endDate } = semester;
		const startMonth = startDate.month;
		const endMonth = endDate.month;

		if ((currentMonth >= startMonth && currentMonth <= endMonth) || (startMonth > endMonth && (currentMonth >= startMonth || currentMonth <= endMonth))) {
			currentSemester = semester;
			break;
		}
	}

	return currentSemester;
};

const getGradeLevelName = (level, gradeLevels) => {
	for (const key in gradeLevels) {
		const foundLevel = gradeLevels[key]?.find((item) => item?.level === level);

		if (foundLevel) {
			return foundLevel?.name;
		}
	}

	return null;
};

const formatDate = (date) => {
	const inputDate = new Date(date);

	const formattedDate = inputDate.toLocaleDateString('en-US', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
	});

	return formattedDate;
};

const calculateMinDate = () => {
	const currentDate = new Date();
	const minDate = new Date(currentDate.getFullYear() - 100, currentDate.getMonth(), currentDate.getDate());

	return minDate.toISOString().split('T')[0];
};

const getCurrentDate = () => {
	const currentDate = new Date();
	return currentDate.toISOString().split('T')[0];
};

const numberToWord = (num) => {
	const numberWords = {
		0: 'Zero',
		1: 'One',
		2: 'Two',
		3: 'Three',
		4: 'Four',
		5: 'Five',
		6: 'Six',
		7: 'Seven',
		8: 'Eight',
		9: 'Nine',
	};

	let word = '';

	for (let i = 0; i < num.length; i++) {
		const digit = num[i];

		word += numberWords[digit];
	}

	return word;
};

const generatePDF = (name) => {
	const pdfContentElement = document.getElementById('pdfContent');

	html2pdf(pdfContentElement, {
		margin: 5,
		filename: `grade_portal_${name.toLowerCase()}.pdf`,
		image: {
			type: 'jpeg',
			quality: 1,
		},
		html2canvas: {
			scale: 2,
			letterRendering: true,
			useCORS: true,
		},
		jsPDF: {
			unit: 'mm',
			format: 'letter',
			orientation: 'landscape',
		},
	});
};

const generateStudentId = (firstName, lastName) => {
	const firstLetter = firstName.charAt(0).toUpperCase();
	const lastNamePrefix = lastName.slice(0, 6).toUpperCase();

	const randomNumber = Math.floor(Math.random() * 900) + 100;

	const studentId = `${firstLetter}${lastNamePrefix}${randomNumber}`;

	return studentId;
};

const generateNewGradeID = () => {
	let randomNumber = '';

	for (let i = 0; i < 10; i++) {
		randomNumber += Math.floor(Math.random() * 10);
	}

	return `GRA${randomNumber}`;
};

const generateNewAssessmentID = () => {
	let randomNumber = '';

	for (let i = 0; i < 10; i++) {
		randomNumber += Math.floor(Math.random() * 10);
	}

	return `ASS${randomNumber}`;
};

const generateNewSubjectID = () => {
	let randomNumber = '';

	for (let i = 0; i < 10; i++) {
		randomNumber += Math.floor(Math.random() * 10);
	}

	return `SUB${randomNumber}`;
};

const generateNewClassID = (schoolId) => {
	const randomNumber = Math.floor(Math.random() * 900) + 100;

	return `${schoolId}${randomNumber}`;
};

const convertToMySQLDate = (day, month, year) => {
	const monthMap = {
		January: '01',
		February: '02',
		March: '03',
		April: '04',
		May: '05',
		June: '06',
		July: '07',
		August: '08',
		September: '09',
		October: '10',
		November: '11',
		December: '12',
	};

	const monthNumber = monthMap[month];
	const mysqlDate = `${year}-${monthNumber}-${day.padStart(2, '0')}`;

	return mysqlDate;
};

const formatPhoneNumber = (phoneNumber) => {
	return phoneNumber.replace(/\D/g, '');
};

export { calculateGPAFromPercentage, generateNewClassID, convertToMySQLDate, formatPhoneNumber, calculateLetterGradeFromPercentage, calculateCXCGradeFromPercentage, generateUserGrades, getCurrentSemester, getProperty, getGradeLevelName, formatDate, generateStudentId, calculateMinDate, getCurrentDate, numberToWord, generatePDF, generateNewGradeID, generateNewAssessmentID, generateNewSubjectID };
