import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import { Link } from 'react-router-dom';
import { AuthContext, DataContext } from '../../context';
import { generatePDF, useAlertHandling } from '../../utils';
import { DeleteModal, MainContainer, MenuDropdown, Table, TableBodyItem, TableHeadItem } from '../../components';

const SubjectsTableContent = ({ subject, setDeleteSubjectId, setDeleteSubjectModalState }) => {
	const { currentUser } = useContext(AuthContext);

	const menuItems = [
		[
			{
				type: 'link',
				danger: false,
				to: `/subjects/${subject?.id}/edit`,
				onClick: null,
				text: 'Edit',
				icon: (
					<>
						<path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
						<path fillRule='evenodd' d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z' />
					</>
				),
			},
		],
		[
			{
				type: 'button',
				danger: true,
				to: null,
				newTab: false,
				onClick: () => {
					setDeleteSubjectId(subject?.id);
					setDeleteSubjectModalState(true);
				},
				text: 'Delete',
				icon: (
					<path d='M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5' />
				),
			},
		],
	];

	return (
		<>
			<TableBodyItem type='select' onClick={() => {}} />

			<TableBodyItem type='primary' contents={subject?.id ? <Link to={`/subjects/${subject?.id}`}>{subject?.id}</Link> : 'N/A'} align='left' />

			<TableBodyItem type='default' contents={subject?.name || 'N/A'} align='left' />

			{(currentUser?.data?.userType === 'principal' || currentUser?.data?.userType === 'admin') && <TableBodyItem type='dropdown' contents={<MenuDropdown type='table' menuItems={menuItems} />} align='center' />}
		</>
	);
};

const Subjects = () => {
	useTitle('Subjects | Grade Portal');

	const { currentUser } = useContext(AuthContext);
	const { appData, divRef, handleScroll, subjects, deleteSubject } = useContext(DataContext);
	const { showAlert } = useAlertHandling();

	const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState('asc');
	const [sortedSubjects, setSortedSubjects] = useState([]);

	const handleSortClick = (field) => {
		if (sortField === field) {
			setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
		} else {
			setSortField(field);
			setSortOrder('asc');
		}
	};

	useEffect(() => {
		const sortSubjects = async () => {
			let sortedData = [...(subjects ?? [])];

			if (sortField) {
				sortedData = sortedData?.sort((a, b) => {
					const valueA = a[sortField];
					const valueB = b[sortField];

					if (sortOrder === 'asc') {
						return valueA?.localeCompare(valueB, undefined, {
							numeric: true,
						});
					} else {
						return valueB?.localeCompare(valueA, undefined, {
							numeric: true,
						});
					}
				});
			}

			setSortedSubjects(sortedData);
		};

		sortSubjects();
	}, [subjects, sortField, sortOrder]);

	const [subjectSearchQuery, setSubjectSearchQuery] = useState('');
	const [filteredSubjects, setFilteredSubjects] = useState([]);

	useEffect(() => {
		const filtered = sortedSubjects?.filter((subject) => {
			const searchString = subjectSearchQuery?.toLowerCase();

			return subject?.id?.toLowerCase().includes(searchString) || subject?.name?.toLowerCase().includes(searchString) || subject?.desc?.toLowerCase().includes(searchString);
		});

		setFilteredSubjects(filtered);
	}, [sortedSubjects, subjectSearchQuery, subjects]);

	const [deleteSubjectId, setDeleteSubjectId] = useState(null);
	const [deleteSubjectModalState, setDeleteSubjectModalState] = useState(false);

	const handleDeleteSubject = async (e) => {
		e.preventDefault();

		try {
			if (deleteSubjectId) {
				await deleteSubject(deleteSubjectId);
				window.location.href = '/subjects';
			} else {
				if (appData.debugMode) {
					console.error("Subject ID can't be empty.");
				}

				showAlert('error', 'An unknown error occurred');
			}
		} catch (error) {
			if (appData.debugMode) {
				console.error(error);
			}

			showAlert('error', 'An unknown error occurred');
		}

		setDeleteSubjectModalState(false);
	};

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<div className='flex flex-row items-center justify-between'>
					<div className='flex flex-row items-center justify-start gap-4'>
						<h3 className='text-lg font-bold text-theme-gray-800 sm:text-xl'>Subjects</h3>

						<div className='w-full'>
							<label htmlFor='subjectSearch' className='sr-only'>
								Search
							</label>

							<div className='relative'>
								<div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
									<svg className='w-4 h-4 text-theme-gray-800' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
										<path d='M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z' fill='none' stroke='currentColor' strokeMiterlimit='10' strokeWidth='32' />
										<path fill='none' stroke='currentColor' strokeLinecap='round' strokeMiterlimit='10' strokeWidth='32' d='M338.29 338.29L448 448' />
									</svg>
								</div>

								<form>
									<input
										type='search'
										onChange={(e) => {
											e.preventDefault();
											setSubjectSearchQuery(e.target.value);
										}}
										className='min-w-64 w-full ps-10 rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'
										id='subjectSearch'
										placeholder='Search subjects'
									/>
								</form>
							</div>
						</div>
					</div>

					<div className='flex flex-row items-center justify-end gap-4'>
						{(currentUser?.data?.userType === 'principal' || currentUser?.data?.userType === 'admin') && (
							<Link type='button' to='/subjects/new' className='inline-block shrink-0 rounded-md border border-blue-500 bg-blue-500 px-4 py-2 text-sm font-medium text-white transition-all duration-300 ease-in-out hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'>
								New Subject
							</Link>
						)}

						<MenuDropdown
							type='download'
							menuItems={[
								[
									{
										type: 'button',
										danger: false,
										to: null,
										onClick: (e) => {
											e.preventDefault();
											generatePDF('Subjects');
										},
										text: 'Download PDF',
										icon: (
											<path
												fillRule='evenodd'
												d='M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z'
											/>
										),
									},
								],
							]}
						/>
					</div>
				</div>
			</section>

			<section className='w-full bg-theme-white'>
				<Table
					name='Subjects'
					headContents={
						<tr>
							<TableHeadItem type='select' onClick={() => {}} />

							<TableHeadItem type='sort' title='Subject ID' align='left' onClick={() => handleSortClick('id')} />

							<TableHeadItem type='sort' title='Name' align='left' onClick={() => handleSortClick('name')} />

							{currentUser?.data?.userType === 'principal' && currentUser?.data?.userType === 'admin' && <TableBodyItem type='dropdown' />}
						</tr>
					}
					bodyContents={({ item }) => <SubjectsTableContent subject={item} setDeleteSubjectId={setDeleteSubjectId} setDeleteSubjectModalState={setDeleteSubjectModalState} />}
					sortedData={sortedSubjects}
					filteredData={filteredSubjects}
					dataLoading={subjects && subjects?.length > 0 ? false : true}
					colSpan={currentUser?.data?.userType === 'principal' || currentUser?.data?.userType === 'admin' ? 4 : 3}
				/>
			</section>

			<DeleteModal title='Subject' deleteId={deleteSubjectId} handleDelete={handleDeleteSubject} deleteModalState={deleteSubjectModalState} setDeleteModalState={setDeleteSubjectModalState} />
		</MainContainer>
	);
};

export default Subjects;
