import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { AuthContext, DataContext } from '../../context';
import { MainContainer, Table, TableBodyItem, TableHeadItem } from '../../components';
import { formatDate, generatePDF, getProperty } from '../../utils';

const childrenContent = ({ child }) => {
	return (
		<>
			<TableBodyItem type='select' onClick={null} />

			<TableBodyItem type='primary' contents={child?.id || 'N/A'} />

			<TableBodyItem type='default' contents={child?.name?.first || 'N/A'} align='left' />

			<TableBodyItem type='default' contents={child?.name?.last || 'N/A'} align='left' />

			<TableBodyItem
				type='default'
				contents={
					<a href={child?.emailAddress ? `mailto:${child?.emailAddress}` : undefined} target='_blank' rel='noopener noreferrer'>
						{child?.emailAddress || 'N/A'}
					</a>
				}
				align='left'
			/>

			<TableBodyItem type='default' contents={child?.school?.id || 'N/A'} align='left' />

			<TableBodyItem type='default' contents={child?.dateOfBirth ? formatDate(child?.dateOfBirth) : 'N/A'} align='center' />

			<TableBodyItem type='default' contents={child?.registrationDate || 'N/A'} align='center' />
		</>
	);
};

const Children = () => {
	useTitle('Children | Grade Portal');

	const { currentUser } = useContext(AuthContext);
	const { appData, users, schools, schoolLevelGroups, divRef, handleScroll } = useContext(DataContext);

	const [children, setChildren] = useState([]);
	const [childrenLoading, setChildrenLoading] = useState(true);

	const [filterOption, setFilterOption] = useState('ALL');
	const [childrenSearchQuery, setChildrenSearchQuery] = useState('');

	useEffect(() => {
		const fetchChildren = async () => {
			try {
				const fetchedChildren = await currentUser?.data?.children;
				let _children = [];

				for (const child of fetchedChildren) {
					const fetchedChild = await users?.find((user) => user?.id === child && user?.userType === 'student');

					_children.push(fetchedChild);
				}

				let filteredChildren = _children?.filter((child) => (filterOption === 'ALL' ? true : child?.school?.id === filterOption));

				if (childrenSearchQuery !== null && childrenSearchQuery !== undefined) {
					const trimmedQuery = childrenSearchQuery?.trim().toLowerCase();

					filteredChildren = filteredChildren?.filter((child) => {
						const id = child?.id ? child?.id?.toLowerCase() : '';
						const firstName = child?.name?.first ? child?.name?.first?.toLowerCase() : '';
						const lastName = child?.name?.last ? child?.name?.last?.toLowerCase() : '';
						const emailAddress = child?.emailAddress ? child?.emailAddress?.toLowerCase() : '';

						return id?.includes(trimmedQuery) || firstName?.includes(trimmedQuery) || lastName?.includes(trimmedQuery) || emailAddress?.includes(trimmedQuery);
					});
				}

				setChildren(filteredChildren);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setChildrenLoading(false);
		};

		fetchChildren();
	}, [appData?.debugMode, currentUser?.data?.children, users, filterOption, childrenSearchQuery]);

	const groupedSchools = schools.reduce((acc, school) => {
		const groupName = schoolLevelGroups[school.level] || 'Other';

		acc[groupName] = acc[groupName] || [];
		acc[groupName].push(school);

		return acc;
	}, {});

	const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState('asc');
	const [sortedStudents, setSortedStudents] = useState([]);

	const handleSortClick = (field) => {
		if (sortField === field) {
			setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
		} else {
			setSortField(field);
			setSortOrder('asc');
		}
	};

	useEffect(() => {
		const sortStudents = async () => {
			let sortedData = [...(children ?? [])];

			if (sortField) {
				sortedData.sort((a, b) => {
					const valueA = getProperty(a, sortField);
					const valueB = getProperty(b, sortField);

					if (sortOrder === 'asc') {
						return valueA?.localeCompare(valueB, undefined, {
							numeric: true,
						});
					} else {
						return valueB?.localeCompare(valueA, undefined, {
							numeric: true,
						});
					}
				});
			}

			setSortedStudents(sortedData);
		};

		sortStudents();
	}, [children, sortField, sortOrder]);

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<div className='flex flex-row items-center justify-between'>
					<div className='flex flex-row items-center justify-start gap-4'>
						<h3 className='text-lg font-bold text-theme-gray-800 sm:text-xl'>Students</h3>

						<select
							value={filterOption}
							onChange={(e) => {
								e.preventDefault();
								setFilterOption(e.target.value);
							}}
							className='w-full rounded-lg bg-theme-white border-theme-gray-200 text-theme-gray-800 sm:text-sm'>
							<option value='ALL'>All Schools</option>

							{Object.entries(groupedSchools).map(([group, schools]) => (
								<optgroup label={group} key={group}>
									{schools.map((school, idx) => (
										<option key={idx} value={school.id}>
											{school.name}
										</option>
									))}
								</optgroup>
							))}
						</select>

						<div className='w-full'>
							<label htmlFor='studentSearch' className='sr-only'>
								Search
							</label>

							<div className='relative'>
								<div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
									<svg className='size-4 text-theme-gray-800' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
										<path d='M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z' fill='none' stroke='currentColor' strokeMiterlimit='10' strokeWidth='32' />
										<path fill='none' stroke='currentColor' strokeLinecap='round' strokeMiterlimit='10' strokeWidth='32' d='M338.29 338.29L448 448' />
									</svg>
								</div>

								<input
									type='search'
									onChange={(e) => {
										e.preventDefault();
										setChildrenSearchQuery(e.target.value);
									}}
									className='min-w-64 w-full ps-10 rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'
									id='studentSearch'
									placeholder='Search students'
								/>
							</div>
						</div>
					</div>

					<div className='flex flex-row items-center justify-end gap-4'>
						<Menu as='div' className='relative inline-block text-left'>
							<Menu.Button className='inline-block shrink-0 rounded-md border border-gray-400 bg-transparent px-2 py-2 text-sm font-medium text-gray-600 transition-all duration-300 ease-in-out hover:bg-transparent hover:text-gray-600/80 focus:outline-none focus:ring focus:ring-gray-400/80 active:text-gray-600/80'>
								<svg className='size-4' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
									<path d='M336 176h40a40 40 0 0140 40v208a40 40 0 01-40 40H136a40 40 0 01-40-40V216a40 40 0 0140-40h40' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' />
									<path fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' d='M176 272l80 80 80-80M256 48v288' />
								</svg>
							</Menu.Button>

							<Transition as={Fragment} enter='transition ease-out duration-100' enterFrom='transform opacity-0 scale-95' enterTo='transform opacity-100 scale-100' leave='transition ease-in duration-75' leaveFrom='transform opacity-100 scale-100' leaveTo='transform opacity-0 scale-95'>
								<Menu.Items className='z-50 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none'>
									<div className='px-1 py-1'>
										<Menu.Item>
											<button
												onClick={(e) => {
													e.preventDefault();
													generatePDF('Children');
												}}
												className='flex w-full items-center transition-all duration-300 ease-in-out px-2 py-2 rounded-lg text-sm text-gray-800 hover:bg-gray-100'>
												<svg className='size-4' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
													<path
														fillRule='evenodd'
														d='M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z'
													/>
												</svg>

												<span className='ml-2'>Download PDF</span>
											</button>
										</Menu.Item>
									</div>
								</Menu.Items>
							</Transition>
						</Menu>
					</div>
				</div>
			</section>

			<section className='w-full bg-theme-white'>
				<Table
					name='Children'
					headContents={
						<tr>
							<TableHeadItem type='select' />

							<TableHeadItem type='sort' title='Student ID' align='left' onClick={() => handleSortClick('id')} />

							<TableHeadItem type='sort' title='First Name' align='left' onClick={() => handleSortClick('name.first')} />

							<TableHeadItem type='sort' title='Last Name' align='left' onClick={() => handleSortClick('name.last')} />

							<TableHeadItem type='default' title='Email Address' align='left' />

							<TableHeadItem type='sort' title='School' align='left' onClick={() => handleSortClick('school.id')} />

							<TableHeadItem type='default' title='D.O.B' align='center' />

							<TableHeadItem type='sort' title='Registration Date' align='center' onClick={() => handleSortClick('registrationDate')} />
						</tr>
					}
					bodyContents={({ item }) =>
						childrenContent({
							child: item,
						})
					}
					sortedData={children}
					filteredData={children}
					dataLoading={childrenLoading}
					colSpan={8}
				/>
			</section>
		</MainContainer>
	);
};

export default Children;
