import React, { useContext, Fragment, useEffect, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { DataContext, AuthContext } from '../context';

const checkImage = (imageSrc, good, bad) => {
	var img = new Image();

	img.onload = good;
	img.onerror = bad;
	img.src = imageSrc;
};

const Dashboard = {
	to: '/',
	icon: (
		<>
			<rect x='48' y='48' width='176' height='176' rx='20' ry='20' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' />
			<rect x='288' y='48' width='176' height='176' rx='20' ry='20' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' />
			<rect x='48' y='288' width='176' height='176' rx='20' ry='20' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' />
			<rect x='288' y='288' width='176' height='176' rx='20' ry='20' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' />
		</>
	),
	text: 'Dashboard',
};

const Schools = {
	to: '/schools',
	icon: (
		<>
			<path fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' d='M176 416v64M80 32h192a32 32 0 0132 32v412a4 4 0 01-4 4H48h0V64a32 32 0 0132-32zM320 192h112a32 32 0 0132 32v256h0-160 0V208a16 16 0 0116-16z' />
			<path d='M98.08 431.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM98.08 351.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM98.08 271.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM98.08 191.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM98.08 111.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM178.08 351.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM178.08 271.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM178.08 191.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM178.08 111.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM258.08 431.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM258.08 351.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM258.08 271.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79z' />
			<ellipse cx='256' cy='176' rx='15.95' ry='16.03' transform='rotate(-45 255.99 175.996)' />
			<path d='M258.08 111.87a16 16 0 1113.79-13.79 16 16 0 01-13.79 13.79zM400 400a16 16 0 1016 16 16 16 0 00-16-16zM400 320a16 16 0 1016 16 16 16 0 00-16-16zM400 240a16 16 0 1016 16 16 16 0 00-16-16zM336 400a16 16 0 1016 16 16 16 0 00-16-16zM336 320a16 16 0 1016 16 16 16 0 00-16-16zM336 240a16 16 0 1016 16 16 16 0 00-16-16z' />
		</>
	),
	text: 'Schools',
};

const Classes = {
	to: '/classes',
	icon: (
		<>
			<rect x='64' y='176' width='384' height='256' rx='28.87' ry='28.87' fill='none' stroke='currentColor' strokeLinejoin='round' strokeWidth='32' />
			<path stroke='currentColor' strokeLinecap='round' strokeMiterlimit='10' strokeWidth='32' d='M144 80h224M112 128h288' />
		</>
	),
	text: 'Classes',
};

const Subjects = {
	to: '/subjects',
	icon: <path d='M256 160c16-63.16 76.43-95.41 208-96a15.94 15.94 0 0116 16v288a16 16 0 01-16 16c-128 0-177.45 25.81-208 64-30.37-38-80-64-208-64-9.88 0-16-8.05-16-17.93V80a15.94 15.94 0 0116-16c131.57.59 192 32.84 208 96zM256 160v288' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' />,
	text: 'Subjects',
};

const Students = {
	to: '/students',
	icon: (
		<>
			<path d='M402 168c-2.93 40.67-33.1 72-66 72s-63.12-31.32-66-72c-3-42.31 26.37-72 66-72s69 30.46 66 72z' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' />
			<path d='M336 304c-65.17 0-127.84 32.37-143.54 95.41-2.08 8.34 3.15 16.59 11.72 16.59h263.65c8.57 0 13.77-8.25 11.72-16.59C463.85 335.36 401.18 304 336 304z' fill='none' stroke='currentColor' strokeMiterlimit='10' strokeWidth='32' />
			<path d='M200 185.94c-2.34 32.48-26.72 58.06-53 58.06s-50.7-25.57-53-58.06C91.61 152.15 115.34 128 147 128s55.39 24.77 53 57.94z' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' />
			<path d='M206 306c-18.05-8.27-37.93-11.45-59-11.45-52 0-102.1 25.85-114.65 76.2-1.65 6.66 2.53 13.25 9.37 13.25H154' fill='none' stroke='currentColor' strokeLinecap='round' strokeMiterlimit='10' strokeWidth='32' />
		</>
	),
	text: 'Students',
};

const Children = {
	to: '/children',
	icon: (
		<>
			<path d='M402 168c-2.93 40.67-33.1 72-66 72s-63.12-31.32-66-72c-3-42.31 26.37-72 66-72s69 30.46 66 72z' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' />
			<path d='M336 304c-65.17 0-127.84 32.37-143.54 95.41-2.08 8.34 3.15 16.59 11.72 16.59h263.65c8.57 0 13.77-8.25 11.72-16.59C463.85 335.36 401.18 304 336 304z' fill='none' stroke='currentColor' strokeMiterlimit='10' strokeWidth='32' />
			<path d='M200 185.94c-2.34 32.48-26.72 58.06-53 58.06s-50.7-25.57-53-58.06C91.61 152.15 115.34 128 147 128s55.39 24.77 53 57.94z' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' />
			<path d='M206 306c-18.05-8.27-37.93-11.45-59-11.45-52 0-102.1 25.85-114.65 76.2-1.65 6.66 2.53 13.25 9.37 13.25H154' fill='none' stroke='currentColor' strokeLinecap='round' strokeMiterlimit='10' strokeWidth='32' />
		</>
	),
	text: 'Children',
};

const Chats = {
	to: '/chats',
	icon: <path d='M408 64H104a56.16 56.16 0 00-56 56v192a56.16 56.16 0 0056 56h40v80l93.72-78.14a8 8 0 015.13-1.86H408a56.16 56.16 0 0056-56V120a56.16 56.16 0 00-56-56z' fill='none' stroke='currentColor' strokeLinejoin='round' strokeWidth='32' />,
	text: 'Chats',
};

const Reports = {
	to: '/reports',
	icon: (
		<>
			<path d='M32 32v432a16 16 0 0016 16h432' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' />
			<rect x='96' y='224' width='80' height='192' rx='20' ry='20' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' />
			<rect x='240' y='176' width='80' height='240' rx='20' ry='20' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' />
			<rect x='383.64' y='112' width='80' height='304' rx='20' ry='20' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' />
		</>
	),
	text: 'Reports',
};

// const Help = {
// 	to: '/help',
// 	icon: (
// 		<>
// 			<path d='M256 80a176 176 0 10176 176A176 176 0 00256 80z' fill='none' stroke='currentColor' strokeMiterlimit='10' strokeWidth='32' />
// 			<path d='M200 202.29s.84-17.5 19.57-32.57C230.68 160.77 244 158.18 256 158c10.93-.14 20.69 1.67 26.53 4.45 10 4.76 29.47 16.38 29.47 41.09 0 26-17 37.81-36.37 50.8S251 281.43 251 296' fill='none' stroke='currentColor' strokeLinecap='round' strokeMiterlimit='10' strokeWidth='28' />
// 			<circle cx='250' cy='348' r='20' />
// 		</>
// 	),
// 	text: 'Help',
// };

const studentsLinks = [Dashboard, Classes, Subjects, Chats, Reports];
const teacherLinks = [Dashboard, Classes, Subjects, Students, Chats, Reports];
const principalLinks = [Dashboard, Classes, Subjects, Students, Chats, Reports];
const adminLinks = [Dashboard, Schools, Subjects, Students, Chats, Reports];
const parentsLinks = [Dashboard, Children, Chats, Reports];
// const parentsLinks = [Dashboard, Children, Schools, Chats, Reports, Help];
// const alumniLinks = [Dashboard, Schools, Classes, Subjects, Students, Chats, Reports, Help];
const alumniLinks = [Dashboard, Classes, Chats, Reports];

const SidebarLink = ({ link, toggleSidebar }) => {
	const activeClasses = 'inline-flex items-center w-full px-4 py-2 rounded-xl focus:shadow-outline text-blue-500 fill-blue-500 hover:text-blue-500 hover:fill-blue-500';
	const inactiveClasses = 'inline-flex items-center w-full px-4 py-2 rounded-xl focus:shadow-outline text-theme-gray-800 fill-theme-gray-800 hover:text-blue-500';

	return (
		<NavLink to={link?.to} type='button' onClick={toggleSidebar} className={({ isActive, isPending }) => (isPending || isActive ? activeClasses : inactiveClasses)}>
			<svg className='size-5' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
				{link?.icon}
			</svg>

			<span className='ml-4 text-base'>{link?.text}</span>
		</NavLink>
	);
};

const SidebarContents = ({ toggleSidebar }) => {
	const { currentUser, logOut } = useContext(AuthContext);
	const { appData } = useContext(DataContext);

	const [profileImgUrl, setProfileImgUrl] = useState(null);
	const [profileImgUrlLoading, setProfileImgUrlLoading] = useState(true);

	const baseUrl = 'https://ui-avatars.com/api/?background=3b82f6&color=fff&name=';

	useEffect(() => {
		checkImage(
			currentUser?.user?.photoURL,
			() => {
				setProfileImgUrl(currentUser?.user?.photoURL);
				setProfileImgUrlLoading(false);
			},
			() => {
				if (currentUser?.data?.name?.first && currentUser?.data?.name?.last) {
					setProfileImgUrl(baseUrl + currentUser?.data?.name?.first + '+' + currentUser?.data?.name?.last);
				} else if (currentUser?.data?.name?.first && !currentUser?.data?.name?.last) {
					setProfileImgUrl(baseUrl + currentUser?.data?.name?.first);
				} else if (!currentUser?.data?.name?.first && currentUser?.data?.name?.last) {
					setProfileImgUrl(baseUrl + currentUser?.data?.name?.last);
				}
			}
		);

		setProfileImgUrlLoading(false);
	}, [currentUser?.data?.name?.first, currentUser?.data?.name?.last, currentUser?.user?.photoURL]);

	return (
		<div className='flex flex-col flex-grow pt-5 overflow-y-auto bg-theme-white border-r'>
			<div className='flex flex-col flex-shrink-0 px-4'>
				<Link to='/' className='flex flex-col items-center justify-center gap-2 border-none focus:border-none ring-0 ring-offset-0 focus:outline-none focus-visible:outline-none focus:ring-offset-0 focus:ring-0 focus-visible:ring-0'>
					<img className='size-12 mx-auto' src={appData?.logo} alt='Grade Portal Logo' />

					<span className='text-lg font-semibold tracking-tighter text-theme-gray-800'>Grade Portal</span>
				</Link>
			</div>

			<div className='flex flex-col flex-grow px-4 mt-5'>
				<nav className='flex-1 space-y-1 bg-theme-white'>
					<ul className='space-y-2'>
						{currentUser?.data?.userType === 'student'
							? studentsLinks?.map((studentsLink, idx) => {
									return (
										<li key={idx}>
											<SidebarLink link={studentsLink} toggleSidebar={toggleSidebar} />
										</li>
									);
							  })
							: currentUser?.data?.userType === 'teacher'
							? teacherLinks.map((teacherLink, idx) => {
									return (
										<li key={idx}>
											<SidebarLink link={teacherLink} toggleSidebar={toggleSidebar} />
										</li>
									);
							  })
							: currentUser?.data?.userType === 'principalLinks'
							? principalLinks.map((principalLink, idx) => {
									return (
										<li key={idx}>
											<SidebarLink link={principalLink} toggleSidebar={toggleSidebar} />
										</li>
									);
							  })
							: currentUser?.data?.userType === 'admin'
							? adminLinks.map((adminLink, idx) => {
									return (
										<li key={idx}>
											<SidebarLink link={adminLink} toggleSidebar={toggleSidebar} />
										</li>
									);
							  })
							: currentUser?.data?.userType === 'parent'
							? parentsLinks.map((parentsLink, idx) => {
									return (
										<li key={idx}>
											<SidebarLink link={parentsLink} toggleSidebar={toggleSidebar} />
										</li>
									);
							  })
							: currentUser?.data?.userType === 'alumni'
							? alumniLinks.map((alumniLink, idx) => {
									return (
										<li key={idx}>
											<SidebarLink link={alumniLink} toggleSidebar={toggleSidebar} />
										</li>
									);
							  })
							: currentUser?.data?.userType === 'principal'
							? principalLinks.map((principalLink, idx) => {
									return (
										<li key={idx}>
											<SidebarLink link={principalLink} toggleSidebar={toggleSidebar} />
										</li>
									);
							  })
							: null}
					</ul>
				</nav>
			</div>

			<Menu as='div' className='relative flex flex-shrink-0 m-2 bg-theme-white text-left border-none focus:border-none ring-0 ring-offset-0 focus:outline-none focus-visible:outline-none focus:ring-offset-0 focus:ring-0 focus-visible:ring-0'>
				<Menu.Button type='button' className='bg-theme-white w-full inline-flex items-center justify-center px-4 py-3 transition-all duration-300 ease-in-out ring-0 ring-offset-0 border-none outline-none hover:opacity-80 focus:outline-none focus-visible:outline-none focus:ring-offset-0 focus:ring-0 focus-visible:ring-0'>
					<div className='flex flex-row items-center justify-center overflow-hidden whitespace-nowrap'>
						{!profileImgUrlLoading && <img className='inline-block object-cover rounded-full size-10' src={profileImgUrl} alt={currentUser?.data?.name?.first + ' ' + currentUser?.data?.name?.last} />}

						<div className='ml-2 text-left overflow-hidden whitespace-nowrap'>
							<p className='text-sm font-medium text-theme-gray-800 truncate' title={currentUser?.data?.name?.first + ' ' + currentUser?.data?.name?.last}>
								{currentUser?.data?.name?.first} {currentUser?.data?.name?.last}
							</p>

							<p className='text-xs text-theme-gray-600 truncate' title={currentUser?.user?.email}>
								{currentUser?.user?.email}
							</p>
						</div>
					</div>
				</Menu.Button>

				<Transition as={Fragment} enter='transition ease-out duration-100' enterFrom='transform opacity-0 scale-95' enterTo='transform opacity-100 scale-100' leave='transition ease-in duration-75' leaveFrom='transform opacity-100 scale-100' leaveTo='transform opacity-0 scale-95'>
					<Menu.Items className='border border-theme-gray-200 bottom-full absolute left-0 mb-2 w-full origin-top-left divide-y divide-theme-gray-200 rounded-md bg-theme-white shadow-lg ring-0 focus:outline-none'>
						<div className='p-2'>
							<Menu.Item>
								<Link
									to='/settings'
									type='button'
									onClick={() => toggleSidebar()}
									className='text-theme-gray-800 w-full flex items-center rounded-md px-2 py-2 text-sm transition-all duration-300 ease-in-out hover:bg-gray-100 dark:hover:bg-slate-600 ring-0 ring-offset-0 border-none outline-none focus:outline-none focus-visible:outline-none focus:ring-offset-0 focus:ring-0 focus-visible:ring-0'>
									Settings
								</Link>
							</Menu.Item>
						</div>

						<div className='p-2'>
							<Menu.Item>
								<button
									type='button'
									onClick={(e) => {
										e.preventDefault();

										logOut(true, true);
										window.location.href = '/auth/login';
									}}
									className='text-red-500 w-full flex items-center rounded-md px-2 py-2 text-sm transition-all duration-300 ease-in-out hover:bg-red-50 dark:hover:text-white dark:hover:bg-red-800 ring-0 ring-offset-0 border-none outline-none focus:outline-none focus-visible:outline-none focus:ring-offset-0 focus:ring-0 focus-visible:ring-0'>
									Logout
								</button>
							</Menu.Item>
						</div>
					</Menu.Items>
				</Transition>
			</Menu>
		</div>
	);
};

const Sidebar = () => {
	const sidebarRef = useRef();
	const { sidebarOpen, setSidebarOpen } = useContext(DataContext);

	useEffect(() => {
		const handleClickOutside = (e) => {
			if (sidebarRef?.current && !sidebarRef?.current?.contains(e.target)) {
				setSidebarOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [setSidebarOpen]);

	return (
		<>
			<div className='hidden lg:flex lg:flex-shrink-0'>
				<div className='flex flex-col w-64 select-none'>
					<SidebarContents toggleSidebar={() => setSidebarOpen(false)} />
				</div>
			</div>

			<div className={`transition-all ease-in-out duration-[400ms] fixed inset-0 z-40 bg-black bg-opacity-30 backdrop-filter backdrop-blur-sm ${sidebarOpen ? 'block' : 'hidden'}`}></div>

			<Transition as={Fragment} show={sidebarOpen} className='h-full w-64 fixed left-0 z-50' enter='transform transition ease-in-out duration-[400ms]' enterFrom='opacity-0 -translate-x-full' enterTo='opacity-100 translate-x-0' leave='transform duration-200 transition ease-in-out' leaveFrom='opacity-100 translate-x-0' leaveTo='opacity-0 -translate-x-full'>
				<div ref={sidebarRef} className='flex flex-col w-64 select-none'>
					<SidebarContents toggleSidebar={() => setSidebarOpen(false)} />
				</div>
			</Transition>
		</>
	);
};

export default Sidebar;
