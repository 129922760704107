import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext, DataContext } from '../context';
import { generateUserGrades } from '../utils';

const PerformanceRanking = ({ initialSelectedClassId, max }) => {
	const { currentUser } = useContext(AuthContext);
	const { appData, currentSchool, assessments, users } = useContext(DataContext);

	const [classes, setClasses] = useState([]);
	const [classesLoading, setClassesLoading] = useState(true);
	const [selectedClassId, setSelectedClassId] = useState(initialSelectedClassId);

	const [students, setStudents] = useState(null);
	const [rankedStudents, setRankedStudents] = useState([]);
	const [rankedStudentsLoading, setRankedStudentsLoading] = useState(true);

	useEffect(() => {
		const fetchStudents = async () => {
			try {
				const filteredStudents = users?.filter((user) => {
					if (user?.userType === 'student' && user?.school?.id === currentSchool?.id) {
						return user?.school?.classes?.some((_class) => _class === selectedClassId);
					}

					return false;
				});

				setStudents(filteredStudents);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}
		};

		fetchStudents();
	}, [appData?.debugMode, users, currentSchool?.id, selectedClassId]);

	useEffect(() => {
		const fetchClasses = async () => {
			try {
				const filteredClasses = currentSchool?.classes?.filter((_class) => _class?.teacherId === currentUser?.data?.id);

				setClasses(filteredClasses);
			} catch (err) {
				console.error(err);
			}

			setClassesLoading(false);
		};

		fetchClasses();
	}, [currentUser?.data?.id, currentSchool?.classes]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				let _rankedStudents = [];

				if (students) {
					for (const student of students) {
						const fetchedStudentWithData = {
							data: student,
						};

						const classAssessments = assessments.filter((assessment) => assessment?.classId === selectedClassId);
						const allUserGrades = await generateUserGrades(currentSchool, fetchedStudentWithData, classAssessments, null, selectedClassId);

						const totalPercentage = allUserGrades?.reduce((accumulator, currentGrade) => {
							return accumulator + parseInt(currentGrade?.grade);
						}, 0);

						let calculatedTotalAverage;

						if (!isNaN(totalPercentage) && totalPercentage > 0) {
							calculatedTotalAverage = totalPercentage / parseFloat(allUserGrades?.length);
						} else {
							calculatedTotalAverage = NaN;
						}

						_rankedStudents?.push({
							userData: student,
							grades: {
								average: calculatedTotalAverage,
							},
						});
					}
				}

				setRankedStudents(_rankedStudents);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setRankedStudentsLoading(false);
		};

		fetchData();
	}, [students, appData?.debugMode, currentSchool, assessments, selectedClassId]);

	return (
		<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
			<div className='mb-4 flex flex-row items-center justify-between'>
				<h3 className='text-xl font-bold text-theme-gray-800'>Performance Ranking</h3>

				<div className='flex flex-row items-center justify-start'>
					{classes && !classesLoading && classes?.length > 0 && (
						<select
							value={selectedClassId ? selectedClassId : classes[0]?.id}
							onChange={(e) => {
								e.preventDefault();
								setSelectedClassId(e.target.value);
							}}
							className='w-full rounded-lg bg-theme-white border-theme-gray-200 text-theme-gray-800 sm:text-xs'>
							{classes?.map((_class, idx) => (
								<option key={idx} value={_class?.id}>
									{_class?.id}
								</option>
							))}
						</select>
					)}
				</div>
			</div>

			<div className='flow-root'>
				{students?.length > 0 && rankedStudents?.length > 0 && !rankedStudentsLoading ? (
					<dl className='-my-3 divide-y divide-theme-gray-200 text-sm'>
						<div className='grid grid-cols-12 gap-4 py-3'>
							<dt className='font-medium text-theme-gray-800 col-span-2 text-center'>Rank</dt>

							<dd className='font-medium text-theme-gray-800 col-span-6'>Student Name</dd>

							<dd className='font-medium text-theme-gray-800 col-span-4 text-center'>Average</dd>
						</div>

						{rankedStudents
							?.sort((a, b) => {
								if (isNaN(a?.grades?.average) && isNaN(b?.grades?.average)) {
									return 0;
								} else if (isNaN(a?.grades?.average)) {
									return 1;
								} else if (isNaN(b?.grades?.average)) {
									return -1;
								} else {
									return b?.grades?.average - a?.grades?.average;
								}
							})
							?.slice(0, max)
							?.map((student, idx) => {
								return (
									<div key={idx} className='grid grid-cols-12 gap-4 py-3'>
										<dt className='font-medium text-theme-gray-800 col-span-2 inline-flex justify-center'>
											{idx + 1 === 1 ? (
												<svg className='size-4 fill-[#FFD700]' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
													<path d='M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M9.283 4.002V12H7.971V5.338h-.065L6.072 6.656V5.385l1.899-1.383z' />
												</svg>
											) : idx + 1 === 2 ? (
												<svg className='size-4 fill-[#C0C0C0]' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
													<path d='M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.646 6.24v.07H5.375v-.064c0-1.213.879-2.402 2.637-2.402 1.582 0 2.613.949 2.613 2.215 0 1.002-.6 1.667-1.287 2.43l-.096.107-1.974 2.22v.077h3.498V12H5.422v-.832l2.97-3.293c.434-.475.903-1.008.903-1.705 0-.744-.557-1.236-1.313-1.236-.843 0-1.336.615-1.336 1.306' />
												</svg>
											) : idx + 1 === 3 ? (
												<svg className='size-4 fill-[#CD7F32]' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
													<path d='M7.918 8.414h-.879V7.342h.838c.78 0 1.348-.522 1.342-1.237 0-.709-.563-1.195-1.348-1.195-.79 0-1.312.498-1.348 1.055H5.275c.036-1.137.95-2.115 2.625-2.121 1.594-.012 2.608.885 2.637 2.062.023 1.137-.885 1.776-1.482 1.875v.07c.703.07 1.71.64 1.734 1.917.024 1.459-1.277 2.396-2.93 2.396-1.705 0-2.707-.967-2.754-2.144H6.33c.059.597.68 1.06 1.541 1.066.973.006 1.6-.563 1.588-1.354-.006-.779-.621-1.318-1.541-1.318' />
													<path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8' />
												</svg>
											) : (
												idx + 1
											)}
										</dt>

										<dd className='text-theme-gray-600 col-span-6'>
											<Link to={`/students/${student?.userData?.id}`} target='_blank' rel='noopener noreferrer' className='text-blue-500 hover:opacity-80'>
												{student?.userData?.name?.first} {student?.userData?.name?.last}
											</Link>
										</dd>

										<dd className='text-theme-gray-600 col-span-4 text-center'>{isNaN(student?.grades?.average) ? 'N/A' : student?.grades?.average + '%'}</dd>
									</div>
								);
							})}
					</dl>
				) : !selectedClassId || selectedClassId === '' || selectedClassId === null ? (
					<p className='px-4 py-2 text-center text-theme-gray-600'>Please select a class.</p>
				) : (
					<p className='px-4 py-2 text-center text-theme-gray-600'>No students found.</p>
				)}
			</div>
		</div>
	);
};

const ClassPerformanceRanking = ({ classId }) => {
	const { appData, currentSchool, assessments, users } = useContext(DataContext);

	const [students, setStudents] = useState(null);
	const [rankedStudents, setRankedStudents] = useState([]);
	const [rankedStudentsLoading, setRankedStudentsLoading] = useState(true);

	useEffect(() => {
		const fetchStudents = async () => {
			try {
				const filteredStudents = users?.filter((user) => {
					if (user?.userType === 'student' && user?.school?.id === currentSchool?.id) {
						return user?.school?.classes?.some((_class) => _class === classId);
					}

					return false;
				});

				setStudents(filteredStudents);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}
		};

		fetchStudents();
	}, [appData?.debugMode, users, currentSchool?.id, classId]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				let _rankedStudents = [];

				if (students) {
					for (const student of students) {
						const fetchedStudentWithData = {
							data: student,
						};

						const classAssessments = assessments.filter((assessment) => assessment?.classId === classId);
						const allUserGrades = await generateUserGrades(currentSchool, fetchedStudentWithData, classAssessments, null, classId);

						const totalPercentage = allUserGrades?.reduce((accumulator, currentGrade) => {
							return accumulator + parseInt(currentGrade?.grade);
						}, 0);

						let calculatedTotalAverage;

						if (!isNaN(totalPercentage) && totalPercentage > 0) {
							calculatedTotalAverage = totalPercentage / parseFloat(allUserGrades?.length);
						} else {
							calculatedTotalAverage = NaN;
						}

						_rankedStudents?.push({
							userData: student,
							grades: {
								average: calculatedTotalAverage,
							},
						});
					}
				}

				setRankedStudents(_rankedStudents);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setRankedStudentsLoading(false);
		};

		fetchData();
	}, [students, appData?.debugMode, currentSchool, assessments, classId]);

	return (
		<div className='w-full bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
			<div className='mb-4 flex flex-row items-center justify-between'>
				<h3 className='text-xl font-bold text-theme-gray-800'>Performance Ranking</h3>
			</div>

			<div className='flow-root'>
				{students?.length > 0 && rankedStudents?.length > 0 && !rankedStudentsLoading ? (
					<dl className='-my-3 divide-y divide-theme-gray-200 text-sm'>
						<div className='grid grid-cols-12 gap-4 py-3'>
							<dt className='font-medium text-theme-gray-800 col-span-2 text-center'>Rank</dt>

							<dd className='font-medium text-theme-gray-800 col-span-6'>Student Name</dd>

							<dd className='font-medium text-theme-gray-800 col-span-4 text-center'>Average</dd>
						</div>

						{rankedStudents
							?.sort((a, b) => {
								if (isNaN(a?.grades?.average) && isNaN(b?.grades?.average)) {
									return 0;
								} else if (isNaN(a?.grades?.average)) {
									return 1;
								} else if (isNaN(b?.grades?.average)) {
									return -1;
								} else {
									return b?.grades?.average - a?.grades?.average;
								}
							})
							?.slice(0, 5)
							?.map((student, idx) => {
								return (
									<div key={idx} className='grid grid-cols-12 gap-4 py-3'>
										<dt className='font-medium text-theme-gray-800 col-span-2 inline-flex justify-center'>
											{idx + 1 === 1 ? (
												<svg className='size-4 fill-[#FFD700]' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
													<path d='M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M9.283 4.002V12H7.971V5.338h-.065L6.072 6.656V5.385l1.899-1.383z' />
												</svg>
											) : idx + 1 === 2 ? (
												<svg className='size-4 fill-[#C0C0C0]' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
													<path d='M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M6.646 6.24v.07H5.375v-.064c0-1.213.879-2.402 2.637-2.402 1.582 0 2.613.949 2.613 2.215 0 1.002-.6 1.667-1.287 2.43l-.096.107-1.974 2.22v.077h3.498V12H5.422v-.832l2.97-3.293c.434-.475.903-1.008.903-1.705 0-.744-.557-1.236-1.313-1.236-.843 0-1.336.615-1.336 1.306' />
												</svg>
											) : idx + 1 === 3 ? (
												<svg className='size-4 fill-[#CD7F32]' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
													<path d='M7.918 8.414h-.879V7.342h.838c.78 0 1.348-.522 1.342-1.237 0-.709-.563-1.195-1.348-1.195-.79 0-1.312.498-1.348 1.055H5.275c.036-1.137.95-2.115 2.625-2.121 1.594-.012 2.608.885 2.637 2.062.023 1.137-.885 1.776-1.482 1.875v.07c.703.07 1.71.64 1.734 1.917.024 1.459-1.277 2.396-2.93 2.396-1.705 0-2.707-.967-2.754-2.144H6.33c.059.597.68 1.06 1.541 1.066.973.006 1.6-.563 1.588-1.354-.006-.779-.621-1.318-1.541-1.318' />
													<path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8' />
												</svg>
											) : (
												idx + 1
											)}
										</dt>

										<dd className='text-theme-gray-600 col-span-6'>
											<Link to={`/students/${student?.userData?.id}`} target='_blank' rel='noopener noreferrer' className='text-blue-500 hover:opacity-80'>
												{student?.userData?.name?.first} {student?.userData?.name?.last}
											</Link>
										</dd>

										<dd className='text-theme-gray-600 col-span-4 text-center'>{isNaN(student?.grades?.average) ? 'N/A' : student?.grades?.average + '%'}</dd>
									</div>
								);
							})}
					</dl>
				) : (
					<p className='px-4 py-2 text-center text-theme-gray-600'>No students found.</p>
				)}
			</div>
		</div>
	);
};

export { PerformanceRanking, ClassPerformanceRanking };
