import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { AuthContext, DataContext } from '../../context';
import { DeleteModal, MainContainer } from '../../components';
import { useAlertHandling } from '../../utils';

const Subject = () => {
	const { subjectId } = useParams();

	useTitle(`${subjectId ? subjectId : 'Subject'} | Grade Portal`);

	const { currentUser } = useContext(AuthContext);
	const { appData, divRef, handleScroll, subjects, deleteSubject } = useContext(DataContext);
	const { showAlert } = useAlertHandling();

	const [subject, setSubject] = useState(null);
	const [subjectLoading, setSubjectLoading] = useState(null);
	const [subjectFound, setSubjectFound] = useState(false);

	useEffect(() => {
		const fetchSubject = async () => {
			try {
				const _subject = await subjects?.find((subject) => subject?.id === subjectId);

				if (_subject) {
					setSubject(_subject);
					setSubjectFound(true);
				} else {
					setSubjectFound(false);
				}
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setSubjectLoading(false);
		};

		fetchSubject();
	}, [appData?.debugMode, subjects, subjectId]);

	const [deleteSubjectId, setDeleteSubjectId] = useState(null);
	const [deleteSubjectModalState, setDeleteSubjectModalState] = useState(false);

	const handleDeleteSubject = async (e) => {
		e.preventDefault();

		try {
			if (deleteSubjectId) {
				await deleteSubject(deleteSubjectId);
				window.location.href = '/subjects';
			} else {
				if (appData.debugMode) {
					console.error("Subject ID can't be empty.");
				}

				showAlert('error', 'An unknown error occurred');
			}
		} catch (error) {
			if (appData.debugMode) {
				console.error(error);
			}

			showAlert('error', 'An unknown error occurred');
		}

		setDeleteSubjectModalState(false);
	};

	if (!subjectFound) {
		return (
			<MainContainer divRef={divRef} handleScroll={handleScroll}>
				<section className='bg-theme-white py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
					<div className='mx-auto max-w-screen-sm text-center'>
						<h1 className='mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-theme-gray-800'>404</h1>

						<p className='mb-4 text-3xl tracking-tight font-bold text-blue-500 md:text-4xl'>Oops! Subject Not Found</p>

						<p className='mb-8 text-lg text-theme-gray-600'>
							It seems that the subject you're trying to access isn't available or authorized for your account. Don't worry, you can explore all subjects{' '}
							<Link to='/subjects' className='focus:outline-none focus:ring text-blue-500 hover:opacity-80 active:opacity-80'>
								here
							</Link>
							.
						</p>

						<Link to='/' className='inline-block shrink-0 rounded-md border px-5 py-2.5 text-sm font-medium focus:outline-none focus:ring text-white border-blue-500 bg-blue-500 hover:bg-transparent hover:text-blue-500 active:text-blue-500'>
							Back to Homepage
						</Link>
					</div>
				</section>
			</MainContainer>
		);
	}

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<h3 className='text-2xl font-bold text-theme-gray-800'>{subject && !subjectLoading && subject?.name}</h3>
			</section>

			<section className='w-full bg-theme-white'>
				<div className='grid grid-cols-3 gap-6'>
					<div className='col-span-3 flex flex-col items-start gap-6'>
						<div className='w-full bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
							<div className='mb-4 flex flex-row items-center justify-between'>
								<h3 className='text-xl font-bold text-theme-gray-800'>Subject Details</h3>

								{(currentUser?.data?.userType === 'principal' || currentUser?.data?.userType === 'admin') && (
									<div className='inline-flex items-center justify-center gap-4'>
										<Link type='button' to={`/subjects/${subjectId}/edit`} className='inline-block shrink-0 rounded-md border border-blue-500 bg-blue-500 px-4 py-2 text-sm font-medium text-white transition-all duration-300 ease-in-out hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'>
											Edit
										</Link>

										<button
											type='button'
											onClick={(e) => {
												e.preventDefault();
												setDeleteSubjectModalState(true);
												setDeleteSubjectId(subjectId);
											}}
											className='inline-block shrink-0 rounded-md border border-red-500 bg-red-500 px-4 py-2 text-sm font-medium text-white transition-all duration-300 ease-in-out ring-red-300 hover:bg-transparent hover:text-red-500 focus:outline-none focus:ring active:text-red-500'>
											Delete
										</button>
									</div>
								)}
							</div>

							<div className='flow-root'>
								<dl className='-my-3 divide-y divide-theme-gray-200 text-sm'>
									<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-6 md:gap-4'>
										<dt className='font-bold text-theme-gray-800 col-span-2 md:col-span-1'>Name:</dt>

										<dd className='text-theme-gray-800 col-span-4 md:col-span-5'>{subjectLoading ? 'Loading...' : !subjectLoading && subject?.name ? subject?.name : 'N/A'}</dd>
									</div>

									<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-6 md:gap-4'>
										<dt className='font-bold text-theme-gray-800 col-span-2 md:col-span-1'>Description:</dt>

										<dd className='text-theme-gray-800 col-span-4 md:col-span-5'>{subjectLoading ? 'Loading...' : !subjectLoading && subject?.desc ? subject?.desc : 'N/A'}</dd>
									</div>
								</dl>
							</div>
						</div>
					</div>
				</div>
			</section>

			<DeleteModal title='Subject' deleteId={deleteSubjectId} handleDelete={handleDeleteSubject} deleteModalState={deleteSubjectModalState} setDeleteModalState={setDeleteSubjectModalState} />
		</MainContainer>
	);
};

export default Subject;
