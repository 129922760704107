import React, { useContext, useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import { Link } from 'react-router-dom';
import { DataContext, AuthContext } from '../../context';
import { ArchiveModal, DeleteModal, MainContainer, MenuDropdown, Table, TableBodyItem, TableHeadItem, UnarchiveModal } from '../../components';
import { generatePDF, useAlertHandling } from '../../utils';

const ClassesTableContent = ({ _class, currentUser, users, subjects, setArchiveClassId, setArchiveClassModalState, setUnarchiveClassId, setUnarchiveClassModalState, setDeleteClassId, setDeleteClassModalState }) => {
	const numberOfStudents = users?.filter((user) => user?.userType === 'student' && user?.school?.classes?.includes(_class?.id))?.length;

	const subject = subjects?.find((subject) => subject?.id === _class?.subjectId);
	const subjectName = subject?.name;

	const teacher = users?.find((user) => user?.userType === 'teacher' && user?.id === _class?.teacherId);
	const teacherName = teacher?.name?.first + ' ' + teacher?.name?.last;

	let menuItems;

	if (_class?.status?.toLowerCase() === 'active') {
		menuItems = [
			[
				{
					type: 'link',
					danger: false,
					to: `/classes/${_class?.id}`,
					onClick: null,
					text: 'Edit',
					icon: (
						<>
							<path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
							<path fillRule='evenodd' d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z' />
						</>
					),
				},
				{
					type: 'button',
					danger: false,
					to: null,
					newTab: false,
					onClick: () => {
						setArchiveClassId(_class?.id);
						setArchiveClassModalState(true);
					},
					text: 'Archive',
					icon: <path d='M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5zm13-3H1v2h14zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5' />,
				},
			],
			[
				{
					type: 'button',
					danger: true,
					to: null,
					newTab: false,
					onClick: () => {
						setDeleteClassId(_class?.id);
						setDeleteClassModalState(true);
					},
					text: 'Delete',
					icon: (
						<path d='M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5' />
					),
				},
			],
		];
	} else {
		menuItems = [
			[
				{
					type: 'link',
					danger: false,
					to: `/classes/${_class?.id}`,
					onClick: null,
					text: 'Edit',
					icon: (
						<>
							<path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
							<path fillRule='evenodd' d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z' />
						</>
					),
				},
				{
					type: 'button',
					danger: false,
					to: null,
					newTab: false,
					onClick: () => {
						setUnarchiveClassId(_class?.id);
						setUnarchiveClassModalState(true);
					},
					text: 'Unarchive',
					icon: <path d='M0 2a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1v7.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 12.5V5a1 1 0 0 1-1-1zm2 3v7.5A1.5 1.5 0 0 0 3.5 14h9a1.5 1.5 0 0 0 1.5-1.5V5zm13-3H1v2h14zM5 7.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5' />,
				},
			],
			[
				{
					type: 'button',
					danger: true,
					to: null,
					newTab: false,
					onClick: () => {
						setDeleteClassId(_class?.id);
						setDeleteClassModalState(true);
					},
					text: 'Delete',
					icon: (
						<path d='M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5' />
					),
				},
			],
		];
	}

	return (
		<>
			<TableBodyItem type='select' onClick={() => {}} />

			{currentUser?.data?.userType === 'alumni' ? <TableBodyItem type='primary' contents={_class?.id ? _class?.id : 'N/A'} align='left' /> : <TableBodyItem type='primary' contents={_class?.id ? <Link to={`/classes/${_class?.id}`}>{_class?.id}</Link> : 'N/A'} align='left' />}

			<TableBodyItem type='default' contents={subject ? subjectName : 'N/A'} align='left' />

			{currentUser?.data?.userType === 'principal' ? <TableBodyItem type='default' contents={teacher ? teacherName : 'N/A'} align='left' /> : null}

			{currentUser?.data?.userType !== 'alumni' && (
				<TableBodyItem
					type='default'
					contents={_class?.status?.toLowerCase() === 'active' ? <span className='select-none rounded-full px-2.5 py-0.5 text-xs bg-green-600 text-white uppercase'>Active</span> : _class?.status?.toLowerCase() === 'inactive' ? <span className='select-none rounded-full px-2.5 py-0.5 text-xs bg-red-600 text-white uppercase'>Inactive</span> : 'N/A'}
					align='center'
				/>
			)}

			<TableBodyItem type='default' contents={_class?.room || 'N/A'} align='center' />

			<TableBodyItem type='default' contents={numberOfStudents} align='center' />

			{currentUser?.data?.userType === 'principal' ? <TableBodyItem type='dropdown' contents={<MenuDropdown type='table' menuItems={menuItems} />} align='center' /> : null}
		</>
	);
};

const Classes = () => {
	useTitle('Classes | Grade Portal');

	const { currentUser } = useContext(AuthContext);
	const { appData, currentSchool, users, subjects, divRef, handleScroll, archiveClass, unarchiveClass, deleteClass } = useContext(DataContext);
	const { showAlert } = useAlertHandling();

	const [classes, setClasses] = useState([]);
	const [classesLoading, setClassesLoading] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const fetchedClasses = await currentSchool?.classes?.filter((_class) => {
					if (currentUser?.data?.userType === 'student') {
						return currentUser?.data?.school?.classes?.some((studentClass) => studentClass === _class?.id);
					} else if (currentUser?.data?.userType === 'teacher') {
						return _class?.teacherId === currentUser?.data?.id;
					} else if (currentUser?.data?.userType === 'principal') {
						return true;
					} else if (currentUser?.data?.userType === 'parent') {
						// return _class?.teacherId === currentUser?.data?.id;
					} else if (currentUser?.data?.userType === 'alumni') {
						return currentUser?.data?.school?.classes?.some((studentClass) => studentClass === _class?.id);
					}

					return false;
				});

				setClasses(fetchedClasses);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setClassesLoading(false);
		};

		fetchData();
	}, [appData?.debugMode, currentSchool?.classes, currentUser?.data?.id, currentUser?.data?.school?.classes, currentUser?.data?.userType]);

	const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState('asc');
	const [sortedClasses, setSortedClasses] = useState([]);

	const handleSortClick = (field) => {
		if (sortField === field) {
			setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
		} else {
			setSortField(field);
			setSortOrder('asc');
		}
	};

	useEffect(() => {
		const sortClasses = async () => {
			let sortedData = [...(classes ?? [])];

			if (sortField) {
				sortedData = sortedData?.sort((a, b) => {
					const valueA = a[sortField];
					const valueB = b[sortField];

					if (sortOrder === 'asc') {
						return valueA?.localeCompare(valueB, undefined, {
							numeric: true,
						});
					} else {
						return valueB?.localeCompare(valueA, undefined, {
							numeric: true,
						});
					}
				});
			}

			setSortedClasses(sortedData);
		};

		sortClasses();
	}, [classes, sortField, sortOrder]);

	const [classSearchQuery, setClassSearchQuery] = useState('');
	const [filteredClasses, setFilteredClasses] = useState([]);

	useEffect(() => {
		const filtered = sortedClasses?.filter((_class) => {
			const searchString = classSearchQuery?.toLowerCase();

			return (
				_class?.id?.toLowerCase().includes(searchString) ||
				_class?.subjectId?.toLowerCase().includes(searchString) ||
				subjects
					?.find((subject) => subject?.id === _class?.subjectId)
					?.name?.toLowerCase()
					.includes(searchString)
			);
		});

		setFilteredClasses(filtered);
	}, [sortedClasses, classSearchQuery, subjects]);

	const [archiveClassId, setArchiveClassId] = useState(null);
	const [archiveClassModalState, setArchiveClassModalState] = useState(false);

	const handleArchiveClass = async (e) => {
		e.preventDefault();

		try {
			if (currentSchool?.id && archiveClassId) {
				await archiveClass(appData, showAlert, currentSchool?.id, archiveClassId);
			} else {
				if (appData.debugMode) {
					console.error("School ID and Class ID can't be empty.");
				}

				showAlert('error', 'An unknown error occurred');
			}
		} catch (error) {
			if (appData.debugMode) {
				console.error(error);
			}

			showAlert('error', 'An unknown error occurred');
		}

		setArchiveClassModalState(false);
	};

	const [unarchiveClassId, setUnarchiveClassId] = useState(null);
	const [unarchiveClassModalState, setUnarchiveClassModalState] = useState(false);

	const handleUnarchiveClass = async (e) => {
		e.preventDefault();

		try {
			if (currentSchool?.id && unarchiveClassId) {
				await unarchiveClass(appData, showAlert, currentSchool?.id, unarchiveClassId);
			} else {
				if (appData.debugMode) {
					console.error("School ID and Class ID can't be empty.");
				}

				showAlert('error', 'An unknown error occurred');
			}
		} catch (error) {
			if (appData.debugMode) {
				console.error(error);
			}

			showAlert('error', 'An unknown error occurred');
		}

		setUnarchiveClassModalState(false);
	};

	const [deleteClassId, setDeleteClassId] = useState(null);
	const [deleteClassModalState, setDeleteClassModalState] = useState(false);

	const handleDeleteClass = async (e) => {
		e.preventDefault();

		try {
			// check for grades
			// await deleteClass(currentSchool?.id, deleteClassId);
			// window.location.href = '/classes';
		} catch (error) {
			if (appData.debugMode) {
				console.error(error);
			}

			showAlert('error', 'An unknown error occurred');
		}

		setDeleteClassModalState(false);
	};

	if (currentUser?.userType === 'admin') {
		return (
			<MainContainer divRef={divRef} handleScroll={handleScroll}>
				<section className='bg-theme-white py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
					<div className='mx-auto max-w-screen-sm text-center'>
						<h1 className='mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-theme-gray-800'>403</h1>

						<p className='mb-4 text-3xl tracking-tight font-bold text-blue-500 md:text-4xl'>Oops! Access Denied</p>

						<p className='mb-8 text-lg text-theme-gray-600'>It seems that the page you're trying to access isn't available.</p>

						<Link to='/' className='inline-block shrink-0 rounded-md border px-5 py-2.5 text-sm font-medium focus:outline-none focus:ring text-white border-blue-500 bg-blue-500 hover:bg-transparent hover:text-blue-500 active:text-blue-500'>
							Back to Homepage
						</Link>
					</div>
				</section>
			</MainContainer>
		);
	}

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<div className='flex flex-row items-center justify-between'>
					<div className='flex flex-row items-center justify-start gap-4'>
						<h3 className='w-full text-lg font-bold text-theme-gray-800 sm:text-xl'>{currentUser?.data?.userType === 'student' || currentUser?.data?.userType === 'teacher' ? 'My Classes' : currentUser?.data?.userType === 'principal' ? 'Classes' : currentUser?.data?.userType === 'parent' ? 'Children Classes' : currentUser?.data?.userType === 'alumni' ? 'Past Classes' : null}</h3>

						<div className='w-full'>
							<label htmlFor='schoolSearch' className='sr-only'>
								Search
							</label>

							<div className='relative'>
								<div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
									<svg className='w-4 h-4 text-theme-gray-800' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
										<path d='M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z' fill='none' stroke='currentColor' strokeMiterlimit='10' strokeWidth='32' />
										<path fill='none' stroke='currentColor' strokeLinecap='round' strokeMiterlimit='10' strokeWidth='32' d='M338.29 338.29L448 448' />
									</svg>
								</div>

								<form>
									<input
										type='search'
										onChange={(e) => {
											e.preventDefault();
											setClassSearchQuery(e.target.value);
										}}
										className='min-w-64 w-full ps-10 rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'
										id='classesSearch'
										placeholder='Search classes'
									/>
								</form>
							</div>
						</div>
					</div>

					<div className='flex flex-row items-center justify-end gap-4'>
						{currentUser?.data?.userType === 'principal' ? (
							<Link type='button' to='/classes/new' className='inline-block shrink-0 rounded-md border border-blue-500 bg-blue-500 px-4 py-2 text-sm font-medium text-white transition-all duration-300 ease-in-out hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'>
								New Class
							</Link>
						) : null}

						<MenuDropdown
							type='download'
							menuItems={[
								[
									{
										type: 'button',
										danger: false,
										to: null,
										onClick: (e) => {
											e.preventDefault();
											generatePDF(currentUser?.data?.userType === 'student' || currentUser?.data?.userType === 'teacher' ? 'My Classes' : currentUser?.data?.userType === 'principal' ? 'Classes' : currentUser?.data?.userType === 'parent' ? 'Children Classes' : currentUser?.data?.userType === 'alumni' ? 'Past Classes' : null);
										},
										text: 'Download PDF',
										icon: (
											<path
												fillRule='evenodd'
												d='M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z'
											/>
										),
									},
								],
							]}
						/>
					</div>
				</div>
			</section>

			<section className='w-full bg-theme-white'>
				<Table
					name='Classes'
					headContents={
						<tr>
							<TableHeadItem type='select' onClick={() => {}} />

							<TableHeadItem type='sort' title='Class ID' align='left' onClick={() => handleSortClick('id')} />

							<TableHeadItem type='default' title='Subject' align='left' />

							{currentUser?.data?.userType === 'principal' ? <TableHeadItem type='default' title='Teacher' align='left' /> : null}

							{currentUser?.data?.userType !== 'alumni' && <TableHeadItem type='default' title='Status' align='center' />}

							<TableHeadItem type='default' title='Room' align='center' />

							<TableHeadItem type='sort' title='Number of Students' align='center' onClick={() => handleSortClick('')} />

							{currentUser?.data?.userType === 'principal' ? <TableBodyItem type='dropdown' /> : null}
						</tr>
					}
					bodyContents={({ item }) => (
						<ClassesTableContent
							_class={item}
							currentUser={currentUser}
							users={users}
							subjects={subjects}
							setArchiveClassId={setArchiveClassId}
							setArchiveClassModalState={setArchiveClassModalState}
							setUnarchiveClassId={setUnarchiveClassId}
							setUnarchiveClassModalState={setUnarchiveClassModalState}
							setDeleteClassId={setDeleteClassId}
							setDeleteClassModalState={setDeleteClassModalState}
						/>
					)}
					sortedData={sortedClasses}
					filteredData={filteredClasses}
					dataLoading={classesLoading}
					colSpan={currentUser?.data?.userType === 'principal' ? 8 : 6}
				/>
			</section>

			<ArchiveModal title='Class' archiveId={archiveClassId} handleArchive={handleArchiveClass} archiveModalState={archiveClassModalState} setArchiveModalState={setArchiveClassModalState} />
			<UnarchiveModal title='Class' unarchiveId={unarchiveClassId} handleUnarchive={handleUnarchiveClass} unarchiveModalState={unarchiveClassModalState} setUnarchiveModalState={setUnarchiveClassModalState} />
			<DeleteModal title='Class' deleteId={deleteClassId} handleDelete={handleDeleteClass} deleteModalState={deleteClassModalState} setDeleteModalState={setDeleteClassModalState} />
		</MainContainer>
	);
};

export default Classes;
