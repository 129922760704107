import React, { useContext, useState } from 'react';
import { useTitle } from 'react-use';
import { AuthContext } from '../../context';
import { Footer } from '../../components';
import { useAlertHandling } from '../../utils';

const Contact = () => {
	useTitle('Contact | Grade Portal');

	const { showAlert } = useAlertHandling();
	const { logOut } = useContext(AuthContext);

	const [formState, setFormState] = useState({
		firstName: '',
		lastName: '',
		subject: '',
		email: '',
		message: '',
	});

	const [lastSubmitTime, setLastSubmitTime] = useState(null);
	const [delay, setDelay] = useState(5 * 60 * 1000); // 5 minutes

	const handleInputChange = (event) => {
		setFormState({
			...formState,
			[event.target.name]: event.target.value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const now = Date.now();

		if (lastSubmitTime && now - lastSubmitTime < delay) {
			const waitTimeInSeconds = Math.ceil((delay - (now - lastSubmitTime)) / 1000);
			const waitTimeInMinutes = Math.ceil(waitTimeInSeconds / 60);
			showAlert('error', `Please wait ${waitTimeInMinutes} minutes before submitting again`);
			return;
		} else {
			showAlert('success', 'Message sent!');
		}
		if (formState.honeypot) {
			document.cookie = 'user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
			logOut(e); // Pass event object to logOut function
			return;
		}

		// Validate email
		if (!validateEmail(formState.email)) {
			showAlert('error', 'Please enter a valid email address');
			return;
		}

		try {
			const response = await fetch('https://us-central1-grade-portal-e6edb.cloudfunctions.net/onUserFormSubmit', {
				method: 'POST',
				mode: 'cors',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formState),
			});

			if (!response.ok) {
				throw new Error('Network response was not ok');
			}

			const responseData = await response.json();
			showAlert('success', 'Your message was sent!');
		} catch (error) {
			// showAlert('error', `An error occurred: ${error.message}`);
		}

		setLastSubmitTime(now);

		console.log(formState);
	};

	const validateEmail = (email) => {
		const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return re.test(email);
	};

	return (
		<main className='relative flex-1 overflow-y-auto focus:outline-none'>
			<div className='flex flex-col gap-8 mx-auto 2xl:max-w-7xl px-4 py-4 sm:px-6 md:px-8 sm:py-6 md:py-8'>
				<section className='w-full bg-theme-white'>
					<h1 className='mb-6 text-3xl font-bold text-theme-gray-800'>Contact US </h1>
				</section>
				<div className='flex flex-wrap space-x-16'>
					<div className='w-full sm:w-6/12 '>
						<div className='w-full bg-theme-white'>
							<h3 className='font-bold text-theme-gray-800 mb-3'> Contact Us at:</h3>
							<a href='tel:17581234567' className='flex flex-row items-center mb-6 hover:opacity-70 cursor-pointer' target='_blank' rel='noopener noreferrer'>
								<div className='bg-blue-500 rounded-full p-3'>
									<svg xmlns='http://www.w3.org/2000/svg' width='17' height='17' fill='currentColor' viewBox='0 0 16 16' className='text-white'>
										<path d='M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z' />
									</svg>
								</div>

								<span className='ml-2 font-normal text-sm text-theme-gray-800'>Tel: 1 (758) 123-4567</span>
							</a>

							<a href='mailto:grade.portal758@gmail.com' className='flex flex-row items-center mb-6 hover:opacity-70 cursor-pointer' target='_blank' rel='noopener noreferrer'>
								<div className='bg-blue-500 rounded-full p-3'>
									<svg xmlns='http://www.w3.org/2000/svg' width='17' height='17' fill='currentColor' class='bi bi-envelope' viewBox='0 0 16 16' className='text-white'>
										<path d='M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z' />
									</svg>
								</div>

								<span className='ml-2 font-normal text-sm text-theme-gray-800'>Email: grade.portal758@gmail.com</span>
							</a>

							<a href='' className='flex flex-row items-center mb-6 hover:opacity-70 cursor-pointer' target='_blank' rel='noopener noreferrer'>
								<div className='bg-blue-500 rounded-full p-3'>
									<svg xmlns='http://www.w3.org/2000/svg' width='17' height='17' fill='currentColor' viewBox='0 0 16 16' className='text-white'>
										<path
											fill-rule='evenodd'
											d='M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999zm2.493 8.574a.5.5 0 0 1-.411.575c-.712.118-1.28.295-1.655.493a1.319 1.319 0 0 0-.37.265.301.301 0 0 0-.057.09V14l.002.008a.147.147 0 0 0 .016.033.617.617 0 0 0 .145.15c.165.13.435.27.813.395.751.25 1.82.414 3.024.414s2.273-.163 3.024-.414c.378-.126.648-.265.813-.395a.619.619 0 0 0 .146-.15.148.148 0 0 0 .015-.033L12 14v-.004a.301.301 0 0 0-.057-.09 1.318 1.318 0 0 0-.37-.264c-.376-.198-.943-.375-1.655-.493a.5.5 0 1 1 .164-.986c.77.127 1.452.328 1.957.594C12.5 13 13 13.4 13 14c0 .426-.26.752-.544.977-.29.228-.68.413-1.116.558-.878.293-2.059.465-3.34.465-1.281 0-2.462-.172-3.34-.465-.436-.145-.826-.33-1.116-.558C3.26 14.752 3 14.426 3 14c0-.599.5-1 .961-1.243.505-.266 1.187-.467 1.957-.594a.5.5 0 0 1 .575.411'
										/>
									</svg>
								</div>
								<span className='ml-2 font-normal text-sm text-theme-gray-800'>Location: WaterFront, Castries</span>
							</a>
						</div>

						<iframe
							title='Google Maps'
							src='https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d8166.695114505136!2d-60.995075059426036!3d14.012154219295427!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sNIC!5e0!3m2!1sen!2s!4v1715100946274!5m2!1sen!2s'
							width='100%'
							height='450'
							className='border-0 rounded-lg'
							allowfullscreen=''
							loading='lazy'
							referrerpolicy='no-referrer-when-downgrade'></iframe>
					</div>

					<div className='w-full sm:w-5/12'>
						<h1 className='mb-3 text-2xl font-bold text-theme-gray-800'>Contact Grade Portal </h1>
						<form onSubmit={handleSubmit} class='space-y-8'>
							<div className='flex space-x-4 gap-5'>
								<div>
									<div className='flex items-center'>
										<label htmlFor='firstName' className='block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'>
											First Name{' '}
										</label>{' '}
										<span className='text-red-500 ml-1 -mt-2'>*</span>
									</div>
									<input
										type='text'
										name='firstName'
										onChange={handleInputChange}
										value={formState.firstName}
										className='shadow-sm bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light'
										placeholder='Jane'
										required
									/>
								</div>

								<div>
									<div className='flex items-center'>
										<label htmlFor='lastName' className='block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'>
											Last Name{' '}
										</label>{' '}
										<span className='text-red-500 ml-1 -mt-2'>*</span>
									</div>
									<input
										type='text'
										name='lastName'
										onChange={handleInputChange}
										value={formState.lastName}
										className='shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light'
										placeholder='Doe'
										required
									/>
								</div>
							</div>

							<div>
								<label htmlFor='subject' className='block w-full mb-2  text-sm font-medium text-gray-900 dark:text-gray-300'>
									Subject
								</label>
								<input
									type='text'
									name='subject'
									onChange={handleInputChange}
									value={formState.subject}
									className='block p-3  w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light w-1/2'
									placeholder='Let us know how we can help you'
									required
								/>
							</div>

							<div>
								<div className='flex items-center'>
									<label htmlFor='email' className='block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'>
										Email
									</label>{' '}
									<span className='text-red-500 ml-1 -mt-2'>*</span>
								</div>
								<input
									type='email'
									name='email'
									onChange={handleInputChange}
									value={formState.email}
									className='block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light w-1/2'
									placeholder='Let us know how we can help you'
									required
								/>
							</div>
							<div className='sm:col-span-2'>
								<label htmlFor='message' className='block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'>
									Message
								</label>
								<textarea
									name='message'
									onChange={handleInputChange}
									value={formState.message}
									className='block p-3  w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light w-1/2'
									placeholder='Type your message here'
									required
								/>
							</div>
							<div className='sm:col-span-2'>
								<label htmlFor='honeypot' className='hidden mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'>
									honeypot
								</label>
								<input
									name='honeypot'
									type='text'
									className='hidden p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light w-1/2 '
									onChange={handleInputChange}
									value={formState.honeypot}
								/>
							</div>
							<button type='submit' className='py-3 px-5 text-sm font-medium text-center bg-blue-500 text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800'>
								Send message
							</button>
						</form>
					</div>
				</div>
			</div>
			<Footer />
		</main>
	);
};

export default Contact;
