import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { generateNewGradeID } from '../utils';

const EnlistedStudentsTable = ({ appData, classId, users, currentUser, currentSchool, assessments, sortedAssessments, setRemoveStudentModalState, setRemoveStudentId, showAlert, createNewGrade, addStudents, archived }) => {
	const [students, setStudents] = useState(null);
	const [studentsLoading, setStudentsLoading] = useState(true);

	useEffect(() => {
		const fetchStudents = async () => {
			try {
				const _students = await users?.filter((user) => user?.userType === 'student' && user?.school?.id === currentSchool?.id && user?.school?.classes?.some((_class) => _class === classId));

				setStudents(_students);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setStudentsLoading(false);
		};

		fetchStudents();
	}, [currentSchool?.id, users, appData?.debugMode, classId]);

	const [studentSearchQuery, setStudentSearchQuery] = useState('');
	const [filteredStudents, setFilteredStudents] = useState([]);

	useEffect(() => {
		const filtered = students?.filter((student) => {
			const trimmedQuery = studentSearchQuery?.trim().toLowerCase();

			const id = student?.id?.toLowerCase() || '';
			const firstName = student?.name?.first?.toLowerCase() || '';
			const lastName = student?.name?.last?.toLowerCase() || '';
			const emailAddress = student?.emailAddress?.toLowerCase() || '';

			return id?.includes(trimmedQuery) || firstName?.includes(trimmedQuery) || lastName?.includes(trimmedQuery) || emailAddress?.includes(trimmedQuery);
		});

		setFilteredStudents(filtered);
	}, [students, studentSearchQuery]);

	const handleGradeChange = (student, assessment, increment) => {
		const inputField = document.getElementById(`${student?.id}-${assessment?.id}-points`);
		let currentValue = parseFloat(inputField?.value);

		if (isNaN(currentValue)) {
			currentValue = 0;
		}

		currentValue += increment;
		currentValue = Math.max(0, Math.min(currentValue, assessment?.grading?.totalPoints));
		inputField.value = currentValue;

		handleNewGrade(student, assessment, currentValue, assessment?.grading?.totalPoints);
	};

	const handleNewGrade = async (student, assessment, grade, totalPoints) => {
		if (grade < 0) {
			showAlert('error', 'Please enter a valid grade');

			if (appData?.debugMode) {
				console.error('Please enter a valid grade');
			}
		} else if (grade > totalPoints) {
			showAlert('error', 'Grade too large');

			if (appData?.debugMode) {
				console.error('Grade too large');
			}
		} else {
			try {
				let newGradeData = {
					id: generateNewGradeID(),
					classId: classId,
					assessmentId: assessment?.id,
					grade: grade,
					addedBy: currentUser?.data?.id,
				};

				if (newGradeData?.id === null || newGradeData?.classId === null || newGradeData?.classId !== classId || newGradeData?.grade === null || newGradeData?.grade !== grade || newGradeData?.addedBy === null || newGradeData?.addedBy !== currentUser?.data?.id) {
					if (appData?.debugMode) {
						console.error('Incomplete Data');
					}

					throw new Error('Incomplete Data');
				} else {
					await createNewGrade(student?.id, newGradeData);
				}
			} catch (error) {
				if (appData?.debugMode) {
					console.error(error);
				}

				showAlert('error', 'An unknown error occurred');
			}
		}
	};

	return (
		<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
			<div className='mb-4 flex flex-row items-center justify-between'>
				<div className='w-full flex flex-row items-center justify-between gap-4'>
					<div className='w-full flex flex-row items-center justify-start gap-4'>
						<h3 className='whitespace-nowrap w-content text-xl font-bold text-theme-gray-800'>Enlisted Students</h3>

						{students?.length > 0 && (
							<div className='w-full max-w-sm'>
								<label htmlFor='studentSearch' className='sr-only'>
									Search
								</label>

								<div className='relative'>
									<div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
										<svg className='size-4 text-theme-gray-800' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
											<path d='M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z' fill='none' stroke='currentColor' strokeMiterlimit='10' strokeWidth='32' />
											<path fill='none' stroke='currentColor' strokeLinecap='round' strokeMiterlimit='10' strokeWidth='32' d='M338.29 338.29L448 448' />
										</svg>
									</div>

									<input
										type='search'
										onChange={(e) => {
											e.preventDefault();
											setStudentSearchQuery(e.target.value);
										}}
										className='min-w-64 w-full ps-10 rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'
										id='studentSearch'
										placeholder='Search students'
									/>
								</div>
							</div>
						)}
					</div>

					{!archived && !addStudents && currentUser?.data?.userType === 'principal' && (
						<Link type='button' to={`/classes/${classId}/students/add`} className='inline-block shrink-0 rounded-md border border-blue-500 bg-blue-500 px-4 py-2 text-sm font-medium text-white transition-all duration-300 ease-in-out hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'>
							Add Students
						</Link>
					)}
				</div>
			</div>

			<div className='w-full'>
				<div className='overflow-x-auto'>
					<table className='table-auto min-w-full divide-y-2 divide-theme-gray-200 bg-theme-white text-sm'>
						<thead>
							<tr>
								<th className='sticky left-0 bg-theme-white whitespace-nowrap px-4 py-2 font-medium text-left text-theme-gray-800'>Student ID</th>

								<th className='whitespace-nowrap px-4 py-2 font-medium text-left text-theme-gray-800'>First Name</th>

								<th className='whitespace-nowrap px-4 py-2 font-medium text-left text-theme-gray-800'>Last Name</th>

								{currentUser?.data?.userType === 'principal' && <th className='whitespace-nowrap px-4 py-2 font-medium text-left text-theme-gray-800'>Email Address</th>}

								{currentUser?.data?.userType === 'teacher' &&
									sortedAssessments &&
									sortedAssessments?.length > 0 &&
									sortedAssessments?.map((assessment) => {
										return (
											<th key={assessment?.id} className={`bg-${assessment?.color}-500 whitespace-nowrap px-4 py-2 font-medium text-center text-white`}>
												{assessment?.name}
											</th>
										);
									})}

								{!archived && currentUser?.data?.userType === 'principal' && <th className='whitespace-nowrap px-4 py-2'></th>}
							</tr>
						</thead>

						<tbody className='w-full divide-y divide-theme-gray-200'>
							{!studentsLoading && filteredStudents?.length > 0 ? (
								filteredStudents?.map((student, idx) => {
									return (
										<tr key={idx}>
											<td className='sticky left-0 bg-theme-gray-100 whitespace-nowrap px-4 py-2 text-left'>
												<span className='text-blue-500 font-bold'>
													{student?.id ? (
														<Link to={`/students/${student?.id}`} target='_blank' rel='noopener noreferrer'>
															{student?.id}
														</Link>
													) : (
														<p>N/A</p>
													)}
												</span>
											</td>

											<td className='whitespace-nowrap px-4 py-2 text-left text-theme-gray-600'>{student?.name?.first || 'N/A'}</td>

											<td className='whitespace-nowrap px-4 py-2 text-left text-theme-gray-600'>{student?.name?.last || 'N/A'}</td>

											{currentUser?.data?.userType === 'principal' && <td className='whitespace-nowrap px-4 py-2 text-left text-theme-gray-600'>{student?.emailAddress || 'N/A'}</td>}

											{currentUser?.data?.userType === 'teacher' &&
												sortedAssessments &&
												sortedAssessments?.length > 0 &&
												sortedAssessments?.map((assessment, idx) => {
													const existingGrade = student?.grades?.find((grade) => grade?.assessmentId === assessment?.id)?.grade;

													return (
														<td key={idx} className='border-l border-theme-gray-200 whitespace-nowrap px-4 py-2 font-medium text-left text-theme-gray-600'>
															<div className='flex flex-row items-center justify-center'>
																<label htmlFor={`${student?.id}-${assessment?.id}-points`} className='sr-only'>
																	{assessment?.id} Points
																</label>

																<div className='flex items-center rounded-lg border border-theme-gray-200'>
																	<button
																		type='button'
																		onClick={(e) => {
																			e.preventDefault();
																			handleGradeChange(student, assessment, -1);
																		}}
																		className='h-10 w-10 leading-10 text-theme-gray-600 hover:opacity-75'>
																		-
																	</button>

																	<input
																		type='number'
																		onChange={(e) => {
																			e.preventDefault();
																		}}
																		onBlur={(e) => {
																			const gradeFloat = parseFloat(e.target.value);

																			if (!isNaN(gradeFloat)) {
																				handleNewGrade(student, assessment, gradeFloat, assessment?.grading?.totalPoints);
																			}
																		}}
																		onKeyPress={(e) => {
																			const allowedCharacters = /[0-9.-]/;
																			const allowedControlKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', '.'];

																			if (!allowedCharacters.test(e.key) && !allowedControlKeys.includes(e.key)) {
																				e.preventDefault();
																			}
																		}}
																		id={`${student?.id}-${assessment?.id}-points`}
																		className='h-10 w-16 bg-theme-white border-transparent text-center [-moz-appearance:_textfield] sm:text-sm [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none'
																		placeholder='0'
																		defaultValue={existingGrade}
																	/>

																	<button
																		type='button'
																		onClick={(e) => {
																			e.preventDefault();
																			handleGradeChange(student, assessment, 1);
																		}}
																		className='h-10 w-10 leading-10 text-theme-gray-600 hover:opacity-75'>
																		+
																	</button>
																</div>

																<span className='ml-2'>/ {assessment?.grading?.totalPoints}</span>
															</div>
														</td>
													);
												})}

											{!archived && currentUser?.data?.userType === 'principal' && (
												<td className='whitespace-nowrap px-4 py-2 text-center'>
													<button
														type='button'
														onClick={(e) => {
															e.preventDefault();
															setRemoveStudentModalState(true);
															setRemoveStudentId(student?.id);
														}}
														className='inline-block shrink-0 rounded-md border border-red-500 bg-red-500 px-4 py-2 text-sm font-medium text-white transition-all duration-300 ease-in-out ring-red-300 hover:bg-transparent hover:text-red-500 focus:outline-none focus:ring active:text-red-500'>
														Remove
													</button>
												</td>
											)}
										</tr>
									);
								})
							) : (
								<tr>
									<td colSpan={currentUser?.data?.userType === 'teacher' ? 3 + assessments?.length : 5} className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>
										No students found
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default EnlistedStudentsTable;
