import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { AuthContext, DataContext } from '../../context';
import { ChatsCard, GreetingMessage, Loader, MainContainer, PerformanceRanking } from '../../components';
import { generateUserGrades } from '../../utils';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const TeacherDashboard = () => {
	useTitle('Dashboard | Grade Portal');

	const { currentUser } = useContext(AuthContext);
	const { appData, currentSchool, users, assessments, subjects, divRef, handleScroll, isDarkMode } = useContext(DataContext);

	const [classes, setClasses] = useState(null);
	const [students, setStudents] = useState(null);

	const [classesLoading, setClassesLoading] = useState(true);
	const [studentsLoading, setStudentsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const filteredClasses = await currentSchool?.classes?.filter((_class) => {
					return _class?.teacherId === currentUser?.data?.id;
				});

				const _students = await users?.filter((user) => {
					if (user?.userType !== 'student') {
						return false;
					}

					const teacherClassIds = filteredClasses?.map((_class) => _class?.id);

					return user?.school?.classes?.some((classId) => teacherClassIds?.includes(classId));
				});

				setClasses(filteredClasses);
				setStudents(_students);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setClassesLoading(false);
			setStudentsLoading(false);
		};

		fetchData();
	}, [appData?.debugMode, currentSchool, currentUser, users]);

	const [studentGrades, setStudentGrades] = useState(null);
	const [studentAveragePerformance, setStudentAveragePerformance] = useState(null);
	const [studentClassGradesLoading, setStudentClassGradesLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				// let _studentClassGrades = [];
				let _studentAveragePerformance = 0;

				if (!classesLoading && classes?.length > 0) {
					if (!studentsLoading && students?.length > 0) {
						const fetchedGrades = [];

						for (const _class of classes) {
							for (const student of students) {
								const studentInClass = student?.school?.classes?.some((classId) => classId === _class?.id);

								if (studentInClass) {
									const studentWithData = {
										data: student,
									};

									const classAssessments = await assessments?.filter((assessment) => assessment?.classId === _class?.id);
									const currentStudentGrades = await generateUserGrades(currentSchool, studentWithData, classAssessments, null, _class?.id);

									const currentStudentTotalPercentage = currentStudentGrades?.reduce((accumulator, currentGrade) => {
										return accumulator + parseInt(currentGrade.grade);
									}, 0);

									const calculatedStudentTotalAverage = currentStudentTotalPercentage / currentStudentGrades?.length;

									fetchedGrades?.push({
										studentId: student?.id,
										classId: _class?.id,
										average: calculatedStudentTotalAverage,
									});

									_studentAveragePerformance += calculatedStudentTotalAverage;
								}
							}
						}

						_studentAveragePerformance /= fetchedGrades.length;

						setStudentGrades(fetchedGrades);
						setStudentAveragePerformance(_studentAveragePerformance);
					}
				}
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setStudentClassGradesLoading(false);
		};

		fetchData();
	}, [appData?.debugMode, assessments, classes, classesLoading, currentSchool, students, studentsLoading]);

	const [classAveragePerformanceChartData, setClassAveragePerformanceChartData] = useState({
		labels: [],
		datasets: [],
	});

	const classAveragePerformanceChartOptions = {
		scales: {
			y: {
				beginAtZero: true,
				title: {
					display: true,
					text: 'Average Performance (%)',
					font: {
						weight: 'bold',
						color: isDarkMode ? '#E5E7EB' : '#1f2937',
						fontSize: 12,
					},
				},
				grid: {
					drawBorder: true,
					color: isDarkMode ? '#E5E7EB' : '#1f2937',
				},
				ticks: {
					color: isDarkMode ? '#E5E7EB' : '#1f2937',
					fontSize: 12,
				},
			},
			x: {
				title: {
					display: true,
					text: 'Classes',
					font: {
						weight: 'bold',
						color: isDarkMode ? '#E5E7EB' : '#1f2937',
						fontSize: 12,
					},
				},
				grid: {
					drawBorder: true,
					color: isDarkMode ? '#E5E7EB' : '#1f2937',
				},
				ticks: {
					color: isDarkMode ? '#E5E7EB' : '#1f2937',
					fontSize: 12,
				},
			},
		},
		plugins: {
			legend: {
				display: false,
			},
		},
	};

	useEffect(() => {
		let classAveragePerformanceData = {};

		if (studentGrades) {
			for (const studentGrade of studentGrades) {
				const { classId, average } = studentGrade;

				if (classAveragePerformanceData.hasOwnProperty(classId)) {
					classAveragePerformanceData[classId] += average;
				} else {
					classAveragePerformanceData[classId] = average;
				}
			}

			for (const classId in classAveragePerformanceData) {
				const totalAverage = classAveragePerformanceData[classId];
				const numberOfStudents = studentGrades?.filter((studentGrade) => studentGrade?.classId === classId)?.length;
				const classAverage = totalAverage / numberOfStudents;

				classAveragePerformanceData[classId] = classAverage;
			}

			setClassAveragePerformanceChartData({
				labels: Object.keys(classAveragePerformanceData).map((classId) => {
					const subjectName = subjects?.find((subject) => subject?.id === classes?.find((_class) => _class?.id === classId)?.subjectId)?.name;
					return `${classId} - ${subjectName}`;
				}),
				datasets: [
					{
						data: Object.values(classAveragePerformanceData)?.map((average) => average?.toFixed(2)),
						backgroundColor: ['#22c55e', '#f97316', '#a855f7', '#eab308', '#14b8a6', '#f43f5e', '#3b82f6'],
						borderColor: ['#22c55e', '#f97316', '#a855f7', '#eab308', '#14b8a6', '#f43f5e', '#3b82f6'],
						borderWidth: 1,
					},
				],
			});
		}
	}, [studentGrades, classes, subjects]);

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<h3 className='mb-6 text-2xl font-bold text-theme-gray-800'>
					<GreetingMessage currentUser={currentUser} />
				</h3>

				<div className='grid grid-cols-3 gap-6'>
					<div className='col-span-3 md:col-span-1 flex flex-col items-center justify-center bg-blue-500 rounded-lg h-62 w-62 p-8 shadow-lg'>
						<h3 className='text-5xl text-center font-bold text-white'>{classesLoading ? <Loader size='sm' /> : !classesLoading && classes ? classes?.length : 'N/A'}</h3>

						<p className='my-4 text-center text-white text-sm'>Total Classes You Teach</p>

						<Link to='/classes' type='button' className='flex justify-center items-center text-white bg-yellow-500 px-4 py-2 rounded-lg transition-all duration-300 ease-in-out ring-0 hover:opacity-80 focus:outline-none focus:ring-0'>
							<svg className='size-5' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
								<rect x='64' y='176' width='384' height='256' rx='28.87' ry='28.87' fill='none' stroke='currentColor' strokeLinejoin='round' strokeWidth='32' />
								<path stroke='currentColor' strokeLinecap='round' strokeMiterlimit='10' strokeWidth='32' d='M144 80h224M112 128h288' />
							</svg>

							<span className='ml-2'>View Classes</span>
						</Link>
					</div>

					<div className='col-span-3 md:col-span-1 flex flex-col items-center justify-center bg-blue-500 rounded-lg h-62 w-62 p-8 shadow-lg'>
						<h3 className='text-5xl text-center font-bold text-white'>{studentClassGradesLoading ? <Loader size='lg' /> : !studentClassGradesLoading && studentAveragePerformance ? studentAveragePerformance?.toFixed(2) + '%' : 'N/A'}</h3>

						<p className='my-4 text-center text-white text-sm'>Average Student Performance</p>

						<Link to='/reports' type='button' className='flex justify-center items-center text-white bg-yellow-500 px-4 py-2 rounded-lg transition-all duration-300 ease-in-out ring-0 hover:opacity-80 focus:outline-none focus:ring-0'>
							<svg className='size-5' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
								<path d='M32 32v432a16 16 0 0016 16h432' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' />
								<rect x='96' y='224' width='80' height='192' rx='20' ry='20' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' />
								<rect x='240' y='176' width='80' height='240' rx='20' ry='20' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' />
								<rect x='383.64' y='112' width='80' height='304' rx='20' ry='20' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' />
							</svg>

							<span className='ml-2'>View Reports</span>
						</Link>
					</div>

					<div className='col-span-3 md:col-span-1 flex flex-col items-center justify-center bg-blue-500 rounded-lg h-62 w-62 p-8 shadow-lg'>
						<h3 className='text-5xl text-center font-bold text-white'>{studentsLoading ? <Loader size='lg' /> : !studentsLoading && students ? students?.length : 'N/A'}</h3>

						<p className='my-4 text-center text-white text-sm'>Students</p>

						<Link to='/students' type='button' className='flex justify-center items-center text-white bg-yellow-500 px-4 py-2 rounded-lg transition-all duration-300 ease-in-out ring-0 hover:opacity-80 focus:outline-none focus:ring-0'>
							<svg className='size-5' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
								<path d='M402 168c-2.93 40.67-33.1 72-66 72s-63.12-31.32-66-72c-3-42.31 26.37-72 66-72s69 30.46 66 72z' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' />
								<path d='M336 304c-65.17 0-127.84 32.37-143.54 95.41-2.08 8.34 3.15 16.59 11.72 16.59h263.65c8.57 0 13.77-8.25 11.72-16.59C463.85 335.36 401.18 304 336 304z' fill='none' stroke='currentColor' strokeMiterlimit='10' strokeWidth='32' />
								<path d='M200 185.94c-2.34 32.48-26.72 58.06-53 58.06s-50.7-25.57-53-58.06C91.61 152.15 115.34 128 147 128s55.39 24.77 53 57.94z' fill='none' stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='32' />
								<path d='M206 306c-18.05-8.27-37.93-11.45-59-11.45-52 0-102.1 25.85-114.65 76.2-1.65 6.66 2.53 13.25 9.37 13.25H154' fill='none' stroke='currentColor' strokeLinecap='round' strokeMiterlimit='10' strokeWidth='32' />
							</svg>

							<span className='ml-2'>View Students</span>
						</Link>
					</div>
				</div>
			</section>

			<section className='w-full bg-theme-white'>
				<div className='grid grid-cols-12 gap-6'>
					<div className='col-span-12 md:col-span-8'>
						{!classesLoading && classes && classes?.length > 0 && classAveragePerformanceChartData && classAveragePerformanceChartOptions && (
							<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
								<div className='mb-4 flex flex-row items-center justify-between'>
									<h3 className='text-xl font-bold text-theme-gray-800'>Class Average Performance</h3>
								</div>

								<Bar data={classAveragePerformanceChartData} options={classAveragePerformanceChartOptions} />
							</div>
						)}
					</div>

					<div className='col-span-12 md:col-span-4 flex flex-col gap-6'>
						{!classesLoading && classes && classes?.length > 0 && <PerformanceRanking initialSelectedClassId={classes[0]?.id} max={5} />}

						<ChatsCard />
					</div>
				</div>
			</section>
		</MainContainer>
	);
};

export default TeacherDashboard;
