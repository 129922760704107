import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTitle } from 'react-use';
import { DataContext, AuthContext } from '../../context';
import { Loader, GreetingMessage, MainContainer, ChatsCard } from '../../components';
import { calculateGPAFromPercentage, generateUserGrades } from '../../utils';


const AlumniDashboard = () => {

	useTitle('Alumni Dashboard | Loyalist College');

	const { currentUser } = useContext(AuthContext);
	const { appData, users, currentSchool, subjects, assessments, currentSemesterDuration, divRef, handleScroll } = useContext(DataContext);

	const [classes, setClasses] = useState(null);
	const [cumulativeGPA, setCumulativeGPA] = useState(null);
	const [semesterGPA, setSemesterGPA] = useState(null);
	const [average, setAverage] = useState(null);
	const [studentGrades, setStudentGrades] = useState(null);
	const [dataLoading, setDataLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const _classes = await currentUser?.data?.school?.classes;
				const studentClasses = await currentSchool?.classes?.filter((_class) => _classes?.includes(_class?.id));

				// let classTotalPoints = 0;

				// for (const _class of studentClasses) {
				// 	const classAssessments = assessments?.filter((assessment) => assessment?.classId === _class);

				// 	for (const assessment of classAssessments) {
				// 		if (!isNaN(assessment?.grading?.totalPoints)) {
				// 			classTotalPoints += parseFloat(assessment?.grading?.totalPoints);
				// 		}
				// 	}

				// 	// return (classTotal / classTotalPoints) * 100;
				// }

				const fetchedGrades = [];

				for (const _class of studentClasses) {
					const classAssessments = await assessments?.filter((assessment) => assessment?.classId === _class?.id);
					const currentStudentGrades = await generateUserGrades(currentSchool, currentUser, classAssessments, null, _class?.id);

					const currentStudentTotalPercentage = currentStudentGrades?.reduce((accumulator, currentGrade) => {
						return accumulator + parseInt(currentGrade.grade);
					}, 0);

					const calculatedStudentTotalAverage = currentStudentTotalPercentage / currentStudentGrades?.length;

					fetchedGrades?.push({
						studentId: currentUser?.data?.id,
						classId: _class?.id,
						subjectId: _class?.subjectId,
						teacherId: _class?.teacherId,
						average: calculatedStudentTotalAverage,
					});
				}

				const studentAveragesSum = {};

				for (const grade of fetchedGrades) {
					const { studentId, average } = grade;

					if (studentAveragesSum.hasOwnProperty(studentId)) {
						studentAveragesSum[studentId] += average;
					} else {
						studentAveragesSum[studentId] = average;
					}
				}

				setStudentGrades(fetchedGrades);

				const _allUserGrades = await generateUserGrades(await currentSchool, await currentUser, await assessments, null, null);
				const _semesterUserGrades = await generateUserGrades(await currentSchool, await currentUser, await assessments, await currentSemesterDuration, null);

				// console.log(_allUserGrades, (_allUserGrades / classTotalPoints) * 100);
				// console.log(_allUserGrades, classTotalPoints);

				const totalPercentage = _allUserGrades?.reduce((accumulator, currentGrade) => {
					return accumulator + currentGrade?.grade;
				}, 0);

				const semesterPercentage = _semesterUserGrades?.reduce((accumulator, currentGrade) => {
					return accumulator + currentGrade?.grade;
				}, 0);

				let calculatedTotalAverage = totalPercentage / _allUserGrades?.length;
				calculatedTotalAverage = calculatedTotalAverage > 100 ? 100 : calculatedTotalAverage;
				const calculatedSemesterAverage = semesterPercentage / _allUserGrades?.length;

				const calculatedCumulativeGPA = calculateGPAFromPercentage(calculatedTotalAverage);
				const calculatedSemesterGPA = calculateGPAFromPercentage(calculatedSemesterAverage);

				setClasses(_classes);
				setCumulativeGPA(calculatedCumulativeGPA);
				setSemesterGPA(calculatedSemesterGPA);
				setAverage(calculatedSemesterAverage);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setDataLoading(false);
		};

		fetchData();
	}, [users, appData?.debugMode, currentSchool, currentUser, currentUser?.data?.children, assessments, currentSemesterDuration]);

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<h3 className='mb-6 text-2xl font-bold text-theme-gray-800'>
					<GreetingMessage currentUser={currentUser} />
				</h3>

				<div className='grid grid-cols-4 gap-6'>
					<div className='col-span-4 md:col-span-1 flex flex-col items-center justify-center bg-blue-500 rounded-lg h-62 w-62 p-8 shadow-lg'>
						<h3 className='text-5xl text-center font-bold text-white'>{dataLoading ? <Loader size='sm' /> : !dataLoading && cumulativeGPA && cumulativeGPA > 0 && !isNaN(cumulativeGPA) ? cumulativeGPA.toFixed(2) : 'N/A'}</h3>

						<p className='mt-4 text-center text-white text-xl'>Cumulative GPA</p>
					</div>

					<div className='col-span-4 md:col-span-1 flex flex-col items-center justify-center bg-blue-500 rounded-lg h-62 w-62 p-8 shadow-lg'>
						<h3 className='text-5xl text-center font-bold text-white'>{dataLoading ? <Loader size='sm' /> : !dataLoading && semesterGPA && semesterGPA > 0 && !isNaN(semesterGPA) ? semesterGPA.toFixed(2) : 'N/A'}</h3>

						<p className='mt-4 text-center text-white text-xl'>Semester GPA</p>
					</div>

					<div className='col-span-4 md:col-span-1 flex flex-col items-center justify-center bg-blue-500 rounded-lg h-62 w-62 p-8 shadow-lg'>
						<h3 className='text-5xl text-center font-bold text-white'>{dataLoading ? <Loader size='sm' /> : !dataLoading && average && average > 0 && !isNaN(average) ? average.toFixed(2) : 'N/A'}</h3>

						<p className='mt-4 text-center text-white text-xl'>Average (%)</p>
					</div>

					<div className='col-span-4 md:col-span-1 flex flex-col items-center justify-center bg-blue-500 rounded-lg h-62 w-62 p-8 shadow-lg'>
						<h3 className='text-5xl text-center font-bold text-white'>{dataLoading ? <Loader size='sm' /> : !dataLoading && classes ? classes?.length : 'N/A'}</h3>

						<p className='mt-4 text-center text-white text-xl'>Classes</p>
					</div>
				</div>
			</section>
			<section className='w-full bg-theme-white'>
				<div className='grid grid-cols-3 gap-6'>
					<div className='col-span-3 lg:col-span-2'>
						<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
							<div className='mb-4 flex flex-row items-center justify-between'>
								<h3 className='text-xl font-bold text-theme-gray-800'>Past Grades</h3>

								<Link type='button' to='/reports' className='inline-block shrink-0 rounded-md border border-blue-500 bg-blue-500 px-4 py-2 text-sm font-medium text-white transition-all duration-300 ease-in-out hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'>
									View All
								</Link>
							</div>

							<div className='w-full rounded-lg border border-theme-gray-200'>
								<div className='rounded-t-lg overflow-x-auto'>
									<table className='min-w-full rounded-lg divide-y-2 divide-theme-gray-200 bg-theme-white text-sm'>
										<thead className='w-full'>
											<th className='whitespace-nowrap px-4 py-2 font-medium text-left text-theme-gray-800'>Subject</th>

											<th className='whitespace-nowrap px-4 py-2 font-medium text-center text-theme-gray-800'>Class ID</th>

											<th className='whitespace-nowrap px-4 py-2 font-medium text-center text-theme-gray-800'>Teacher</th>

											<th className='whitespace-nowrap px-4 py-2 font-medium text-center text-theme-gray-800'>Status</th>

											<th className='whitespace-nowrap px-4 py-2 font-medium text-center text-theme-gray-800'>Grade</th>
										</thead>

										<tbody className='w-full divide-y divide-theme-gray-200'>
											{!dataLoading && studentGrades && studentGrades?.length > 0 ? (
												studentGrades?.slice(0, 5)?.map((studentGrade, idx) => {
													const foundClass = currentSchool?.classes?.find((cls) => cls?.id === studentGrade?.classId);
													const subjectName = subjects?.find((subject) => subject?.id === foundClass?.subjectId)?.name;
													const foundTeacher = users?.find((user) => user.id === foundClass?.teacherId && user.userType === 'teacher');
													const teacherName = foundTeacher?.name?.first + ' ' + foundTeacher?.name?.last;

													return (
														<tr key={idx} className='odd:bg-theme-gray-50'>
															<td className='whitespace-nowrap px-4 py-2 text-left text-theme-gray-600'>{subjectName || 'N/A'}</td>

															<td className='whitespace-nowrap px-4 py-2 text-left text-theme-gray-600'>{studentGrade?.classId || 'N/A'}</td>

															<td className='whitespace-nowrap px-4 py-2 text-left text-theme-gray-600'>{foundTeacher ? teacherName : 'N/A'}</td>

															<td className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>
																{studentGrade?.average && studentGrade?.average >= currentSchool?.passMark ? (
																	<span className='rounded-full px-2.5 py-0.5 text-xs bg-green-600 text-white uppercase'>Pass</span>
																) : studentGrade?.average && studentGrade?.average < currentSchool?.passMark ? (
																	<span className='rounded-full px-2.5 py-0.5 text-xs bg-red-600 text-white uppercase'>Fail</span>
																) : (
																	<span className='rounded-full px-2.5 py-0.5 text-xs bg-gray-600 text-white uppercase'>Unassigned</span>
																)}
															</td>

															<td className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>{studentGrade?.average ? studentGrade?.average?.toFixed(2) + '%' : 'N/A'}</td>
														</tr>
													);
												})
											) : (
												<tr>
													<td colSpan='5' className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>
														No data available
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>

					<div className='col-span-3 lg:col-span-1'>
						<div>
							<ChatsCard />
						</div>
					</div>
				</div>
			</section>
		</MainContainer>
	);
};

export default AlumniDashboard;
