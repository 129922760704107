import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';

const MenuDropdown = ({ type, menuItems }) => {
	const defaultClass = 'group w-full flex items-center rounded-md px-2 py-2 text-sm ring-0 ring-offset-0 focus:outline-none focus:ring-0 focus-visible:ring-0';

	return (
		<Menu as='div' className='relative inline-block text-left'>
			<Menu.Button className='group p-1.5 flex items-center justify-center rounded-lg border border-theme-gray-400 text-center text-theme-gray-200 hover:opacity-80'>
				{type === 'download' ? (
					<svg className='size-5 p-0.5 transition-all duration-300 ease-in-out fill-theme-gray-600 group-hover:fill-theme-gray-600/80' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
						<path d='M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5' />
						<path d='M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z' />
					</svg>
				) : type === 'table' ? (
					<svg className='size-4 transition-all duration-300 ease-in-out fill-theme-gray-600 group-hover:fill-theme-gray-600/80' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
						<path d='M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0' />
					</svg>
				) : null}
			</Menu.Button>

			<Transition as={Fragment} enter='transition ease-out duration-100' enterFrom='transform opacity-0 scale-95' enterTo='transform opacity-100 scale-100' leave='transition ease-in duration-75' leaveFrom='transform opacity-100 scale-100' leaveTo='transform opacity-0 scale-95'>
				<Menu.Items
					style={{
						zIndex: 1000,
					}}
					className='absolute border border-theme-gray-200 mt-2 w-56 right-0 origin-top-right divide-y divide-theme-gray-200 rounded-md bg-theme-white shadow-lg ring-0 focus:outline-none'>
					{menuItems?.map((list, listIndex) => (
						<div key={listIndex} className='p-2'>
							{list.map((menuItem, menuItemIndex) => (
								<Menu.Item key={menuItemIndex}>
									{menuItem?.type === 'link' ? (
										<Link
											to={menuItem?.to}
											type='button'
											{...(menuItem?.newTab
												? {
														target: '_blank',
														rel: 'noopener noreferrer',
												  }
												: {})}
											className={`${defaultClass} ${menuItem?.danger ? 'text-red-500 hover:bg-red-50 dark:hover:text-white dark:hover:bg-red-800' : 'text-theme-gray-800 hover:bg-gray-100 dark:hover:bg-slate-600'}`}>
											<svg className={`size-4 transition-all duration-300 ease-in-out ${menuItem?.danger ? 'fill-red-500 dark:group-hover:fill-white' : 'fill-theme-gray-800'}`} xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
												{menuItem?.icon}
											</svg>

											<span className='ml-2'>{menuItem?.text}</span>
										</Link>
									) : menuItem?.type === 'button' ? (
										<button type='button' onClick={menuItem?.onClick} className={`${defaultClass} ${menuItem?.danger ? 'text-red-500 hover:bg-red-50 dark:hover:text-white dark:hover:bg-red-800' : 'text-theme-gray-800 hover:bg-gray-100 dark:hover:bg-slate-600'}`}>
											<svg className={`size-4 transition-all duration-300 ease-in-out ${menuItem?.danger ? 'fill-red-500 dark:group-hover:fill-white' : 'fill-theme-gray-800'}`} xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
												{menuItem?.icon}
											</svg>

											<span className='ml-2'>{menuItem?.text}</span>
										</button>
									) : null}
								</Menu.Item>
							))}
						</div>
					))}
				</Menu.Items>
			</Transition>
		</Menu>
	);
};

export default MenuDropdown;
