import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTitle } from 'react-use';
import { DataContext, AuthContext } from '../../context';
import { Loader, GreetingMessage, MainContainer, ChatsCard, TableBodyItem, TableHeadItem, Table } from '../../components';
import { generateUserGrades, getGradeLevelName, getProperty } from '../../utils';

const studentsTableContent = ({ student, gradeLevels }) => {
	return (
		<>
			<TableBodyItem type='primary' contents={student?.id ? <Link to={`/students/${student?.id}`}>{student?.id}</Link> : 'N/A'} />

			<TableBodyItem type='default' contents={student?.name?.first || 'N/A'} align='left' />

			<TableBodyItem type='default' contents={student?.name?.last || 'N/A'} align='left' />

			<TableBodyItem type='default' contents={student?.school?.gradeLevel ? getGradeLevelName(String(student?.school?.gradeLevel), gradeLevels) : 'N/A'} align='center' />
		</>
	);
};

const PrincipalDashboard = () => {
	useTitle('Principal Dashboard | Grade Portal');

	const { currentUser } = useContext(AuthContext);
	const { appData, currentSchool, users, subjects, assessments, divRef, handleScroll, gradeLevels } = useContext(DataContext);

	const [numClasses, setNumClasses] = useState(null);
	const [numSubjects, setNumSubjects] = useState(null);
	const [numStudents, setNumStudents] = useState(null);
	const [numTeachers, setNumTeachers] = useState(null);
	const [numParents, setNumParents] = useState(null);

	const [numClassesLoading, setNumClassesLoading] = useState(true);
	const [numSubjectsLoading, setNumSubjectsLoading] = useState(true);
	const [numStudentsLoading, setNumStudentsLoading] = useState(true);
	const [numTeachersLoading, setNumTeachersLoading] = useState(true);
	const [numParentsLoading, setNumParentsLoading] = useState(true);

	const [students, setStudents] = useState([]);
	const [studentsLoading, setStudentsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const students = await users?.filter((user) => user?.userType === 'student' && user?.school?.id === currentSchool?.id);
				const teachersLength = await users?.filter((user) => user?.userType === 'teacher' && user?.school?.id === currentSchool?.id)?.length;
				const parents = await users?.filter((user) => user?.userType === 'parent');
				let parentCount = 0;

				parents?.forEach((parent) => {
					if (parent?.children) {
						parent?.children?.forEach((childId) => {
							const child = students?.find((student) => student?.id === childId);

							if (child) {
								parentCount++;
								return;
							}
						});
					}
				});

				const classIds = currentSchool?.classes?.map((_class) => _class?.id);

				const studentsWithoutClass = await students?.filter((student) => {
					return !student?.school?.classId || student?.school?.classId === '' || student?.school?.classId === null || student?.school?.classId === undefined || !classIds?.includes(student?.school?.classId);
				});

				setNumClasses(await currentSchool?.classes?.length);
				setNumSubjects(await subjects?.length);
				setNumStudents(students?.length);
				setNumTeachers(teachersLength);
				setNumParents(await parentCount);
				setStudents(studentsWithoutClass);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setNumClassesLoading(false);
			setNumSubjectsLoading(false);
			setNumStudentsLoading(false);
			setNumTeachersLoading(false);
			setNumParentsLoading(false);
		};

		fetchData();
	}, [appData?.debugMode, currentSchool?.classes?.length, currentSchool?.id, subjects?.length, users, currentSchool?.classes]);

	useEffect(() => {
		const fetchStudents = async () => {
			try {
				const fetchedUsers = await users;
				const fetchedStudents = await fetchedUsers?.filter((user) => user?.userType === 'student');
				let filteredStudents = await fetchedStudents?.filter((student) => student?.school?.id === currentSchool?.id);

				setStudents(filteredStudents);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setStudentsLoading(false);
		};

		fetchStudents();
	}, [appData?.debugMode, currentSchool?.id, currentUser?.data?.userType, users]);

	const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState('asc');
	const [sortedStudents, setSortedStudents] = useState([]);

	const handleSortClick = (field) => {
		if (sortField === field) {
			setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
		} else {
			setSortField(field);
			setSortOrder('asc');
		}
	};

	useEffect(() => {
		const sortStudents = async () => {
			let sortedData = [...(students ?? [])];

			if (!studentsLoading && sortField) {
				sortedData?.sort((a, b) => {
					const valueA = getProperty(a, sortField);
					const valueB = getProperty(b, sortField);

					if (valueA && valueB) {
						const numA = Number(valueA);
						const numB = Number(valueB);

						if (!isNaN(numA) && !isNaN(numB)) {
							return sortOrder === 'asc' ? numA - numB : numB - numA;
						} else {
							if (sortOrder === 'asc') {
								return valueA?.localeCompare(valueB, undefined, {
									numeric: true,
								});
							} else {
								return valueB?.localeCompare(valueA, undefined, {
									numeric: true,
								});
							}
						}
					} else {
						return 0;
					}
				});
			}

			setSortedStudents(sortedData);
		};

		sortStudents();
	}, [students, sortField, sortOrder, studentsLoading]);

	const [classes, setClasses] = useState(null);
	const [classesLoading, setClassesLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setClasses(currentSchool?.classes);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setClassesLoading(false);
		};

		fetchData();
	}, [appData?.debugMode, currentSchool?.classes]);

	const [studentGrades, setStudentGrades] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (!classesLoading && classes?.length > 0) {
					if (!studentsLoading && students?.length > 0) {
						const fetchedGrades = [];

						for (const _class of classes) {
							for (const student of students) {
								const studentInClass = student?.school?.classes?.some((classId) => classId === _class?.id);

								if (studentInClass) {
									const studentWithData = {
										data: student,
									};

									const classAssessments = await assessments?.filter((assessment) => assessment?.classId === _class?.id);
									const currentStudentGrades = await generateUserGrades(currentSchool, studentWithData, classAssessments, null, _class?.id);

									const currentStudentTotalPercentage = currentStudentGrades?.reduce((accumulator, currentGrade) => {
										return accumulator + parseInt(currentGrade.grade);
									}, 0);

									let calculatedStudentTotalAverage = currentStudentTotalPercentage / currentStudentGrades?.length;
									calculatedStudentTotalAverage = calculatedStudentTotalAverage > 100 ? 100 : calculatedStudentTotalAverage;
									fetchedGrades?.push({
										studentId: student?.id,
										classId: _class?.id,
										average: calculatedStudentTotalAverage,
									});
								}
							}
						}

						const studentAveragesSum = {};

						for (const grade of fetchedGrades) {
							const { studentId, average } = grade;

							if (studentAveragesSum.hasOwnProperty(studentId)) {
								studentAveragesSum[studentId] += average;
							} else {
								studentAveragesSum[studentId] = average;
							}
						}

						setStudentGrades(fetchedGrades);
					}
				}
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}
		};

		fetchData();
	}, [appData?.debugMode, assessments, classes, currentSchool, students, classesLoading, studentsLoading]);

	const [classAveragePerformanceData, setClassAveragePerformanceData] = useState(null);
	const [classAveragePerformanceDataLoading, setClassAveragePerformanceDataLoading] = useState(true);

	useEffect(() => {
		let _classAveragePerformanceData = [];

		if (!classesLoading && !studentsLoading && studentGrades) {
			for (const studentGrade of studentGrades) {
				const { classId, average } = studentGrade;

				if (_classAveragePerformanceData.hasOwnProperty(classId)) {
					_classAveragePerformanceData[classId].total += average;
					_classAveragePerformanceData[classId].grades.push(average);
				} else {
					_classAveragePerformanceData[classId] = {
						total: average,
						grades: [average],
					};
				}
			}

			for (const classId in _classAveragePerformanceData) {
				const totalAverage = _classAveragePerformanceData[classId]?.total || 0;
				const grades = _classAveragePerformanceData[classId]?.grades || [];

				const numberOfStudents = grades.length;
				const classAverage = numberOfStudents > 0 ? totalAverage / numberOfStudents : 0;

				const lowestGrade = numberOfStudents > 0 ? Math.min(...grades) : 0;
				const highestGrade = numberOfStudents > 0 ? Math.max(...grades) : 0;

				_classAveragePerformanceData[classId] = {
					classId: classId,
					classAverage: classAverage,
					lowestAverage: lowestGrade,
					highestAverage: highestGrade,
				};
			}

			setClassAveragePerformanceData(_classAveragePerformanceData);
			setClassAveragePerformanceDataLoading(false);
		}
	}, [studentGrades, classes, subjects, classesLoading, studentsLoading]);

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<h3 className='mb-6 text-2xl font-bold text-theme-gray-800'>
					<GreetingMessage currentUser={currentUser} />
				</h3>

				<div className='grid grid-cols-5 gap-6'>
					<div className='col-span-5 md:col-span-1'>
						<div className='flex flex-col items-center justify-center bg-blue-500 rounded-lg h-62 w-62 p-8 shadow-lg'>
							<h3 className='text-5xl text-center font-bold text-white'>{numClassesLoading ? <Loader size='sm' /> : !numClassesLoading && numClasses ? numClasses : 'N/A'}</h3>

							<p className='mt-2 text-center text-white text-xl'>Classes</p>
						</div>
					</div>

					<div className='col-span-5 md:col-span-1'>
						<div className='flex flex-col items-center justify-center bg-blue-500 rounded-lg h-62 w-62 p-8 shadow-lg'>
							<h3 className='text-5xl text-center font-bold text-white'>{numSubjectsLoading ? <Loader size='sm' /> : !numSubjectsLoading && numSubjects ? numSubjects : 'N/A'}</h3>

							<p className='mt-2 text-center text-white text-xl'>Subjects</p>
						</div>
					</div>

					<div className='col-span-5 md:col-span-1'>
						<div className='flex flex-col items-center justify-center bg-blue-500 rounded-lg h-62 w-62 p-8 shadow-lg'>
							<h3 className='text-5xl text-center font-bold text-white'>{numStudentsLoading ? <Loader size='sm' /> : !numStudentsLoading && numStudents ? numStudents : 'N/A'}</h3>

							<p className='mt-2 text-center text-white text-xl'>Students</p>
						</div>
					</div>

					<div className='col-span-5 md:col-span-1'>
						<div className='flex flex-col items-center justify-center bg-blue-500 rounded-lg h-62 w-62 p-8 shadow-lg'>
							<h3 className='text-5xl text-center font-bold text-white'>{numTeachersLoading ? <Loader size='sm' /> : !numTeachersLoading && numTeachers ? numTeachers : 'N/A'}</h3>

							<p className='mt-2 text-center text-white text-xl'>Teachers</p>
						</div>
					</div>

					<div className='col-span-5 md:col-span-1'>
						<div className='flex flex-col items-center justify-center bg-blue-500 rounded-lg h-62 w-62 p-8 shadow-lg'>
							<h3 className='text-5xl text-center font-bold text-white'>{numParentsLoading ? <Loader size='sm' /> : !numParentsLoading && numParents ? numParents : 'N/A'}</h3>

							<p className='mt-2 text-center text-white text-xl'>Parents</p>
						</div>
					</div>
				</div>
			</section>

			<section className='w-full bg-theme-white'>
				<div className='grid grid-cols-12 gap-6'>
					<div className='col-span-12 md:col-span-8'>
						<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
							<div className='mb-4 flex flex-row items-center justify-between'>
								<h3 className='text-xl font-bold text-theme-gray-800'>Students w/ No Class</h3>
							</div>

							<Table
								name='Students'
								headContents={
									<tr>
										<TableHeadItem type='sort' title='Student ID' align='left' onClick={() => handleSortClick('id')} />

										<TableHeadItem type='sort' title='First Name' align='left' onClick={() => handleSortClick('name.first')} />

										<TableHeadItem type='sort' title='Last Name' align='left' onClick={() => handleSortClick('name.last')} />

										<TableHeadItem type='sort' title='Grade/Form' align='center' onClick={() => handleSortClick('school.gradeLevel')} />
									</tr>
								}
								bodyContents={({ item }) =>
									studentsTableContent({
										student: item,
										gradeLevels: gradeLevels,
									})
								}
								sortedData={sortedStudents}
								filteredData={sortedStudents}
								dataLoading={studentsLoading}
								colSpan={4}
							/>
						</div>
					</div>

					<div className='col-span-12 md:col-span-4 flex flex-col gap-6'>
						<div>
							<ChatsCard />
						</div>

						<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
							<div className='mb-4 flex flex-row items-center justify-between'>
								<h3 className='text-xl font-bold text-theme-gray-800'>Class Performance</h3>

								<Link type='button' to='/reports' className='inline-block shrink-0 rounded-md border border-blue-500 bg-blue-500 px-4 py-2 text-sm font-medium text-white transition-all duration-300 ease-in-out hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'>
									View All
								</Link>
							</div>

							<div className='w-full rounded-lg border border-theme-gray-200'>
								<div className='rounded-t-lg overflow-x-auto'>
									<table className='min-w-full rounded-lg divide-y-2 divide-theme-gray-200 bg-theme-white text-sm'>
										<thead className='w-full'>
											<th className='whitespace-nowrap px-4 py-2 font-medium text-left text-theme-gray-800'>Class ID</th>

											<th className='whitespace-nowrap px-4 py-2 font-medium text-center text-theme-gray-800'>Status</th>

											<th className='whitespace-nowrap px-4 py-2 font-medium text-center text-theme-gray-800'>Average (%)</th>
										</thead>

										<tbody className='w-full divide-y divide-theme-gray-200'>
											{!classAveragePerformanceDataLoading && classAveragePerformanceData && Object.keys(classAveragePerformanceData)?.length > 0 ? (
												Object.keys(classAveragePerformanceData)
													?.slice(0, 5)
													?.map((classId, idx) => {
														const classData = classAveragePerformanceData[classId];

														return (
															<tr key={idx} className='odd:bg-theme-gray-50'>
																<td className='whitespace-nowrap px-4 py-2 text-left text-theme-gray-600'>{classId}</td>

																<td className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>
																	{classData?.classAverage && classData?.classAverage >= currentSchool?.passMark ? (
																		<span className='rounded-full px-2.5 py-0.5 text-xs bg-green-600 text-white uppercase'>Pass</span>
																	) : classData?.classAverage && classData?.classAverage < currentSchool?.passMark ? (
																		<span className='rounded-full px-2.5 py-0.5 text-xs bg-red-600 text-white uppercase'>Fail</span>
																	) : (
																		<span className='rounded-full px-2.5 py-0.5 text-xs bg-gray-600 text-white uppercase'>Unassigned</span>
																	)}
																</td>

																<td className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>{classData?.classAverage ? classData?.classAverage?.toFixed(2) + '%' : 'N/A'}</td>
															</tr>
														);
													})
											) : (
												<tr>
													<td colSpan='3' className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>
														No data available
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='w-full bg-theme-white'>
				<div className='grid grid-cols-12 gap-6'>
					{/* <div className='col-span-12 md:col-span-8'>
						{!classesLoading && classes && classes?.length > 0 && classAveragePerformanceChartData && classAveragePerformanceChartOptions && (
							<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
								<div className='mb-4 flex flex-row items-center justify-between'>
									<h3 className='text-xl font-bold text-theme-gray-800'>Class Average Performance</h3>
								</div>

								<Bar data={classAveragePerformanceChartData} options={classAveragePerformanceChartOptions} />
							</div>
						)}
					</div> */}

					<div className='col-span-12 md:col-span-4 flex flex-col gap-6'>
						{/* {!classesLoading && classes && classes?.length > 0 && <PerformanceRanking initialSelectedClassId={classes[0]?.id} max={5} />} */}

						{/* <ChatsCard /> */}
					</div>
				</div>
			</section>
		</MainContainer>
	);
};

export default PrincipalDashboard;
