import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import { Link, useParams } from 'react-router-dom';
import { DataContext } from '../../context';
import { generatePDF } from '../../utils';
import { DeleteModal, MainContainer, MenuDropdown, Table, TableBodyItem, TableHeadItem } from '../../components';

const ClassesTableContent = ({ _class, currentSchool, subjects, teachers, setDeleteClassId, setDeleteClassModalState }) => {
	const subjectName = subjects?.find((subject) => subject?.id === _class?.subjectId)?.name;
	const teacher = teachers?.find((teacher) => teacher?.id === _class?.teacherId);
	const teacherName = teacher?.name?.first + ' ' + teacher?.name?.last;

	return (
		<>
			<TableBodyItem type='select' onClick={() => {}} />

			<TableBodyItem type='primary' contents={_class?.id ? <Link to={`/schools/${currentSchool?.id}/classes/${_class?.id}`}>{_class?.id}</Link> : 'N/A'} align='left' />

			<TableBodyItem type='default' contents={subjectName || 'N/A'} align='left' />

			<TableBodyItem type='default' contents={teacherName || 'N/A'} align='left' />

			<TableBodyItem type='default' contents={_class?.room || 'N/A'} align='center' />

			<TableBodyItem
				type='default'
				contents={_class?.duration?.semester === 1 ? <span className='rounded-full px-2.5 py-0.5 text-xs bg-blue-500 text-white uppercase'>Semester 1 - {_class?.duration?.year || 'N/A'}</span> : _class?.duration?.semester === 2 ? <span className='rounded-full px-2.5 py-0.5 text-xs bg-yellow-500 text-white uppercase'>Semester 2 - {_class?.duration?.year || 'N/A'}</span> : 'N/A'}
				align='center'
			/>

			<TableBodyItem
				type='dropdown'
				contents={
					<MenuDropdown
						type='table'
						menuItems={[
							[
								{
									type: 'link',
									danger: false,
									to: `/schools/${currentSchool?.id}/classes/${_class?.id}`,
									onClick: null,
									text: 'Edit',
									icon: (
										<>
											<path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
											<path fillRule='evenodd' d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z' />
										</>
									),
								},
								{
									type: 'link',
									danger: false,
									to: `mailto:${teacher?.emailAddress}`,
									newTab: false,
									onClick: null,
									text: 'Email Teacher',
									icon: <path d='M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z' />,
								},
							],
							[
								{
									type: 'button',
									danger: true,
									to: null,
									newTab: false,
									onClick: () => {
										setDeleteClassId(_class?.id);
										setDeleteClassModalState(true);
									},
									text: 'Delete',
									icon: (
										<path d='M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5' />
									),
								},
							],
						]}
					/>
				}
				align='center'
			/>
		</>
	);
};

const SchoolClasses = () => {
	const { schoolId } = useParams();

	useTitle(`${schoolId ? schoolId + ' ' : null}Classes | Grade Portal`);

	const { appData, divRef, handleScroll, users, schools, subjects } = useContext(DataContext);

	const [currentSchool, setCurrentSchool] = useState([]);
	const [classes, setClasses] = useState([]);
	const [teachers, setTeachers] = useState([]);

	const [currentSchoolLoading, setCurrentSchoolLoading] = useState(true);
	const [classesLoading, setClassesLoading] = useState(true);
	const [teachersLoading, setTeachersLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const fetchedSchool = schools?.find((school) => school?.id === schoolId);
				const fetchedClasses = currentSchool?.classes;
				const fetchedTeachers = users?.filter((user) => user?.userType === 'teacher');

				setCurrentSchool(fetchedSchool);
				setClasses(fetchedClasses);
				setTeachers(fetchedTeachers);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setCurrentSchoolLoading(false);
			setClassesLoading(false);
			setTeachersLoading(false);
		};

		fetchData();
	}, [appData?.debugMode, schools, users, currentSchool?.classes, schoolId]);

	const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState('asc');
	const [sortedClasses, setSortedClasses] = useState([]);

	const handleSortClick = (field) => {
		if (sortField === field) {
			setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
		} else {
			setSortField(field);
			setSortOrder('asc');
		}
	};

	useEffect(() => {
		const sortClasses = async () => {
			let sortedData = [...(classes ?? [])];

			if (sortField) {
				sortedData = sortedData?.sort((a, b) => {
					const valueA = a[sortField];
					const valueB = b[sortField];

					if (sortOrder === 'asc') {
						return valueA?.localeCompare(valueB, undefined, {
							numeric: true,
						});
					} else {
						return valueB?.localeCompare(valueA, undefined, {
							numeric: true,
						});
					}
				});
			}

			setSortedClasses(sortedData);
		};

		sortClasses();
	}, [classes, sortField, sortOrder]);

	const [filterOption, setFilterOption] = useState('ALL');

	const [classSearchQuery, setClassSearchQuery] = useState('');
	const [filteredClasses, setFilteredClasses] = useState([]);

	useEffect(() => {
		const filtered = classes
			?.filter((_class) => {
				const isSubjectIdInSubjects = (subjectId) => {
					return subjects?.some((subject) => subject?.id === subjectId);
				};

				if (filterOption === 'All') {
					return true;
				}

				if (isSubjectIdInSubjects(filterOption)) {
					return _class?.subjectId === filterOption;
				}

				return true;
			})
			?.filter((_class) => {
				const searchString = classSearchQuery?.toLowerCase();
				const subject = subjects?.find((subject) => subject?.id === _class?.subjectId);
				const teacher = teachers?.find((teacher) => teacher?.id === _class?.teacherId);

				return _class?.id?.toLowerCase().includes(searchString) || _class?.subjectId?.toLowerCase().includes(searchString) || subject?.name?.toLowerCase().includes(searchString) || teacher?.name?.first?.toLowerCase().includes(searchString) || teacher?.name?.last?.toLowerCase().includes(searchString);
			});

		setFilteredClasses(filtered);
	}, [classSearchQuery, classes, subjects, teachers, filterOption]);

	const [deleteClassId, setDeleteClassId] = useState(null);
	const [deleteClassModalState, setDeleteClassModalState] = useState(false);

	const handleDeleteClass = () => {};

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<div className='flex flex-row items-center justify-between'>
					<div className='flex flex-row items-center justify-start gap-4'>
						<h3 className='text-lg font-bold text-theme-gray-800 sm:text-xl'>Classes</h3>

						<select
							value={filterOption}
							onChange={(e) => {
								e.preventDefault();
								setFilterOption(e.target.value);
							}}
							className='w-full rounded-lg bg-theme-white border-theme-gray-200 text-theme-gray-800 sm:text-sm'>
							<option value='ALL'>All Classes</option>

							{subjects
								?.sort((a, b) => a.name.localeCompare(b.name))
								?.map((subject, idx) => {
									return (
										<option key={idx} value={subject.id}>
											{subject.name}
										</option>
									);
								})}
						</select>

						<div className='w-full'>
							<label htmlFor='classSearch' className='sr-only'>
								Search
							</label>

							<div className='relative'>
								<div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
									<svg className='w-4 h-4 text-theme-gray-800' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
										<path d='M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z' fill='none' stroke='currentColor' strokeMiterlimit='10' strokeWidth='32' />
										<path fill='none' stroke='currentColor' strokeLinecap='round' strokeMiterlimit='10' strokeWidth='32' d='M338.29 338.29L448 448' />
									</svg>
								</div>

								<form>
									<input
										type='search'
										onChange={(e) => {
											e.preventDefault();
											setClassSearchQuery(e.target.value);
										}}
										className='min-w-64 w-full ps-10 rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'
										id='classSearch'
										placeholder='Search classes'
									/>
								</form>
							</div>
						</div>
					</div>

					<div className='flex flex-row items-center justify-end gap-4'>
						<Link type='button' to={`/schools/${schoolId}/classes/new`} className='inline-block shrink-0 rounded-md border border-blue-500 bg-blue-500 px-4 py-2 text-sm font-medium text-white transition-all duration-300 ease-in-out hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'>
							New Class
						</Link>

						<MenuDropdown
							type='download'
							menuItems={[
								[
									{
										type: 'button',
										danger: false,
										to: null,
										onClick: (e) => {
											e.preventDefault();
											generatePDF('Classes');
										},
										text: 'Download PDF',
										icon: (
											<path
												fillRule='evenodd'
												d='M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z'
											/>
										),
									},
									{
										type: 'button',
										danger: false,
										to: null,
										onClick: null,
										text: 'Download CSV',
										icon: (
											<path
												fillRule='evenodd'
												d='M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM3.517 14.841a1.13 1.13 0 0 0 .401.823q.195.162.478.252.284.091.665.091.507 0 .859-.158.354-.158.539-.44.187-.284.187-.656 0-.336-.134-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.566-.21l-.621-.144a1 1 0 0 1-.404-.176.37.37 0 0 1-.144-.299q0-.234.185-.384.188-.152.512-.152.214 0 .37.068a.6.6 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.2-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.439 0-.776.15-.337.149-.527.421-.19.273-.19.639 0 .302.122.524.124.223.352.367.228.143.539.213l.618.144q.31.073.463.193a.39.39 0 0 1 .152.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.167.07-.413.07-.175 0-.32-.04a.8.8 0 0 1-.248-.115.58.58 0 0 1-.255-.384zM.806 13.693q0-.373.102-.633a.87.87 0 0 1 .302-.399.8.8 0 0 1 .475-.137q.225 0 .398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.4 1.4 0 0 0-.489-.272 1.8 1.8 0 0 0-.606-.097q-.534 0-.911.223-.375.222-.572.632-.195.41-.196.979v.498q0 .568.193.976.197.407.572.626.375.217.914.217.439 0 .785-.164t.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.8.8 0 0 1-.118.363.7.7 0 0 1-.272.25.9.9 0 0 1-.401.087.85.85 0 0 1-.478-.132.83.83 0 0 1-.299-.392 1.7 1.7 0 0 1-.102-.627zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879z'
											/>
										),
									},
								],
							]}
						/>
					</div>
				</div>
			</section>

			<section className='w-full bg-theme-white'>
				<Table
					name='Classes'
					headContents={
						<tr>
							<TableHeadItem type='select' onClick={() => {}} />

							<TableHeadItem type='sort' title='Class ID' align='left' onClick={() => handleSortClick('id')} />

							<TableHeadItem type='sort' title='Subject' align='left' onClick={() => handleSortClick('subjectId')} />

							<TableHeadItem type='sort' title='Teacher' align='left' onClick={() => handleSortClick('')} />

							<TableHeadItem type='default' title='Room' align='center' onClick={() => handleSortClick('')} />

							<TableHeadItem type='sort' title='Semester' align='center' onClick={() => handleSortClick('')} />

							<TableBodyItem type='dropdown' />
						</tr>
					}
					bodyContents={({ item }) => <ClassesTableContent _class={item} currentSchool={currentSchool} subjects={subjects} teachers={teachers} setDeleteClassId={setDeleteClassId} setDeleteClassModalState={setDeleteClassModalState} />}
					sortedData={sortedClasses}
					filteredData={filteredClasses}
					dataLoading={classesLoading || currentSchoolLoading || teachersLoading}
					colSpan={7}
				/>
			</section>

			<DeleteModal title='Class' deleteId={deleteClassId} handleDelete={handleDeleteClass} deleteModalState={deleteClassModalState} setDeleteModalState={setDeleteClassModalState} />
		</MainContainer>
	);
};

export default SchoolClasses;
