import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTitle } from 'react-use';
import { AuthContext, DataContext } from '../../context';
import { Loader, MainContainer } from '../../components';
import { ErrorHandling, generateNewClassID, useAlertHandling, useInputWithError } from '../../utils';

const NewClass = () => {
	useTitle('New Class | Grade Portal');

	const { currentUser } = useContext(AuthContext);
	const { appData, currentSchool, divRef, handleScroll, subjects, users, classStatusOptions, semesterData, createNewClass } = useContext(DataContext);
	const { showAlert } = useAlertHandling();

	const [canAccessClass, setCanAccessClass] = useState(false);

	useEffect(() => {
		if (currentUser?.data?.userType === 'principal') {
			setCanAccessClass(true);
		} else {
			setCanAccessClass(false);
		}
	}, [currentUser?.data?.userType]);

	const [teachers, setTeachers] = useState([]);
	const [teachersLoading, setTeachersLoading] = useState(true);

	useEffect(() => {
		const fetchTeachers = async () => {
			try {
				const fetchedTeachers = await users?.filter((user) => user?.userType === 'teacher' && user?.school?.id === currentSchool?.id);
				setTeachers(fetchedTeachers);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setTeachersLoading(false);
		};

		fetchTeachers();
	}, [appData?.debugMode, users, currentSchool?.id, currentUser?.data?.userType]);

	const { value: subject, setValue: setSubject, error: subjectError, setError: setSubjectError } = useInputWithError(null);
	const { value: teacher, setValue: setTeacher, error: teacherError, setError: setTeacherError } = useInputWithError(null);

	const { value: room, setValue: setRoom, error: roomError, setError: setRoomError } = useInputWithError(null);
	const { value: status, setValue: setStatus, error: statusError, setError: setStatusError } = useInputWithError(null);

	const { value: semester, setValue: setSemester, error: semesterError, setError: setSemesterError } = useInputWithError(null);
	const { value: year, setValue: setYear, error: yearError, setError: setYearError } = useInputWithError(null);

	const [handleNewClassLoading, setHandleNewClassLoading] = useState(false);

	const handleNewClass = async (e) => {
		e.preventDefault();
		setHandleNewClassLoading(true);

		let newClassData = {
			id: generateNewClassID(currentSchool?.id),
			subjectId: subject,
			teacherId: teacher,
			room: room,
			status: status,
			duration: {
				semester: semester,
				year: year,
			},
			creationDate: new Date().toISOString().split('T')[0],
			addedBy: currentUser?.data?.id,
		};

		if (
			(await ErrorHandling.handleClassSubjectError(subject, setSubjectError, showAlert)) ||
			(await ErrorHandling.handleClassTeacherError(teacher, setTeacherError, showAlert)) ||
			(await ErrorHandling.handleClassRoomError(room, setRoomError, showAlert)) ||
			(await ErrorHandling.handleClassStatusError(status, setStatusError, showAlert)) ||
			(await ErrorHandling.handleClassSemesterError(semester, setSemesterError, showAlert)) ||
			(await ErrorHandling.handleClassYearError(year, setYearError, showAlert))
		) {
			setHandleNewClassLoading(false);
		} else {
			try {
				await createNewClass(currentSchool?.id, newClassData);

				window.location.href = `/classes/${newClassData?.id}`;
			} catch (error) {
				if (appData.debugMode) {
					console.error(error);
				}

				showAlert('error', 'An unknown error occurred');
			}
		}

		setHandleNewClassLoading(false);
	};

	if (!canAccessClass) {
		return (
			<MainContainer divRef={divRef} handleScroll={handleScroll}>
				<section className='bg-theme-white py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
					<div className='mx-auto max-w-screen-sm text-center'>
						<h1 className='mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-theme-gray-800'>403</h1>

						<p className='mb-4 text-3xl tracking-tight font-bold text-blue-500 md:text-4xl'>Oops! Access Denied</p>

						<p className='mb-8 text-lg text-theme-gray-600'>It seems that the page you're trying to access isn't available.</p>

						<Link to='/' className='inline-block shrink-0 rounded-md border px-5 py-2.5 text-sm font-medium focus:outline-none focus:ring text-white border-blue-500 bg-blue-500 hover:bg-transparent hover:text-blue-500 active:text-blue-500'>
							Back to Homepage
						</Link>
					</div>
				</section>
			</MainContainer>
		);
	}

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<h3 className='text-2xl font-bold text-theme-gray-800'>New Class</h3>
			</section>

			<section className='w-full bg-theme-white'>
				<form autoComplete='off'>
					<div className='grid grid-cols-12 gap-4 mt-4'>
						<div className='w-full col-span-12 sm:col-span-6'>
							<label htmlFor='subject' className='block text-sm font-medium text-theme-gray-800'>
								Subject
								<span className='text-red-500 ml-1'>*</span>
							</label>

							<select
								onChange={(e) => {
									e.preventDefault();

									setSubject(e.target.value);
									setSubjectError(null);
								}}
								className={`mt-1 w-full rounded-md border ${subjectError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
								id='subject'
								value={subject || ''}>
								<option value='' disabled>
									Select a subject
								</option>

								{subjects
									?.slice()
									.sort((a, b) => {
										return a?.name?.localeCompare(b?.name);
									})
									?.map((subject, idx) => {
										return (
											<option key={idx} value={subject?.id}>
												{subject?.name}
											</option>
										);
									})}
							</select>

							{subjectError && <span className='mt-2 text-sm text-red-500'>{subjectError}</span>}
						</div>

						<div className='w-full col-span-12 sm:col-span-6'>
							<label htmlFor='teacher' className='block text-sm font-medium text-theme-gray-800'>
								Teacher
								<span className='text-red-500 ml-1'>*</span>
							</label>

							<select
								onChange={(e) => {
									e.preventDefault();

									setTeacher(e.target.value);
									setTeacherError(null);
								}}
								className={`mt-1 w-full rounded-md border ${teacherError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
								id='teacher'
								defaultValue={teacher || ''}>
								<option value='' disabled>
									Select a teacher
								</option>

								{!teachersLoading &&
									teachers
										?.slice()
										.sort((a, b) => {
											return a?.name?.last?.localeCompare(b?.name?.last);
										})
										?.map((teacher, idx) => {
											return (
												<option key={idx} value={teacher?.id}>
													{teacher?.name?.first} {teacher?.name?.last} ({teacher?.id})
												</option>
											);
										})}
							</select>

							{teacherError && <span className='mt-2 text-sm text-red-500'>{teacherError}</span>}
						</div>

						<div className='w-full col-span-12 sm:col-span-6'>
							<label htmlFor='room' className='block text-sm font-medium text-theme-gray-800'>
								Room
								<span className='text-red-500 ml-1'>*</span>
							</label>

							<input
								type='number'
								onChange={(e) => {
									e.preventDefault();

									setRoom(e.target.value);
									setRoomError(null);
								}}
								className={`mt-1 w-full rounded-md border ${roomError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
								id='room'
								placeholder='10'
								min={0}
								max={1000}
								required
							/>

							{roomError && <span className='mt-2 text-sm text-red-500'>{roomError}</span>}
						</div>

						<div className='w-full col-span-12 sm:col-span-6'>
							<label htmlFor='status' className='block text-sm font-medium text-theme-gray-800'>
								Status
								<span className='text-red-500 ml-1'>*</span>
							</label>

							<select
								onChange={(e) => {
									e.preventDefault();

									setStatus(e.target.value);
									setStatusError(null);
								}}
								className={`mt-1 w-full rounded-md border ${statusError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
								id='status'
								value={status || ''}>
								<option value='' disabled>
									Select a status
								</option>

								{classStatusOptions?.map((classStatus, idx) => {
									return (
										<option key={idx} value={classStatus?.name}>
											{classStatus?.name} - {classStatus?.desc}
										</option>
									);
								})}
							</select>

							{statusError && <span className='mt-2 text-sm text-red-500'>{statusError}</span>}
						</div>

						<div className='w-full col-span-12 sm:col-span-6'>
							<label htmlFor='semester' className='block text-sm font-medium text-theme-gray-800'>
								Semester
								<span className='text-red-500 ml-1'>*</span>
							</label>

							<select
								onChange={(e) => {
									e.preventDefault();

									setSemester(e.target.value);
									setSemesterError(null);
								}}
								className={`mt-1 w-full rounded-md border ${semesterError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
								id='semester'
								value={semester || ''}>
								<option value='' disabled>
									Select a semester
								</option>

								{semesterData?.map((semester, idx) => {
									const startDate = `${semester.startDate.day}/${semester.startDate.month}`;
									const endDate = `${semester.endDate.day}/${semester.endDate.month}`;

									return (
										<option key={idx} value={semester?.name}>
											{`Semester ${semester.name}: ${startDate} - ${endDate}`}
										</option>
									);
								})}
							</select>

							{semesterError && <span className='mt-2 text-sm text-red-500'>{semesterError}</span>}
						</div>

						<div className='w-full col-span-12 sm:col-span-6'>
							<label htmlFor='year' className='block text-sm font-medium text-theme-gray-800'>
								Year
								<span className='text-red-500 ml-1'>*</span>
							</label>

							<select
								onChange={(e) => {
									e.preventDefault();

									setYear(e.target.value);
									setYearError(null);
								}}
								className={`mt-1 w-full rounded-md border ${yearError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
								id='year'
								value={year || ''}>
								<option value='' disabled>
									Select a year
								</option>

								{(() => {
									const currentYear = new Date().getFullYear();
									const tenYearsAgo = currentYear - 2;
									const tenYearsLater = currentYear + 10;

									const yearOptions = [];

									for (let year = tenYearsAgo; year <= tenYearsLater; year++) {
										yearOptions?.push(
											<option key={year} value={year}>
												{year}
											</option>
										);
									}

									return yearOptions;
								})()}
							</select>

							{yearError && <span className='mt-2 text-sm text-red-500'>{yearError}</span>}
						</div>
					</div>

					<hr className='my-8' />

					<div className='w-full mt-4 col-span-6 flex flex-col gap-4 sm:flex-row sm:items-center'>
						<button
							type='submit'
							onClick={handleNewClass}
							disabled={handleNewClassLoading}
							className={`inline-flex items-center justify-center shrink-0 rounded-md border border-blue-500 bg-blue-500 text-white px-4 py-2 gap-2 text-sm font-medium ${handleNewClassLoading ? 'opacity-60 cursor-not-allowed' : 'hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'}`}>
							{handleNewClassLoading ? (
								<div className='flex items-center justify-center'>
									<Loader />
								</div>
							) : (
								'Create New Class'
							)}
						</button>
					</div>
				</form>
			</section>
		</MainContainer>
	);
};

export default NewClass;
