import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import { Link, useParams } from 'react-router-dom';
import { DataContext, AuthContext } from '../../context';
import { numberToWord, useAlertHandling } from '../../utils';
import { DeleteModal, MainContainer, Table, TableBodyItem, TableHeadItem } from '../../components';

const ClassesTableContent = ({ _class, currentSchool, subjects, teachers }) => {
	const subjectName = subjects?.find((subject) => subject?.id === _class?.subjectId)?.name;
	const teacher = teachers?.find((teacher) => teacher?.id === _class?.teacherId);
	const teacherName = teacher?.name?.first + ' ' + teacher?.name?.last;

	return (
		<>
			<TableBodyItem type='primary' contents={_class?.id ? <Link to={`/schools/${currentSchool?.id}/classes/${_class?.id}`}>{_class?.id}</Link> : 'N/A'} align='left' />

			<TableBodyItem type='default' contents={subjectName || 'N/A'} align='left' />

			<TableBodyItem type='default' contents={teacherName || 'N/A'} align='left' />

			<TableBodyItem type='default' contents={_class?.room || 'N/A'} align='center' />
		</>
	);
};

const TeachersTableContent = ({ teacher, currentSchool }) => {
	const teacherName = teacher?.name?.first + ' ' + teacher?.name?.last;

	return (
		<>
			<TableBodyItem type='primary' contents={teacher?.id ? <Link to={`/schools/${currentSchool?.id}/classes/${teacher?.id}`}>{teacher?.id}</Link> : 'N/A'} align='left' />

			<TableBodyItem type='default' contents={teacherName || 'N/A'} align='left' />

			<TableBodyItem type='default' contents={teacher?.sex || 'N/A'} align='center' />

			<TableBodyItem
				type='default'
				contents={
					teacher?.emailAddress ? (
						<a href={'mailto:' + teacher?.emailAddress} className='text-blue-500 hover:opacity-80' target='_blank' rel='noopener noreferrer'>
							{teacher?.emailAddress}
						</a>
					) : (
						'N/A'
					)
				}
				align='left'
			/>
		</>
	);
};

const School = () => {
	const { schoolId, classId } = useParams();

	useTitle(`${schoolId ? schoolId : 'School'} | Grade Portal`);

	const { currentUser } = useContext(AuthContext);
	const { appData, divRef, handleScroll, schools, subjects, assessments, users } = useContext(DataContext);

	const [currentSchool, setCurrentSchool] = useState([]);
	const [classes, setClasses] = useState([]);
	const [teachers, setTeachers] = useState([]);
	const [school, setSchool] = useState(null);
	const [principal, setPrincipal] = useState(null);

	const [currentSchoolLoading, setCurrentSchoolLoading] = useState(true);
	const [classesLoading, setClassesLoading] = useState(true);
	const [teachersLoading, setTeachersLoading] = useState(true);
	const [schoolLoading, setSchoolLoading] = useState(true);
	const [principalLoading, setPrincipalLoading] = useState(true);

	const [canAccessSchool, setCanAccessSchool] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const fetchedSchool = await schools?.find((school) => school?.id === schoolId);
				const fetchedClasses = await currentSchool?.classes;
				const fetchedTeachers = await users?.filter((user) => user?.userType === 'teacher' && user?.school?.id === schoolId);
				const fetchedPrincipal = await users?.find((user) => user?.userType === 'principal' && user?.school?.id === schoolId);

				setPrincipal(fetchedPrincipal);
				setSchool(fetchedSchool);
				setCurrentSchool(fetchedSchool);
				setClasses(fetchedClasses);
				setTeachers(fetchedTeachers);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setSchoolLoading(false);
			setCurrentSchoolLoading(false);
			setClassesLoading(false);
			setTeachersLoading(false);
		};

		fetchData();
	}, [currentUser?.data?.id, currentSchool?.classes, subjects, assessments, users, schoolId, schools, appData?.debugMode]);

	useEffect(() => {
		if (!schoolLoading) {
			const fetchPrincipal = async () => {
				try {
					// const fetchedPrincipal = await users?.filter((user) => user?.userType === fetchedSchool.principal);
					// setPrincipal(fetchedPrincipal);
				} catch (err) {
					if (appData?.debugMode) {
						console.error(err);
					}
				}

				setPrincipalLoading(false);
			};

			if (school) {
				fetchPrincipal();
				setCanAccessSchool(true);
			} else {
				setCanAccessSchool(false);
			}
		}
	}, [schools, appData?.debugMode, classId, users, school, schoolLoading, subjects]);

	const [deleteSchoolId, setDeleteSchoolId] = useState(null);
	const [deleteSchoolModalState, setDeleteSchoolModalState] = useState(false);

	const handleDeleteSchool = () => {
		setDeleteSchoolModalState(false);
	};

	//

	if (!canAccessSchool) {
		return (
			<MainContainer divRef={divRef} handleScroll={handleScroll}>
				<section className='bg-theme-white py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
					<div className='mx-auto max-w-screen-sm text-center'>
						<h1 className='mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-theme-gray-800'>404</h1>

						<p className='mb-4 text-3xl tracking-tight font-bold text-blue-500 md:text-4xl'>Oops! School Not Found</p>

						<p className='mb-8 text-lg text-theme-gray-600'>
							It seems that the school you're trying to access isn't available. Don't worry, you can explore all schools{' '}
							<Link to='/schools' className='focus:outline-none focus:ring text-blue-500 hover:opacity-80 active:opacity-80'>
								here
							</Link>
							.
						</p>

						<Link to='/' className='inline-block shrink-0 rounded-md border px-5 py-2.5 text-sm font-medium focus:outline-none focus:ring text-white border-blue-500 bg-blue-500 hover:bg-transparent hover:text-blue-500 active:text-blue-500'>
							Back to Homepage
						</Link>
					</div>
				</section>
			</MainContainer>
		);
	}

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<h3 className='text-2xl font-bold text-theme-gray-800'>{!schoolLoading && school && `${school?.name}`}</h3>
			</section>

			<section className='w-full bg-theme-white'>
				<div className='grid grid-cols-6 gap-6'>
					<div className='col-span-6 grid grid-cols-6 gap-6'>
						<div className='col-span-6 md:col-span-3'>
							<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
								<div className='mb-4 flex flex-row items-center justify-between'>
									<h3 className='text-xl font-bold text-theme-gray-800'>School Details</h3>

									<button
										type='button'
										onClick={() => {
											setDeleteSchoolId(school?.id);
											setDeleteSchoolModalState(true);
										}}
										className='inline-block shrink-0 rounded-md border border-red-500 bg-red-500 px-4 py-2 text-sm font-medium text-white hover:bg-transparent hover:text-red-500 focus:outline-none focus:ring ring-red-300 active:text-red-500'>
										Delete School
									</button>
								</div>

								<div className='flow-root'>
									<dl className='-my-3 divide-y divide-theme-gray-200 text-sm'>
										<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-12 md:gap-4'>
											<dt className='font-bold text-theme-gray-800 col-span-12 md:col-span-3'>Name</dt>

											<dd className='text-theme-gray-800 col-span-4 md:col-span-9'>{schoolLoading ? 'Loading...' : school?.name || 'N/A'}</dd>
										</div>

										<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-12 md:gap-4'>
											<dt className='font-bold text-theme-gray-800 col-span-12 md:col-span-3'>District</dt>

											<dd className='text-theme-gray-800 col-span-4 md:col-span-9'>{schoolLoading ? 'Loading...' : numberToWord(school?.district) || 'N/A'}</dd>
										</div>

										<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-12 md:gap-4'>
											<dt className='font-bold text-theme-gray-800 col-span-12 md:col-span-3'>Level</dt>

											<dd className='text-theme-gray-800 col-span-4 md:col-span-9'>
												{schoolLoading ? 'Loading...' : school?.level === '1' ? <span className='rounded-full px-2.5 py-0.5 text-xs bg-blue-500 text-white uppercase'>Primary</span> : school?.level === '2' ? <span className='rounded-full px-2.5 py-0.5 text-xs bg-yellow-500 text-white uppercase'>Secondary</span> : 'N/A'}
											</dd>
										</div>

										<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-12 md:gap-4'>
											<dt className='font-bold text-theme-gray-800 col-span-12 md:col-span-3'>Pass mark</dt>

											<dd className='text-theme-gray-800 col-span-4 md:col-span-9'>{schoolLoading ? 'Loading...' : school?.passMark + '%' || 'N/A'}</dd>
										</div>

										<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-12 md:gap-4'>
											<dt className='font-bold text-theme-gray-800 col-span-12 md:col-span-3'>Email address</dt>

											<dd className='text-theme-gray-800 col-span-4 md:col-span-9'>
												{schoolLoading ? (
													'Loading...'
												) : !schoolLoading && school?.emailAddress ? (
													<a href={'mailto:' + school?.emailAddress} className='text-blue-500 hover:opacity-80' target='_blank' rel='noopener noreferrer'>
														{school?.emailAddress}
													</a>
												) : (
													'N/A'
												)}
											</dd>
										</div>

										<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-12 md:gap-4'>
											<dt className='font-bold text-theme-gray-800 col-span-12 md:col-span-3'>Phone number</dt>

											<dd className='text-theme-gray-800 col-span-4 md:col-span-9'>
												{schoolLoading ? (
													'Loading...'
												) : !schoolLoading && school?.phone ? (
													<a href={'tel:' + school?.phone} className='text-blue-500 hover:opacity-80' target='_blank' rel='noopener noreferrer'>
														{school?.phone}
													</a>
												) : (
													'N/A'
												)}
											</dd>
										</div>

										<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-12 md:gap-4'>
											<dt className='font-bold text-theme-gray-800 col-span-12 md:col-span-3'>Website</dt>

											<dd className='text-theme-gray-800 col-span-4 md:col-span-9'>
												{schoolLoading ? (
													'Loading...'
												) : !schoolLoading && school?.website ? (
													<a href={school?.website} className='text-blue-500 hover:opacity-80' target='_blank' rel='noopener noreferrer'>
														{school?.website}
													</a>
												) : (
													'N/A'
												)}
											</dd>
										</div>
									</dl>
								</div>
							</div>
						</div>

						<div className='col-span-6 md:col-span-3'>
							<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
								<div className='mb-4 flex flex-row items-center justify-between'>
									<h3 className='text-xl font-bold text-theme-gray-800'>Principal Details</h3>

									<Link type='button' to={``} className='inline-block shrink-0 rounded-md border border-blue-500 bg-blue-500 px-4 py-2 text-sm font-medium text-white hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'>
										Add Principal
									</Link>
								</div>

								<div className='flow-root'>
									<dl className='-my-3 divide-y divide-theme-gray-200 text-sm'>
										<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-12 md:gap-4'>
											<dt className='font-bold text-theme-gray-800 col-span-12 md:col-span-3'>First name:</dt>

											<dd className='text-theme-gray-800 col-span-4 md:col-span-9'>{principalLoading ? 'Loading...' : principal?.name?.first || 'N/A'}</dd>
										</div>

										<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-12 md:gap-4'>
											<dt className='font-bold text-theme-gray-800 col-span-12 md:col-span-3'>Last name:</dt>

											<dd className='text-theme-gray-800 col-span-4 md:col-span-9'>{principalLoading ? 'Loading...' : principal?.name?.last || 'N/A'}</dd>
										</div>

										<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-12 md:gap-4'>
											<dt className='font-bold text-theme-gray-800 col-span-12 md:col-span-3'>Email address:</dt>

											<dd className='text-theme-gray-800 col-span-4 md:col-span-9'>
												{principalLoading ? (
													'Loading...'
												) : (
													<a href={`mailto:${principal?.emailAddress}`} className='text-blue-500 hover:opacity-80'>
														{principal?.emailAddress || 'N/A'}
													</a>
												)}
											</dd>
										</div>
									</dl>
								</div>
							</div>
						</div>
					</div>

					<div className='col-span-3'>
						<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
							<div className='mb-4 flex flex-row items-center justify-between'>
								<h3 className='text-xl font-bold text-theme-gray-800'>Classes</h3>

								<Link type='button' to={`/schools/${schoolId}/classes/new`} className='inline-block shrink-0 rounded-md border border-blue-500 bg-blue-500 px-4 py-2 text-sm font-medium text-white hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'>
									New Class
								</Link>
							</div>

							<Table
								name='Classes'
								headContents={
									<tr>
										<TableHeadItem type='default' title='Class ID' align='left' />

										<TableHeadItem type='default' title='Subject' align='left' />

										<TableHeadItem type='default' title='Teacher' align='left' />

										<TableHeadItem type='default' title='Room' align='center' />
									</tr>
								}
								bodyContents={({ item }) => <ClassesTableContent _class={item} currentSchool={currentSchool} subjects={subjects} teachers={teachers} />}
								sortedData={classes}
								filteredData={classes}
								dataLoading={classesLoading || currentSchoolLoading || teachersLoading}
								colSpan={7}
							/>
						</div>
					</div>

					<div className='col-span-3'>
						<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
							<div className='mb-4 flex flex-row items-center justify-between'>
								<h3 className='text-xl font-bold text-theme-gray-800'>Teachers</h3>

								<button type='button' onClick={() => {}} className='inline-block shrink-0 rounded-md border border-blue-500 bg-blue-500 px-4 py-2 text-sm font-medium text-white hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'>
									Add Teacher
								</button>
							</div>

							<Table
								name='Teachers'
								headContents={
									<tr>
										<TableHeadItem type='default' title='User ID' align='left' />

										<TableHeadItem type='default' title='Name' align='left' />

										<TableHeadItem type='default' title='Sex' align='center' />

										<TableHeadItem type='default' title='Email' align='left' />
									</tr>
								}
								bodyContents={({ item }) => <TeachersTableContent teacher={item} currentSchool={currentSchool} />}
								sortedData={teachers}
								filteredData={teachers}
								dataLoading={currentSchoolLoading || teachersLoading}
								colSpan={4}
							/>
						</div>
					</div>
				</div>
			</section>

			<DeleteModal title='School' deleteId={deleteSchoolId} handleDelete={handleDeleteSchool} deleteModalState={deleteSchoolModalState} setDeleteModalState={setDeleteSchoolModalState} />
		</MainContainer>
	);
};

export default School;
