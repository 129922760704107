import React, { useContext, useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import { AuthContext, DataContext } from '../../context';
import { Loader, GreetingMessage, MainContainer, Table, TableHeadItem, TableBodyItem } from '../../components';
import { calculateGPAFromPercentage, generateUserGrades } from '../../utils';

const childSemesterGradesTableContent = ({ child }) => {
	const childName = child?.userData?.name?.first + ' ' + child?.userData?.name?.last;

	return (
		<>
			<TableBodyItem type='primary' contents={child?.userData?.id || 'N/A'} />

			<TableBodyItem type='default' contents={childName || 'N/A'} align='left' />

			<TableBodyItem type='default' contents={child?.school?.name || 'N/A'} align='left' />

			<TableBodyItem type='default' contents={child?.userData?.school?.gradeLevel || 'N/A'} align='center' />

			<TableBodyItem
				type='default'
				contents={
					child?.grades?.average >= child?.school?.passMark ? (
						<span className='rounded-full px-2.5 py-0.5 text-xs bg-green-600 text-white uppercase'>Pass</span>
					) : child?.grades?.average < child?.school?.passMark ? (
						<span className='rounded-full px-2.5 py-0.5 text-xs bg-red-600 text-white uppercase'>Fail</span>
					) : (
						<span className='rounded-full px-2.5 py-0.5 text-xs bg-gray-600 text-white uppercase'>Unassigned</span>
					)
				}
				align='center'
			/>

			<TableBodyItem type='default' contents={child?.grades?.semesterGPA <= 0 || isNaN(child?.grades?.semesterGPA) ? 'N/A' : child?.grades?.semesterGPA > 0 ? child?.grades?.semesterGPA?.toFixed(2) : 'N/A'} align='center' />

			<TableBodyItem type='default' contents={child?.grades?.cumulativeGPA <= 0 || isNaN(child?.grades?.cumulativeGPA) ? 'N/A' : child?.grades?.cumulativeGPA > 0 ? child?.grades?.cumulativeGPA?.toFixed(2) : 'N/A'} align='center' />

			<TableBodyItem type='default' contents={child?.grades?.average <= 0 || isNaN(child?.grades?.average) ? 'N/A' : child?.grades?.average > 0 ? Math.round(child?.grades?.average) + '%' : 'N/A'} align='center' />
		</>
	);
};

const childClassGradesTableContent = ({ grade, assessments, subjects, classes, users }) => {
	const childName = grade?.name?.first + ' ' + grade?.name?.last;
	const _class = classes?.find((_class) => _class?.id === grade?.grade?.classId);
	const subjectName = _class ? subjects?.find((subject) => subject?.id === _class.subjectId)?.name : 'N/A';

	const assessmentName = assessments?.find((assessment) => assessment?.id === grade?.grade?.assessmentId)?.name;
	const assessmentTotal = assessments?.find((assessment) => assessment?.id === grade?.grade?.assessmentId)?.grading?.totalPoints;
	const percentage = parseFloat(((grade?.grade?.grade / assessmentTotal) * 100).toFixed(2)) + '%';

	const teacher = users?.find((user) => user.id === grade?.grade?.addedBy);
	const teacherName = teacher && teacher?.name ? teacher?.name?.first + ' ' + teacher?.name?.last : grade?.grade?.addedBy;

	return (
		<>
			<TableBodyItem type='primary' contents={grade?.id || 'N/A'} />

			<TableBodyItem type='default' contents={childName || 'N/A'} align='left' />

			<TableBodyItem type='default' contents={teacherName || 'N/A'} align='left' />

			<TableBodyItem type='default' contents={grade?.grade?.classId || 'N/A'} align='left' />

			<TableBodyItem type='default' contents={subjectName || 'N/A'} align='left' />

			<TableBodyItem type='default' contents={assessmentName || 'N/A'} align='left' />

			<TableBodyItem type='default' contents={percentage || 'N/A'} align='center' />
		</>
	);
};

const ParentDashboard = () => {
	useTitle('Parent Dashboard | Grade Portal');

	const { currentUser } = useContext(AuthContext);
	const { appData, divRef, handleScroll, users, schools, subjects, assessments, currentSemesterDuration } = useContext(DataContext);

	const [children, setChildren] = useState([]);
	const [numChildren, setNumChildren] = useState(null);
	const [numSchools, setNumSchools] = useState(null);
	const [numClasses, setNumClasses] = useState(null);

	const [childrenLoading, setChildrenLoading] = useState(true);
	const [numChildrenLoading, setNumChildrenLoading] = useState(true);
	const [numSchoolsLoading, setNumSchoolsLoading] = useState(true);
	const [numClassesLoading, setNumClassesLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const fetchedChildren = await currentUser?.data?.children;
				let _children = [];

				for (const child of fetchedChildren) {
					const fetchedChild = await users?.find((user) => user?.id === child);
					const fetchedCurrentSchool = await schools?.find((school) => school?.id === fetchedChild?.school?.id);

					const fetchedChildWithData = {
						data: fetchedChild,
					};

					const allUserGrades = await generateUserGrades(await fetchedCurrentSchool, fetchedChildWithData, await assessments, null, null);
					const semesterUserGrades = await generateUserGrades(await fetchedCurrentSchool, fetchedChildWithData, await assessments, currentSemesterDuration, null);

					const totalPercentage = allUserGrades?.reduce((accumulator, currentGrade) => {
						return accumulator + parseInt(currentGrade?.grade);
					}, 0);

					const semesterPercentage = semesterUserGrades?.reduce((accumulator, currentGrade) => {
						return accumulator + parseInt(currentGrade?.grade);
					}, 0);

					let calculatedTotalAverage = totalPercentage / allUserGrades?.length;
					calculatedTotalAverage = calculatedTotalAverage > 100 ? 100 : calculatedTotalAverage;


					const calculatedSemesterAverage = semesterPercentage / semesterUserGrades?.length;

					const calculatedCumulativeGPA = calculateGPAFromPercentage(calculatedTotalAverage);
					const calculatedSemesterGPA = calculateGPAFromPercentage(calculatedSemesterAverage);

					_children?.push({
						userData: fetchedChild,
						school: fetchedCurrentSchool,
						grades: {
							cumulativeGPA: calculatedCumulativeGPA,
							semesterGPA: calculatedSemesterGPA,
							average: calculatedTotalAverage,
						},
					});
				}

				setChildren(_children);
				setNumChildren(fetchedChildren?.length);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setChildrenLoading(false);
			setNumChildrenLoading(false);
		};

		fetchData();
	}, [users, appData?.debugMode, assessments, currentSemesterDuration, currentUser?.data?.children, schools]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const uniqueSchoolIds = new Set();

				children?.forEach((child) => {
					if (child?.school && child?.school?.id) {
						uniqueSchoolIds?.add(child?.school?.id);
					}
				});

				const numUniqueSchools = uniqueSchoolIds?.size;

				let classCount = 0;

				children?.forEach((child) => {
					if (child?.userData?.school?.classes && Array.isArray(child?.userData?.school?.classes)) {
						classCount += child?.userData?.school?.classes.length;
					}
				});

				setNumSchools(numUniqueSchools);
				setNumClasses(classCount);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setNumSchoolsLoading(false);
			setNumClassesLoading(false);
		};

		fetchData();
	}, [users, appData?.debugMode, children]);

	const [grades, setGrades] = useState(null);
	const [gradesLoading, setGradesLoading] = useState(true);

	useEffect(() => {
		const flattenGrades = (childrenArray) => {
			return childrenArray?.reduce((acc, child) => {
				const childGrades = child?.userData?.grades?.map((grade) => ({
					id: child?.userData?.id,
					name: {
						first: child?.userData?.name?.first,
						last: child?.userData?.name?.last,
					},
					grade,
				}));

				return acc?.concat(childGrades);
			}, []);
		};

		const allGrades = flattenGrades(children);

		setGrades(allGrades);
		setGradesLoading(false);
	}, [children]);

	const [classes, setClasses] = useState(null);
	const [classesLoading, setClassesLoading] = useState(true);

	useEffect(() => {
		const flattenGrades = (schoolsArray) => {
			return schoolsArray?.reduce((acc, school) => {
				return acc?.concat(school?.classes);
			}, []);
		};

		const allClasses = flattenGrades(schools);

		setClasses(allClasses);
		setClassesLoading(false);
	}, [schools]);

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<h3 className='mb-6 text-2xl font-bold text-theme-gray-800'>
					<GreetingMessage currentUser={currentUser} />
				</h3>

				<div className='grid grid-cols-3 gap-6'>
					<div className='col-span-3 md:col-span-1 flex flex-col items-center justify-center bg-blue-500 rounded-lg h-62 w-62 p-8 shadow-lg'>
						<h3 className='text-5xl font-bold text-white'>{!numChildrenLoading ? numChildren : <Loader size='lg' />}</h3>

						<p className='mt-2 text-white text-xl'>Children</p>
					</div>

					<div className='col-span-3 md:col-span-1 flex flex-col items-center justify-center bg-blue-500 rounded-lg h-62 w-62 p-8 shadow-lg'>
						<h3 className='text-5xl font-bold text-white'>{!numSchoolsLoading ? numSchools : <Loader size='lg' />}</h3>

						<p className='mt-2 text-white text-xl'>Schools</p>
					</div>

					<div className='col-span-3 md:col-span-1 flex flex-col items-center justify-center bg-blue-500 rounded-lg h-62 w-62 p-8 shadow-lg'>
						<h3 className='text-5xl font-bold text-white'>{!numClassesLoading ? numClasses : <Loader size='lg' />}</h3>

						<p className='mt-2 text-white text-xl'>Classes</p>
					</div>
				</div>
			</section>

			<section className='w-full bg-theme-white'>
				<div className='grid grid-cols-3 gap-6'>
					<div className='col-span-3'>
						<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm gap-8'>
							<div className='mb-4 flex flex-row items-center justify-between'>
								<h3 className='text-xl font-bold text-theme-gray-800'>Children Class Grades</h3>
							</div>

							<Table
								name='Children'
								headContents={
									<tr>
										<TableHeadItem type='default' title='Child ID' align='left' />

										<TableHeadItem type='default' title='Name' align='left' />

										<TableHeadItem type='default' title='School' align='left' />

										<TableHeadItem type='default' title='Grade/Form' align='left' />

										<TableHeadItem type='default' title='Status' align='center' />

										<TableHeadItem type='default' title='Semester GPA' align='center' />

										<TableHeadItem type='default' title='Cumulative GPA' align='center' />

										<TableHeadItem type='default' title='Average' align='center' />
									</tr>
								}
								bodyContents={({ item }) =>
									childSemesterGradesTableContent({
										child: item,
									})
								}
								sortedData={children}
								filteredData={children}
								dataLoading={childrenLoading}
								colSpan={8}
							/>
						</div>

						<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm mt-5'>
							<div className='mb-4 flex flex-row items-center justify-between'>
								<h3 className='text-xl font-bold text-theme-gray-800'>Semester {currentSemesterDuration?.semester}</h3>
							</div>

							<Table
								name='Children Grades'
								headContents={
									<tr>
										<TableHeadItem type='default' title='Child ID' align='left' />

										<TableHeadItem type='default' title='Name' align='left' />

										<TableHeadItem type='default' title='Teacher' align='left' />

										<TableHeadItem type='default' title='Class' align='left' />

										<TableHeadItem type='default' title='Subject' align='left' />

										<TableHeadItem type='default' title='Assessment' align='left' />

										<TableHeadItem type='default' title='Grade' align='center' />
									</tr>
								}
								bodyContents={({ item }) =>
									childClassGradesTableContent({
										grade: item,
										assessments: assessments,
										subjects: subjects,
										classes: classes,
										users: users,
									})
								}
								sortedData={grades}
								filteredData={grades}
								dataLoading={gradesLoading || classesLoading || subjects || assessments}
								colSpan={7}
							/>
						</div>
					</div>
				</div>
			</section>
		</MainContainer>
	);
};

export default ParentDashboard;
