import React, { Fragment } from 'react';
import { Transition, Dialog } from '@headlessui/react';

const ArchiveModal = ({ title, archiveId, handleArchive, archiveModalState, setArchiveModalState }) => {
	return (
		<Transition appear show={archiveModalState} as={Fragment}>
			<Dialog as='div' className='relative z-50' onClose={() => setArchiveModalState(false)}>
				<Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0' enterTo='opacity-100' leave='ease-in duration-200' leaveFrom='opacity-100' leaveTo='opacity-0'>
					<div className='fixed inset-0 bg-black/25' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0 scale-95' enterTo='opacity-100 scale-100' leave='ease-in duration-200' leaveFrom='opacity-100 scale-100' leaveTo='opacity-0 scale-95'>
							<Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-xl bg-white p-6 text-left align-middle shadow-xl transition-all duration-300 ease-in-out'>
								<Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-800'>
									Archive {title} ({archiveId})
								</Dialog.Title>

								<div className='mt-2'>
									<p className='text-sm text-gray-600'>Are you sure you want to archive this {title.toLowerCase()}?</p>
								</div>

								<div className='mt-4'>
									<button type='button' onClick={handleArchive} className='inline-block shrink-0 rounded-md border border-gray-500 bg-gray-500 px-4 py-2 text-sm font-medium text-white transition-all duration-300 ease-in-out hover:bg-transparent hover:text-gray-500 focus:outline-none focus:ring focus:ring-gray-300 active:text-gray-500'>
										Archive
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export default ArchiveModal;
