import React, { useContext, useState, useEffect } from 'react';
import { useTitle } from 'react-use';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { ErrorHandling, useAlertHandling, useInputWithError, calculateMinDate, getCurrentDate } from '../utils';
import { AuthContext, DataContext } from '../context';
import { ProfileInformation, SchoolDetails, LanguageTime, Preferences, ChangePassword, PersonalDetails, ContactDetails, AddressDetails, ParentInformation, MainContainer } from '../components';
import { storage } from '../config/firebase';

const parents = [
	{
		userType: 'parent',
		id: 'MPENCIL753',
		name: {
			title: 'Mr.',
			first: 'Mike',
			middle: null,
			last: 'Pencil',
		},
		emailAddress: 'mikepencil@mail.com',
		mobilePhone: null,
		homePhone: null,
		dateOfBirth: null,
		sex: 'M',
		address: {
			street: null,
			community: null,
			district: null,
			country: null,
		},
		dependents: [
			{
				id: 'GBELIZA123',
				relationship: 'Father',
			},
			{
				id: 'SELIBOX866',
				relationship: 'Father',
			},
		],
		preferences: {
			language: null,
			timeZone: null,
			theme: 'system',
		},
		registrationDate: null,
		addedBy: null,
	},
];

const StudentSettings = () => {
	useTitle(`Profile & Settings | Grade Portal`);

	const { currentUser } = useContext(AuthContext);
	const { appData, users, countryList, languageOptions, timeZoneOptions, themeOptions, divRef, handleScroll, currentSchool, updatePersonalInformation, updateContactInformation, updateAddressInformation, updateProfileImg, updateLanguage, updateTimeZone, updateUserPassword, updateTheme } = useContext(DataContext);
	const { showAlert } = useAlertHandling();

	const [allStudents, setAllStudents] = useState([]);

	useEffect(() => {
		const fetchStudents = async () => {
			try {
				const fetchedUsers = await users;
				const filteredStudents = await fetchedUsers?.filter((user) => user?.userType === 'student');

				setAllStudents(filteredStudents);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}
		};

		fetchStudents();
	}, [currentSchool?.classes, currentUser?.data?.id, users, appData?.debugMode]);

	const [providerGoogle, setProviderGoogle] = useState(null);

	useEffect(() => {
		currentUser?.user?.providerData.forEach((userInfo) => {
			if (userInfo?.providerId === 'google.com') {
				setProviderGoogle(true);
			} else {
				setProviderGoogle(false);
			}
		});
	}, [currentUser?.user?.providerData]);

	const { value: firstName, setValue: setFirstName, error: firstNameError, setError: setFirstNameError } = useInputWithError(currentUser?.data?.name?.first);
	const { value: middleName, setValue: setMiddleName, error: middleNameError, setError: setMiddleNameError } = useInputWithError(currentUser?.data?.name?.middle);
	const { value: lastName, setValue: setLastName, error: lastNameError, setError: setLastNameError } = useInputWithError(currentUser?.data?.name?.last);

	const [sex, setSex] = useState(currentUser?.data?.sex);
	const [sexError, setSexError] = useState(null);

	const [dateOfBirth, setDateOfBirth] = useState(currentUser?.data?.dateOfBirth);
	const [dateOfBirthError, setDateOfBirthError] = useState(null);

	const { value: emailAddress, setValue: setEmailAddress, error: emailAddressError, setError: setEmailAddressError } = useInputWithError(currentUser?.data?.emailAddress);
	const { value: mobilePhone, setValue: setMobilePhone, error: mobilePhoneError, setError: setMobilePhoneError } = useInputWithError(currentUser?.data?.mobilePhone);
	const { value: homePhone, setValue: setHomePhone, error: homePhoneError, setError: setHomePhoneError } = useInputWithError(currentUser?.data?.homePhone);

	const { value: addressStreet, setValue: setAddressStreet, error: addressStreetError, setError: setAddressStreetError } = useInputWithError(currentUser?.data?.address?.street);
	const { value: addressCommunity, setValue: setAddressCommunity, error: addressCommunityError, setError: setAddressCommunityError } = useInputWithError(currentUser?.data?.address?.community);
	const { value: addressDistrict, setValue: setAddressDistrict, error: addressDistrictError, setError: setAddressDistrictError } = useInputWithError(currentUser?.data?.address?.district);
	const { value: addressCountry, setValue: setAddressCountry, error: addressCountryError, setError: setAddressCountryError } = useInputWithError(currentUser?.data?.address?.country);

	const parentWithDependent = parents?.find((parent) => parent?.dependents?.some((dependent) => dependent.id === currentUser?.data?.id));

	const [fatherFirstName, setFatherFirstName] = useState(null);
	const [fatherLastName, setFatherLastName] = useState(null);
	const [fatherEmailAddress, setFatherEmailAddress] = useState(null);
	const [fatherMobilePhone, setFatherMobilePhone] = useState(null);

	const [motherFirstName, setMotherFirstName] = useState(null);
	const [motherLastName, setMotherLastName] = useState(null);
	const [motherEmailAddress, setMotherEmailAddress] = useState(null);
	const [motherMobilePhone, setMotherMobilePhone] = useState(null);

	useEffect(() => {
		parentWithDependent?.dependents?.forEach((dependent) => {
			if (dependent?.relationship?.toLowerCase() === 'father') {
				setFatherFirstName(parentWithDependent?.name?.first);
				setFatherLastName(parentWithDependent?.name?.last);
				setFatherEmailAddress(parentWithDependent?.emailAddress);
				setFatherMobilePhone(parentWithDependent?.mobilePhone);
			} else if (dependent?.relationship?.toLowerCase() === 'mother') {
				setMotherFirstName(parentWithDependent?.name?.first);
				setMotherLastName(parentWithDependent?.name?.last);
				setMotherEmailAddress(parentWithDependent?.emailAddress);
				setMotherMobilePhone(parentWithDependent?.mobilePhone);
			}
		});
	}, [parentWithDependent]);

	const [profileImg, setProfileImg] = useState(currentUser?.user?.photoURL);
	const [language, setLanguage] = useState(currentUser?.data?.preferences?.language);
	const [timeZone, setTimeZone] = useState(currentUser?.data?.preferences?.timeZone);
	const [theme, setTheme] = useState(currentUser?.data?.preferences?.theme);

	const [profileImgLoading, setProfileImgLoading] = useState(false);
	const [languageChangeLoading, setLanguageChangeLoading] = useState(false);
	const [timeZoneChangeLoading, setTimeZoneChangeLoading] = useState(false);
	const [themeChangeLoading, setThemeChangeLoading] = useState(false);
	const [passwordChangeLoading, setPasswordChangeLoading] = useState(false);

	const [showPassword, setShowPassword] = useState(false);

	const [currentPassword, setCurrentPassword] = useState(null);
	const [newPassword, setNewPassword] = useState(null);
	const [confirmPassword, setConfirmPassword] = useState(null);

	const [currentPasswordError, setCurrentPasswordError] = useState(null);
	const [newPasswordError, setNewPasswordError] = useState(null);
	const [confirmPasswordError, setConfirmPasswordError] = useState(null);

	const handleProfileImgUploadChange = async (e) => {
		e.preventDefault();

		setProfileImgLoading(true);

		const imgFile = e.target.files[0];

		if (imgFile && imgFile.type.startsWith('image/')) {
			const storageRef = ref(storage, `userImages/${currentUser?.user?.uid}`);
			const uploadTask = await uploadBytesResumable(storageRef, imgFile);

			await uploadTask.on(
				'state_changed',
				(snapshot) => {},
				(error) => {
					if (appData?.debugMode) {
						console.error(error);
					}

					setProfileImgLoading(false);
					showAlert('error', 'An unknown error occurred');
				},
				() => {
					try {
						getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
							await updateProfileImg(downloadURL);

							setProfileImg(currentUser?.user?.photoURL);
							setProfileImgLoading(false);
						});
					} catch (error) {
						if (appData?.debugMode) {
							console.error(error);
						}

						setProfileImgLoading(false);
						showAlert('error', 'An unknown error occurred');
					}
				}
			);
		} else {
			setProfileImgLoading(false);
			showAlert('error', 'Please select a valid image');
		}
	};

	const handleLanguageChange = async (e) => {
		e.preventDefault();

		setLanguage(e.target.value);
		setLanguageChangeLoading(true);

		try {
			await updateLanguage(currentUser?.user?.uid, e.target.value);

			setLanguageChangeLoading(false);
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			setLanguageChangeLoading(false);
			showAlert('error', 'An unknown error occurred');
		}
	};

	const handleTimeZoneChange = async (e) => {
		e.preventDefault();

		setTimeZone(e.target.value);
		setTimeZoneChangeLoading(true);

		try {
			await updateTimeZone(currentUser?.user?.uid, e.target.value);

			setTimeZoneChangeLoading(false);
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			setTimeZoneChangeLoading(false);
			showAlert('error', 'An unknown error occurred');
		}
	};

	const handleThemeChange = async (e) => {
		e.preventDefault();

		setTheme(e.target.value);
		setThemeChangeLoading(true);

		try {
			await updateTheme(currentUser?.user?.uid, e.target.value);

			setThemeChangeLoading(false);
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			setThemeChangeLoading(false);
			showAlert('error', 'An unknown error occurred');
		}
	};

	const handlePasswordChange = async (e) => {
		e.preventDefault();

		setPasswordChangeLoading(true);

		let error = false;

		try {
			if (!currentPassword || currentPassword?.trim() === '') {
				setCurrentPasswordError('Please enter your current password');

				error = true;
			}

			if (!newPassword || newPassword?.trim() === '') {
				setNewPasswordError('Please enter a new password');

				error = true;
			}

			if (!confirmPassword || confirmPassword?.trim() === '') {
				setConfirmPasswordError('Please enter a new password');

				error = true;
			}

			if (newPassword !== confirmPassword) {
				setNewPasswordError('Passwords do not match');
				setConfirmPasswordError('Passwords do not match');

				error = true;
			}

			if (newPassword?.length < 8) {
				setNewPasswordError('Password must be at least 8 characters long');
				setConfirmPasswordError('Password must be at least 8 characters long');

				error = true;
			}

			if (!/\d/.test(newPassword)) {
				setNewPasswordError('Password must contain at least one number');
				setConfirmPasswordError('Password must contain at least one number');

				error = true;
			}

			if (!/[!@#$%^&*(),.?":{}|<>]/.test(newPassword)) {
				setNewPasswordError('Password must contain at least one special character');
				setConfirmPasswordError('Password must contain at least one special character');

				error = true;
			}

			if (!/[A-Z]/.test(newPassword)) {
				setNewPasswordError('Password must contain at least one uppercase letter');
				setConfirmPasswordError('Password must contain at least one uppercase letter');

				error = true;
			}

			if (!/[a-z]/.test(newPassword)) {
				setNewPasswordError('Password must contain at least one lowercase letter');
				setConfirmPasswordError('Password must contain at least one lowercase letter');

				error = true;
			}

			if (!error) {
				const data = {
					currentPassword: currentPassword,
					newPassword: newPassword,
				};

				await updateUserPassword(currentUser?.user?.uid, data);
			}

			setPasswordChangeLoading(false);
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			setPasswordChangeLoading(false);
			showAlert('error', 'An unknown error occurred');
		}
	};

	const [editPersonalInformation, setEditPersonalInformation] = useState(false);
	const [editPersonalInformationLoading, setEditPersonalInformationLoading] = useState(false);

	const [editContactInformation, setEditContactInformation] = useState(false);
	const [editContactInformationLoading, setEditContactInformationLoading] = useState(false);

	const [editAddressInformation, setEditAddressInformation] = useState(false);
	const [editAddressInformationLoading, setEditAddressInformationLoading] = useState(false);

	const handleSavePersonalInformation = async (e) => {
		e.preventDefault();

		setEditPersonalInformationLoading(true);

		const updatedUserData = {
			name: {
				first: firstName,
				middle: middleName ? middleName : null,
				last: lastName,
			},
			sex: sex,
			dateOfBirth: dateOfBirth,
		};

		let error = false;

		try {
			if (!sex || sex === null || sex === undefined || sex === '') {
				setSexError('Please select a sex');

				error = true;
			}

			if (!dateOfBirth || dateOfBirth === null || dateOfBirth === undefined || dateOfBirth === '') {
				setDateOfBirthError('Please select a date of birth');

				error = true;
			}

			if ((await ErrorHandling.handleSettingsFirstNameError(firstName, setFirstNameError)) || (await ErrorHandling.handleSettingsMiddleNameError(middleName, setMiddleNameError)) || (await ErrorHandling.handleSettingsLastNameError(lastName, setLastNameError))) {
				error = true;
			}

			if (!error) {
				await updatePersonalInformation(currentUser?.user?.uid, updatedUserData);
				setEditPersonalInformation(false);
			}

			setEditPersonalInformationLoading(false);
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			setEditPersonalInformationLoading(false);
			showAlert('error', 'An unknown error occurred');
		}
	};

	const handleSaveContactInformation = async (e) => {
		e.preventDefault();

		setEditContactInformationLoading(true);

		const updatedUserData = {
			emailAddress: emailAddress,
			mobilePhone: mobilePhone ? mobilePhone : null,
			homePhone: homePhone ? homePhone : null,
		};

		let error = false;

		try {
			if ((await ErrorHandling.handleSettingsEmailAddressError(emailAddress, setEmailAddressError)) || (await ErrorHandling.handleSettingsMobilePhoneError(mobilePhone, setMobilePhoneError)) || (await ErrorHandling.handleSettingsHomePhoneError(homePhone, setHomePhoneError))) {
				error = true;
			}

			if (!error) {
				await updateContactInformation(currentUser?.user?.uid, updatedUserData);
				setEditContactInformation(false);
			}

			setEditContactInformationLoading(false);
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			setEditContactInformationLoading(false);
			showAlert('error', 'An unknown error occurred');
		}
	};

	const handleSaveAddressInformation = async (e) => {
		e.preventDefault();

		setEditAddressInformationLoading(true);

		const updatedUserData = {
			address: {
				street: addressStreet ? addressStreet : null,
				community: addressCommunity ? addressCommunity : null,
				district: addressDistrict,
				country: addressCountry,
			},
		};

		let error = false;

		try {
			if (await ErrorHandling.handleSettingsAddressError(addressStreet, setAddressStreetError, addressCommunity, setAddressCommunityError, addressDistrict, setAddressDistrictError, addressCountry, setAddressCountryError)) {
				error = true;
			}

			if (!error) {
				await updateAddressInformation(currentUser?.user?.uid, updatedUserData);
				setEditAddressInformation(false);
			}

			setEditAddressInformationLoading(false);
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			setEditAddressInformationLoading(false);
			showAlert('error', 'An unknown error occurred');
		}
	};

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<h3 className='text-2xl font-bold text-theme-gray-800'>Profile & Settings</h3>
			</section>

			<section className='w-full bg-theme-white'>
				<div className='grid grid-cols-3 gap-6'>
					<div className='col-span-3 xl:col-span-1 flex flex-col gap-6'>
						<ProfileInformation currentUser={currentUser} profileImg={profileImg} profileImgLoading={profileImgLoading} handleProfileImgUploadChange={handleProfileImgUploadChange} />

						{currentUser?.data?.userType !== 'admin' && <SchoolDetails currentUser={currentUser} currentSchool={currentSchool} students={allStudents} />}

						<LanguageTime language={language} languageOptions={languageOptions} languageChangeLoading={languageChangeLoading} handleLanguageChange={handleLanguageChange} timeZone={timeZone} timeZoneOptions={timeZoneOptions} timeZoneChangeLoading={timeZoneChangeLoading} handleTimeZoneChange={handleTimeZoneChange} />

						<Preferences theme={theme} themeOptions={themeOptions} themeChangeLoading={themeChangeLoading} handleThemeChange={handleThemeChange} />

						{!providerGoogle && currentUser?.data?.userType === 'student' && (
							<ChangePassword
								currentPassword={currentPassword}
								setCurrentPassword={setCurrentPassword}
								currentPasswordError={currentPasswordError}
								setCurrentPasswordError={setCurrentPasswordError}
								newPassword={newPassword}
								setNewPassword={setNewPassword}
								newPasswordError={newPasswordError}
								setNewPasswordError={setNewPasswordError}
								confirmPassword={confirmPassword}
								setConfirmPassword={setConfirmPassword}
								confirmPasswordError={confirmPasswordError}
								setConfirmPasswordError={setConfirmPasswordError}
								showPassword={showPassword}
								setShowPassword={setShowPassword}
								handlePasswordChange={handlePasswordChange}
								passwordChangeLoading={passwordChangeLoading}
							/>
						)}
					</div>

					<div className='col-span-3 xl:col-span-2 flex flex-col gap-6'>
						<PersonalDetails
							setEditPersonalInformationLoading={setEditPersonalInformationLoading}
							editPersonalInformation={editPersonalInformation}
							editPersonalInformationLoading={editPersonalInformationLoading}
							firstName={firstName}
							setFirstName={setFirstName}
							firstNameError={firstNameError}
							setFirstNameError={setFirstNameError}
							middleName={middleName}
							setMiddleName={setMiddleName}
							middleNameError={middleNameError}
							setMiddleNameError={setMiddleNameError}
							lastName={lastName}
							setLastName={setLastName}
							lastNameError={lastNameError}
							setLastNameError={setLastNameError}
							sex={sex}
							setSex={setSex}
							sexError={sexError}
							setSexError={setSexError}
							dateOfBirth={dateOfBirth}
							setDateOfBirth={setDateOfBirth}
							dateOfBirthError={dateOfBirthError}
							setDateOfBirthError={setDateOfBirthError}
							calculateMinDate={calculateMinDate}
							getCurrentDate={getCurrentDate}
							handleSavePersonalInformation={handleSavePersonalInformation}
							setEditPersonalInformation={setEditPersonalInformation}
						/>

						<ContactDetails
							editContactInformation={editContactInformation}
							editContactInformationLoading={editContactInformationLoading}
							emailAddress={emailAddress}
							setEmailAddress={setEmailAddress}
							emailAddressError={emailAddressError}
							setEmailAddressError={setEmailAddressError}
							mobilePhone={mobilePhone}
							setMobilePhone={setMobilePhone}
							mobilePhoneError={mobilePhoneError}
							setMobilePhoneError={setMobilePhoneError}
							homePhone={homePhone}
							setHomePhone={setHomePhone}
							homePhoneError={homePhoneError}
							setHomePhoneError={setHomePhoneError}
							handleSaveContactInformation={handleSaveContactInformation}
							setEditContactInformation={setEditContactInformation}
							setEditContactInformationLoading={setEditContactInformationLoading}
						/>

						<AddressDetails
							editAddressInformation={editAddressInformation}
							editAddressInformationLoading={editAddressInformationLoading}
							addressStreet={addressStreet}
							setAddressStreet={setAddressStreet}
							addressStreetError={addressStreetError}
							setAddressStreetError={setAddressStreetError}
							addressCommunity={addressCommunity}
							setAddressCommunity={setAddressCommunity}
							addressCommunityError={addressCommunityError}
							setAddressCommunityError={setAddressCommunityError}
							addressDistrict={addressDistrict}
							setAddressDistrict={setAddressDistrict}
							addressDistrictError={addressDistrictError}
							setAddressDistrictError={setAddressDistrictError}
							addressCountry={addressCountry}
							setAddressCountry={setAddressCountry}
							addressCountryError={addressCountryError}
							setAddressCountryError={setAddressCountryError}
							handleSaveAddressInformation={handleSaveAddressInformation}
							setEditAddressInformation={setEditAddressInformation}
							setEditAddressInformationLoading={setEditAddressInformationLoading}
							countryList={countryList}
						/>

						{currentUser?.data?.userType === 'student' && <ParentInformation motherFirstName={motherFirstName} motherLastName={motherLastName} motherEmailAddress={motherEmailAddress} motherMobilePhone={motherMobilePhone} fatherFirstName={fatherFirstName} fatherLastName={fatherLastName} fatherEmailAddress={fatherEmailAddress} fatherMobilePhone={fatherMobilePhone} />}

						{!providerGoogle && currentUser?.data?.userType !== 'student' && (
							<ChangePassword
								currentPassword={currentPassword}
								setCurrentPassword={setCurrentPassword}
								currentPasswordError={currentPasswordError}
								setCurrentPasswordError={setCurrentPasswordError}
								newPassword={newPassword}
								setNewPassword={setNewPassword}
								newPasswordError={newPasswordError}
								setNewPasswordError={setNewPasswordError}
								confirmPassword={confirmPassword}
								setConfirmPassword={setConfirmPassword}
								confirmPasswordError={confirmPasswordError}
								setConfirmPasswordError={setConfirmPasswordError}
								showPassword={showPassword}
								setShowPassword={setShowPassword}
								handlePasswordChange={handlePasswordChange}
								passwordChangeLoading={passwordChangeLoading}
							/>
						)}
					</div>
				</div>
			</section>
		</MainContainer>
	);
};

export default StudentSettings;
