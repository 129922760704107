import React, { useContext, useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import { Link } from 'react-router-dom';
import { DataContext, AuthContext } from '../../context';
import { generatePDF, numberToWord } from '../../utils';
import { DeleteModal, MainContainer, MenuDropdown, Table, TableBodyItem, TableHeadItem } from '../../components';

const SchoolsTableContent = ({ school, setDeleteSchoolId, setDeleteSchoolModalState }) => {
	const principalName = `${school?.principal?.title} ${school?.principal?.firstName} ${school?.principal?.lastName}`;

	return (
		<>
			<TableBodyItem type='select' onClick={() => {}} />

			<TableBodyItem type='primary' contents={school?.id ? <Link to={`/schools/${school?.id}`}>{school?.id}</Link> : 'N/A'} align='left' />

			<TableBodyItem type='default' contents={school?.name || 'N/A'} align='left' />

			<TableBodyItem type='default' contents={school?.level === '1' ? <span className='rounded-full px-2.5 py-0.5 text-xs bg-blue-500 text-white uppercase'>Primary</span> : school?.level === '2' ? <span className='rounded-full px-2.5 py-0.5 text-xs bg-yellow-500 text-white uppercase'>Secondary</span> : 'N/A'} align='center' />

			<TableBodyItem type='default' contents={principalName || 'N/A'} align='left' />

			<TableBodyItem type='default' contents={school?.district ? numberToWord(school?.district) : 'N/A'} align='center' />

			<TableBodyItem type='default' contents={school?.phone || 'N/A'} align='center' />

			<TableBodyItem
				type='dropdown'
				contents={
					<MenuDropdown
						type='table'
						menuItems={[
							[
								{
									type: 'link',
									danger: false,
									to: `/schools/${school?.id}`,
									onClick: null,
									text: 'Edit',
									icon: (
										<>
											<path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
											<path fillRule='evenodd' d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z' />
										</>
									),
								},
								{
									type: 'link',
									danger: false,
									to: school?.website,
									newTab: true,
									onClick: null,
									text: 'Website',
									icon: (
										<path d='M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 1.855A8 8 0 0 0 5.145 4H7.5zM4.09 4a9.3 9.3 0 0 1 .64-1.539 7 7 0 0 1 .597-.933A7.03 7.03 0 0 0 2.255 4zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a7 7 0 0 0-.656 2.5zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5zM8.5 5v2.5h2.99a12.5 12.5 0 0 0-.337-2.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5zM5.145 12q.208.58.468 1.068c.552 1.035 1.218 1.65 1.887 1.855V12zm.182 2.472a7 7 0 0 1-.597-.933A9.3 9.3 0 0 1 4.09 12H2.255a7 7 0 0 0 3.072 2.472M3.82 11a13.7 13.7 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5zm6.853 3.472A7 7 0 0 0 13.745 12H11.91a9.3 9.3 0 0 1-.64 1.539 7 7 0 0 1-.597.933M8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855q.26-.487.468-1.068zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.7 13.7 0 0 1-.312 2.5m2.802-3.5a7 7 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7 7 0 0 0-3.072-2.472c.218.284.418.598.597.933M10.855 4a8 8 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4z' />
									),
								},
								{
									type: 'link',
									danger: false,
									to: `mailto:${school?.emailAddress}`,
									newTab: false,
									onClick: null,
									text: 'Email',
									icon: <path d='M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z' />,
								},
								{
									type: 'link',
									danger: false,
									to: `tel:${school?.phone}`,
									newTab: false,
									onClick: null,
									text: 'Call',
									icon: (
										<path d='M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z' />
									),
								},
							],
							[
								{
									type: 'button',
									danger: true,
									to: null,
									newTab: false,
									onClick: () => {
										setDeleteSchoolId(school?.id);
										setDeleteSchoolModalState(true);
									},
									text: 'Delete',
									icon: (
										<path d='M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5' />
									),
								},
							],
						]}
					/>
				}
				align='center'
			/>
		</>
	);
};

const Schools = () => {
	useTitle('Schools | Grade Portal');

	const { currentUser } = useContext(AuthContext);
	const { appData, divRef, handleScroll, schools, schoolLevelGroups } = useContext(DataContext);

	const [allSchools, setAllSchools] = useState([]);
	const [allSchoolsLoading, setAllSchoolsLoading] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const fetchedSchools = await schools;

				setAllSchools(fetchedSchools);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setAllSchoolsLoading(false);
		};

		fetchData();
	}, [appData?.debugMode, schools]);

	const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState('asc');
	const [sortedSchools, setSortedSchools] = useState([]);

	const handleSortClick = (field) => {
		if (sortField === field) {
			setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
		} else {
			setSortField(field);
			setSortOrder('asc');
		}
	};

	useEffect(() => {
		const sortSchools = async () => {
			let sortedData = [...(allSchools ?? [])];

			if (sortField) {
				sortedData = sortedData?.sort((a, b) => {
					const valueA = a[sortField];
					const valueB = b[sortField];

					if (sortOrder === 'asc') {
						return valueA?.localeCompare(valueB, undefined, {
							numeric: true,
						});
					} else {
						return valueB?.localeCompare(valueA, undefined, {
							numeric: true,
						});
					}
				});
			}

			setSortedSchools(sortedData);
		};

		sortSchools();
	}, [allSchools, sortField, sortOrder]);

	const [filterOption, setFilterOption] = useState('ALL');

	const [schoolSearchQuery, setSchoolSearchQuery] = useState('');
	const [filteredSchools, setFilteredSchools] = useState([]);

	useEffect(() => {
		const filtered = allSchools
			?.filter((school) => {
				if (filterOption === 'Primary') {
					return school?.level === '1';
				}

				if (filterOption === 'Secondary') {
					return school?.level === '2';
				}

				return true;
			})
			?.filter((school) => {
				const searchString = schoolSearchQuery?.toLowerCase();

				return school?.id?.toLowerCase().includes(searchString) || school?.name?.toLowerCase().includes(searchString) || school?.principal?.firstName?.toLowerCase().includes(searchString) || school?.principal?.lastName?.toLowerCase().includes(searchString);
			});

		setFilteredSchools(filtered);
	}, [schoolSearchQuery, allSchools, currentUser?.data?.id, filterOption]);

	const [deleteSchoolId, setDeleteSchoolId] = useState(null);
	const [deleteSchoolModalState, setDeleteSchoolModalState] = useState(false);

	const handleDeleteSchool = () => {};

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<div className='flex flex-row items-center justify-between'>
					<div className='flex flex-row items-center justify-start gap-4'>
						<h3 className='text-lg font-bold text-theme-gray-800 sm:text-xl'>Schools</h3>

						<select
							value={filterOption}
							onChange={(e) => {
								e.preventDefault();
								setFilterOption(e.target.value);
							}}
							className='w-full rounded-lg bg-theme-white border-theme-gray-200 text-theme-gray-800 sm:text-sm'>
							<option value='ALL'>All Schools</option>

							{Object.values(schoolLevelGroups)?.map((schoolLevelGroup, idx) => {
								return (
									<option key={idx} value={schoolLevelGroup}>
										{schoolLevelGroup}
									</option>
								);
							})}
						</select>

						<div className='w-full'>
							<label htmlFor='schoolSearch' className='sr-only'>
								Search
							</label>

							<div className='relative'>
								<div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
									<svg className='w-4 h-4 text-theme-gray-800' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
										<path d='M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z' fill='none' stroke='currentColor' strokeMiterlimit='10' strokeWidth='32' />
										<path fill='none' stroke='currentColor' strokeLinecap='round' strokeMiterlimit='10' strokeWidth='32' d='M338.29 338.29L448 448' />
									</svg>
								</div>

								<form>
									<input
										type='search'
										onChange={(e) => {
											e.preventDefault();
											setSchoolSearchQuery(e.target.value);
										}}
										className='min-w-64 w-full ps-10 rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'
										id='schoolSearch'
										placeholder='Search schools'
									/>
								</form>
							</div>
						</div>
					</div>

					<div className='flex flex-row items-center justify-end gap-4'>
						<Link type='button' to='/schools/new' className='inline-block shrink-0 rounded-md border border-blue-500 bg-blue-500 px-4 py-2 text-sm font-medium text-white transition-all duration-300 ease-in-out hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'>
							New School
						</Link>

						<MenuDropdown
							type='download'
							menuItems={[
								[
									{
										type: 'button',
										danger: false,
										to: null,
										onClick: (e) => {
											e.preventDefault();
											generatePDF('Students');
										},
										text: 'Download PDF',
										icon: (
											<path
												fillRule='evenodd'
												d='M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z'
											/>
										),
									},
								],
							]}
						/>
					</div>
				</div>
			</section>

			<section className='w-full bg-theme-white'>
				<Table
					name='Schools'
					headContents={
						<tr>
							<TableHeadItem type='select' onClick={() => {}} />

							<TableHeadItem type='sort' title='School ID' align='left' onClick={() => handleSortClick('id')} />

							<TableHeadItem type='sort' title='Name' align='left' onClick={() => handleSortClick('name')} />

							<TableHeadItem type='sort' title='Level' align='center' onClick={() => handleSortClick('level')} />

							<TableHeadItem type='sort' title='Principal' align='left' onClick={() => handleSortClick('principal.lastName')} />

							<TableHeadItem type='sort' title='District' align='center' onClick={() => handleSortClick('district')} />

							<TableHeadItem type='default' title='Phone Number' align='center' onClick={() => handleSortClick('phone')} />

							<TableBodyItem type='dropdown' />
						</tr>
					}
					bodyContents={({ item }) => <SchoolsTableContent school={item} setDeleteSchoolId={setDeleteSchoolId} setDeleteSchoolModalState={setDeleteSchoolModalState} />}
					sortedData={sortedSchools}
					filteredData={filteredSchools}
					dataLoading={allSchoolsLoading}
					colSpan={7}
				/>
			</section>

			<DeleteModal title='School' deleteId={deleteSchoolId} handleDelete={handleDeleteSchool} deleteModalState={deleteSchoolModalState} setDeleteModalState={setDeleteSchoolModalState} />
		</MainContainer>
	);
};

export default Schools;
