import React, { useContext, useEffect, useState } from 'react';
import { Navigate, redirect } from 'react-router-dom';
import { DataContext, AuthContext } from '../../context';
import { Footer, Loader } from '../../components';
import { useTitle } from 'react-use';

const Login = () => {
	const { currentUser, handleLoginLoading, setHandleLoginLoading, emailLogin, googleLogin } = useContext(AuthContext);
	const { appData, schools } = useContext(DataContext);

	useEffect(() => {
		if (currentUser != null) {
			Navigate('/');
		}
	}, [currentUser]);

	const [userType, setUserType] = useState(null);

	let title = 'Login | Grade Portal';

	const isNextButtonDisabled = !userType;

	const [showLoginForm, setShowLoginForm] = useState(false);

	if (showLoginForm && userType) {
		if (userType === 'student') {
			title = 'Student Login | Grade Portal';
		} else if (userType === 'teacher') {
			title = 'Teacher Login | Grade Portal';
		} else if (userType === 'principal') {
			title = 'Principal Login | Grade Portal';
		} else if (userType === 'admin') {
			title = 'Admin Login | Grade Portal';
		} else if (userType === 'alumni') {
			title = 'Alumni Login | Grade Portal';
		} else if (userType === 'parent') {
			title = 'Parent Login | Grade Portal';
		}
	}

	useTitle(title);

	const [error, setError] = useState(null);
	const [emailAddressError, setEmailAddressError] = useState(null);
	const [passwordError, setPasswordError] = useState(null);

	const [emailAddress, setEmailAddress] = useState('');
	const [password, setPassword] = useState('');
	const [rememberMe, setRememberMe] = useState(null);

	const validateEmailDomain = (email) => {
		const [, domain] = email.split('@');
		const isValidDomain = schools.some((school) => school.emailDomain === domain);

		return isValidDomain;
	};

	const handleNext = (e) => {
		e.preventDefault();

		setShowLoginForm(true);
	};

	const handleBack = (e) => {
		e.preventDefault();

		setShowLoginForm(false);
		setUserType(null);
	};

	const handleLogin = async (e) => {
		e.preventDefault();

		setHandleLoginLoading(true);

		const isEmailValid = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/;
		const isPasswordValid = password.length >= 8;

		if (!emailAddress || !isEmailValid.test(emailAddress) || !validateEmailDomain(emailAddress)) {
			setError('An error occurred. Please try again.');
			setEmailAddressError('Please enter a valid email address');

			if (appData.debugMode) {
				console.error('Please enter a valid email address');
			}
		}

		if (!isPasswordValid) {
			setError('An error occurred. Please try again.');
			setPasswordError('Please enter a valid password');

			if (appData.debugMode) {
				console.error('Please enter a valid password');
			}
		}

		if (!userType) {
			setError('An error occurred. Please try again.');

			if (appData.debugMode) {
				console.error('userType null');
			}
		}

		if (emailAddress && isEmailValid.test(emailAddress) && isPasswordValid && userType) {
			setError(false);
			setEmailAddressError(false);
			setPasswordError(false);

			try {
				const creds = {
					userType,
					emailAddress,
					password,
					rememberMe,
				};

				await emailLogin(creds);

				return redirect('/');
			} catch (err) {
				if (err.code === 'auth/user-not-found') {
					setError('User not found. Please check your credentials.');
				} else if (err.code === 'auth/invalid-credential') {
					setError('Invalid credentials. Please try again.');
				} else if (err.code === 'auth/wrong-password') {
					setError('Incorrect password. Please try again.');
				} else {
					setError('An error occurred. Please try again.');
				}

				if (appData.debugMode) {
					console.error(error);
				}
			}
		}

		setHandleLoginLoading(false);
	};

	const handleGoogleLogin = async (e) => {
		e.preventDefault();
		setHandleLoginLoading(true);

		try {
			await googleLogin(userType);

			return redirect('/');
		} catch (err) {
			if (err?.message === 'Access denied' || err?.message === 'Account does not exist') {
				setError(err?.message);
			} else {
				setError('An unknown error occurred. Please try again.');
			}

			if (appData?.debugMode) {
				console.error(err);
			}
		}

		setHandleLoginLoading(false);
	};

	return (
		<div className='bg-white lg:grid lg:min-h-screen lg:grid-cols-12'>
			<section className='relative flex h-48 items-end bg-blue-500 lg:col-span-5 lg:h-full xl:col-span-6'>
				<img className='absolute inset-0 h-full w-full object-cover opacity-80' src='https://images.pexels.com/photos/18003175/pexels-photo-18003175/free-photo-of-the-view-of-the-st-lucia-mountains-from-the-sea.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' alt='Saint Lucia Pitons' />

				<div className='hidden lg:relative lg:block lg:p-12'>
					<h2 className='mt-6 text-2xl font-bold text-white sm:text-3xl md:text-4xl'>Welcome to Grade Portal</h2>

					<p className='mt-4 leading-relaxed text-white/90'>Unlock your academic journey with ease. Log in now to access grades, assignments, and important announcements.</p>
				</div>
			</section>

			<main className='flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6'>
				<div className='w-full max-w-xl lg:max-w-3xl'>
					<div className='relative -mt-16 block lg:hidden'>
						<div className='inline-flex h-16 w-16 items-center justify-center rounded-full bg-white text-blue-600 sm:h-20 sm:w-20'>
							<span className='sr-only'>Home</span>

							<img className='w-12 h-12 mx-auto' src={appData.logo} alt='Grade Portal Logo' />
						</div>

						<h1 className='mt-2 text-2xl font-bold text-gray-800 sm:text-3xl md:text-4xl'>Welcome to Grade Portal</h1>

						<p className='mt-4 leading-relaxed text-gray-600'>Unlock your academic journey with ease. Log in now to access grades, assignments, and important announcements.</p>
					</div>

					{showLoginForm ? (
						<form className='w-full mt-8 grid grid-cols-6 gap-6'>
							<div className='w-full col-span-6'>
								<h2 className='mt-6 text-2xl font-bold text-gray-800 sm:text-3xl md:text-4xl'>{userType === 'student' ? 'Student' : userType === 'teacher' ? 'Teacher' : userType === 'principal' ? 'Principal' : userType === 'admin' ? 'Administration' : userType === 'alumni' ? 'Alumni' : userType === 'parent' ? 'Parent' : null} Login</h2>
							</div>

							<div className='w-full col-span-6'>
								<label htmlFor='emailAddress' className='block text-sm font-medium text-gray-800'>
									Email
								</label>

								<input
									type='email'
									onChange={(e) => {
										setEmailAddress(e.target.value);
										setEmailAddressError(null);
									}}
									className={`mt-1 w-full rounded-md border ${emailAddressError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-200 text-gray-800'} bg-white text-sm shadow-sm focus:outline-none`}
									id='emailAddress'
									required
								/>

								{emailAddressError && <span className='mt-2 text-sm text-red-500'>{emailAddressError}</span>}
							</div>

							<div className='w-full col-span-6'>
								<label htmlFor='password' className='block text-sm font-medium text-gray-800'>
									Password
								</label>

								<input
									type='password'
									onChange={(e) => {
										setPassword(e.target.value);
										setPasswordError(null);
									}}
									className={`mt-1 w-full rounded-md border ${passwordError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-200 text-gray-800'} bg-white text-sm shadow-sm focus:outline-none`}
									id='password'
									required
								/>

								{passwordError && <span className='mt-2 text-sm text-red-500'>{passwordError}</span>}
							</div>

							<div className='w-full col-span-6'>
								<label htmlFor='rememberMe' className='flex gap-4'>
									<input
										type='checkbox'
										onChange={(e) => {
											setRememberMe(e.target.checked);
										}}
										className='h-5 w-5 rounded-md border-gray-200 bg-white shadow-sm'
										id='rememberMe'
									/>

									<span className='text-sm text-gray-800'>Remember me</span>
								</label>
							</div>

							{error && (
								<div className='w-full col-span-6'>
									<div className='rounded border-s-4 border-red-500 bg-red-50 p-4'>
										<strong className='block font-medium text-red-800'>Something went wrong</strong>

										<p className='mt-2 text-sm text-red-700'>{error}</p>
									</div>
								</div>
							)}

							<div className='w-full col-span-6 lg:col-span-4 grid grid-cols-6 gap-4'>
								<button
									type='button'
									onClick={handleBack}
									disabled={handleLoginLoading}
									className={`w-full col-span-3 inline-flex items-center justify-center shrink-0 rounded-md border border-gray-500 bg-gray-500 text-white px-4 py-2 gap-2 text-sm font-medium ${handleLoginLoading ? 'opacity-60 cursor-not-allowed' : 'hover:bg-transparent hover:text-gray-500 focus:outline-none focus:ring active:text-gray-500'}`}>
									{handleLoginLoading ? (
										<div className='flex items-center justify-center'>
											<Loader />
										</div>
									) : (
										'Back'
									)}
								</button>

								<button
									type='submit'
									onClick={handleLogin}
									disabled={handleLoginLoading}
									className={`w-full col-span-3 inline-flex items-center justify-center shrink-0 rounded-md border border-blue-500 bg-blue-500 text-white px-4 py-2 gap-2 text-sm font-medium ${handleLoginLoading ? 'opacity-60 cursor-not-allowed' : 'hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'}`}>
									{handleLoginLoading ? (
										<div className='flex items-center justify-center'>
											<Loader />
										</div>
									) : (
										'Login'
									)}
								</button>

								<div className='relative w-full col-span-6 inline-flex items-center justify-center'>
									<hr className='w-64 h-px my-2 bg-gray-200 border-0' />

									<span className='absolute px-3 font-medium text-gray-800 -translate-x-1/2 bg-white left-1/2'>or</span>
								</div>

								<button
									type='button'
									onClick={handleGoogleLogin}
									disabled={handleLoginLoading}
									className={`w-full col-span-6 inline-flex items-center justify-center shrink-0 rounded-md border border-gray-500 bg-transparent text-gray-500 px-4 py-2 gap-2 text-sm font-medium ${handleLoginLoading ? 'opacity-60 cursor-not-allowed' : 'hover:bg-transparent hover:text-gray-500 focus:outline-none focus:ring hover:opacity-60'}`}>
									{handleLoginLoading ? (
										<div className='flex items-center justify-center'>
											<Loader />
										</div>
									) : (
										<>
											<img className='size-4' src='https://www.svgrepo.com/show/475656/google-color.svg' alt='Google logo' />

											<span>Google</span>
										</>
									)}
								</button>
							</div>
						</form>
					) : (
						<form className='w-full mt-8 grid grid-cols-6 gap-6'>
							<div className='w-full col-span-6'>
								<h2 className='mt-6 text-2xl font-bold text-gray-800 sm:text-3xl md:text-4xl'>Login</h2>
							</div>

							<div className='w-full col-span-6'>
								<fieldset className='grid grid-cols-1 md:grid-cols-2 gap-4 select-none'>
									<legend className='sr-only'>User Type</legend>

									<div>
										<input
											type='radio'
											onChange={(e) => {
												setUserType(e.target.value);
											}}
											className='peer hidden [&:checked_+_label_svg]:block'
											id='userTypeStudent'
											name='userType'
											value='student'
										/>

										<label htmlFor='userTypeStudent' className='block cursor-pointer rounded-lg border border-gray-200 bg-white p-4 shadow-sm transition-all duration-300 ease-in-out hover:border-gray-400 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500'>
											<p className='text-base font-medium text-gray-800'>Student</p>
										</label>
									</div>

									<div>
										<input
											type='radio'
											onChange={(e) => {
												setUserType(e.target.value);
											}}
											className='peer hidden [&:checked_+_label_svg]:block'
											id='userTypeTeacher'
											name='userType'
											value='teacher'
										/>

										<label htmlFor='userTypeTeacher' className='block cursor-pointer rounded-lg border border-gray-200 bg-white p-4 shadow-sm transition-all duration-300 ease-in-out hover:border-gray-400 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500'>
											<p className='text-base font-medium text-gray-800'>Teacher</p>
										</label>
									</div>

									<div>
										<input
											type='radio'
											onChange={(e) => {
												setUserType(e.target.value);
											}}
											className='peer hidden [&:checked_+_label_svg]:block'
											id='userTypePrincipal'
											name='userType'
											value='principal'
										/>

										<label htmlFor='userTypePrincipal' className='block cursor-pointer rounded-lg border border-gray-200 bg-white p-4 shadow-sm transition-all duration-300 ease-in-out hover:border-gray-400 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500'>
											<p className='text-base font-medium text-gray-800'>Principal</p>
										</label>
									</div>

									<div>
										<input
											type='radio'
											onChange={(e) => {
												setUserType(e.target.value);
											}}
											className='peer hidden [&:checked_+_label_svg]:block'
											id='userTypeAdmin'
											name='userType'
											value='admin'
										/>

										<label htmlFor='userTypeAdmin' className='block cursor-pointer rounded-lg border border-gray-200 bg-white p-4 shadow-sm transition-all duration-300 ease-in-out hover:border-gray-400 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500'>
											<p className='text-base font-medium text-gray-800'>Administrator</p>
										</label>
									</div>

									<div>
										<input
											type='radio'
											onChange={(e) => {
												setUserType(e.target.value);
											}}
											className='peer hidden [&:checked_+_label_svg]:block'
											id='userTypeAlumni'
											name='userType'
											value='alumni'
										/>

										<label htmlFor='userTypeAlumni' className='block cursor-pointer rounded-lg border border-gray-200 bg-white p-4 shadow-sm transition-all duration-300 ease-in-out hover:border-gray-400 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500'>
											<p className='text-base font-medium text-gray-800'>Alumni</p>
										</label>
									</div>

									<div>
										<input
											type='radio'
											onChange={(e) => {
												setUserType(e.target.value);
											}}
											className='peer hidden [&:checked_+_label_svg]:block'
											id='userTypeParents'
											name='userType'
											value='parent'
										/>

										<label htmlFor='userTypeParents' className='block cursor-pointer rounded-lg border border-gray-200 bg-white p-4 shadow-sm transition-all duration-300 ease-in-out hover:border-gray-400 peer-checked:border-blue-500 peer-checked:ring-1 peer-checked:ring-blue-500'>
											<p className='text-base font-medium text-gray-800'>Parent</p>
										</label>
									</div>
								</fieldset>
							</div>

							<div className='w-full col-span-6 flex flex-col gap-4 sm:flex-row sm:items-center'>
								<button
									type='button'
									onClick={handleNext}
									disabled={isNextButtonDisabled}
									className={`inline-flex items-center justify-center shrink-0 rounded-md border border-blue-500 bg-blue-500 text-white px-4 py-2 gap-2 text-sm font-medium ${isNextButtonDisabled ? 'opacity-60 cursor-not-allowed' : 'hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'}`}>
									Next
								</button>
							</div>
						</form>
					)}
				</div>
			</main>

			<div className='block px-8 sm:px-12 lg:hidden'>
				<Footer />
			</div>
		</div>
	);
};

export default Login;
