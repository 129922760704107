import React, { useContext, useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import { AuthContext, DataContext } from '../../context';
import { MainContainer } from '../../components';
import { calculateGPAFromPercentage, generateUserGrades } from '../../utils';

const ParentReports = () => {
	useTitle('Reports | Grade Portal');

	const { currentUser } = useContext(AuthContext);
	const { appData, divRef, users, schools, handleScroll, subjects, currentSemesterDuration, assessments } = useContext(DataContext);

	const [children, setChildren] = useState([]);
	const [childrenLoading, setChildrenLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const fetchedChildren = await currentUser?.data?.children;
				let _children = [];

				for (const child of fetchedChildren) {
					const fetchedChild = await users?.find((user) => user?.id === child);
					const fetchedCurrentSchool = await schools?.find((school) => school?.id === fetchedChild?.school?.id);

					const fetchedChildWithData = {
						data: fetchedChild,
					};

					const allUserGrades = await generateUserGrades(await fetchedCurrentSchool, fetchedChildWithData, await assessments, null, null);
					const semesterUserGrades = await generateUserGrades(await fetchedCurrentSchool, fetchedChildWithData, await assessments, currentSemesterDuration, null);

					const totalPercentage = allUserGrades?.reduce((accumulator, currentGrade) => {
						return accumulator + parseInt(currentGrade?.grade);
					}, 0);

					const semesterPercentage = semesterUserGrades?.reduce((accumulator, currentGrade) => {
						return accumulator + parseInt(currentGrade?.grade);
					}, 0);

					const calculatedTotalAverage = totalPercentage / allUserGrades?.length;
					const calculatedSemesterAverage = semesterPercentage / semesterUserGrades?.length;

					const calculatedCumulativeGPA = calculateGPAFromPercentage(calculatedTotalAverage);
					const calculatedSemesterGPA = calculateGPAFromPercentage(calculatedSemesterAverage);

					_children?.push({
						userData: fetchedChild,
						school: fetchedCurrentSchool,
						grades: {
							cumulativeGPA: calculatedCumulativeGPA,
							semesterGPA: calculatedSemesterGPA,
							average: calculatedTotalAverage,
							grades: allUserGrades,
						},
					});
				}

				setChildren(_children);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setChildrenLoading(false);
		};

		fetchData();
	}, [appData?.debugMode, assessments, currentSemesterDuration, currentUser?.data?.children, schools, users]);

	console.log(children);
	console.log(children.length);

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<div className='flex flex-row items-center justify-between'>
					<div className='flex flex-row items-center justify-start gap-4'>
						<h3 className='text-lg font-bold text-theme-gray-800 sm:text-xl'>Children Performance Reports</h3>
					</div>
				</div>
			</section>

			{!childrenLoading &&
				children?.length > 0 &&
				children?.map((child, idx) => {
					const childName = child?.userData?.name?.first + ' ' + child?.userData?.name?.last;

					return (
						<section key={idx} className='w-full bg-theme-white'>
							<h3 className='text-base mb-2 font-bold text-theme-gray-800 sm:text-lg'>{childName || 'N/A'}</h3>

							<div className='w-full rounded-lg border border-theme-gray-200'>
								<div className='rounded-t-lg overflow-x-auto'>
									<table className='min-w-full rounded-lg divide-y-2 divide-theme-gray-200 bg-theme-white text-sm'>
										<thead className='w-full'>
											<th className='whitespace-nowrap px-4 py-2 font-medium text-left text-theme-gray-800'>Class ID</th>

											<th className='whitespace-nowrap px-4 py-2 font-medium text-left text-theme-gray-800'>Subject</th>

											<th className='whitespace-nowrap px-4 py-2 font-medium text-left text-theme-gray-800'>Teacher</th>

											<th className='whitespace-nowrap px-4 py-2 font-medium text-center text-theme-gray-800'>Status</th>

											<th className='whitespace-nowrap px-4 py-2 font-medium text-center text-theme-gray-800'>Class Average (%)</th>

											<th className='whitespace-nowrap px-4 py-2 font-medium text-center text-theme-gray-800'>Average (%)</th>
										</thead>

										<tbody className='w-full divide-y divide-theme-gray-200'>
											{!childrenLoading && !child?.grades?.grades ? (
												<tr>
													<td colSpan='7' className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>
														No data available
													</td>
												</tr>
											) : (
												child?.grades?.grades?.map((grade, idx) => {
													const currentSchool = schools?.find((school) => school?.id === grade?.schoolId) || {};
													const classInfo = currentSchool?.classes?.find((_class) => _class?.id === grade?.classId) || {};
													const subjectName = subjects?.find((subject) => subject?.id === classInfo?.subjectId)?.name || 'N/A';
													const teacher = users?.find((teacher) => teacher?.userType === 'teacher' && teacher?.id === classInfo?.teacherId) || {};
													const teacherName = teacher?.name ? teacher?.name?.first + ' ' + teacher?.name?.last : 'N/A';
													const average = grade?.grade / child?.grades?.grades?.length || 'N/A';

													return (
														<tr key={idx} className='odd:bg-theme-gray-50'>
															<td className='whitespace-nowrap px-4 py-2 text-left text-theme-gray-600'>{grade?.classId || 'N/A'}</td>

															<td className='whitespace-nowrap px-4 py-2 text-left text-theme-gray-600'>{subjectName}</td>

															<td className='whitespace-nowrap px-4 py-2 text-left text-theme-gray-600'>{teacherName}</td>

															<td className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>
																{average && average >= currentSchool?.passMark ? (
																	<span className='rounded-full px-2.5 py-0.5 text-xs bg-green-600 text-white uppercase'>Pass</span>
																) : average && average < currentSchool?.passMark ? (
																	<span className='rounded-full px-2.5 py-0.5 text-xs bg-red-600 text-white uppercase'>Fail</span>
																) : (
																	<span className='rounded-full px-2.5 py-0.5 text-xs bg-gray-600 text-white uppercase'>Unassigned</span>
																)}
															</td>

															<td className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>{average ? average?.toFixed(2) + '%' : 'N/A'}</td>

															<td className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>{grade?.lowestAverage ? grade?.lowestAverage.toFixed(2) + '%' : 'N/A'}</td>
														</tr>
													);
												})
											)}
										</tbody>
									</table>
								</div>
							</div>
						</section>
					);
				})}
		</MainContainer>
	);
};

export default ParentReports;
