import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { AuthContext, DataContext } from '../../context';
import { Loader, MainContainer } from '../../components';
import { ErrorHandling, useAlertHandling, useInputWithError } from '../../utils';

const EditSubject = () => {
	const { subjectId } = useParams();

	useTitle(`${subjectId ? 'Edit ' + subjectId : 'Edit Assessment'} | Grade Portal`);

	const { currentUser } = useContext(AuthContext);
	const { appData, divRef, subjects, handleScroll, editSubject } = useContext(DataContext);
	const { showAlert } = useAlertHandling();

	const [subject, setSubject] = useState(null);
	const [subjectLoading, setSubjectLoading] = useState(true);
	const [canAccessSubject, setCanAccessSubject] = useState(false);

	useEffect(() => {
		const fetchSubject = async () => {
			try {
				if (currentUser?.data?.userType === 'principal' || currentUser?.data?.userType === 'admin') {
					const _subject = subjects?.find((subject) => subject?.id === subjectId);

					setCanAccessSubject(_subject);
					setSubject(_subject);
				} else {
					setCanAccessSubject(false);
				}
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setSubjectLoading(false);
		};

		fetchSubject();
	}, [appData?.debugMode, currentUser?.data?.userType, subjects, subjectId]);

	const { value: name, setValue: setName, error: nameError, setError: setNameError } = useInputWithError('');
	const [description, setDescription] = useState(null);

	const [handleEditSubjectLoading, setHandleEditSubjectLoading] = useState(false);

	const handleEditSubject = async (e) => {
		e.preventDefault();
		setHandleEditSubjectLoading(true);

		let editedSubjectData = {
			id: subject?.id,
			name: name || subject?.name || '',
			desc: description || subject?.description || '',
			creationDate: subject?.creationDate,
			addedBy: subject?.addedBy,
		};

		if (await ErrorHandling?.handleSubjectNameError(editedSubjectData?.name, setNameError, showAlert)) {
			setHandleEditSubjectLoading(false);
		} else {
			try {
				await editSubject(editedSubjectData);
				setHandleEditSubjectLoading(false);

				window.location.href = `/subjects/${editedSubjectData?.id}`;
			} catch (error) {
				if (appData.debugMode) {
					console.error(error);
				}

				showAlert('error', 'An unknown error occurred');
			}
		}

		setHandleEditSubjectLoading(false);
	};

	if (!canAccessSubject) {
		return (
			<MainContainer divRef={divRef} handleScroll={handleScroll}>
				<section className='bg-theme-white py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
					<div className='mx-auto max-w-screen-sm text-center'>
						<h1 className='mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-theme-gray-800'>404</h1>

						<p className='mb-4 text-3xl tracking-tight font-bold text-blue-500 md:text-4xl'>Oops! Subject Not Found</p>

						<p className='mb-8 text-lg text-theme-gray-600'>
							It seems that the subject you're trying to access isn't available or authorized for your account. Don't worry, you can explore all subjects{' '}
							<Link to='/subjects' className='focus:outline-none focus:ring text-blue-500 hover:opacity-80 active:opacity-80'>
								here
							</Link>
							.
						</p>

						<Link to='/' className='inline-block shrink-0 rounded-md border px-5 py-2.5 text-sm font-medium focus:outline-none focus:ring text-white border-blue-500 bg-blue-500 hover:bg-transparent hover:text-blue-500 active:text-blue-500'>
							Back to Homepage
						</Link>
					</div>
				</section>
			</MainContainer>
		);
	}

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<h3 className='text-2xl font-bold text-theme-gray-800'>Edit Subject - {subject?.name}</h3>
			</section>

			<section className='w-full bg-theme-white'>
				{!subjectLoading && subject && (
					<form autoComplete='off'>
						<div className='grid grid-cols-12 gap-4 mt-4'>
							<div className='w-full col-span-12'>
								<label htmlFor='name' className='block text-sm font-medium text-theme-gray-800'>
									Name
									<span className='text-red-500 ml-1'>*</span>
								</label>

								<input
									type='text'
									onChange={(e) => {
										e.preventDefault();
										setName(e.target.value);
									}}
									defaultValue={name || subject?.name || ''}
									className={`mt-1 w-full rounded-md border ${nameError ? 'border-red-500 text-red-500 focus:border-red-500 focus:ring-red-500' : 'border-theme-gray-200 text-theme-gray-800'} bg-theme-white text-sm shadow-sm focus:outline-none`}
									id='name'
									required
								/>

								{nameError && <span className='mt-2 text-sm text-red-500'>{nameError}</span>}
							</div>

							<div className='w-full col-span-12'>
								<label htmlFor='description' className='block text-sm font-medium text-theme-gray-800'>
									Description
								</label>

								<textarea
									onChange={(e) => {
										e.preventDefault();
										setDescription(e.target.value);
									}}
									id='description'
									rows='5'
									defaultValue={description || subject?.desc || ''}
									className='mt-1 w-full rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'></textarea>
							</div>
						</div>

						<hr className='my-8' />

						<div className='w-full mt-4 col-span-6 flex flex-col gap-4 sm:flex-row sm:items-center'>
							<button
								type='submit'
								onClick={handleEditSubject}
								disabled={handleEditSubjectLoading}
								className={`inline-flex items-center justify-center shrink-0 rounded-md border border-blue-500 bg-blue-500 text-white px-4 py-2 gap-2 text-sm font-medium ${handleEditSubjectLoading ? 'opacity-60 cursor-not-allowed' : 'hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'}`}>
								{handleEditSubjectLoading ? (
									<div className='flex items-center justify-center'>
										<Loader />
									</div>
								) : (
									'Edit Subject'
								)}
							</button>
						</div>
					</form>
				)}
			</section>
		</MainContainer>
	);
};

export default EditSubject;
