import React, { useContext, useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useFullscreen, useToggle } from 'react-use';
import { DataContext, AuthContext } from './context';
import { Sidebar, Header, Loader, AlertContainer, NetworkStateOffline } from './components';
import {
	StudentDashboard,
	TeacherDashboard,
	PrincipalDashboard,
	AdminDashboard,
	AlumniDashboard,
	ParentDashboard,
	Schools,
	School,
	SchoolClasses,
	SchoolClass,
	NewSchool,
	Classes,
	Class,
	NewClass,
	NewAssessment,
	Subjects,
	Subject,
	NewSubject,
	Students,
	Student,
	NewStudent,
	Chats,
	Chat,
	NewReport,
	Settings,
	Faq,
	Contact,
	TermsConditions,
	PrivacyPolicy,
	Login,
	SignUp,
	ForgotPassword,
	ResetPassword,
	AuthenticatedNotFound,
	UnauthenticatedNotFound,
	Unauthorized,
	Children,
	AddStudents,
	Assessment,
	EditAssessment,
	EditSubject,
	EditClass,
	StudentReports,
	TeacherReports,
	ParentReports,
	AlumniReports,
	AdminReports,
	PrincipalReports,
	EditStudent,
} from './pages';
import './assets/scss/style.css';

const DashboardPage = ({ userType }) => {
	switch (userType) {
		case 'student':
			return <StudentDashboard />;
		case 'teacher':
			return <TeacherDashboard />;
		case 'principal':
			return <PrincipalDashboard />;
		case 'admin':
			return <AdminDashboard />;
		case 'alumni':
			return <AlumniDashboard />;
		case 'parent':
			return <ParentDashboard />;
		default:
			return null;
	}
};

const ReportsPage = ({ userType }) => {
	switch (userType) {
		case 'student':
			return <StudentReports />;
		case 'teacher':
			return <TeacherReports />;
		case 'principal':
			return <PrincipalReports />;
		case 'admin':
			return <AdminReports />;
		case 'alumni':
			return <AlumniReports />;
		case 'parent':
			return <ParentReports />;
		default:
			return null;
	}
};

const App = () => {
	const { currentUser } = useContext(AuthContext);
	const { appData, networkState, loading, setLoading } = useContext(DataContext);

	const fullscreenRef = useRef(null);
	const [toggleFullscreen, setToggleFullscreen] = useToggle(false);

	const isFullscreen = useFullscreen(fullscreenRef, toggleFullscreen, {
		onClose: () => setToggleFullscreen(false),
	});

	const [secondaryLoader, setSecondaryLoader] = useState(true);

	useEffect(() => {
		const timer = setTimeout(() => {
			setSecondaryLoader(false);
		}, appData?.secondaryLoaderTime);

		return () => clearTimeout(timer);
	}, [appData?.secondaryLoaderTime, setLoading]);

	if (loading || secondaryLoader) {
		return (
			<div className='h-screen w-full flex flex-col items-center justify-center gap-8'>
				<div className='flex flex-col flex-shrink-0 px-4 animate-pulse'>
					<div className='flex flex-col items-center justify-center gap-2'>
						<img className='size-24 mx-auto' src={appData?.logo} alt='Grade Portal Logo' />

						<span className='text-xl font-semibold tracking-tighter text-theme-gray-800'>Grade Portal</span>
					</div>
				</div>

				<Loader size='lg' />
			</div>
		);
	}

	return (
		<div className='relative w-full h-full'>
			{!networkState?.online && <NetworkStateOffline />}

			<Router>
				{currentUser ? (
					<div ref={fullscreenRef} className='h-screen w-full flex overflow-hidden bg-theme-white'>
						<Sidebar />

						<div className='flex flex-col flex-1 w-full overflow-hidden'>
							<Header isFullscreen={isFullscreen} toggleFullscreen={setToggleFullscreen} />

							<Routes>
								<Route path='/' element={<DashboardPage userType={currentUser?.data?.userType} />} />

								<Route path='/schools' element={<Schools />} />
								<Route path='/schools/:schoolId' element={<School />} />
								<Route path='/schools/new' element={<NewSchool />} />

								<Route path='/schools/:schoolId/classes' element={<SchoolClasses />} />
								<Route path='/schools/:schoolId/classes/:classId' element={<SchoolClass />} />
								<Route path='/schools/:schoolId/classes/new' element={<NewClass />} />
								{/* <Route path='/schools/:schoolId/classes/:classId/assessments' element={<School />} /> */}
								{/* <Route path='/schools/:schoolId/classes/:classId/assessments/new' element={<School />} /> */}

								<Route path='/classes' element={<Classes />} />
								<Route path='/classes/:classId' element={<Class />} />
								<Route path='/classes/:classId/edit' element={<EditClass />} />
								<Route path='/classes/new' element={<NewClass />} />
								<Route path='/classes/:classId/edit' element={<EditClass />} />
								<Route path='/classes/:classId/assessments/:assessmentId' element={<Assessment />} />
								<Route path='/classes/:classId/assessments/new' element={<NewAssessment />} />
								<Route path='/classes/:classId/assessments/:assessmentId/edit' element={<EditAssessment />} />
								<Route path='/classes/:classId/students/add' element={<AddStudents />} />

								<Route path='/classes/:classId/assessments/:assessmentId' element={<Assessment />} />
								<Route path='/classes/:classId/assessments/:assessmentId/edit' element={<EditAssessment />} />
								<Route path='/classes/:classId/assessments/new' element={<NewAssessment />} />

								<Route path='/subjects' element={<Subjects />} />
								<Route path='/subjects/:subjectId' element={<Subject />} />
								<Route path='/subjects/:subjectId/edit' element={<EditSubject />} />
								<Route path='/subjects/new' element={<NewSubject />} />

								<Route path='/students' element={<Students />} />
								<Route path='/students/:studentId' element={<Student />} />
								<Route path='/students/new' element={<NewStudent />} />
								<Route path='/students/:studentId/edit' element={<EditStudent />} />

								<Route path='/chats' element={<Chats />} />
								<Route path='/chats/:chatId' element={<Chat />} />

								<Route path='/children' element={<Children />} />

								<Route path='/reports' element={<ReportsPage userType={currentUser?.data?.userType} />} />
								<Route path='/reports/new' element={<NewReport />} />

								<Route path='/settings' element={<Settings />} />
								<Route path='/profile' element={<Navigate to='/settings' />} />

								<Route path='/faq' element={<Faq />} />
								<Route path='/Contact' element={<Contact />} />
								<Route path='/terms' element={<TermsConditions />} />
								<Route path='/privacy' element={<PrivacyPolicy />} />

								<Route path='/auth/login' element={<Navigate to='/' />} />
								<Route path='/auth/register' element={<Navigate to='/' />} />
								<Route path='/auth/forgot-password' element={<ForgotPassword />} />
								<Route path='/auth/reset-password' element={<ResetPassword />} />

								<Route path='/404' element={<AuthenticatedNotFound />} status={404} />
								<Route path='*' element={<AuthenticatedNotFound />} status={404} />
							</Routes>
						</div>
					</div>
				) : (
					<Routes>
						<Route path='/' element={<Navigate to='/auth/login' />} />

						<Route path='/schools' element={<Unauthorized status={403} />} />
						<Route path='/schools/:schoolId' element={<Unauthorized status={403} />} />
						<Route path='/schools/new' element={<Unauthorized status={403} />} />

						<Route path='/classes' element={<Unauthorized status={403} />} />
						<Route path='/classes/:classId' element={<Unauthorized status={403} />} />
						<Route path='/classes/new' element={<Unauthorized status={403} />} />
						<Route path='/classes/:classId/assessments/new' element={<Unauthorized status={403} />} />

						<Route path='/subjects' element={<Unauthorized status={403} />} />
						<Route path='/subjects/:subjectId' element={<Unauthorized status={403} />} />
						<Route path='/subjects/new' element={<Unauthorized status={403} />} />

						<Route path='/students' element={<Unauthorized status={403} />} />
						<Route path='/students/:studentId' element={<Unauthorized status={403} />} />
						<Route path='/students/new' element={<Unauthorized status={403} />} />

						<Route path='/chats' element={<Unauthorized status={403} />} />
						<Route path='/chats/:chatId' element={<Unauthorized status={403} />} />
						<Route path='/chats/new' element={<Unauthorized status={403} />} />

						<Route path='/reports' element={<Unauthorized status={403} />} />
						<Route path='/reports/:reportId' element={<Unauthorized status={403} />} />
						<Route path='/reports/new' element={<Unauthorized status={403} />} />

						<Route path='/settings' element={<Unauthorized status={403} />} />
						<Route path='/profile' element={<Navigate to='/settings' />} />

						<Route path='/faq' element={<Faq />} />
						<Route path='/contact' element={<Contact />} />
						<Route path='/terms' element={<TermsConditions />} />
						<Route path='/privacy' element={<PrivacyPolicy />} />

						<Route path='/auth/login' element={<Login />} />
						<Route path='/auth/register' element={<SignUp />} />
						<Route path='/auth/forgot-password' element={<ForgotPassword />} />
						<Route path='/auth/reset-password' element={<ResetPassword />} />

						<Route path='/404' element={<UnauthenticatedNotFound />} status={404} />
						<Route path='*' element={<UnauthenticatedNotFound />} status={404} />
					</Routes>
				)}
			</Router>

			<AlertContainer />
		</div>
	);
};

export default App;

// fix terms, help, faq, privacy pages

// add loading to buttons
// forgot password
// firebase login ; firebase init ; firebase deploy

// https://www.education.gov.lc/school-supervision/district-two/
// https://github.com/streamich/react-use

// FEATURES:
// When user is idle for __ minutes, automatically logout for security -> https://github.com/streamich/react-use/blob/master/docs/useIdle.md
// When network connection is lost, show error -> https://github.com/streamich/react-use/blob/master/docs/useNetworkState.md
// copy to clipboard

// add classId to new student form

// add features to readme.md
// add faq to readme.md

//

// side bar -> <img className='inline-block object-cover rounded-full h-10 w-10' src={'https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=' + currentUser?.displayName + currentUser?.displayName} alt={currentUser?.displayName} /> */}
// system dark mode
// login and sign up page paragrph

// QUESTIONS:

// should students be able to create an account?
// should alumni be able to create an account?
// should parents be able to create an account?

// should i enable creation/deletion of accounts?

// {/* <div class="relative">
//     <img class="w-10 h-10 rounded-full" src="/docs/images/people/profile-picture-5.jpg" alt="">
//     <span class="top-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
// </div>
// <div class="relative">
//     <img class="w-10 h-10 rounded" src="/docs/images/people/profile-picture-5.jpg" alt="">
//     <span class="absolute top-0 left-8 transform -translate-y-1/2 w-3.5 h-3.5 bg-red-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
// </div>
// <div class="relative">
//     <img class="w-10 h-10 rounded-full" src="/docs/images/people/profile-picture-5.jpg" alt="">
//     <span class="bottom-0 left-7 absolute  w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
// </div>
// <div class="relative">
//     <img class="w-10 h-10 rounded" src="/docs/images/people/profile-picture-5.jpg" alt="">
//     <span class="absolute bottom-0 left-8 transform translate-y-1/4 w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
// </div> */}

// {/* <div class="flex -space-x-4 rtl:space-x-reverse">
//     <img class="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800" src="/docs/images/people/profile-picture-5.jpg" alt="">
//     <img class="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800" src="/docs/images/people/profile-picture-2.jpg" alt="">
//     <img class="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800" src="/docs/images/people/profile-picture-3.jpg" alt="">
//     <img class="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800" src="/docs/images/people/profile-picture-4.jpg" alt="">
// </div>
// <div class="flex -space-x-4 rtl:space-x-reverse">
//     <img class="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800" src="/docs/images/people/profile-picture-5.jpg" alt="">
//     <img class="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800" src="/docs/images/people/profile-picture-2.jpg" alt="">
//     <img class="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800" src="/docs/images/people/profile-picture-3.jpg" alt="">
//     <a class="flex items-center justify-center w-10 h-10 text-xs font-medium text-white bg-gray-700 border-2 border-white rounded-full hover:bg-gray-600 dark:border-gray-800" href="#">+99</a>
// </div> */}

// download csv schools
// add school?.emailAddress to schools
// fix numbers in schools
// sort in schools and schoolclasses

// npx tailwindcss -i ./src/assets/scss/style.css -o ./public/css/style.css --watch

// TEACHER DASHBOARD
// Grade Distribution: A histogram or bar chart showing the distribution of grades on assignments, quizzes, or exams can help teachers understand how students are performing overall and identify areas where additional support may be needed.

// Student Progress Over Time: Line graphs or area charts can be used to visualize individual student progress over time. This could include grades on assignments or assessments, participation levels, or behavior metrics.

// Class Average Performance: A line graph or bar chart showing the class average performance on assignments or assessments over time can help teachers track overall progress and identify trends.

// Attendance Trends: A line graph or pie chart showing attendance trends over time can help teachers monitor student attendance patterns and identify any issues that may need to be addressed.

// Student Comparison: A side-by-side bar chart or radar chart comparing the performance of individual students can help teachers identify strengths and weaknesses and tailor their instruction accordingly.

// Gradebook Overview: A table or heatmap summarizing grades for each student across different assignments or assessments can provide a quick overview of student performance and help teachers identify areas where additional support may be needed.

// Parental Engagement: A bar chart or line graph showing parental engagement metrics, such as attendance at parent-teacher conferences or responses to communication, can help teachers assess the level of parental involvement and identify opportunities for improvement.

// Subject Performance Comparison: A stacked bar chart or grouped bar chart comparing student performance across different subjects or topics can help teachers identify areas of strength and weakness in the curriculum and adjust their teaching strategies accordingly.

// Goal Progress: A progress bar or line graph tracking students' progress towards academic or behavioral goals can help motivate students and provide feedback on their efforts.
