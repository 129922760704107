import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { AuthContext, DataContext } from '../../context';
import { MainContainer, UserProfileInformation, UserSchoolDetails, UserPersonalDetails, UserContactDetails, UserAddressDetails, UserParentInformation, DeleteModal } from '../../components';
import { useAlertHandling } from '../../utils';

const Student = () => {
	const { studentId } = useParams();
	const { currentUser } = useContext(AuthContext);
	const { appData, divRef, users, currentSchool, handleScroll, schools, deleteStudent } = useContext(DataContext);
	const { showAlert } = useAlertHandling();

	const [student, setStudent] = useState(null);
	const [school, setSchool] = useState(null);
	const [studentLoading, setStudentLoading] = useState(true);
	const [canAccessStudent, setCanAccessStudent] = useState(false);

	useTitle(`${student ? `${student?.name?.first} ${student?.name?.last} (${student?.id})` : 'Student'} | Grade Portal`);

	useEffect(() => {
		const fetchStudent = async () => {
			try {
				if (currentUser?.data?.userType === 'student' || currentUser?.data?.userType === 'alumni') {
					setCanAccessStudent(studentId === currentUser?.data?.id);
				} else if (currentUser?.data?.userType === 'teacher' || currentUser?.data?.userType === 'principal') {
					const studentInSchool = users?.find((user) => user?.userType === 'student' && user?.id === studentId && user?.school?.id === currentSchool?.id);
					setCanAccessStudent(studentInSchool);
				} else if (currentUser?.data?.userType === 'admin') {
					setCanAccessStudent(true);
				} else if (currentUser?.data?.userType === 'parent') {
					const childExists = currentUser?.data?.children?.some((childId) => childId === studentId);
					setCanAccessStudent(childExists);
				} else {
					setCanAccessStudent(false);
				}

				const _student = users?.find((user) => user?.userType === 'student' && user?.id === studentId);
				const _school = await schools?.find((school) => school?.id === _student?.school?.id);

				setStudent(_student);
				setSchool(_school);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setStudentLoading(false);
		};

		fetchStudent();
	}, [appData?.debugMode, currentUser?.data?.children, currentSchool?.id, schools, currentUser?.data?.id, currentUser?.data?.userType, studentId, users]);

	const [deleteStudentModalState, setDeleteStudentModalState] = useState(false);

	const handleDeleteStudent = async (e) => {
		e.preventDefault();

		try {
			if (studentId) {
				await deleteStudent(studentId);
				window.location.href = '/students';
			} else {
				if (appData.debugMode) {
					console.error("Student ID can't be empty.");
				}

				showAlert('error', 'An unknown error occurred');
			}
		} catch (err) {
			if (appData?.debugMode) {
				console.error(err);
			}

			showAlert('error', 'An unknown error occurred');
		}

		setDeleteStudentModalState(false);
	};

	if (!canAccessStudent && !studentLoading) {
		return (
			<MainContainer divRef={divRef} handleScroll={handleScroll}>
				<section className='bg-theme-white py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
					<div className='mx-auto max-w-screen-sm text-center'>
						<h1 className='mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-theme-gray-800'>404</h1>

						<p className='mb-4 text-3xl tracking-tight font-bold text-blue-500 md:text-4xl'>Oops! Student Not Found</p>

						<p className='mb-8 text-lg text-theme-gray-600'>
							It seems that the student you're trying to access isn't available or authorized for your account. Don't worry, you can explore your students{' '}
							<Link to='/students' className='focus:outline-none focus:ring text-blue-500 hover:opacity-80 active:opacity-80'>
								here
							</Link>
							.
						</p>

						<Link to='/' className='inline-block shrink-0 rounded-md border px-5 py-2.5 text-sm font-medium focus:outline-none focus:ring text-white border-blue-500 bg-blue-500 hover:bg-transparent hover:text-blue-500 active:text-blue-500'>
							Back to Homepage
						</Link>
					</div>
				</section>
			</MainContainer>
		);
	}

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<div className='flex flex-row items-center justify-between'>
					<div className='flex flex-row items-center justify-start gap-4'>
						<h3 className='text-lg font-bold text-theme-gray-800 sm:text-xl'>{student ? `${student?.name?.first} ${student?.name?.last} (${student?.id})` : 'Student'}</h3>
					</div>

					{(currentUser?.data?.userType === 'principal' || currentUser?.data?.userType === 'admin') && (
						<div className='inline-flex items-center justify-end gap-4'>
							<Link type='button' to={`/students/${studentId}/edit`} className='inline-block shrink-0 rounded-md border border-blue-500 bg-blue-500 px-4 py-2 text-sm font-medium text-white transition-all duration-300 ease-in-out hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'>
								Edit
							</Link>

							<button
								type='button'
								onClick={(e) => {
									e.preventDefault();
									setDeleteStudentModalState(true);
								}}
								className='inline-block shrink-0 rounded-md border border-red-500 bg-red-500 px-4 py-2 text-sm font-medium text-white transition-all duration-300 ease-in-out ring-red-300 hover:bg-transparent hover:text-red-500 focus:outline-none focus:ring active:text-red-500'>
								Delete
							</button>
						</div>
					)}
				</div>
			</section>

			<section className='w-full bg-theme-white'>
				{!studentLoading && student && (
					<div className='grid grid-cols-3 gap-6'>
						<div className='col-span-3 xl:col-span-1 flex flex-col gap-6'>
							<UserProfileInformation user={student} />

							<UserSchoolDetails user={student} school={school} students={null} />
						</div>

						<div className='col-span-3 xl:col-span-2 flex flex-col gap-6'>
							<UserPersonalDetails user={student} />

							<UserContactDetails user={student} />

							<UserAddressDetails user={student} />

							<UserParentInformation user={student} />
						</div>
					</div>
				)}
			</section>

			<DeleteModal title='Student' deleteId={studentId} handleDelete={handleDeleteStudent} deleteModalState={deleteStudentModalState} setDeleteModalState={setDeleteStudentModalState} />
		</MainContainer>
	);
};

export default Student;
