import React, { useContext } from 'react';
import { useTitle } from 'react-use';
import { Link } from 'react-router-dom';
import { DataContext } from '../../context';

const UnauthenticatedNotFound = () => {
	useTitle('404 | Grade Portal');

	const { appData } = useContext(DataContext);

	return (
		<div className='overflow-y-auto bg-white lg:grid lg:min-h-screen lg:grid-cols-12'>
			<section className='relative flex h-32 items-end bg-blue-500 lg:col-span-5 lg:h-full xl:col-span-6'>
				<img className='absolute inset-0 h-full w-full object-cover opacity-80' src='https://images.pexels.com/photos/18003175/pexels-photo-18003175/free-photo-of-the-view-of-the-st-lucia-mountains-from-the-sea.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1' alt='Saint Lucia Pitons' />

				<div className='hidden lg:relative lg:block lg:p-12'>
					<h2 className='mt-6 text-2xl font-bold text-white sm:text-3xl md:text-4xl'>Welcome to Grade Portal</h2>

					<p className='mt-4 leading-relaxed text-white/90'>Unlock your academic journey with ease. Log in now to access grades, assignments, and important announcements.</p>
				</div>
			</section>

			<main className='flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6'>
				<div className='w-full max-w-xl lg:max-w-3xl'>
					<div className='relative -mt-16 block lg:hidden'>
						<div className='inline-flex h-16 w-16 items-center justify-center rounded-full bg-white text-blue-600 sm:h-20 sm:w-20'>
							<span className='sr-only'>Home</span>

							<img className='w-12 h-12 mx-auto' src={appData.logo} alt='Grade Portal Logo' />
						</div>

						<h1 className='mt-2 text-2xl font-bold text-gray-800 sm:text-3xl md:text-4xl'>Welcome to Grade Portal</h1>

						<p className='mt-4 leading-relaxed text-gray-600'>Unlock your academic journey with ease. Log in now to access grades, assignments, and important announcements.</p>
					</div>

					<div className='bg-theme-white py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
						<div className='mx-auto max-w-screen-sm text-center'>
							<h1 className='mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-theme-gray-800'>404</h1>

							<p className='mb-4 text-3xl tracking-tight font-bold text-blue-500 md:text-4xl'>Oops! Page Not Found</p>

							<p className='mb-8 text-lg text-theme-gray-600'>
								It seems you've stumbled upon a page that doesn't exist. Don't worry, you login{' '}
								<Link to='/auth/login' className='focus:outline-none focus:ring text-blue-500 hover:opacity-80 active:opacity-80'>
									here
								</Link>
								.
							</p>

							<Link to='/auth/login' className='inline-block shrink-0 rounded-md border px-5 py-2.5 text-sm font-medium focus:outline-none focus:ring text-white border-blue-500 bg-blue-500 hover:bg-transparent hover:text-blue-500 active:text-blue-500'>
								Login to your account
							</Link>
						</div>
					</div>
				</div>
			</main>
		</div>
	);
};

export default UnauthenticatedNotFound;
