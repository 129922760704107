import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext, DataContext } from '../context';
import { useAlertHandling } from '../utils';

const Header = ({ isFullscreen, toggleFullscreen }) => {
	const { currentUser } = useContext(AuthContext);
	const { appData, sidebarOpen, setSidebarOpen, currentSchool, isDarkMode, updateTheme, isScrolled } = useContext(DataContext);
	const { showAlert } = useAlertHandling();

	const [themeChangeLoading, setThemeChangeLoading] = useState(false);

	return (
		<header className={`select-none bg-theme-white ${isScrolled && 'shadow'} transition-shadow duration-300 ease-in-out z-40 h-[10vh] w-full max-w-screen-xl px-4 sm:px-6 lg:px-8`}>
			<div className='h-[10vh] w-full flex flex-row items-center justify-between'>
				<div className='flex lg:hidden flex-shrink-0 px-4'>
					<Link to='/' className='flex flex-row items-center justify-center gap-4 focus:outline-none'>
						<img className='w-8 h-8 mx-auto' src={appData.logo} alt='Grade Portal Logo' />

						<span className='text-lg font-semibold tracking-tighter text-theme-gray-800'>Grade Portal</span>
					</Link>
				</div>

				<h3 className='hidden md:block text-lg font-bold text-theme-gray-800 sm:text-xl'>{currentUser?.data?.userType === 'admin' ? 'Ministry of Education' : currentSchool?.name}</h3>

				<div className='flex items-center justify-end text-theme-gray-800 gap-4'>
					<div className='flex items-center gap-4'>
						<Link to='/faq' className='hidden md:block shrink-0 text-theme-gray-800 text-sm hover:text-blue-500'>
							FAQ
						</Link>
					</div>

					<span className='hidden md:block h-6 w-px rounded-full bg-theme-gray-200'></span>

					<div className='flex items-center gap-4'>
						<Link to='/contact' className='hidden md:block shrink-0 text-theme-gray-800 text-sm hover:text-blue-500'>
							Contact
						</Link>

						<button
							type='button'
							onClick={async (e) => {
								e.preventDefault();
								setThemeChangeLoading(true);

								let newTheme = null;

								if (isDarkMode) {
									newTheme = 'Light';
								} else {
									newTheme = 'Dark';
								}

								try {
									// await updateTheme(appData, showAlert, currentUser?.user?.uid, newTheme);
									await updateTheme(currentUser?.user?.uid, newTheme);
									setThemeChangeLoading(false);
								} catch (error) {
									if (appData?.debugMode) {
										console.error(error);
									}

									setThemeChangeLoading(false);
									showAlert('error', 'An unknown error occurred');
								}
							}}
							disabled={themeChangeLoading}>
							<svg className='size-4 transition-all duration-300 ease-in-out fill-theme-gray-800 hover:fill-blue-500' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
								{isDarkMode ? (
									<path d='M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708' />
								) : (
									<path d='M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278M4.858 1.311A7.27 7.27 0 0 0 1.025 7.71c0 4.02 3.279 7.276 7.319 7.276a7.32 7.32 0 0 0 5.205-2.162q-.506.063-1.029.063c-4.61 0-8.343-3.714-8.343-8.29 0-1.167.242-2.278.681-3.286' />
								)}
							</svg>
						</button>

						<button type='button' onClick={toggleFullscreen}>
							<svg className='size-4 transition-all duration-300 ease-in-out fill-theme-gray-800 hover:fill-blue-500' xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' viewBox='0 0 16 16'>
								{isFullscreen ? (
									<path d='M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5m5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5M0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5m10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0z' />
								) : (
									<path d='M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5M.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5' />
								)}
							</svg>
						</button>
					</div>

					<span className='block lg:hidden h-6 w-px rounded-full bg-theme-gray-200'></span>

					<div className='flex lg:hidden items-center gap-4'>
						<button type='button' onClick={() => setSidebarOpen(!sidebarOpen)} disabled={sidebarOpen} className='group text-theme-gray-800 flex flex-row items-center justify-center rounded-lg ring-0 outline-none hover:text-blue-500 focus:text-blue-500 focus:ring-0 focus:outline-none disabled:text-blue-500 disabled:hover:text-blue-500'>
							<span className='mr-2 text-sm'>Menu</span>

							<svg className={`size-6 transition-all duration-300 ease-in-out ${sidebarOpen ? 'fill-blue-500 hover:fill-blue-500 focus:fill-blue-500' : 'fill-theme-gray-800 group-hover:fill-blue-500 group-focus:fill-blue-500'}`} fill='currentColor' viewBox='0 0 20 20'>
								{sidebarOpen ? (
									<path fillRule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clipRule='evenodd'></path>
								) : (
									<path fillRule='evenodd' d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z' clipRule='evenodd'></path>
								)}
							</svg>
						</button>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
