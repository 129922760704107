import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { AuthContext, DataContext } from '../../context';
import { Assessments, MainContainer } from '../../components';

const Assessment = () => {
	const { classId, assessmentId } = useParams();

	useTitle(`${assessmentId ? assessmentId : 'Assessment'} | Grade Portal`);

	const { currentUser } = useContext(AuthContext);
	const { appData, divRef, handleScroll, currentSchool, subjects, assessments, users } = useContext(DataContext);

	const [assessment, setAssessment] = useState(null);
	const [assessmentAddedBy, setAssessmentAddedBy] = useState(null);
	const [assessmentLoading, setAssessmentLoading] = useState(true);

	useEffect(() => {
		const fetchAssessments = async () => {
			try {
				const classAssessments = await assessments?.filter((assessment) => assessment?.classId === classId);
				const _assessment = await classAssessments?.find((assessment) => assessment?.id === assessmentId);
				const _assessmentAddedBy = await users?.find((user) => user?.id === _assessment?.addedBy);

				setAssessment(_assessment);
				setAssessmentAddedBy(_assessmentAddedBy);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setAssessmentLoading(false);
		};

		fetchAssessments();
	}, [appData?.debugMode, assessments, assessmentId, classId, users]);

	const [subject, setSubject] = useState(null);
	const [subjectLoading, setSubjectLoading] = useState(true);

	const [classTeacher, setClassTeacher] = useState(null);
	const [classTeacherLoading, setClassTeacherLoading] = useState(true);
	const [canAccessAssessment, setCanAccessAssessment] = useState(false);

	useEffect(() => {
		let filteredClasses = [];

		if (currentUser?.data?.userType === 'teacher') {
			filteredClasses = currentSchool?.classes?.filter((_class) => _class?.teacherId === currentUser?.data?.id);
		} else if (currentUser?.data?.userType === 'principal' && currentUser?.data?.school?.id === currentSchool?.id) {
			filteredClasses = currentSchool?.classes;
		}

		const foundClass = filteredClasses?.find((_class) => _class?.id === classId);

		const fetchSubject = async (foundClass) => {
			try {
				const foundSubject = subjects?.find((subject) => subject?.id === foundClass?.subjectId);
				setSubject(foundSubject);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setSubjectLoading(false);
		};

		const fetchTeacher = async (foundClass) => {
			try {
				const foundClassTeacher = users?.find((user) => user?.id === foundClass?.teacherId);
				setClassTeacher(foundClassTeacher);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setClassTeacherLoading(false);
		};

		if (foundClass && assessment) {
			fetchSubject(foundClass);
			fetchTeacher(foundClass);
			setCanAccessAssessment(true);
		} else {
			setCanAccessAssessment(false);
		}
	}, [currentSchool?.classes, currentUser?.data?.id, users, currentSchool?.id, currentUser?.data?.school?.id, currentUser?.data?.userType, appData?.debugMode, classId, subjects, assessment]);

	if (!canAccessAssessment) {
		return (
			<MainContainer divRef={divRef} handleScroll={handleScroll}>
				<section className='bg-theme-white py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
					<div className='mx-auto max-w-screen-sm text-center'>
						<h1 className='mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-theme-gray-800'>404</h1>

						<p className='mb-4 text-3xl tracking-tight font-bold text-blue-500 md:text-4xl'>Oops! Assessment Not Found</p>

						<p className='mb-8 text-lg text-theme-gray-600'>
							It seems that the assessment you're trying to access isn't available or authorized for your account. Don't worry, you can explore your classes{' '}
							<Link to='/classes' className='focus:outline-none focus:ring text-blue-500 hover:opacity-80 active:opacity-80'>
								here
							</Link>
							.
						</p>

						<Link to='/' className='inline-block shrink-0 rounded-md border px-5 py-2.5 text-sm font-medium focus:outline-none focus:ring text-white border-blue-500 bg-blue-500 hover:bg-transparent hover:text-blue-500 active:text-blue-500'>
							Back to Homepage
						</Link>
					</div>
				</section>
			</MainContainer>
		);
	}

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<h3 className='text-2xl font-bold text-theme-gray-800'>
					{assessment && classId ? (
						<>
							{assessment?.name} - <Link to={`/classes/${classId}`}>{classId}</Link>
						</>
					) : (
						'Assessment'
					)}
				</h3>
			</section>

			<section className='w-full bg-theme-white'>
				<div className='grid grid-cols-3 gap-6'>
					<div className='col-span-3 md:col-span-2 flex flex-col items-start gap-6'>
						<div className='w-full bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
							<div className='mb-4 flex flex-row items-center justify-between'>
								<h3 className='text-xl font-bold text-theme-gray-800'>Assessment Details</h3>

								{currentUser?.data?.userType === 'teacher' && (
									<Link type='button' to={`/classes/${classId}/assessments/${assessmentId}/edit`} className='inline-block shrink-0 rounded-md border border-blue-500 bg-blue-500 px-4 py-2 text-sm font-medium text-white transition-all duration-300 ease-in-out hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'>
										Edit
									</Link>
								)}
							</div>

							<div className='flow-root'>
								<dl className='-my-3 divide-y divide-theme-gray-200 text-sm'>
									<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-6 md:gap-4'>
										<dt className='font-bold text-theme-gray-800 col-span-2 md:col-span-1'>Name:</dt>

										<dd className='text-theme-gray-800 col-span-4 md:col-span-5'>{assessmentLoading ? 'Loading...' : !assessmentLoading && assessment?.name ? assessment?.name : 'N/A'}</dd>
									</div>

									<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-6 md:gap-4'>
										<dt className='font-bold text-theme-gray-800 col-span-2 md:col-span-1'>Type:</dt>

										<dd className='text-theme-gray-800 col-span-4 md:col-span-5'>
											{assessmentLoading ? (
												'Loading...'
											) : !assessmentLoading && assessment?.type === 'Assignment' ? (
												<span className='bg-fuchsia-300 py-1 px-3 rounded-full'>Assignment</span>
											) : !assessmentLoading && assessment?.type === 'Exam' ? (
												<span className='bg-sky-300 py-1 px-3 rounded-full'>Exam</span>
											) : !assessmentLoading && assessment?.type === 'Quiz' ? (
												<span className='bg-emerald-300 py-1 px-3 rounded-full'>Quiz</span>
											) : !assessmentLoading && assessment?.type === 'Project' ? (
												<span className='bg-pink-300 py-1 px-3 rounded-full'>Project</span>
											) : !assessmentLoading && assessment?.type === 'Presentation' ? (
												<span className='bg-lime-300 py-1 px-3 rounded-full'>Presentation</span>
											) : (
												'N/A'
											)}
										</dd>
									</div>

									<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-6 md:gap-4'>
										<dt className='font-bold text-theme-gray-800 col-span-2 md:col-span-1'>Description:</dt>

										<dd className='text-theme-gray-800 col-span-4 md:col-span-5'>{assessmentLoading ? 'Loading...' : !assessmentLoading && assessment?.description ? assessment?.description : 'N/A'}</dd>
									</div>

									<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-6 md:gap-4'>
										<dt className='font-bold text-theme-gray-800 col-span-2 md:col-span-1'>Weight:</dt>

										<dd className='text-theme-gray-800 col-span-4 md:col-span-5'>{assessmentLoading ? 'Loading...' : !assessmentLoading && assessment?.grading?.weight ? parseFloat(assessment?.grading?.weight)?.toFixed(2) + '%' : 'N/A'}</dd>
									</div>

									<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-6 md:gap-4'>
										<dt className='font-bold text-theme-gray-800 col-span-2 md:col-span-1'>Total Points:</dt>

										<dd className='text-theme-gray-800 col-span-4 md:col-span-5'>{assessmentLoading ? 'Loading...' : !assessmentLoading && assessment?.grading?.totalPoints ? parseFloat(assessment?.grading?.totalPoints)?.toFixed(2) : 'N/A'}</dd>
									</div>

									<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-6 md:gap-4'>
										<dt className='font-bold text-theme-gray-800 col-span-2 md:col-span-1'>Creation Date:</dt>

										<dd className='text-theme-gray-800 col-span-4 md:col-span-5'>{assessmentLoading ? 'Loading...' : !assessmentLoading && assessment?.creationDate ? assessment?.creationDate : 'N/A'}</dd>
									</div>

									<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-6 md:gap-4'>
										<dt className='font-bold text-theme-gray-800 col-span-2 md:col-span-1'>Added By:</dt>

										<dd className='text-theme-gray-800 col-span-4 md:col-span-5'>{assessmentLoading ? 'Loading...' : !assessmentLoading && assessmentAddedBy ? assessmentAddedBy?.name?.first + ' ' + assessmentAddedBy?.name?.last : 'N/A'}</dd>
									</div>
								</dl>
							</div>
						</div>
					</div>

					<div className='col-span-3 md:col-span-1'>
						<div className='w-full bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
							<div className='mb-4 flex flex-row items-center justify-between'>
								<h3 className='text-xl font-bold text-theme-gray-800'>Class Details</h3>
							</div>

							<div className='flow-root'>
								<dl className='-my-3 divide-y divide-theme-gray-200 text-sm'>
									<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-6 md:gap-4'>
										<dt className='font-bold text-theme-gray-800 col-span-2'>Class:</dt>

										<dd className='text-theme-gray-800 col-span-4'>{subjectLoading ? 'Loading...' : !subjectLoading && classId ? <Link to={`/classes/${classId}`}>{classId}</Link> : 'N/A'}</dd>
									</div>

									<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-6 md:gap-4'>
										<dt className='font-bold text-theme-gray-800 col-span-2'>Subject:</dt>

										<dd className='text-theme-gray-800 col-span-4'>{subjectLoading ? 'Loading...' : !subjectLoading && subject?.name ? subject?.name : 'N/A'}</dd>
									</div>

									<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-6 md:gap-4'>
										<dt className='font-bold text-theme-gray-800 col-span-2'>Teacher:</dt>

										<dd className='text-theme-gray-800 col-span-4'>{classTeacherLoading ? 'Loading...' : !classTeacherLoading && classTeacher ? `${classTeacher?.name?.first} ${classTeacher?.name?.last}` : 'N/A'}</dd>
									</div>
								</dl>
							</div>
						</div>
					</div>

					<div className='col-span-3'>
						<Assessments classId={classId} assessmentId={assessmentId} other={true} />
					</div>
				</div>
			</section>
		</MainContainer>
	);
};

export default Assessment;
