import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { DataContext } from '../context';

const Footer = ({ footerRef }) => {
	const { appData } = useContext(DataContext);
	const currentYear = new Date().getFullYear();

	return (
		<footer ref={footerRef} className='select-none bg-theme-white mx-auto max-w-screen-xl px-4 py-6 sm:px-6 lg:px-8'>
			<div className='flex flex-col md:flex-row md:justify-between md:text-left'>
				<p className='w-full flex items-center justify-center md:justify-end gap-4'>
					<Link to='/terms' className='inline-block text-sm text-blue-500 hover:text-blue-500/80'>
						Terms & Conditions
					</Link>

					<span className='text-sm text-theme-gray-800'>&middot;</span>

					<Link to='/privacy' className='inline-block text-sm text-blue-500 hover:text-blue-500/80'>
						Privacy Policy
					</Link>
				</p>

				<p className='w-full flex items-center justify-center md:justify-start mt-4 text-sm text-theme-gray-800 md:order-first md:mt-0'>
					Copyright &copy; {currentYear} {appData.name} | All rights reserved.
				</p>
			</div>
		</footer>
	);
};

export default Footer;
