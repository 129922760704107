import React, { useContext, useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import { Link } from 'react-router-dom';
import { AuthContext, DataContext } from '../../context';
import { MainContainer, MenuDropdown, Table, TableBodyItem, TableHeadItem } from '../../components';
import { formatDate, generatePDF, getProperty, getGradeLevelName } from '../../utils';

const studentsTableContent = ({ student, gradeLevels, userType, currentUser }) => {
	const menuItems = [
		[
			{
				type: 'link',
				danger: false,
				to: `/students/${student?.id}/edit`,
				onClick: null,
				text: 'Edit',
				icon: (
					<>
						<path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
						<path fillRule='evenodd' d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z' />
					</>
				),
			},
		],
	];

	return (
		<>
			<TableBodyItem type='select' onClick={() => {}} />

			<TableBodyItem type='primary' contents={student?.id ? <Link to={`/students/${student?.id}`}>{student?.id}</Link> : 'N/A'} />

			<TableBodyItem type='default' contents={student?.name?.first || 'N/A'} align='left' />

			<TableBodyItem type='default' contents={student?.name?.last || 'N/A'} align='left' />

			{userType === 'admin' && <TableBodyItem type='default' contents={student?.school?.id || 'N/A'} align='left' />}

			<TableBodyItem type='default' contents={student?.dateOfBirth ? formatDate(student?.dateOfBirth) : 'N/A'} align='center' />

			<TableBodyItem type='default' contents={student?.school?.gradeLevel ? getGradeLevelName(String(student?.school?.gradeLevel), gradeLevels) : 'N/A'} align='center' />

			<TableBodyItem type='default' contents={student?.registrationDate ? formatDate(student?.registrationDate) : 'N/A'} align='center' />

			{(currentUser?.data?.userType === 'principal' || currentUser?.data?.userType === 'admin') && <TableBodyItem type='dropdown' contents={<MenuDropdown type='table' menuItems={menuItems} />} align='center' />}
		</>
	);
};

const Students = () => {
	useTitle('Students | Grade Portal');

	const { currentUser } = useContext(AuthContext);
	const { appData, users, currentSchool, schools, schoolLevelGroups, divRef, handleScroll, gradeLevels } = useContext(DataContext);

	const [students, setStudents] = useState([]);
	const [studentsLoading, setStudentsLoading] = useState(true);
	const [filterOption, setFilterOption] = useState('ALL');
	const [studentSearchQuery, setStudentSearchQuery] = useState('');

	useEffect(() => {
		const fetchStudents = async () => {
			try {
				const fetchedUsers = await users;
				const fetchedStudents = fetchedUsers?.filter((user) => user?.userType === 'student');
				let filteredStudents = [];

				if (currentUser?.data?.userType === 'admin') {
					filteredStudents = fetchedStudents?.filter((student) => (filterOption === 'ALL' ? true : student?.school?.id === filterOption));
				} else if (currentUser?.data?.userType === 'principal') {
					filteredStudents = fetchedStudents?.filter((student) => student?.school?.id === currentSchool?.id);
				} else if (currentUser?.data?.userType === 'teacher') {
					const teacherClasses = currentSchool?.classes?.filter((_class) => _class?.teacherId === currentUser?.data?.id);
					const teacherClassesIds = teacherClasses?.map((_class) => _class?.id);

					filteredStudents = fetchedStudents?.filter((student) => {
						if (student?.school?.classes) {
							if (student?.school?.id === currentSchool?.id) {
								if (teacherClassesIds?.some((id) => student?.school?.classes?.includes(id))) {
									return true;
								} else {
									return false;
								}
							} else {
								return false;
							}
						} else {
							return false;
						}
					});
				}

				if (studentSearchQuery !== null && studentSearchQuery !== undefined) {
					const trimmedQuery = studentSearchQuery?.trim().toLowerCase();

					filteredStudents = filteredStudents?.filter((student) => {
						const id = student?.id ? student?.id?.toLowerCase() : '';
						const firstName = student?.name?.first ? student?.name?.first?.toLowerCase() : '';
						const lastName = student?.name?.last ? student?.name?.last?.toLowerCase() : '';
						const emailAddress = student?.emailAddress ? student?.emailAddress?.toLowerCase() : '';

						return id?.includes(trimmedQuery) || firstName?.includes(trimmedQuery) || lastName?.includes(trimmedQuery) || emailAddress?.includes(trimmedQuery);
					});
				}

				setStudents(filteredStudents);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setStudentsLoading(false);
		};

		fetchStudents();
	}, [appData?.debugMode, currentSchool?.id, currentSchool?.classes, currentUser?.data?.id, currentUser?.data?.userType, users, filterOption, studentSearchQuery]);

	const groupedSchools = schools?.reduce((acc, school) => {
		const groupName = schoolLevelGroups[school.level] || 'Other';

		acc[groupName] = acc[groupName] || [];
		acc[groupName].push(school);

		return acc;
	}, {});

	const [sortField, setSortField] = useState(null);
	const [sortOrder, setSortOrder] = useState('asc');
	const [sortedStudents, setSortedStudents] = useState([]);

	const handleSortClick = (field) => {
		if (sortField === field) {
			setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
		} else {
			setSortField(field);
			setSortOrder('asc');
		}
	};

	useEffect(() => {
		const sortStudents = async () => {
			let sortedData = [...(students ?? [])];

			if (!studentsLoading && sortField) {
				sortedData?.sort((a, b) => {
					const valueA = getProperty(a, sortField);
					const valueB = getProperty(b, sortField);

					if (valueA && valueB) {
						const numA = Number(valueA);
						const numB = Number(valueB);

						if (!isNaN(numA) && !isNaN(numB)) {
							return sortOrder === 'asc' ? numA - numB : numB - numA;
						} else {
							if (sortOrder === 'asc') {
								return valueA?.localeCompare(valueB, undefined, {
									numeric: true,
								});
							} else {
								return valueB?.localeCompare(valueA, undefined, {
									numeric: true,
								});
							}
						}
					} else {
						return 0;
					}
				});
			}

			setSortedStudents(sortedData);
		};

		sortStudents();
	}, [students, sortField, sortOrder, studentsLoading]);

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<div className='flex flex-row items-center justify-between'>
					<div className='flex flex-row items-center justify-start gap-4'>
						<h3 className='text-lg font-bold text-theme-gray-800 sm:text-xl'>Students</h3>

						{currentUser?.data?.userType === 'admin' && (
							<select
								onChange={(e) => {
									e.preventDefault();
									setFilterOption(e.target.value);
								}}
								value={filterOption || 'ALL'}
								className='w-full rounded-lg bg-theme-white border-theme-gray-200 text-theme-gray-800 sm:text-sm'>
								<option value='ALL'>All Schools</option>

								{Object.entries(groupedSchools)?.map(([group, schools]) => (
									<optgroup label={group} key={group}>
										{schools?.map((school, idx) => (
											<option key={idx} value={school?.id}>
												{school?.name}
											</option>
										))}
									</optgroup>
								))}
							</select>
						)}

						<div className='w-full'>
							<label htmlFor='studentSearch' className='sr-only'>
								Search
							</label>

							<div className='relative'>
								<div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
									<svg className='size-4 text-theme-gray-800' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'>
										<path d='M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z' fill='none' stroke='currentColor' strokeMiterlimit='10' strokeWidth='32' />
										<path fill='none' stroke='currentColor' strokeLinecap='round' strokeMiterlimit='10' strokeWidth='32' d='M338.29 338.29L448 448' />
									</svg>
								</div>

								<input
									type='search'
									onChange={(e) => {
										e.preventDefault();
										setStudentSearchQuery(e.target.value);
									}}
									className='min-w-64 w-full ps-10 rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'
									id='studentSearch'
									placeholder='Search students'
								/>
							</div>
						</div>
					</div>

					<div className='flex flex-row items-center justify-end gap-4'>
						{(currentUser?.data?.userType === 'principal' || currentUser?.data?.userType === 'admin') && (
							<Link type='button' to='/students/new' className='inline-block shrink-0 rounded-md border border-blue-500 bg-blue-500 px-4 py-2 text-sm font-medium text-white transition-all duration-300 ease-in-out hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'>
								New Student
							</Link>
						)}

						<MenuDropdown
							type='download'
							menuItems={[
								[
									{
										type: 'button',
										danger: false,
										to: null,
										onClick: (e) => {
											e.preventDefault();
											generatePDF('Students');
										},
										text: 'Download PDF',
										icon: (
											<path
												fillRule='evenodd'
												d='M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z'
											/>
										),
									},
									{
										type: 'button',
										danger: false,
										to: null,
										onClick: null,
										text: 'Download CSV',
										icon: (
											<path
												fillRule='evenodd'
												d='M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM3.517 14.841a1.13 1.13 0 0 0 .401.823q.195.162.478.252.284.091.665.091.507 0 .859-.158.354-.158.539-.44.187-.284.187-.656 0-.336-.134-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.566-.21l-.621-.144a1 1 0 0 1-.404-.176.37.37 0 0 1-.144-.299q0-.234.185-.384.188-.152.512-.152.214 0 .37.068a.6.6 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.2-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.439 0-.776.15-.337.149-.527.421-.19.273-.19.639 0 .302.122.524.124.223.352.367.228.143.539.213l.618.144q.31.073.463.193a.39.39 0 0 1 .152.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.167.07-.413.07-.175 0-.32-.04a.8.8 0 0 1-.248-.115.58.58 0 0 1-.255-.384zM.806 13.693q0-.373.102-.633a.87.87 0 0 1 .302-.399.8.8 0 0 1 .475-.137q.225 0 .398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.4 1.4 0 0 0-.489-.272 1.8 1.8 0 0 0-.606-.097q-.534 0-.911.223-.375.222-.572.632-.195.41-.196.979v.498q0 .568.193.976.197.407.572.626.375.217.914.217.439 0 .785-.164t.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.8.8 0 0 1-.118.363.7.7 0 0 1-.272.25.9.9 0 0 1-.401.087.85.85 0 0 1-.478-.132.83.83 0 0 1-.299-.392 1.7 1.7 0 0 1-.102-.627zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879z'
											/>
										),
									},
								],
							]}
						/>
					</div>
				</div>
			</section>

			<section className='w-full bg-theme-white'>
				<Table
					name='Students'
					headContents={
						<tr>
							<TableHeadItem type='select' onClick={() => {}} />

							<TableHeadItem type='sort' title='Student ID' align='left' onClick={() => handleSortClick('id')} />

							<TableHeadItem type='sort' title='First Name' align='left' onClick={() => handleSortClick('name.first')} />

							<TableHeadItem type='sort' title='Last Name' align='left' onClick={() => handleSortClick('name.last')} />

							{currentUser?.data?.userType === 'admin' && <TableHeadItem type='sort' title='School' align='left' onClick={() => handleSortClick('school.id')} />}

							<TableHeadItem type='default' title='D.O.B' align='center' />

							<TableHeadItem type='sort' title='Grade/Form' align='center' onClick={() => handleSortClick('school.gradeLevel')} />

							<TableHeadItem type='sort' title='Registration Date' align='center' onClick={() => handleSortClick('registrationDate')} />

							{(currentUser?.data?.userType === 'principal' || currentUser?.data?.userType === 'admin') && <TableHeadItem type='dropdown' align='center' />}
						</tr>
					}
					bodyContents={({ item }) =>
						studentsTableContent({
							student: item,
							gradeLevels: gradeLevels,
							userType: currentUser?.data?.userType,
							currentUser: currentUser,
						})
					}
					sortedData={sortedStudents}
					filteredData={sortedStudents}
					dataLoading={studentsLoading}
					colSpan={currentUser?.data?.userType === 'principal' ? 8 : currentUser?.data?.userType === 'admin' ? 9 : 7}
				/>
			</section>
		</MainContainer>
	);
};

export default Students;
