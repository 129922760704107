import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { AuthContext, DataContext } from '../../context';
import { ArchiveModal, Assessments, ClassPerformanceRanking, EnlistedStudentsTable, Loader, MainContainer, RemoveModal, UnarchiveModal } from '../../components';
import { generateNotificationEmailHtml, useAlertHandling } from '../../utils';

const Class = () => {
	const { classId } = useParams();

	useTitle(`${classId || 'Class'} | Grade Portal`);

	const { currentUser } = useContext(AuthContext);
	const { appData, divRef, handleScroll, currentSchool, subjects, archiveClass, unarchiveClass, assessments, users, createNewGrade, removeStudentFromClass } = useContext(DataContext);
	const { showAlert } = useAlertHandling();

	const emailFormRef = useRef();

	const [students, setStudents] = useState(null);
	const [teacher, setTeacher] = useState(null);
	const [_class, setClass] = useState(null);

	const [studentsLoading, setStudentsLoading] = useState(true);
	const [teacherLoading, setTeacherLoading] = useState(true);
	const [classLoading, setClassLoading] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const filteredStudents = await users?.filter((user) => user?.userType === 'student' && user?.school?.id === currentSchool?.id && user?.school?.classes?.some((_class) => _class === classId));
				const teacher = await users?.find((user) => user?.id === currentSchool?.classes?.find((_class) => _class?.id === classId)?.teacherId);
				const __class = await currentSchool?.classes?.find((_class) => _class?.id === classId);

				setStudents(filteredStudents);
				setTeacher(teacher);
				setClass(__class);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setStudentsLoading(false);
			setTeacherLoading(false);
			setClassLoading(false);
		};

		fetchData();
	}, [currentSchool?.id, users, appData?.debugMode, classId, currentSchool?.classes]);

	const [classAssessments, setClassAssessments] = useState(null);
	const [classAssessmentsLoading, setClassAssessmentsLoading] = useState(true);

	useEffect(() => {
		const fetchAssessments = async () => {
			try {
				const _classAssessments = await assessments?.filter((assessment) => assessment?.classId === classId);
				setClassAssessments(_classAssessments);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setClassAssessmentsLoading(false);
		};

		fetchAssessments();
	}, [appData?.debugMode, assessments, classId]);

	const [canAccessClass, setCanAccessClass] = useState(false);
	const [subject, setSubject] = useState(null);

	useEffect(() => {
		let filteredClasses = [];

		if (currentUser?.data?.userType === 'teacher') {
			filteredClasses = currentSchool?.classes?.filter((_class) => _class?.teacherId === currentUser?.data?.id);
		} else if (currentUser?.data?.userType === 'principal' && currentUser?.data?.school?.id === currentSchool?.id) {
			filteredClasses = currentSchool?.classes;
		} else if (currentUser?.data?.userType === 'student') {
			filteredClasses = currentSchool?.classes?.filter((_class) => {
				return currentUser?.data?.school?.classes?.includes(_class?.id);
			});
		}

		const foundClass = filteredClasses?.find((_class) => _class?.id === classId);

		const fetchSubject = async (foundClass) => {
			try {
				const foundSubject = subjects?.find((subject) => subject?.id === foundClass?.subjectId);
				setSubject(foundSubject);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}
		};

		if (foundClass) {
			fetchSubject(foundClass);
			setCanAccessClass(true);
		} else {
			setCanAccessClass(false);
		}
	}, [appData?.debugMode, classId, currentSchool?.classes, currentSchool?.id, currentUser?.data?.id, currentUser?.data?.school?.classes, currentUser?.data?.school?.id, currentUser?.data?.userType, subjects]);

	const [archiveClassId, setArchiveClassId] = useState(null);
	const [archiveClassModalState, setArchiveClassModalState] = useState(false);

	const handleArchiveClass = async (e) => {
		e.preventDefault();

		try {
			if (currentSchool?.id && archiveClassId) {
				await archiveClass(currentSchool?.id, archiveClassId);
			} else {
				if (appData.debugMode) {
					console.error("School ID and Class ID can't be empty.");
				}

				showAlert('error', 'An unknown error occurred');
			}
		} catch (error) {
			if (appData.debugMode) {
				console.error(error);
			}

			showAlert('error', 'An unknown error occurred');
		}

		setArchiveClassModalState(false);
	};

	const [unarchiveClassId, setUnarchiveClassId] = useState(null);
	const [unarchiveClassModalState, setUnarchiveClassModalState] = useState(false);

	const handleUnarchiveClass = async (e) => {
		e.preventDefault();

		try {
			if (currentSchool?.id && unarchiveClassId) {
				await unarchiveClass(currentSchool?.id, unarchiveClassId);
			} else {
				if (appData.debugMode) {
					console.error("School ID and Class ID can't be empty.");
				}

				showAlert('error', 'An unknown error occurred');
			}
		} catch (error) {
			if (appData.debugMode) {
				console.error(error);
			}

			showAlert('error', 'An unknown error occurred');
		}

		setUnarchiveClassModalState(false);
	};

	const [removeStudentId, setRemoveStudentId] = useState(null);
	const [removeStudentModalState, setRemoveStudentModalState] = useState(false);

	const handleRemoveStudent = async (studentId) => {
		try {
			await removeStudentFromClass(studentId, classId);
		} catch (error) {
			if (appData?.debugMode) {
				console.error(error);
			}

			showAlert('error', 'An unknown error occurred');
		}
	};

	//

	const [emailSubject, setEmailSubject] = useState(null);
	const [emailMessage, setEmailMessage] = useState(null);
	const [sendClassEmailLoading, setSendClassEmailLoading] = useState(false);
	const [timeOfDay, setTimeOfDay] = useState('');

	useEffect(() => {
		const currentTime = new Date().getHours();

		if (currentTime >= 5 && currentTime < 12) {
			setTimeOfDay('morning');
		} else if (currentTime >= 12 && currentTime < 17) {
			setTimeOfDay('afternoon');
		} else if (currentTime >= 17 && currentTime < 20) {
			setTimeOfDay('evening');
		} else {
			setTimeOfDay('night');
		}
	}, []);

	const handleSendClassEmail = async (e) => {
		e.preventDefault();
		setSendClassEmailLoading(true);

		try {
			const emailList = students?.map((student) => student?.emailAddress);
			const studentName = students?.map((student) => student?.name?.first + ' ' + student?.name?.last);
			const teacherEmail = teacher?.emailAddress;

			const emailPromises = emailList?.map(async (email, idx) => {
				const name = studentName[idx];

				try {
					const response = await fetch('https://us-central1-grade-portal-e6edb.cloudfunctions.net/sendEmail', {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							to: email,
							subject: `New Notification - ${emailSubject} | Grade Portal`,
							text: emailMessage,
							html: generateNotificationEmailHtml(`New Notification - ${emailSubject} | Grade Portal`, emailMessage, timeOfDay, name, teacherEmail),
						}),
					});

					if (!response.ok) {
						if (appData?.debugMode) {
							console.error(`Error sending email to ${email}`);
						}

						throw new Error(`Error sending email to ${email}`);
					}
				} catch (err) {
					if (appData?.debugMode) {
						console.error(err);
					}

					throw err;
				}
			});

			await Promise.all(emailPromises);
			showAlert('success', 'Email sent successfully');
		} catch (err) {
			if (appData?.debugMode) {
				console.error(err);
			}

			showAlert('error', 'An unknown error occurred');
		}

		emailFormRef.current.reset();
		setSendClassEmailLoading(false);
	};

	//

	if (!canAccessClass) {
		return (
			<MainContainer divRef={divRef} handleScroll={handleScroll}>
				<section className='bg-theme-white py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6'>
					<div className='mx-auto max-w-screen-sm text-center'>
						<h1 className='mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-theme-gray-800'>404</h1>

						<p className='mb-4 text-3xl tracking-tight font-bold text-blue-500 md:text-4xl'>Oops! Class Not Found</p>

						<p className='mb-8 text-lg text-theme-gray-600'>
							It seems that the class you're trying to access isn't available or authorized for your account. Don't worry, you can explore your classes{' '}
							<Link to='/classes' className='focus:outline-none focus:ring text-blue-500 hover:opacity-80 active:opacity-80'>
								here
							</Link>
							.
						</p>

						<Link to='/' className='inline-block shrink-0 rounded-md border px-5 py-2.5 text-sm font-medium focus:outline-none focus:ring text-white border-blue-500 bg-blue-500 hover:bg-transparent hover:text-blue-500 active:text-blue-500'>
							Back to Homepage
						</Link>
					</div>
				</section>
			</MainContainer>
		);
	}

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<h3 className='text-2xl font-bold text-theme-gray-800'>{subject && classId ? `${classId} - ${subject?.name}` : 'Class'}</h3>
			</section>

			<section className='w-full bg-theme-white'>
				<div className='grid grid-cols-3 gap-6'>
					<div className='col-span-3 lg:col-span-2 flex flex-col items-start gap-6'>
						<div className='w-full bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
							<div className='mb-4 flex flex-row items-center justify-between'>
								<h3 className='text-xl font-bold text-theme-gray-800'>Class Details</h3>

								{currentUser?.data?.userType === 'principal' && (
									<div className='flex flex-row items-center justify-end gap-4'>
										{!classLoading && _class?.status?.toLowerCase() === 'active' && (
											<Link type='button' to={`/classes/${classId}/edit`} className='inline-block shrink-0 rounded-md border border-blue-500 bg-blue-500 px-4 py-2 text-sm font-medium text-white transition-all duration-300 ease-in-out hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'>
												Edit
											</Link>
										)}

										{!classLoading && _class?.status?.toLowerCase() === 'active' ? (
											<button
												type='button'
												onClick={(e) => {
													e.preventDefault();
													setArchiveClassModalState(true);
													setArchiveClassId(classId);
												}}
												className='inline-block shrink-0 rounded-md border border-gray-500 bg-gray-500 px-4 py-2 text-sm font-medium text-white transition-all duration-300 ease-in-out ring-gray-300 hover:bg-transparent hover:text-gray-500 focus:outline-none focus:ring active:text-gray-500'>
												Archive
											</button>
										) : !classLoading && _class?.status?.toLowerCase() !== 'active' ? (
											<button
												type='button'
												onClick={(e) => {
													e.preventDefault();
													setUnarchiveClassModalState(true);
													setUnarchiveClassId(classId);
												}}
												className='inline-block shrink-0 rounded-md border border-gray-500 bg-gray-500 px-4 py-2 text-sm font-medium text-white transition-all duration-300 ease-in-out ring-gray-300 hover:bg-transparent hover:text-gray-500 focus:outline-none focus:ring active:text-gray-500'>
												Unarchive
											</button>
										) : null}
									</div>
								)}
							</div>

							<div className='flow-root'>
								<dl className='-my-3 divide-y divide-theme-gray-200 text-sm'>
									<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-6 md:gap-4'>
										<dt className='font-bold text-theme-gray-800 col-span-2 md:col-span-1'>Subject:</dt>

										<dd className='text-theme-gray-800 col-span-4 md:col-span-5'>{!subject?.name ? 'Loading...' : subject?.name}</dd>
									</div>

									<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-6 md:gap-4'>
										<dt className='font-bold text-theme-gray-800 col-span-2 md:col-span-1'>Description:</dt>

										<dd className='text-theme-gray-800 col-span-4 md:col-span-5'>{!subject?.desc ? 'Loading...' : subject?.desc}</dd>
									</div>
								</dl>
							</div>
						</div>

						{currentUser?.data?.userType !== 'student' && <ClassPerformanceRanking classId={classId} />}
					</div>

					<div className='col-span-3 lg:col-span-1 flex flex-col items-start gap-6'>
						{(currentUser?.data?.userType === 'principal' || currentUser?.data?.userType === 'student') && (
							<div className='w-full bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
								<div className='mb-4 flex flex-row items-center justify-between'>
									<h3 className='text-xl font-bold text-theme-gray-800'>Teacher Details</h3>

									<div className='flex flex-row items-center justify-end gap-4'>
										{currentUser?.data?.userType !== 'student' && !classLoading && _class?.status?.toLowerCase() === 'active' && (
											<Link type='button' to={`/classes/${classId}/edit`} className='inline-block shrink-0 rounded-md border border-blue-500 bg-blue-500 px-4 py-2 text-sm font-medium text-white transition-all duration-300 ease-in-out hover:bg-transparent hover:text-blue-500 focus:outline-none focus:ring active:text-blue-500'>
												Reassign
											</Link>
										)}
									</div>
								</div>

								<div className='flow-root'>
									<dl className='-my-3 divide-y divide-theme-gray-200 text-sm'>
										<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-6 md:gap-4'>
											<dt className='font-bold text-theme-gray-800 col-span-6 sm:col-span-1'>ID: </dt>

											<dd className='text-theme-gray-800 col-span-6 sm:col-span-5'>&nbsp;{teacherLoading ? 'Loading...' : !teacherLoading && teacher?.id ? teacher?.id : 'N/A'}</dd>
										</div>

										<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-6 lg:gap-4'>
											<dt className='font-bold text-theme-gray-800 col-span-6 sm:col-span-1'>Name:</dt>

											<dd className='text-theme-gray-800 col-span-6 sm:col-span-5'>&nbsp;{teacherLoading ? 'Loading...' : !teacherLoading && teacher?.name ? teacher?.name?.first + ' ' + teacher?.name?.last : 'N/A'}</dd>
										</div>

										<div className='grid grid-cols-1 gap-1 py-3 md:grid-cols-6 md:gap-4'>
											<dt className='font-bold text-theme-gray-800 col-span-6 sm:col-span-1'>Email:</dt>

											<dd className='text-theme-gray-800 col-span-6 sm:col-span-5'>&nbsp;{teacherLoading ? 'Loading...' : !teacherLoading && teacher?.emailAddress ? teacher?.emailAddress : 'N/A'}</dd>
										</div>
									</dl>
								</div>
							</div>
						)}

						{currentUser?.data?.userType === 'teacher' && (
							<div className='bg-theme-white rounded-lg border border-theme-gray-200 p-8 shadow-sm'>
								<div className='mb-4 flex flex-row items-center justify-between'>
									<h3 className='text-xl font-bold text-theme-gray-800'>Send Class Email</h3>
								</div>

								<form ref={emailFormRef} onSubmit={handleSendClassEmail} className='grid grid-cols-12 gap-4 mt-4'>
									<div className='w-full col-span-12'>
										<label htmlFor='subject' className='block text-sm font-medium text-theme-gray-800'>
											Subject
											<span className='text-red-500 ml-1'>*</span>
										</label>

										<input
											type='text'
											onChange={(e) => {
												setEmailSubject(e.target.value);
											}}
											className='mt-1 w-full rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'
											name='subject'
											required
										/>
									</div>

									<div className='w-full col-span-12'>
										<label htmlFor='message' className='block text-sm font-medium text-theme-gray-800'>
											Message
											<span className='text-red-500 ml-1'>*</span>
										</label>

										<textarea
											onChange={(e) => {
												setEmailMessage(e.target.value);
											}}
											className='mt-1 w-full rounded-md border border-theme-gray-200 text-theme-gray-800 bg-theme-white text-sm shadow-sm focus:outline-none'
											name='message'
											cols='30'
											rows='10'
											required></textarea>
									</div>

									<button
										type='submit'
										className='inline-block shrink-0 rounded-md border text-sm font-medium transition-all duration-300 ease-in-out focus:outline-none focus:ring border-blue-500 bg-blue-500 text-white hover:bg-transparent hover:text-blue-500 active:text-blue-500'
										style={{
											width: '12rem',
											height: '2.5rem',
											textAlign: 'center',
										}}>
										{sendClassEmailLoading ? <Loader /> : 'Send Email'}
									</button>
								</form>
							</div>
						)}
					</div>

					{(currentUser?.data?.userType === 'teacher' || currentUser?.data?.userType === 'student') && (
						<div className='col-span-3'>
							<Assessments classId={classId} assessmentId={null} other={false} student={true} />
						</div>
					)}

					{currentUser?.data?.userType !== 'student' && (
						<div className='col-span-3'>
							{!studentsLoading && !classAssessmentsLoading && (
								<EnlistedStudentsTable
									appData={appData}
									classId={classId}
									users={users}
									currentUser={currentUser}
									currentSchool={currentSchool}
									assessments={classAssessments}
									sortedAssessments={classAssessments}
									setRemoveStudentModalState={setRemoveStudentModalState}
									setRemoveStudentId={setRemoveStudentId}
									showAlert={showAlert}
									createNewGrade={createNewGrade}
									archived={!classLoading && _class?.status?.toLowerCase() !== 'active'}
								/>
							)}
						</div>
					)}
				</div>
			</section>

			<RemoveModal title='Student' removeId={removeStudentId} handleRemove={handleRemoveStudent} removeModalState={removeStudentModalState} setRemoveModalState={setRemoveStudentModalState} />
			<ArchiveModal title='Class' archiveId={archiveClassId} handleArchive={handleArchiveClass} archiveModalState={archiveClassModalState} setArchiveModalState={setArchiveClassModalState} />
			<UnarchiveModal title='Class' unarchiveId={unarchiveClassId} handleUnarchive={handleUnarchiveClass} unarchiveModalState={unarchiveClassModalState} setUnarchiveModalState={setUnarchiveClassModalState} />
		</MainContainer>
	);
};

export default Class;
