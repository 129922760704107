import React from 'react';

const TableBodyItem = ({ type, idx, contents, align, onClick, checked, onChange }) => {
	if (type === 'select') {
		return (
			<td className='px-4 py-2' data-html2canvas-ignore='true'>
				<label htmlFor={`row${idx}`} className='sr-only'>
					Row {idx}
				</label>

				<input type='checkbox' checked={checked} onChange={onChange} onClick={onClick} id={`row${idx}`} className='size-5 rounded bg-theme-white border-theme-gray-400' />
			</td>
		);
	} else if (type === 'default') {
		return <td className={`whitespace-nowrap px-4 py-2 ${align === 'left' ? 'text-left' : align === 'center' ? 'text-center' : null} text-theme-gray-600`}>{contents}</td>;
	} else if (type === 'primary') {
		return <td className={`whitespace-nowrap px-4 py-2 ${align === 'left' ? 'text-left' : align === 'center' ? 'text-center' : null} text-blue-500 font-bold`}>{contents}</td>;
	} else if (type === 'dropdown') {
		return (
			<td className='whitespace-nowrap px-4 py-2 text-center' data-html2canvas-ignore='true'>
				{contents}
			</td>
		);
	}
};

export default TableBodyItem;
