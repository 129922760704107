import React, { useContext, useEffect, useState } from 'react';
import { useTitle } from 'react-use';
import { AuthContext, DataContext } from '../../context';
import { MainContainer } from '../../components';
import { calculateGPAFromPercentage, generateUserGrades } from '../../utils';

const PrincipalReports = () => {
	useTitle('Reports | Grade Portal');

	const { currentUser } = useContext(AuthContext);
	const { appData, divRef, users, currentSchool, handleScroll, subjects, assessments } = useContext(DataContext);

	const [classes, setClasses] = useState(null);
	const [students, setStudents] = useState(null);

	const [classesLoading, setClassesLoading] = useState(true);
	const [studentsLoading, setStudentsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const _students = await users?.filter(async (user) => {
					if (user?.userType !== 'student') {
						return false;
					}

					const schoolClassIds = await currentSchool?.classes?.map((_class) => _class?.id);

					return user?.school?.classes?.some((classId) => schoolClassIds?.includes(classId));
				});

				setClasses(currentSchool?.classes);
				setStudents(_students);
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}

			setClassesLoading(false);
			setStudentsLoading(false);
		};

		fetchData();
	}, [appData?.debugMode, currentSchool?.classes, currentUser?.data?.id, users]);

	const [studentGrades, setStudentGrades] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (!classesLoading && classes?.length > 0) {
					if (!studentsLoading && students?.length > 0) {
						const fetchedGrades = [];

						for (const _class of classes) {
							for (const student of students) {
								const studentInClass = student?.school?.classes?.some((classId) => classId === _class?.id);

								if (studentInClass) {
									const studentWithData = {
										data: student,
									};

									const classAssessments = await assessments?.filter((assessment) => assessment?.classId === _class?.id);
									const currentStudentGrades = await generateUserGrades(currentSchool, studentWithData, classAssessments, null, _class?.id);

									const currentStudentTotalPercentage = currentStudentGrades?.reduce((accumulator, currentGrade) => {
										return accumulator + parseInt(currentGrade?.grade);
									}, 0);

									const calculatedStudentTotalAverage = currentStudentTotalPercentage / currentStudentGrades?.length;

									fetchedGrades?.push({
										studentId: student?.id,
										classId: _class?.id,
										average: calculatedStudentTotalAverage,
									});
								}
							}
						}

						setStudentGrades(fetchedGrades);
					}
				}
			} catch (err) {
				if (appData?.debugMode) {
					console.error(err);
				}
			}
		};

		fetchData();
	}, [appData?.debugMode, assessments, classes, currentSchool, students, classesLoading, studentsLoading]);

	const [classAveragePerformanceData, setClassAveragePerformanceData] = useState(null);
	const [classAveragePerformanceDataLoading, setClassAveragePerformanceDataLoading] = useState(true);

	useEffect(() => {
		let _classAveragePerformanceData = [];

		if (!classesLoading && !studentsLoading && studentGrades) {
			for (const studentGrade of studentGrades) {
				let { classId, average } = studentGrade;

				if (_classAveragePerformanceData.hasOwnProperty(classId)) {
					average = parseFloat(average);

					_classAveragePerformanceData[classId].total += average;
					_classAveragePerformanceData[classId]?.grades?.push(average);
				} else {
					average = parseFloat(average);

					_classAveragePerformanceData[classId] = {
						total: average,
						grades: [average],
					};
				}
			}

			for (const classId in _classAveragePerformanceData) {
				const totalAverage = _classAveragePerformanceData[classId]?.total || 0;
				const grades = _classAveragePerformanceData[classId]?.grades || [];

				const validGrades = grades?.filter((grade) => typeof grade === 'number' && !isNaN(grade) && grade > 0);

				const numberOfStudents = validGrades?.length;
				const classAverage = numberOfStudents > 0 ? totalAverage / numberOfStudents : 0;

				const lowestGrade = validGrades?.length > 0 ? Math.min(...validGrades) : 0;
				const highestGrade = validGrades?.length > 0 ? Math.max(...validGrades) : 0;

				_classAveragePerformanceData[classId] = {
					classId: classId,
					classAverage: classAverage,
					lowestAverage: lowestGrade,
					highestAverage: highestGrade,
				};
			}

			setClassAveragePerformanceData(_classAveragePerformanceData);
			setClassAveragePerformanceDataLoading(false);
		}
	}, [studentGrades, classes, subjects, classesLoading, studentsLoading]);

	const [studentAveragePerformanceData, setStudentAveragePerformanceData] = useState(null);
	const [studentAveragePerformanceDataLoading, setStudentAveragePerformanceDataLoading] = useState(true);

	useEffect(() => {
		const studentAveragesMap = new Map();

		if (!classesLoading && !studentsLoading && studentGrades) {
			studentGrades?.forEach((grade) => {
				let { studentId, classId, average } = grade;

				const validAverage = typeof average === 'number' && !isNaN(average) && average > 0;

				if (studentAveragesMap?.has(studentId)) {
					const currentAverages = studentAveragesMap?.get(studentId);
					const newAverage = (currentAverages * currentAverages?.length + average) / (currentAverages?.length + 1);

					if (validAverage) {
						studentAveragesMap?.set(studentId, {
							average: newAverage,
							classId,
						});
					}
				} else {
					if (validAverage) {
						average = parseFloat(average);

						studentAveragesMap?.set(studentId, {
							average,
							classId,
						});
					}
				}
			});

			const studentAverages = Array.from(studentAveragesMap, ([studentId, { average, classId }]) => ({
				studentId,
				average,
				classId,
			}));

			setStudentAveragePerformanceData(studentAverages);
			setStudentAveragePerformanceDataLoading(false);
		}
	}, [classesLoading, studentsLoading, studentGrades]);

	return (
		<MainContainer divRef={divRef} handleScroll={handleScroll}>
			<section className='w-full bg-theme-white'>
				<div className='flex flex-row items-center justify-between'>
					<div className='flex flex-row items-center justify-start gap-4'>
						<h3 className='text-lg font-bold text-theme-gray-800 sm:text-xl'>School Performance Report</h3>
					</div>
				</div>
			</section>

			<section className='w-full bg-theme-white'>
				<h3 className='text-base mb-2 font-bold text-theme-gray-800 sm:text-lg'>Class Performance</h3>

				<div className='w-full rounded-lg border border-theme-gray-200'>
					<div className='rounded-t-lg overflow-x-auto'>
						<table className='min-w-full rounded-lg divide-y-2 divide-theme-gray-200 bg-theme-white text-sm'>
							<thead className='w-full'>
								<th className='whitespace-nowrap px-4 py-2 font-medium text-left text-theme-gray-800'>Class ID</th>

								<th className='whitespace-nowrap px-4 py-2 font-medium text-left text-theme-gray-800'>Subject</th>

								<th className='whitespace-nowrap px-4 py-2 font-medium text-left text-theme-gray-800'>Teacher</th>

								<th className='whitespace-nowrap px-4 py-2 font-medium text-center text-theme-gray-800'>Status</th>

								<th className='whitespace-nowrap px-4 py-2 font-medium text-center text-theme-gray-800'>Class Average (%)</th>

								<th className='whitespace-nowrap px-4 py-2 font-medium text-center text-theme-gray-800'>Lowest Average (%)</th>

								<th className='whitespace-nowrap px-4 py-2 font-medium text-center text-theme-gray-800'>Highest Average (%)</th>
							</thead>

							<tbody className='w-full divide-y divide-theme-gray-200'>
								{!classAveragePerformanceDataLoading && classAveragePerformanceData && Object.keys(classAveragePerformanceData)?.length > 0 ? (
									Object.keys(classAveragePerformanceData)?.map((classId, idx) => {
										const classData = classAveragePerformanceData[classId];
										const classInfo = classes?.find((_class) => _class?.id === classId) || {};
										const subjectName = subjects?.find((subject) => subject?.id === classInfo?.subjectId)?.name || 'N/A';
										const teacher = users?.find((teacher) => teacher?.userType === 'teacher' && teacher?.id === classInfo?.teacherId) || {};
										const teacherName = teacher?.name ? teacher?.name?.first + ' ' + teacher?.name?.last : 'N/A';

										return (
											<tr key={idx} className='odd:bg-theme-gray-50'>
												<td className='whitespace-nowrap px-4 py-2 text-left text-theme-gray-600'>{classId}</td>

												<td className='whitespace-nowrap px-4 py-2 text-left text-theme-gray-600'>{subjectName}</td>

												<td className='whitespace-nowrap px-4 py-2 text-left text-theme-gray-600'>{teacherName}</td>

												<td className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>
													{classData?.classAverage && classData?.classAverage >= currentSchool?.passMark ? (
														<span className='rounded-full px-2.5 py-0.5 text-xs bg-green-600 text-white uppercase'>Pass</span>
													) : classData?.classAverage && classData?.classAverage < currentSchool?.passMark ? (
														<span className='rounded-full px-2.5 py-0.5 text-xs bg-red-600 text-white uppercase'>Fail</span>
													) : (
														<span className='rounded-full px-2.5 py-0.5 text-xs bg-gray-600 text-white uppercase'>Unassigned</span>
													)}
												</td>

												<td className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>{classData?.classAverage ? classData?.classAverage?.toFixed(2) + '%' : 'N/A'}</td>

												<td className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>{classData?.lowestAverage ? classData?.lowestAverage.toFixed(2) + '%' : 'N/A'}</td>

												<td className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>{classData?.highestAverage ? classData?.highestAverage.toFixed(2) + '%' : 'N/A'}</td>
											</tr>
										);
									})
								) : (
									<tr>
										<td colSpan='7' className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>
											No data available
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</section>

			<section className='w-full bg-theme-white'>
				<h3 className='text-base mb-2 font-bold text-theme-gray-800 sm:text-lg'>Student Performance</h3>

				<div className='w-full rounded-lg border border-theme-gray-200'>
					<div className='rounded-t-lg overflow-x-auto'>
						<table className='min-w-full rounded-lg divide-y-2 divide-theme-gray-200 bg-theme-white text-sm'>
							<thead className='w-full'>
								<th className='whitespace-nowrap px-4 py-2 font-medium text-left text-theme-gray-800'>Student ID</th>

								<th className='whitespace-nowrap px-4 py-2 font-medium text-left text-theme-gray-800'>First Name</th>

								<th className='whitespace-nowrap px-4 py-2 font-medium text-left text-theme-gray-800'>Last Name</th>

								<th className='whitespace-nowrap px-4 py-2 font-medium text-center text-theme-gray-800'>Sex</th>

								<th className='whitespace-nowrap px-4 py-2 font-medium text-center text-theme-gray-800'>Status</th>

								<th className='whitespace-nowrap px-4 py-2 font-medium text-center text-theme-gray-800'>Average (%)</th>

								<th className='whitespace-nowrap px-4 py-2 font-medium text-center text-theme-gray-800'>Average (GPA)</th>
							</thead>

							<tbody className='w-full divide-y divide-theme-gray-200'>
								{!studentAveragePerformanceDataLoading && studentAveragePerformanceData && studentAveragePerformanceData?.length > 0 ? (
									Object.keys(studentAveragePerformanceData)?.map((studentId, idx) => {
										const studentGradeData = studentAveragePerformanceData[studentId];
										const studentInfo = users?.find((user) => user?.userType === 'student' && user?.id === studentGradeData?.studentId) || {};

										return (
											<tr key={idx} className='odd:bg-theme-gray-50'>
												<td className='whitespace-nowrap px-4 py-2 text-left text-theme-gray-600'>{studentGradeData?.studentId}</td>

												<td className='whitespace-nowrap px-4 py-2 text-left text-theme-gray-600'>{studentInfo?.name?.first || 'N/A'}</td>

												<td className='whitespace-nowrap px-4 py-2 text-left text-theme-gray-600'>{studentInfo?.name?.last || 'N/A'}</td>

												<td className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>{studentInfo?.sex || 'N/A'}</td>

												<td className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>
													{studentGradeData?.average >= currentSchool?.passMark ? (
														<span className='rounded-full px-2.5 py-0.5 text-xs bg-green-600 text-white uppercase'>Pass</span>
													) : studentGradeData?.average < currentSchool?.passMark ? (
														<span className='rounded-full px-2.5 py-0.5 text-xs bg-red-600 text-white uppercase'>Fail</span>
													) : (
														<span className='rounded-full px-2.5 py-0.5 text-xs bg-gray-600 text-white uppercase'>Unassigned</span>
													)}
												</td>

												<td className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>{studentGradeData?.average ? studentGradeData?.average?.toFixed(2) + '%' : 'N/A'}</td>

												<td className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>{studentGradeData?.average ? calculateGPAFromPercentage(studentGradeData?.average) : 'N/A'}</td>
											</tr>
										);
									})
								) : (
									<tr>
										<td colSpan='7' className='whitespace-nowrap px-4 py-2 text-center text-theme-gray-600'>
											No data available
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</section>
		</MainContainer>
	);
};

export default PrincipalReports;
