import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
	apiKey: 'AIzaSyCX6ubAVH_DsFRtIRZI9GN81MJddXevdFs',
	authDomain: 'grade-portal-e6edb.firebaseapp.com',
	projectId: 'grade-portal-e6edb',
	storageBucket: 'grade-portal-e6edb.appspot.com',
	messagingSenderId: '1064721555465',
	appId: '1:1064721555465:web:9b5d6b1615635d23b918d8',
	measurementId: 'G-Q3D9P3J6JN',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);

export { app, auth, db, storage, functions };
